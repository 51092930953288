<template>
  <v-combobox
    ref="vInput"
    v-bind="{
      ...$attrs,
      ...$props,
      label: _label,
      hideDetails: _hideDetails,
    }"
    v-on="$listeners"
    @change="onChange"
    @input="onChange"
  >
    <template v-for="(_, name) in $scopedSlots" #[name]="render">
      <slot :name="name" v-bind="render"></slot>
    </template>

    <!-- static slots definition -->
    <template v-if="description !== undefined" #label>
      <icon-tooltip :content="$t(label)" :description="$t(description)" />
    </template>
    <template v-else #label>
      <slot name="label"></slot>
    </template>
  </v-combobox>
</template>
<script>
import IconTooltip from '@/components/DesignSystem/OTooltip/IconTooltip.vue';
import baseInput from '@/components/DesignSystem/OInput/base_input_script';

export default {
  name: 'OCombobox',
  components: { IconTooltip },
  props: {
    ...baseInput.props,
    chips: {
      type: Boolean,
      default: true,
    },
    deletableChips: {
      type: Boolean,
      default: true,
    },
    smallChips: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['input'],
  computed: {
    ...baseInput.computed,
    internalSearch() {
      return this.$refs.vInput.internalSearch;
    },
  },
  methods: {
    ...baseInput.methods,
    onChange(event) {
      this.$emit('input', event);
      this.$emit('change', event);
    },
  },
};
</script>

<style scoped></style>
