<template>
  <o-row>
    <o-col cols="12">
      <o-data-table :headers="headers" :items="getAllCurrencies">
        <template #top>
          <o-row><o-divider /></o-row>
          <o-row><o-card-title>Opti Yield Currencies</o-card-title></o-row>
          <o-row class="justify-end mb-10">
            <o-col cols="2" class="text-center">
              <o-btn color="primary" @click="editPublisher({})">
                Add new currency <o-icon dark right> add_box </o-icon>
              </o-btn>
            </o-col>
          </o-row>
        </template>
        <template #item.action="{ item }">
          <div style="white-space: nowrap">
            <o-tooltip bottom>
              <template #activator="{ on }">
                <o-btn
                  depressed
                  icon
                  target="_blank"
                  v-on="on"
                  @click="editPublisher(item)"
                >
                  <o-icon small color="blue"> edit </o-icon>
                </o-btn>
              </template>
              <span>Edit</span>
            </o-tooltip>
          </div>
        </template>
      </o-data-table>
      <price-floor-settings-dialog
        ref="pf_currency_dialog"
        :edited-item="editedItem"
        :edited-index="editedIndex"
      />
    </o-col>
  </o-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { filterMixin } from '../../../helpers/filterMixin';
import PriceFloorSettingsDialog from './PriceFloorSettingsDialog';

export default {
  name: 'PriceFloorSettings',
  components: {
    PriceFloorSettingsDialog,
  },
  mixins: [filterMixin],
  data() {
    return {
      options: {},
      readOnly: false,
      headers: [
        {
          text: 'ID',
          value: 'id',
          sortable: false,
          align: 'left',
        },
        {
          text: 'Code',
          value: 'code',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Name',
          value: 'name',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Action',
          value: 'action',
          sortable: false,
          align: 'center',
        },
      ],
      editedIndex: -1,
      editedItem: [],
      confirmationDialog: false,
    };
  },
  computed: {
    ...mapGetters('priceFloorSettings', ['getAllCurrencies']),
  },
  mounted() {
    this.fetchAllCurrencies();
  },
  methods: {
    ...mapActions('priceFloorSettings', ['fetchAllCurrencies']),
    linkColor(param = false) {
      if (param) return 'blue';
      return 'grey';
    },
    editPublisher(item) {
      if (
        item &&
        Object.keys(item).length === 0 &&
        Object.getPrototypeOf(item) === Object.prototype
      ) {
        this.editedIndex = -1;
      } else {
        this.editedIndex = 1;
      }
      this.editedItem = { ...item };
      this.$refs.pf_currency_dialog.readOnly = false;
      this.$refs.pf_currency_dialog.show = true;
    },
    onArchive() {
      this.confirmationDialog = true;
    },
    confirmArchive() {
      this.confirmationDialog = false;
    },
    closeArchive() {
      this.confirmationDialog = false;
    },
  },
};
</script>

<style scoped></style>
