import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VOverlay,_vm._g(_vm._b({on:{"change":function($event){return _vm.$emit('input', $event)}}},'v-overlay',{
      ..._vm.$attrs,
      ..._vm.$props,
    },false),_vm.$listeners),[_c(VProgressCircular,{attrs:{"size":"64","indeterminate":"","color":"primary"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }