<template>
  <o-row>
    <o-col cols="12">
      <o-row>
        <o-card-title>{{ $tc('publishers.publisher', 2) }}</o-card-title>
      </o-row>
      <publishers-table />
    </o-col>
  </o-row>
</template>

<script>
import PublishersTable from '../../components/Accounting/Publishers/PublishersTable.vue';

export default {
  name: 'Publishers',
  components: { PublishersTable },
};
</script>

<style scoped></style>
