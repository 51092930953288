import { VDataTable } from 'vuetify/lib/components/VDataTable';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDataTable,_vm._g(_vm._b({scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,name){return {key:name,fn:function(render){return [_vm._t(name,null,null,render)]}}}),(_vm.hideDefaultHeader)?{key:"header",fn:function({
        props: {
          headers,
          options: { sortBy, sortDesc },
        },
        on: { sort },
      }){return [_c('o-data-table-header',{attrs:{"headers":headers,"sort-desc":sortDesc,"sort-by":sortBy,"sort":sort}})]}}:null],null,true)},'v-data-table',{
      ..._vm.$attrs,
      ..._vm.$props,
      ..._vm.footerPropsInjector,
    },false),_vm.$listeners))
}
var staticRenderFns = []

export { render, staticRenderFns }