<template>
  <v-stepper-step
    v-bind="{
      ...$attrs,
      ...$props,
    }"
    v-on="$listeners"
  >
    <template v-for="(_, name) in $scopedSlots" #[name]="render">
      <slot :name="name" v-bind="render"></slot>
    </template>
    <slot></slot>
  </v-stepper-step>
</template>
<script>
export default {
  name: 'OStepperStep',
};
</script>

<style scoped></style>
