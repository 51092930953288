<template>
  <o-row>
    <o-col cols="12">
      <o-row>
        <o-card-title>Audit Log</o-card-title>
      </o-row>
      <o-row align="center" justify="space-around">
        <o-col cols="4">
          <o-row>
            <o-autocomplete
              v-model="searchUser"
              label="User email"
              :items="getUsersEmails"
              hide-no-data
              :loading="getLoading"
              @change="
                () => {
                  setSearchDataAction({
                    searchAction: searchAction,
                    searchUser: searchUser,
                  });
                  options.page = 1;
                }
              "
            />
            <o-icon
              :disabled="!searchUser"
              color="red darken-3"
              icon
              @click="
                () => {
                  searchUser = '';
                  options.page = 1;
                  setSearchDataAction({
                    searchAction: searchAction,
                    searchUser: searchUser,
                  });
                }
              "
            >
              mdi-close-circle
            </o-icon>
          </o-row>
        </o-col>
        <o-col cols="4">
          <o-row>
            <o-autocomplete
              v-model="searchAction"
              label="Action"
              :items="getAllActions"
              hide-no-data
              :loading="getLoading"
              @change="
                () => {
                  setSearchDataAction({
                    searchAction: searchAction,
                    searchUser: searchUser,
                  });
                  options.page = 1;
                }
              "
            />
            <o-icon
              :disabled="!searchAction"
              color="red darken-3"
              icon
              @click="
                () => {
                  searchAction = '';
                  options.page = 1;
                  setSearchDataAction({
                    searchAction: searchAction,
                    searchUser: searchUser,
                  });
                }
              "
            >
              mdi-close-circle
            </o-icon>
          </o-row>
        </o-col>
      </o-row>
      <o-data-table
        dense
        height="60vh"
        :loading="getLoading"
        :headers="headers"
        :items="getAuditLogs"
        :server-items-length="getAuditLogCount"
        :items-per-page="getInfoItemsPerPage"
        :page="getInfoPage"
        :options.sync="options"
        :footer-props="{
          'show-first-last-page': true,
          'items-per-page-options': [10, 20, 30, 50, 100],
        }"
      />
      <o-card-actions class="justify-center">
        <o-btn color="primary" @click="fetchStartDate()">
          {{ $t('buttons.general.refresh') }}
        </o-btn>
      </o-card-actions>
    </o-col>
  </o-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'AuditLogFullPage',
  computed: {
    ...mapGetters('auditLog', [
      'getLoading',
      'getInfoItemsPerPage',
      'getInfoPage',
      'getAuditLogs',
      'getAuditLogCount',
      'getAuditStartDate',
      'getUsersEmails',
      'getAllActions',
    ]),
  },
  data() {
    return {
      options: {},
      headers: [
        { text: 'Date', value: 'date', sortable: false },
        { text: 'User', value: 'user', sortable: false },
        { text: 'Action', value: 'action', sortable: false },
        { text: 'Action Message', value: 'action_message', sortable: false },
      ],
      searchUser: '',
      searchAction: '',
    };
  },
  watch: {
    options: {
      handler() {
        if (this.getAuditStartDate !== '') {
          this.updatePaginationInfo({
            page: this.options.page,
            itemsPerPage: this.options.itemsPerPage,
          });
        }
      },
      deep: true,
    },
  },
  beforeMount() {
    this.clearData();
    this.fetchStartDate();
  },
  methods: {
    ...mapActions('auditLog', [
      'clearData',
      'updatePaginationInfo',
      'setSearchDataAction',
      'fetchStartDate',
    ]),
  },
};
</script>

<style></style>
