<template>
  <o-dialog v-model="show" max-width="80%">
    <o-card style="overflow-x: hidden">
      <o-row align="center">
        <o-col cols="auto">
          <o-card-title>
            <span>Edit Publisher's data:</span>
          </o-card-title>
        </o-col>
        <o-spacer />
      </o-row>
      <o-card-text>
        <o-container>
          <o-form ref="form" v-model="valid" lazy-validation>
            <o-row>
              <o-row>
                <o-col cols="12">
                  <o-card-title>{{ $t('publishers.general') }}:</o-card-title>
                </o-col>
                <o-col cols="3">
                  <o-text-field
                    v-model="getPublisherData.name"
                    readonly
                    label="Name"
                  />
                </o-col>
                <o-col cols="3">
                  <o-text-field
                    v-model="getPublisherData.gam_network_id"
                    readonly
                    label="GAM Network ID"
                  />
                </o-col>
                <o-col cols="3">
                  <o-text-field
                    v-model="getPublisherData.odas_account_name"
                    readonly
                    label="ODAS Account Name"
                  />
                </o-col>
                <o-col cols="3">
                  <o-text-field
                    v-model="getPublisherData.url"
                    readonly
                    label="URL"
                  />
                </o-col>
              </o-row>
              <o-row>
                <o-col cols="12">
                  <o-card-title>
                    {{ $t('publishers.company_info') }}:
                  </o-card-title>
                </o-col>
                <o-col cols="12" sm="6" md="4">
                  <o-text-field
                    v-model="getPublisherData.company_name"
                    readonly
                    label="Company Name"
                  />
                </o-col>
                <o-col cols="12" sm="6" md="4">
                  <o-text-field
                    v-model="getPublisherData.company_address"
                    readonly
                    label="Company Address"
                  />
                </o-col>
                <o-col cols="12" sm="6" md="4">
                  <o-text-field
                    v-model="getPublisherData.company_country"
                    readonly
                    label="Company Country"
                  />
                </o-col>
                <o-col cols="12" sm="6" md="4">
                  <o-text-field
                    v-model="getPublisherData.company_iban"
                    readonly
                    label="Company IBAN"
                  />
                </o-col>
                <o-col cols="12" sm="6" md="4">
                  <o-text-field
                    v-model="getPublisherData.company_bic"
                    readonly
                    label="Company BIC"
                  />
                </o-col>
                <o-col cols="auto">
                  <o-switch
                    v-model="getPublisherData.vat_present"
                    readonly
                    label="Use VAT"
                  />
                </o-col>
                <o-col cols="12" sm="6" md="4">
                  <o-text-field
                    v-model="getPublisherData.company_vat"
                    readonly
                    label="Company VAT"
                  />
                </o-col>
                <o-col cols="12" sm="6" md="4">
                  <o-text-field
                    v-model="getPublisherData.sales_contact_name"
                    readonly
                    label="Sales Contact Name"
                  />
                </o-col>
                <o-col cols="12" sm="6" md="4">
                  <o-text-field
                    v-model="getPublisherData.sales_contact_email"
                    readonly
                    label="Sales Contact Email"
                  />
                </o-col>
              </o-row>
            </o-row>
          </o-form>
        </o-container>
      </o-card-text>
      <o-card-actions>
        <o-spacer />
        <o-btn color="blue darken-1" text @click="close">
          {{ $t('buttons.general.close') }}
        </o-btn>
      </o-card-actions>
    </o-card>
  </o-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'PublisherDialog',
  props: ['editedIndex', 'publisher-data'],
  data() {
    return {
      show: false,
      readOnly: true,
      valid: true,
    };
  },
  computed: {
    ...mapGetters('priceFloorPublishers', ['getPublisherData']),
  },
  methods: {
    ...mapActions('priceFloorPublishers', ['fetchDataForPublisher']),

    close() {
      this.show = false;
    },
  },
};
</script>

<style scoped></style>
