<template>
  <v-chip
    v-bind="{
      ...$attrs,
      ...$props,
    }"
    v-on="$listeners"
  >
    <template v-for="(_, name) in $scopedSlots" #[name]="render">
      <slot :name="name" v-bind="render"></slot>
    </template>
  </v-chip>
</template>
<script>
export default {
  name: 'OChip',
};
</script>

<style scoped></style>
