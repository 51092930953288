<template>
  <v-footer app :color="env === 'production' ? '#448AFF' : 'green lighten-6'">
    <span
      v-if="env !== 'production'"
      class="amber--text"
      style="min-width: 50px"
      >{{ env }}</span
    >
    <span class="white--text">Opti-Digital &copy; {{ current_year }}</span>
    <o-spacer />
    <span class="white--text">{{ appVersion }}</span>
  </v-footer>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters('context', ['appVersion', 'env']),
    current_year: () => new Date().getFullYear(),
  },
};
</script>

<style></style>
