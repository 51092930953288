<template>
  <o-col>
    <v-app-bar id="pfc-switcher" app fixed class="stickyTop">
      <o-row justify="space-between" align="center" class="test">
        <o-col cols="auto">
          <o-card-title>PFUnits Manager</o-card-title>
        </o-col>
        <o-spacer />
        <o-col cols="auto">
          <o-btn color="orange" @click="openConfirmation">
            Manual Deploy for all Publishers
          </o-btn>
          <confirmation-dialog
            ref="confirmation"
            msg="Are you sure you want to do manual release?"
            @confirm="onManualRelease"
            @close="closeConfirmation"
          />
        </o-col>
      </o-row>
      <o-row ref="filterRow" align="center">
        <o-col>
          <o-autocomplete
            v-model="publisher"
            :items="getPublishersList"
            return-object
            label="Publisher"
            @change="onPublisherSelected"
          />
        </o-col>
        <o-divider
          v-show="treeLen !== 0 && publisher && publisher.id != null"
          vertical
        />
        <o-col v-for="item in getPublisherMapping.mapping" :key="item.id">
          <o-autocomplete
            :items="uniqueElementsObject[item.key]"
            item-text="text"
            item-value="value"
            :label="item.label"
            :value="filters[item.key]"
            multiple
            @change="
              (value) => {
                filters[item.key] = value;
                generateTree();
              }
            "
          >
            <template #prepend-item>
              <select-all-checkbox
                :element="item.key"
                :filters="filters[item.key]"
                :all="uniqueElementsObject[item.key]"
                @toggle="selectAll"
              />
            </template>
            <template #append-outer>
              <o-icon
                icon
                @click="
                  () => {
                    filters[item.key] = '';
                    generateTree();
                  }
                "
              >
                delete
              </o-icon>
            </template>
          </o-autocomplete>
        </o-col>
      </o-row>
      <o-row v-show="treeLen !== 0 && publisher && publisher.id != null">
        <o-card-actions>
          <o-row align="center" justify="start">
            <o-col>
              <o-btn
                color="green"
                :disabled="changedPfUnitsList.length === 0"
                class="my-4"
                @click="onSave"
              >
                Save
              </o-btn>
              <o-btn
                color="blue"
                class="ml-4 my-4"
                @click="showAllElementsInTree"
              >
                {{ expandedTxt }}
              </o-btn>
              <o-dialog v-model="saveDialog">
                <o-card>
                  <o-card-title> Items to save </o-card-title>
                  <o-card-text>
                    <o-simple-table>
                      <template #default>
                        <thead>
                          <tr>
                            <th class="text-left">Path</th>
                            <th class="text-left">Original Status</th>
                            <th class="text-left">New Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in changedPfUnitsList" :key="item.id">
                            <td>{{ generatePath(item) }}</td>
                            <td>
                              <o-chip
                                v-if="item.originalEnabledValue"
                                color="green"
                              >
                                Enabled
                              </o-chip>
                              <o-chip v-else color="gray"> Disabled </o-chip>
                            </td>
                            <td>
                              <o-chip v-if="item.enabled" color="green">
                                Enabled
                              </o-chip>
                              <o-chip v-else color="gray"> Disabled </o-chip>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </o-simple-table>
                  </o-card-text>
                  <o-card-actions>
                    <o-spacer />
                    <o-btn color="primary" @click="savePfListModification">
                      Confirm and Save
                    </o-btn>
                    <o-btn
                      @click="
                        () => {
                          saveDialog = false;
                        }
                      "
                    >
                      Cancel
                    </o-btn>
                  </o-card-actions>
                </o-card>
              </o-dialog>
            </o-col>
          </o-row>
        </o-card-actions>
      </o-row>
    </v-app-bar>
    <o-divider />
    <o-row
      :style="
        treeLen !== 0 && publisher && publisher.id != null
          ? `margin-top: ${filtersRowHeight}px`
          : `margin-top: ${filtersRowEmptyHeight}px`
      "
    >
      <o-col>
        <o-card v-show="treeLen !== 0 && publisher && publisher.id != null">
          <v-treeview
            ref="priceFloorTreeConfiguration"
            v-model="selectedItemsInTree"
            selectable
            hoverable
            return-object
            :items="treeItems"
            @input="onStatusChanged"
          >
            <template #label="{ item, leaf, selected }">
              <o-row align="center">
                <o-col cols="auto">
                  {{ item.name }}
                </o-col>
                <o-spacer />
                <o-col v-if="leaf" cols="auto">
                  <o-chip v-if="selected" color="green"> Enabled </o-chip>
                  <o-chip v-else color="gray"> Disabled </o-chip>
                </o-col>
              </o-row>
            </template>
          </v-treeview>
        </o-card>
        <o-card
          v-show="treeLen === 0 && publisher && publisher.id != null"
          class="ma-5"
        >
          <o-card-title class="ma-4">
            No PF units that are active (have traffic) or present in system
          </o-card-title>
        </o-card>
        <o-card v-show="publisher && publisher.id == null" class="ma-5">
          <o-card-title class="ma-4"> Please select publisher </o-card-title>
        </o-card>
      </o-col>
    </o-row>
  </o-col>
</template>

<script>
import ConfirmationDialog from '@/components/DesignSystem/ODialog/ConfirmationDialog.vue';
import SelectAllCheckbox from './SelectAllCheckbox.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    ConfirmationDialog,
    SelectAllCheckbox,
  },
  created() {
    this.clearSwitcherData();
    this.fetchPublishersList();
  },
  computed: {
    ...mapGetters('priceFloorConfiguration', [
      'getLoading',
      'getPublishersList',
      'getSelectedPublisher',
      'getPfUnitList',
      'getPublisherMapping',
      'getEnableOptions',
    ]),
    changedPfUnitsList() {
      const itemsList = [];
      this.getPfUnitList.forEach((element) => {
        if (element.enabled !== element.originalEnabledValue) {
          itemsList.push(element);
        }
      });
      return itemsList;
    },
    generateFiltersWrapper() {
      this.getPublisherMapping['mapping'].forEach((item, i) => {
        const obj = {
          name: item.key,
          level: i,
          value: [],
        };
        this.filtersWrapper.push(obj);
      });
      return this.filtersWrapper;
    },
  },
  data: () => ({
    publisher: {},
    keys: [],
    filters: {},
    selectedItemsInTree: [],
    prevSelectedItemsInTree: [],
    isSelectedItemsInTreeInputEnabled: false,
    treeItems: [],
    itemsArrayFilter: [],
    filteredPfUnits: [],
    saveDialog: false,
    uniqueElementsObject: {},
    treeLen: 0,
    expaned: false,
    expandedTxt: 'Expand all',
    manualReleaseConfirmationDialog: false,
    filtersRowHeight: 200,
    filtersRowEmptyHeight: 100,
    filtersWrapper: [],
  }),
  watch: {
    getPublishersList() {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const queryStringParams = Object.fromEntries(urlSearchParams.entries());
      if (Object.entries(queryStringParams).length !== 0) {
        this.getPublishersList.forEach((item) => {
          if (item.name === queryStringParams.publisher) {
            this.publisher = item;
            this.onPublisherSelected(item);
          }
        });
      }
    },
  },
  methods: {
    ...mapActions('priceFloorConfiguration', [
      'fetchPublishersList',
      'selectPublisher',
      'savePfUnits',
      'clearSwitcherData',
      'doManualRelease',
    ]),
    closeConfirmation() {
      this.$refs.confirmation.close();
    },
    openConfirmation() {
      this.$refs.confirmation.open();
    },
    filterItems(array, filters) {
      filters.forEach((filter) => {
        array = array.filter((item) => {
          return item[filter[0]] === filter[1];
        });
      });
      return array;
    },
    filterItemsMultiple(array, filters) {
      this.generateFiltersWrapper;
      this.filtersWrapper.forEach((arr) => {
        arr.value = [];
      });

      filters.forEach((filter) => {
        const result = this.filtersWrapper.filter((obj) => {
          return obj.name === filter[0];
        });
        const currentLevel = result[0].level;
        let previousLevel;
        this.filtersWrapper.forEach((arr, index) => {
          if (arr.value.length && index !== currentLevel) {
            previousLevel = arr.level;
          }
        });

        if (previousLevel >= 0) {
          this.filtersWrapper[currentLevel].value = this.filtersWrapper[
            currentLevel
          ].value.concat(
            this.filtersWrapper[previousLevel].value.filter((item) => {
              return item[filter[0]] === filter[1];
            }),
          );
        } else {
          this.filtersWrapper[currentLevel].value = this.filtersWrapper[
            currentLevel
          ].value.concat(
            array.filter((item) => {
              return item[filter[0]] === filter[1];
            }),
          );
        }
      });
      const result = this.filtersWrapper.filter((obj) => {
        return obj.name === filters[filters.length - 1][0];
      });

      return result[0].value;
    },
    async onPublisherSelected(publisher) {
      await this.selectPublisher(publisher);
      const filterObject = {};
      const keys = [];

      this.getPublisherMapping.mapping.forEach((element) => {
        if (this.filters && Object.keys(this.filters).length > 0) {
          if (typeof this.filters[element.key] === 'undefined') {
            this.filters[element.key] = '';
          }
          filterObject[element.key] = this.filters[element.key];
        } else {
          filterObject[element.key] = '';
        }
        keys.push(element.key);
      });

      this.filters = filterObject;
      this.keys = keys;
      this.selectedItemsInTree = [];
      this.generateUniqueElementObj();

      this.generateTree();
    },
    async savePfListModification() {
      await this.savePfUnits(this.changedPfUnitsList);
      this.saveDialog = false;
      await this.onPublisherSelected(this.publisher);
    },
    generateUniqueElementObj() {
      const uniqueElementsObject = {};
      this.keys.forEach((key) => {
        const arr = [];
        this.getPfUnitList.forEach((element) => {
          arr.push(element[key]);
        });
        uniqueElementsObject[key] = [...new Set(arr)];
      });

      Object.keys(uniqueElementsObject).forEach((item) => {
        if (this.filters[item].length) {
          const arr = [];
          uniqueElementsObject[item].map((el) => {
            if (this.filters[item].includes(el)) {
              arr.push(el);
            }
          });
          if (arr.length === 0) {
            this.filters[item] = '';
          } else {
            this.filters[item] = arr;
          }
        }
      });

      if (uniqueElementsObject['enabled']) {
        uniqueElementsObject['enabled'] = uniqueElementsObject['enabled'].map(
          (item, index) => {
            return this.getEnableOptions[index];
          },
        );
      }

      this.uniqueElementsObject = uniqueElementsObject;
    },
    generateTree() {
      this.isSelectedItemsInTreeInputEnabled = false;
      const that = this;
      const keysOptions = this.keys;
      let tree = [];
      const selectedItemsInTree = [];

      this.itemsArrayFilter = [];

      this.keys.forEach((key) => {
        if (this.filters[key].toString().length) {
          this.filters[key].forEach((item) => {
            this.itemsArrayFilter.push([key, item]);
          });
        }
      });

      if (this.itemsArrayFilter.length === 0) {
        this.filteredPfUnits = this.getPfUnitList;
      } else {
        this.filteredPfUnits = this.filterItemsMultiple(
          this.getPfUnitList,
          this.itemsArrayFilter,
        );
      }
      function generateChild(array, filters, depth, path) {
        const return_array = [];

        let unique_elements = [];
        array.forEach((element) => {
          unique_elements.push(element[keysOptions[depth]]);
        });

        unique_elements = [...new Set(unique_elements)];
        if (unique_elements[0] !== true || unique_elements[0] !== false) {
          unique_elements.forEach((element) => {
            const filter = [...filters, [keysOptions[depth], element]];
            const id = path + '/' + element;
            if (depth === keysOptions.length - 2) {
              const itemObj = that.filterItems(array, filter)[0];
              const leafObj = {
                id,
                name: element,
                filter,
                enabled: itemObj.enabled,
              };
              if (itemObj.enabled) {
                selectedItemsInTree.push(leafObj);
              }
              return_array.push(leafObj);
            } else {
              const branchObj = {
                id,
                name: element,
                filter,
                children: generateChild(
                  that.filterItems(array, [
                    ...filters,
                    [keysOptions[depth], element],
                  ]),
                  [...filters, [keysOptions[depth], element]],
                  depth + 1,
                  id,
                ),
              };
              return_array.push(branchObj);
            }
          });
          return return_array;
        }
      }
      tree = generateChild(this.filteredPfUnits, [], 0, '');
      this.treeLen = tree.length;
      this.treeItems = [
        {
          id: -1,
          name: this.publisher ? this.publisher.name : '',
          filter: [],
          children: tree,
        },
      ];
      this.$nextTick(() => {
        this.selectedItemsInTree = [...selectedItemsInTree];
        this.prevSelectedItemsInTree = [...this.selectedItemsInTree];
        this.isSelectedItemsInTreeInputEnabled = true;
      });

      setTimeout(() => {
        this.filtersRowHeight = 150 + this.$refs.filterRow.clientHeight;
        this.filtersRowEmptyHeight = 20 + this.$refs.filterRow.clientHeight;
      }, 100);
    },
    onStatusChanged() {
      if (this.isSelectedItemsInTreeInputEnabled) {
        const toEnabled = this.selectedItemsInTree.filter(
          (x) => !this.prevSelectedItemsInTree.includes(x),
        );

        const toDisable = this.prevSelectedItemsInTree.filter(
          (x) => !this.selectedItemsInTree.includes(x),
        );

        toEnabled.forEach((treeItem) => {
          const listItem = this.filterItems(
            this.filteredPfUnits,
            treeItem.filter,
          )[0];
          listItem.enabled = true;
        });

        toDisable.forEach((treeItem) => {
          const listItem = this.filterItems(
            this.filteredPfUnits,
            treeItem.filter,
          )[0];
          listItem.enabled = false;
        });

        this.prevSelectedItemsInTree = [...this.selectedItemsInTree];
      }
    },
    generatePath(item) {
      let path = '';
      this.keys.forEach((key, index) => {
        if (index === 0) {
          path += item[key];
        } else if (this.keys.length === index + 1) {
          path += '';
        } else {
          path += '/' + item[key];
        }
      });
      return path;
    },
    onSave() {
      this.saveDialog = true;
    },
    showAllElementsInTree() {
      if (this.expanded) {
        this.$refs.priceFloorTreeConfiguration.updateAll(false);
      } else {
        this.$refs.priceFloorTreeConfiguration.updateAll(true);
      }
      this.expanded = !this.expanded;
      this.expandedTxt = this.expanded ? 'Collapse all' : 'Expand all';
    },
    onManualRelease() {
      this.doManualRelease();
      this.this.$refs.confirmation.close();
    },
    selectAll(element) {
      this.$nextTick(() => {
        if (
          this.uniqueElementsObject[element].length ===
          this.filters[element].length
        ) {
          this.filters[element] = '';
          this.generateTree();
        } else {
          this.filters[element] = this.uniqueElementsObject[element];
          this.generateTree();
        }
      });
    },
  },
};
</script>

<style>
#pfc-switcher > .v-toolbar__content {
  display: grid;
  height: auto !important;
  background-color: #fff;
  padding-top: 50px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.v-select__selections {
  max-height: 100px;
  overflow: hidden;
}
</style>
