<template>
  <o-container>
    <o-row v-if="label !== ''" class="my-0 mx-5" dense>
      <slot name="label">
        <v-label>{{ label }}</v-label>
      </slot>
    </o-row>
    <o-row class="my-0" dense>
      <o-col>
        <o-row dense>
          <prism-editor
            class="my-editor"
            :value="getValue"
            :highlight="getHighlighter"
            line-numbers
            :readonly="readonly"
            @input="
              updateValue($event);
              validate($event);
            "
          />
          <o-row v-if="!readonly" class="my-0 mx-5" dense>
            <o-card-text style="color: red">
              {{ validation_error }}
            </o-card-text>
            <o-btn color="primary" @click="beautify"> Beautify </o-btn>
          </o-row>
        </o-row>
      </o-col>
    </o-row>
  </o-container>
</template>

<script>
import { highlight, languages } from 'prismjs/components/prism-core';
import { PrismEditor } from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-json';
import 'prismjs/themes/prism-coy.css';
import { js_beautify } from 'js-beautify';
import { parse } from 'esprima';

export default {
  components: {
    PrismEditor,
  },
  props: {
    language: {
      type: String,
      required: true,
      validator(lang) {
        return ['js', 'json'].indexOf(lang) !== -1;
      },
    },
    value: {
      type: [String, Object],
      required: false,
      default: '',
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      validation_error: '',
    };
  },
  computed: {
    getValue() {
      if (!this.value) return '';

      if (typeof this.value === 'string') {
        return this.value;
      }

      return JSON.stringify(this.value);
    },
    getHighlighter() {
      if (this.language === 'js') {
        return this.js_highlighter;
      }
      if (this.language === 'json') {
        return this.json_highlighter;
      }
      return (v) => {
        return v;
      };
    },
  },
  methods: {
    updateValue(value) {
      this.$emit('input', value);
    },
    beautify() {
      if (this.language === 'js') {
        this.beautify_js();
      }
      if (this.language === 'json') {
        this.beautify_json();
      }
    },
    beautify_json() {
      if (this.validate_json(this.value)) {
        const beautify_value = JSON.stringify(JSON.parse(this.value), null, 2);
        this.updateValue(beautify_value);
        if (this.language === 'js') {
          this.validate_javascript(beautify_value);
        }
      }
    },
    beautify_js() {
      if (this.validate_javascript(this.value)) {
        let temp_js = `var optiDigitalSetup = {${this.value}};`;
        temp_js = js_beautify(temp_js);
        const start = temp_js.indexOf('{');
        const end = temp_js.lastIndexOf('}');
        const beautify_value =
          temp_js
            .substring(start + 1, end)
            .split('\n')
            .filter((line) => line.trim())
            .map((line) => (line.startsWith('    ') ? line.substring(4) : line))
            .join('\n') || this.siteWebConfig.customFields;
        this.updateValue(beautify_value);
        if (this.language === 'js') {
          this.validate_javascript(beautify_value);
        }
      }
    },
    js_highlighter(code) {
      return highlight(code, languages.js, 'javascript'); // languages.<insert language> to return html with markup
    },
    json_highlighter(code) {
      return highlight(code, languages.json, 'json'); // languages.<insert language> to return html with markup
    },
    validate(code) {
      if (this.language === 'js') {
        this.validate_javascript(code);
      }
      if (this.language === 'json') {
        this.validate_json(code);
      }
    },
    validate_javascript(code) {
      const temp_js = `var optiDigitalSetup = {${code}};`;
      try {
        parse(temp_js); //throws error on invalid code input
        this.validation_error = '';
        return true;
      } catch (err) {
        this.validation_error =
          'Invalid Java Script code, Error: ' +
          '"' +
          err.description +
          '"' +
          ', Line number: ' +
          '"' +
          err.lineNumber +
          '".';
        return false;
      }
    },
    validate_json(str) {
      try {
        this.validation_error = '';
        JSON.parse(str);
      } catch (e) {
        this.validation_error = String(e);
        return false;
      }
      return true;
    },
  },
};
</script>

<style scoped>
.my-editor {
  background: #ffffff;
  color: #000;
  font-family: Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
}
</style>
