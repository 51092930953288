<template>
  <thead>
    <tr>
      <th
        v-for="h in headers"
        :id="h.text + '-header'"
        :key="h.text"
        @click="(h.sortable ?? true) && sort(h.value)"
        v-on="$listeners"
      >
        <sortable-span
          v-if="!h.description"
          :header="h"
          :sort-by="sortBy"
          :sort-desc="sortDesc[0]"
        />
        <o-tooltip v-else bottom>
          <template #activator="{ on }">
            <sortable-span
              :header="h"
              :sort-by="sortBy"
              :sort-desc="sortDesc[0]"
              v-on="on"
            />
            <o-icon light color="#000" small class="help-mark" v-on="on">
              mdi-help-circle-outline
            </o-icon>
          </template>
          {{ /*eslint-disable-next-line vue/no-v-html*/ }}
          <span v-html="h.description" />
        </o-tooltip>
      </th>
    </tr>
  </thead>
</template>

<script>
import SortableSpan from '@/components/DesignSystem/ODataTable/SortableSpan.vue';

export default {
  name: 'ODataTableHeader',
  components: { SortableSpan },
  props: {
    headers: {
      type: Array,
      required: true,
    },
    sortBy: {
      type: Array,
      required: true,
    },
    sortDesc: {
      type: Array,
      required: true,
    },
    sort: {
      type: Function,
      required: true,
    },
  },
};
</script>
<style scoped>
.help-mark {
  margin: 0 0 1px 0;
}
</style>
