<template>
  <o-simple-table fixed-header height="63vh">
    <thead>
      <tr>
        <th class="clickable" @click="sortBy(status_sort, { name: 'status' })">
          {{ $t('floor_manager.label.pf.status') }}
          <o-icon
            v-if="
              price_sort.method_name === 'status' && price_sort.desc !== null
            "
            small
          >
            {{ price_sort.desc ? 'mdi-arrow-up' : 'mdi-arrow-down' }}
          </o-icon>
        </th>
        <th class="clickable" @click="sortBy(name_sort, { name: 'name' })">
          <icon-tooltip
            :content="$t('floor_manager.label.pf.name')"
            :description="$t('floor_manager.help.pf.name')"
          />
          <o-icon
            v-if="price_sort.method_name === 'name' && price_sort.desc !== null"
            small
          >
            {{ price_sort.desc ? 'mdi-arrow-up' : 'mdi-arrow-down' }}
          </o-icon>
        </th>
        <th class="clickable" @click="sortBy(floor_sort, { name: 'floor' })">
          <icon-tooltip
            :content="$t('floor_manager.label.pf.value')"
            :description="$t('floor_manager.help.pf.value')"
          />
          <o-icon
            v-if="
              price_sort.method_name === 'floor' && price_sort.desc !== null
            "
            small
          >
            {{ price_sort.desc ? 'mdi-arrow-up' : 'mdi-arrow-down' }}
          </o-icon>
        </th>
        <th class="clickable" @click="sortBy(target_sort)">
          <icon-tooltip
            :content="$t('floor_manager.label.pf.target')"
            :description="$t('floor_manager.help.pf.target')"
          />
          <o-icon
            v-if="
              price_sort.method_name === 'target' && price_sort.desc !== null
            "
            small
          >
            {{ price_sort.desc ? 'mdi-arrow-up' : 'mdi-arrow-down' }}
          </o-icon>
        </th>
        <th v-if="widthRate > 50">
          <icon-tooltip
            :content="$t('floor_manager.label.pf.synchro')"
            :description="$t('floor_manager.help.pf.synchro')"
          />
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="pf in prepared_price_floor"
        :key="pf.id"
        :class="pf.id === active ? 'clicked_active' : ''"
        @click="() => setSelection(pf)"
      >
        <td>
          <o-switch
            v-model="pf.status"
            :disabled="!isAbleToEdit"
            show-details
            @click="changeStatus(pf)"
          />
        </td>
        <td>
          <a class="rule-name" href @click.prevent>{{
            priceFloorNameHandler(pf)
          }}</a>
        </td>
        <td>
          {{
            (+pf.floor).toLocaleString('fr', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          }}
          EUR
        </td>
        <td>
          <o-menu open-on-hover offset-y bottom>
            <template #activator="{ on, attrs }">
              <o-btn icon dark v-bind="attrs" v-on="on">
                <o-icon
                  :color="gradiantDimension(configured_dimensions(pf))"
                  @click.prevent
                >
                  mdi-target
                </o-icon>
              </o-btn>
            </template>
            <o-card class="pa-0">
              <o-list>
                <o-list-item
                  v-for="(dim, i) in configured_dimensions(pf)"
                  :key="i"
                >
                  {{ dim.name }} = {{ targetValueHandler(dim) }}
                </o-list-item>
                <o-list-item v-if="configured_dimensions(pf).length === 0">
                  {{ $t('floor_manager.label.no_dimension') }}
                </o-list-item>
              </o-list>
            </o-card>
          </o-menu>
        </td>
        <td v-if="widthRate > 50">
          <o-menu open-on-hover offset-y bottom>
            <template #activator="{ on, attrs }">
              <o-icon
                :color="
                  isSynchronized(pf)
                    ? pf.status
                      ? 'success'
                      : 'grey'
                    : 'warning'
                "
                v-bind="attrs"
                v-on="on"
              >
                {{
                  isSynchronized(pf)
                    ? pf.status
                      ? 'mdi-cached'
                      : 'mdi-sync-off'
                    : 'mdi-timelapse'
                }}
              </o-icon>
            </template>
            <o-card class="pa-0">
              <o-card-text>
                {{
                  isSynchronized(pf)
                    ? pf.status
                      ? 'pushed'
                      : 'excluded'
                    : 'still not pushed'
                }}
              </o-card-text>
            </o-card>
          </o-menu>
        </td>
      </tr>
    </tbody>
  </o-simple-table>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { eventBus } from '@/main';
import IconTooltip from '@/components/DesignSystem/OTooltip/IconTooltip.vue';

export default {
  name: 'FloorTable',
  components: { IconTooltip },
  props: {
    price_floor: {
      type: Array,
    },
    name_filter: {
      type: Array,
    },
    isAbleToEdit: {
      type: Boolean,
    },
    widthRate: {
      type: Number,
      default: 100,
    },
  },
  emits: ['select_price_floor'],
  data() {
    return {
      active: null,
      price_sorting_method: this.target_sort,
      price_sort: {
        method: this.target_sort,
        method_name: 'target',
        desc: null,
      },
    };
  },
  async mounted() {
    eventBus.$on('cancel_select_price_floor', () => {
      this.cancelSelection();
    });
  },
  computed: {
    ...mapGetters('floorRules', ['getLastConfigPushed', 'getDynamicItems']),
    prepared_price_floor() {
      return this.priceFloorFiltration().sort(this.price_sort.method);
    },
    dynamicItems: {
      get() {
        return this.getDynamicItems;
      },
    },
  },
  methods: {
    ...mapActions('floorRules', ['changeStatus']),
    sortBy(method, { name = 'target' } = {}) {
      if (this.price_sort.method_name !== name) {
        this.price_sort.desc = null;
        this.price_sort.method_name = name;
      }
      if (this.price_sort.desc === false) {
        this.price_sort.desc = null;
        this.price_sort.method = this.target_sort;
        this.method_name = 'target';
        return;
      }

      this.price_sort.desc =
        this.price_sort.desc === null ? true : !this.price_sort.desc;
      this.price_sort.method = !this.price_sort.desc
        ? (a, b) => method(b, a)
        : method;
    },
    target_sort(a, b) {
      if (a.status !== b.status) {
        return b.status - a.status;
      }
      if (a.schema.binary_weight !== b.schema.binary_weight) {
        return b.schema.binary_weight - a.schema.binary_weight;
      }
      if (a.floor !== b.floor) {
        return b.floor - a.floor;
      }

      return a.name.localeCompare(b.name);
    },
    name_sort(a, b) {
      return a.name.localeCompare(b.name);
    },
    status_sort(a, b) {
      return b.status - a.status;
    },
    floor_sort(a, b) {
      return b.floor - a.floor;
    },
    configured_dimensions({ dimensions }) {
      return dimensions.filter(({ value }) => {
        return value && value !== '' && value?.length > 0;
      });
    },
    cancelSelection() {
      this.active = null;
      this.$emit('select_price_floor', null);
    },
    setSelection(item) {
      const old = window.structuredClone(this.active);
      this.active = item.id;
      this.$emit('select_price_floor', item, {
        old,
      });
    },
    resetSelection(id) {
      this.active = id;
    },
    priceFloorFiltration() {
      this.price_floor ??= [];
      if (this.name_filter && this.name_filter.length > 0) {
        return this.price_floor.filter(({ name }) =>
          this.name_filter.some((fname) =>
            name.toLowerCase().includes(fname.toLowerCase()),
          ),
        );
      }
      return this.price_floor;
    },
    priceFloorNameHandler({ name }) {
      return name;
    },
    targetValueHandler({ value, type }) {
      if (type.component === 'CountrySelector') {
        return value?.length < 15
          ? value.map(({ name }) => name).join(', ')
          : `${value.length} selected countries`;
      }

      const items = type.options?.['items'];

      if (items && typeof items !== 'string') {
        return type.options.items
          .filter((item) => value.includes(item.v))
          .map((item) => item.t)
          .join(', ');
      }

      if (
        items &&
        typeof items === 'string' &&
        this.dynamicItems[items.substring(1)]
      ) {
        const reference = items.substring(1);
        return this.dynamicItems[reference]
          .filter((item) => value.includes(item.v))
          .map((item) => item.t)
          .join(', ');
      }

      if (Array.isArray(value)) {
        return value.join(', ');
      }
      return value;
    },
    gradiantDimension(dimensions) {
      if (dimensions.length > 0) {
        return 'primary';
      }
      return 'orange';
    },
    isSynchronized({ updated_on }) {
      return (
        new Date(updated_on).getTime() <=
        new Date(this.getLastConfigPushed).getTime()
      );
    },
  },
};
</script>

<style scoped>
.clicked_active {
  background-color: #73d679aa !important;
  border-radius: 15%;
}

.rule-name {
  cursor: pointer;
}

.clickable {
  cursor: pointer;
  text-decoration: underline;
}

.clickable:hover {
  opacity: 0.7;
}
</style>
