<template>
  <o-row>
    <o-col cols="12">
      <o-row>
        <o-card-title>
          {{ $t('financial_reporting.payments') }}
        </o-card-title>
      </o-row>
      <payments-table />
    </o-col>
  </o-row>
</template>

<script>
import PaymentsTable from '../../components/Accounting/Payments/PaymentsTable';
export default {
  name: 'Payments',
  components: { PaymentsTable },
};
</script>

<style scoped></style>
