<template>
  <o-container
    class="text-center"
    fill-height
    style="height: calc(100vh - 58px)"
  >
    <o-row align="center">
      <o-col>
        <h1 class="display-2 primary--text">Whoops, 404</h1>

        <p>The page you were looking for does not exist</p>
        <p>or is under development.</p>

        <o-btn :to="`/`" color="primary" outlined> Get me out of here! </o-btn>
      </o-col>
    </o-row>
  </o-container>
</template>

<script>
export default {
  name: 'FourOhFour',
  props: {
    lang: {
      type: String,
      default: 'en',
    },
  },
};
</script>

<style scoped></style>
