<template>
  <div ref="container" class="infinite-scroll-container" @scroll="handleScroll">
    <slot :displayed-items="displayedItems" :is-loading="isLoading" />
    <slot v-if="error" name="error" :error="error">
      <o-alert type="error" dense class="ma-2">
        {{ error }}
      </o-alert>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'OInfiniteScroll',

  props: {
    items: {
      type: Array,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      default: 50,
    },
    height: {
      type: String,
      default: '63vh',
    },
    threshold: {
      type: Number,
      default: 100,
    },
  },

  data() {
    return {
      currentPage: 1,
      isLoading: false,
      error: null,
      hasMore: true,
    };
  },

  computed: {
    displayedItems() {
      return this.items.slice(0, this.itemsPerPage * this.currentPage);
    },

    hasMoreItems() {
      return this.hasMore && this.displayedItems.length < this.items.length;
    },
  },

  methods: {
    handleScroll(event) {
      const target = event.target;
      if (this.isLoading || !this.hasMoreItems) return;

      if (
        target.scrollHeight - target.scrollTop - target.clientHeight <
        this.threshold
      ) {
        this.loadMore();
      }
    },

    loadMore() {
      this.error = null;
      this.isLoading = true;

      const handlers = {
        empty: () => {
          this.isLoading = false;
          this.hasMore = false;
        },
        loading: () => {
          this.isLoading = true;
        },
        ok: () => {
          this.isLoading = false;
          this.currentPage += 1;
        },
        error: (errorMessage) => {
          this.isLoading = false;
          this.error =
            errorMessage || 'An error occurred while loading more items';
        },
      };

      const done = (status, errorMessage) => {
        const handler = handlers[status];
        if (handler) {
          handler(errorMessage);
        } else {
          console.warn('Unknown status:', status);
        }
      };

      this.$emit('load', { done });
    },
  },
};
</script>

<style scoped>
.infinite-scroll-container {
  overflow-y: auto;
  height: v-bind(height);
}
</style>
