<template>
  <o-col>
    <o-row>
      <o-card-title>Publisher Financial View</o-card-title>
      <o-snackbar v-model="getInvoiceSnackbar" top :color="snackbar_color">
        {{ getInvoiceSentNotification }}
        <o-btn color="white" text @click="snackbar = false"> Close </o-btn>
      </o-snackbar>
    </o-row>
    <reporting-turnover-final-table />
  </o-col>
</template>

<script>
import ReportingTurnoverFinalTable from '../../components/Accounting/ReportingTurnoverFinal/ReportingTurnoverFinalTable';
import { mapGetters } from 'vuex';

export default {
  name: 'ReportingTurnoverFinal',
  components: { ReportingTurnoverFinalTable },
  data() {
    return {
      snackbar_color: 'blue',
    };
  },
  computed: {
    ...mapGetters('turnoverFinal', [
      'getInvoiceSentNotification',
      'getInvoiceSnackbar',
    ]),
  },
};
</script>

<style scoped></style>
