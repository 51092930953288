<template>
  <o-dialog
    v-bind="{
      ...$props,
      ...$attrs,
    }"
    v-on="$listeners"
  >
    <o-card :class="cardClass">
      <template v-for="(_, name) in $scopedSlots" #[name]="render">
        <slot :name="name" v-bind="render"></slot>
      </template>

      <slot></slot>
    </o-card>
  </o-dialog>
</template>
<script>
export default {
  name: 'OCardDialog',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    cardClass: {
      type: [String, Object, Array],
      default: '',
    },
  },
};
</script>
<style scoped></style>
