import axios from 'axios';

const store = {
  namespaced: true,

  state: {
    webConfig: {
      id: 0,
      site_entity_id: 101,
      revision: 1,
      description: 'desc',
      ab_test: 'string',
      timeouts_prebidDirect: 0,
      timeouts_prebidIndirect: 0,
      timeouts_failsafe: 0,
      timeouts_lazyLoadPxInterval: 0,
      analytics_status: 'string',
      analytics_sampling: 0,
      analytics_optiSampling: 0,
      analytics_property: 'string',
      analytics_customDimensions: '',
      prebidConsentManagement_cmpApi: 'string',
      prebidConsentManagement_timeout: 0,
      prebidConsentManagement_allowAuctionWithoutConsent: true,
      prebidPriceGranularity: '',
      defaultDiscount: 0,
      adServerCurrency: 'string',
      enableArticleReporting: true,
      enableSourceReporting: true,
      sourceDomains: [],
      adSlotsConfig_adManagerKeyName: 'string',
      adSlotsConfig_widthBreakpoints: 'string',
      aliasBidders: 'string',
      bidders: 'string',
      prebidSafeframe_status: 'string',
      prebidSafeframe_bidders: 'string',
      customFields: 'string',
      created_on: null,
      updated_on: null,
      updated_by: null,
      publication_date: null,
      end_date: null,
      domains_protected: 'string',
      domain_protection: false,
      allowExternCustomFields: false,
      schain: 'optidigital',
      enable_all_bids: false,
      stickyAdConfiguration_nameDesktop: 'Desktop_Sticky_Bottom',
      stickyAdConfiguration_nameMobile: 'Mobile_Sticky_Bottom',
      nameInterstitialMobile: 'Mobile_Interstitial',
      nameInterstitialDesktop: 'Desktop_Interstitial',
      direct_min_refresh_delay: 0,
      campaign: [],
      direct_anchor: false,
      campaign_anchor: false,
      anchorModeAdvertisers: [],
      anchorModeLineItems: [],
      anchor_time: 4_000,
      mobile_inserter: null,
      desktop_inserter: null,
      side_rail: {
        left: {},
        right: {},
      },
    },
    ab_test_status: {
      enabled: false,
      state: '',
      sampling: 0,
    },
    sync_in_progress: false,
    engageBackfill: false,
    stickyAd_top_trigger_items: [],
  },

  getters: {
    getSyncInProgress: (state) => state.sync_in_progress,
    getWebSetting: (state) => state.webConfig,
    getSchainSetting: (state) => state.webConfig.schain,
    getABTestStatus: (state) => state.ab_test_status,
    getABTestState: (state) => state.ab_test_status.state,
    getConfigStatus: (state) => state.webConfig.status,
    getCreateDate: (state) => state.webConfig.created_on,
    getUpdateDate: (state) => state.webConfig.updated_on,
    getUpdatedBy: (state) => state.webConfig.updated_by,
    getPublicationDate: (state) => state.webConfig.publication_date,
    getEndDate: (state) => state.webConfig.end_date,
    isDefaultConfig: (state) => state.webConfig.site_entity_id === null,
    getStickyAdTopTriggerItems: (state) => state.stickyAd_top_trigger_items,
  },

  mutations: {
    setWebSetting(state, swc) {
      console.log('siteWebConfig -- set with', swc);
      state.webConfig = swc;
    },
    setSyncInProgress(state, sync) {
      state.sync_in_progress = sync;
    },
    setRevisions(state, data) {
      state.revisions = data.versions;
    },
    setSchainSetting(state, payload) {
      state.webConfig.schain = payload;
    },
    setSiteWebConfigFromApi(state, payload) {
      state.webConfig = payload;
      state.webConfig.is_ab_test = !!payload.ab_test;
      state.webConfig.prebidPriceGranularity = JSON.stringify(
        payload.prebidPriceGranularity,
      );
      state.webConfig.analytics_customDimensions = JSON.stringify(
        payload.analytics_customDimensions,
      );
      console.log(
        'siteWebConfig -- set from api with:',
        payload,
        'new state:',
        state.webConfig,
      );
    },
    setSiteABTestConfigFromApi(state, payload) {
      state.ab_test_status = payload;
    },
    setSiteWebConfigFromModel(state) {
      console.log(
        '(before) siteWebConfig -- set from model (user input) with:',
        state.webConfig.name,
        state.webConfig,
      );
      state.webConfig.ab_test = state.webConfig.ab_test || null;

      function _parse({ input, target }) {
        if (typeof input !== 'string') return input;
        // to avoid cache issue from Sentry
        if ('[object Object]' === input) return target;

        try {
          return JSON.parse(input);
        } catch (e) {
          console.warn('_parse fallback on target', e);
          return target;
        }
      }

      state.webConfig.prebidPriceGranularity = _parse({
        input: state.webConfig.prebidPriceGranularity,
        target: state.webConfig.prebidPriceGranularity,
      });

      state.webConfig.analytics_customDimensions = _parse({
        input: state.webConfig.analytics_customDimensions,
        target: state.webConfig.analytics_customDimensions,
      });

      console.log(
        'siteWebConfig -- set from model (user input) with:',
        state.webConfig.name,
        state.webConfig,
      );
    },
    setStickyAdTopTriggerItems(state, payload) {
      state.stickyAd_top_trigger_items = payload;
    },
  },

  actions: {
    async fetchSiteWebConfig({ commit, rootState }) {
      console.log('siteWebConfig -- fetch launched');
      commit('tagsManager/setLoadingConfigData', true, { root: true });
      const payload = {
        se_id: rootState.tagsManager.site.id,
        revision: rootState.tagsManager.revision?.number,
        inventory_type: rootState.tagsManager.inventoryType,
        read_only: rootState.tagsManager.read_only,
      };
      await axios
        .get('/site_web_config/', { params: payload })
        .then((response) => {
          commit('setSiteWebConfigFromApi', response.data.data);
          commit('tagsManager/setLoadingConfigData', false, { root: true });
        });
      console.log('siteWebConfig -- has been fetched');
    },
    async fetchSiteABTest({ commit, rootState }) {
      commit('tagsManager/setLoadingConfigData', true, { root: true });
      const payload = {
        se_id: rootState.tagsManager.site.id,
        revision: rootState.tagsManager.revision?.number,
        inventory_type: rootState.tagsManager.inventoryType,
      };
      await axios
        .get('/site_web_config/ab_test_setup', { params: payload })
        .then((response) => {
          commit('setSiteABTestConfigFromApi', response.data.data);
          commit('tagsManager/setLoadingConfigData', false, { root: true });
        });
    },
    async updateSiteWebConfig({ commit, dispatch, getters, rootState }) {
      commit('tagsManager/setLoadingConfigData', true, { root: true });
      const params = {
        se_id: rootState.tagsManager.site.id,
        revision: rootState.tagsManager.revision.number,
        inventory_type: rootState.tagsManager.inventoryType,
      };
      await axios
        .post('/site_web_config/', getters.getWebSetting, { params })
        .then((response) => {
          commit(
            'tagsManager/setRevision',
            {
              name: response.data.data.name,
              status: response.data.data.status,
              number: response.data.data.revision,
            },
            {
              root: true,
            },
          );
          dispatch('fetchSiteWebConfig');
          commit('tagsManager/setLoadingConfigData', false, { root: true });
        });
    },
    async pushSiteWebConfig(
      { commit, dispatch, rootState, state },
      { push_odas = false, prerelease = false },
    ) {
      console.log(
        `siteWebConfig -- push launched ${prerelease ? 'prerelease' : 'live'}`,
      );
      const params = {
        se_id: rootState.tagsManager.site.id,
        revision: rootState.tagsManager.revision.number,
        inventory_type: rootState.tagsManager.inventoryType,
        push_odas,
        prerelease,
      };
      commit('tagsManager/setLoadingConfigData', true, { root: true });
      commit('tagsManager/setLoadingConfigInfo', true, { root: true });
      await axios
        .post('/tags_manager/pushLive', state.webConfig, {
          params,
        })
        .then(() => {
          commit('warnings/snackbarInfo', 'Action completed', {
            root: true,
          });
        })
        .catch((err) => {
          console.log(err.response);
          if (err.response) {
            const msg = 'Error occurred, action aborted.';
            commit('warnings/snackbarError', msg, {
              root: true,
            });
            commit(
              'warnings/addWarning',
              JSON.stringify(err.response.data) || '',
              {
                root: true,
              },
            );
          }
          throw err;
        })
        .finally(() => {
          dispatch('fetchSiteWebConfig', params);
          commit('tagsManager/setLoadingConfigData', false, { root: true });
          commit('tagsManager/setLoadingConfigInfo', false, { root: true });
        });
      console.log(
        `siteWebConfig -- has been pushed ${prerelease ? 'prerelease' : 'live'}`,
      );
    },
    async pushAllOdasSr() {
      await axios.post('/tags_manager/pushAllOdasSr');
    },
    async makeModified({ commit, dispatch, rootState, state }) {
      const params = {
        se_id: rootState.tagsManager.site.id,
        revision: rootState.tagsManager.revision.number,
        inventory_type: rootState.tagsManager.inventoryType,
      };
      commit('tagsManager/setLoadingConfigData', true, { root: true });
      commit('tagsManager/setLoadingConfigInfo', true, { root: true });

      await axios
        .post('/site_web_config/back_to_modified/', state.webConfig, {
          params,
        })
        .then(() => {
          dispatch('tagsManager/fetchSiteConfigInfo', null, { root: true });
          dispatch('tagsManager/fetchVerificationChecklist', null, {
            root: true,
          });
          commit('warnings/snackbarInfo', 'Action completed', {
            root: true,
          });
        })
        .catch((err) => {
          console.log(err.response);
          if (err.response) {
            const msg = 'Error occurred, action aborted.';
            commit('warnings/snackbarError', msg, {
              root: true,
            });
            commit(
              'warnings/addWarning',
              JSON.stringify(err.response.data) || '',
              {
                root: true,
              },
            );
          }
          throw err;
        })
        .finally(() => {
          dispatch('fetchSiteWebConfig', params);
          commit('tagsManager/setLoadingConfigData', false, { root: true });
          commit('tagsManager/setLoadingConfigInfo', false, { root: true });
        });
    },
    async fetchStickyAdTopTriggerItems({ commit }) {
      commit('tagsManager/setLoadingConfigData', true, { root: true });
      await axios
        .get('/site_web_config/options/sticky_top_trigger')
        .then((response) => {
          commit('setStickyAdTopTriggerItems', response.data);
          commit('tagsManager/setLoadingConfigData', false, { root: true });
        });
    },
  },
};

export default store;
