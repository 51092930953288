import Vue from 'vue';
import VueAxios from 'vue-axios';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import axios from './axios';
import * as Sentry from '@sentry/vue';

import './filters';
import i18n from './i18n';
import './global.js';

if (process.env.NODE_ENV !== 'DEV') {
  Sentry.init({
    Vue,
    dsn: 'https://4562b18d372242a39effc3b5df304cb5@o4505345925513216.ingest.sentry.io/4505482691149824',
    environment: process.env.NODE_ENV,
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          'localhost',
          'admin.optidigital.com',
          'dashboard-dot-sandbox-311612.ew.r.appspot.com',
          'dashboard-dot-optidigital-dashboard-stage-xd.ew.r.appspot.com',
        ],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

Vue.config.productionTip = false;

Vue.use(VueAxios, axios);

export const eventBus = new Vue();

const app = new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: (h) => h(App),
});

app.$mount('#app');
