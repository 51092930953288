const baseInput = {
  props: {
    showDetails: {
      type: [Boolean, String],
      default: 'auto',
    },
    hideDetails: {
      type: [Boolean, String],
      default: 'auto',
    },
    dense: {
      type: Boolean,
      default: true,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    label: {
      type: [String, undefined],
      default: undefined,
    },
    description: {
      type: [String, undefined],
      default: undefined,
    },
  },
  computed: {
    _label() {
      if (/^[a-z_0-9]+(\.[a-z_0-9]+)+$/.test(this.label)) {
        return this.$t(this.label);
      }
      return this.label;
    },
    _hideDetails() {
      if ([this.hideDetails, this.showDetails].every((v) => v === 'auto')) {
        return 'auto';
      }

      if (this.hideDetails === 'auto') {
        return !this.showDetails;
      }

      return this.hideDetails;
    },
  },
  methods: {
    blur() {
      this.$refs.vInput.blur?.();
    },
    focus(...args) {
      this.$refs.vInput.focus?.(...args);
    },
    resetValidation(...args) {
      return this.$refs.vInput?.resetValidation?.(...args);
    },
    validate(...args) {
      return this.$refs.vInput?.validate?.(...args);
    },
  },
};

export default baseInput;
