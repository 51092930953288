<template>
  <div>
    <div
      v-if="paymentInfo.status == 'validated'"
      class="d-flex flex-wrap flex-column"
    >
      <o-btn
        :loading="getLoading"
        class="ma-1"
        color="primary"
        @click="onGenerateInvoice"
      >
        <o-icon class="mr-2"> description </o-icon>
        Generate Payment
      </o-btn>
    </div>
    <div
      v-if="paymentInfo.status == 'invoiced_draft'"
      class="d-flex flex-wrap flex-column"
    >
      <o-text-field label="Invoice Date" :value="paymentInfo.invoice_date" />
      <o-text-field label="Due Date" :value="paymentInfo.invoice_due_date" />
      <o-btn
        :loading="getLoading"
        class="ma-1"
        color="lime"
        @click="onDownloadInvoice"
      >
        <o-icon class="mr-2"> download </o-icon>
        Download Invoice
      </o-btn>
      <o-btn
        :loading="getLoading"
        class="ma-1"
        color="warning"
        @click="onGenerateInvoice"
      >
        <o-icon class="mr-2"> autorenew </o-icon>
        Regenerate Invoice
      </o-btn>
      <o-btn
        :loading="getLoading"
        class="ma-1"
        color="green"
        @click="onValidateInvoice"
      >
        <o-icon class="mr-2"> done </o-icon>
        Validate Invoice
      </o-btn>
    </div>
    <div
      v-if="paymentInfo.status == 'invoiced'"
      class="d-flex flex-wrap flex-column"
    >
      <o-text-field label="Invoice Date" :value="paymentInfo.invoice_date" />
      <o-text-field label="Due Date" :value="paymentInfo.invoice_due_date" />
      <o-btn
        :loading="getLoading"
        class="ma-1"
        color="lime"
        @click="onDownloadInvoice"
      >
        <o-icon class="mr-2"> download </o-icon>
        Download Invoice
      </o-btn>
      <o-btn
        :loading="getLoading"
        class="ma-1"
        color="blue"
        @click="onSendInvoice"
      >
        <o-icon class="mr-2"> done </o-icon>
        Send Invoice
      </o-btn>
      <o-btn
        :loading="getLoading"
        class="ma-1"
        color="green"
        @click="onValidatePayment"
      >
        <o-icon class="mr-2"> done </o-icon>
        Payment Validated
      </o-btn>
    </div>
    <div
      v-if="paymentInfo.status == 'payment_done'"
      class="d-flex flex-wrap flex-column"
    >
      <o-text-field
        label="Payment Date"
        :value="paymentInfo.invoice_payment_date"
      />
      <o-btn
        :loading="getLoading"
        class="ma-1"
        color="lime"
        @click="onDownloadInvoice"
      >
        <o-icon class="mr-2"> download </o-icon>
        Download Invoice
      </o-btn>
    </div>
    <div
      v-if="paymentInfo.status == 'invoice_sent'"
      class="d-flex flex-wrap flex-column"
    >
      <o-text-field label="Invoice Date" :value="paymentInfo.invoice_date" />
      <o-text-field label="Due Date" :value="paymentInfo.invoice_due_date" />
      <o-text-field
        label="Invoice Sent Date"
        :value="paymentInfo.invoice_sent_date"
      />
      <o-btn
        :loading="getLoading"
        class="ma-1"
        color="lime"
        @click="onDownloadInvoice"
      >
        <o-icon class="mr-2"> download </o-icon>
        Download Invoice
      </o-btn>
      <o-btn
        :loading="getLoading"
        class="ma-1"
        color="green"
        @click="onValidatePayment"
      >
        <o-icon class="mr-2"> done </o-icon>
        Payment Validated
      </o-btn>
      <o-btn
        :loading="getLoading"
        class="ma-1"
        color="yellow"
        @click="onSendInvoice"
      >
        <o-icon class="mr-2"> done </o-icon>
        Re-Send Invoice
      </o-btn>
    </div>
    <payment-date-dialog-vue
      :visible="dateDialog"
      :title-text="dateDialogTitle"
      :on-confirm-callback="dateDialogCallback"
      :on-cancel-callback="onHideDateDialog"
      :dpa-type="dpa_type"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PaymentDateDialogVue from './PaymentDateDialog.vue';

export default {
  components: {
    PaymentDateDialogVue,
  },
  props: {
    paymentInfo: {
      type: Object,
    },
    dpa_type: {
      type: String,
    },
    payment_term: {
      type: String,
    },
  },
  data() {
    return {
      dateDialog: false,
      dateDialogCallback: new Function(),
      dateDialogTitle: '',
    };
  },
  computed: {
    ...mapGetters('turnoverFinal', ['getLoading']),
    ...mapGetters('publishers', ['getPennylaneMappingPublisher']),
    isSupplierMapped() {
      const supplierMapping = this.getPennylaneMappingPublisher.supplier;
      return !!supplierMapping?.publisher_id;
    },
    isCustomerMapped() {
      const customerMapping = this.getPennylaneMappingPublisher.customer;
      return !!customerMapping?.publisher_id;
    },
  },
  methods: {
    ...mapActions('warnings', ['showSnackbarError']),
    ...mapActions('turnoverFinal', [
      'generateInvoice',
      'downloadInvoice',
      'validatePayment',
      'validateInvoice',
      'sendInvoice',
    ]),
    onShowDateDialog(title, callback) {
      this.dateDialogTitle = title;
      this.dateDialogCallback = callback;
      this.dateDialog = true;
    },
    onHideDateDialog() {
      this.dateDialog = false;
    },
    onGenerateInvoice() {
      this.onShowDateDialog('Select Invoice Date', (generateDate) => {
        if (this.dpa_type === 'publisher' || this.dpa_type === 'optidigital') {
          if (this.dpa_type === 'publisher' && !this.isCustomerMapped) {
            this.showSnackbarError("Publisher's Customer is not connected");
            this.onHideDateDialog();
            return;
          }
          if (this.dpa_type === 'optidigital' && !this.isSupplierMapped) {
            this.showSnackbarError("Publisher's Supplier is not connected");
            this.onHideDateDialog();
            return;
          }

          this.generateInvoice({
            dpa_type: this.dpa_type,
            payment_term: this.payment_term,
            invoice_date: generateDate,
          });
          this.onHideDateDialog();
        }
      });
    },
    onDownloadInvoice() {
      this.downloadInvoice({
        dpa_type: this.dpa_type,
        payment_term: this.payment_term,
      });
    },
    onSendInvoice() {
      this.sendInvoice({
        dpa_type: this.dpa_type,
        payment_term: this.payment_term,
      });
    },
    onValidateInvoice() {
      this.validateInvoice({
        dpa_type: this.dpa_type,
        payment_term: this.payment_term,
      });
    },
    onValidatePayment() {
      this.onShowDateDialog('Select Payment Date', (paymentDate) => {
        this.validatePayment({
          dpa_type: this.dpa_type,
          payment_term: this.payment_term,
          payment_date: paymentDate,
        });
        this.onHideDateDialog();
      });
    },
  },
};
</script>

<style></style>
