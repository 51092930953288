<template>
  <o-row class="sites-grid">
    <o-col cols="12">
      <o-data-table
        height="60vh"
        :loading="getLoadingFinancialReporting"
        :headers="headers"
        :items="getFinancialReportMain.table"
        :footer-props="{
          'show-first-last-page': true,
          'items-per-page-options': [10, 20, 30, 50, 100],
        }"
      >
        <template slot="top">
          <o-row>
            <o-col cols="4">
              <o-menu
                v-model="datePicker"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template #activator="{ on }">
                  <o-text-field
                    v-model="datePickerDate"
                    prepend-icon="event"
                    readonly
                    v-on="on"
                  />
                </template>
                <o-date-picker
                  v-model="datePickerDate"
                  type="month"
                  @input="selectDate(datePickerDate)"
                />
              </o-menu>
            </o-col>
            <o-col cols="4">
              <o-autocomplete
                v-model="demandPartnerAutocomplete"
                :loading="getLoadingDemandPartners"
                :items="getDemandPartners"
                clearable
                hide-no-data
                @change="fetchFinancialReporting"
                @input="selectDPA($event)"
              />
            </o-col>
          </o-row>
        </template>
        <template #item.action="{ item }">
          <o-btn color="primary" @click="openDialog(item)">
            {{ $t('buttons.general.edit') }}
          </o-btn>
        </template>
        <template #item.estimated_revenue="{ value }">
          {{ formatMoney(value) + ' €' }}
        </template>
        <template #item.final_revenue="{ value }">
          {{ formatMoney(value) + ' €' }}
        </template>
        <template #item.correction_value="{ value }">
          {{ formatMoney(value) + ' €' }}
        </template>
        <template #item.status="{ value }">
          <o-tooltip bottom :disabled="value.name === 'Estimated'">
            <template #activator="{ on }">
              <o-chip :color="mapFinancialStatusColor(value.name)" v-on="on">
                {{ value.name }}
              </o-chip>
            </template>
            <span v-if="value.name !== 'Estimated'"> {{ value.date }} </span>
          </o-tooltip>
        </template>
        <template slot="body.prepend">
          <tr class="font-weight-bold">
            <td v-for="(h, index) in headers" :key="index">
              <div v-if="h.value === 'dp_name'">Total</div>
              <div v-if="h.value === 'estimated_revenue'" class="text-right">
                {{ formatMoney(getFinancialReportMain.total_estimated) + ' €' }}
              </div>
              <div v-if="h.value === 'final_revenue'" class="text-right">
                {{ formatMoney(getFinancialReportMain.total_final) + ' €' }}
              </div>
            </td>
          </tr>
        </template>
      </o-data-table>
      <financial-reporting-dialog
        ref="dialogRef"
        :close-dialog="closeDialog"
        :is-open="dialog"
        :refresh-parent="fetchFinancialReporting"
      />
      <o-snackbar v-model="snackbarUnsavedChanges" top color="error" multi-line>
        {{ $t('financial_reporting.warning_unsaved_changes') }}
        <o-btn inverted color="red" @click="snackbarUnsavedChanges = false">
          {{ $t('buttons.general.ok') }}
        </o-btn>
      </o-snackbar>
    </o-col>
  </o-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FinancialReportingDialog from './FinancialReportingDialog';
import { moneyMixin } from '../../../helpers/moneyMixin';
import { mapStatusColor } from '@/helpers/mapStatusColorMixin';

export default {
  name: 'FinancialReportingTable',
  components: { FinancialReportingDialog },
  mixins: [moneyMixin, mapStatusColor],
  data: () => ({
    snackbarUnsavedChanges: false,
    dialog: false,
    dialogRow: {},
    datePicker: false,
    datePickerDate: null,
    demandPartnerAutocomplete: null,
    headers: [
      {
        text: 'Demand Partner',
        value: 'dp_name',
      },
      {
        text: 'Demand Partner Account',
        value: 'dpa_name',
      },
      {
        text: 'Estimated Revenue',
        value: 'estimated_revenue',
        align: 'right',
      },
      {
        text: 'Final Revenue',
        value: 'final_revenue',
        align: 'right',
      },
      {
        text: 'Correction Ratio',
        value: 'correction_ratio',
      },
      {
        text: 'Correction Value',
        value: 'correction_value',
      },
      {
        text: 'Publishers exceptions',
        value: 'publishers_exceptions',
      },
      {
        text: 'Status',
        value: 'status',
      },
      {
        text: 'Action',
        value: 'action',
        sortable: false,
        filterable: false,
        align: 'center',
      },
    ],
  }),
  mounted() {
    this.fetchDemandPartners();
    this.fetchFinancialReporting();
    this.setUpDate(this);
    this.demandPartnerAutocomplete = this.getDPAId || null;
  },
  computed: {
    ...mapGetters('demandPartners', ['getDemandPartners']),
    ...mapGetters('demandPartners', { getLoadingDemandPartners: 'getLoading' }),
    ...mapGetters('financialReporting', [
      'getFinancialReportMain',
      'anyModsUnsaved',
      'getDate',
      'getDPAId',
    ]),
    ...mapGetters('financialReporting', {
      getLoadingFinancialReporting: 'getLoading',
    }),
  },
  methods: {
    ...mapActions('demandPartners', ['fetchDemandPartners']),
    ...mapActions('financialReporting', [
      'fetchFinancialReportMain',
      'setDPA',
      'setSelectedDate',
    ]),
    openDialog(item) {
      this.$refs.dialogRef.setDialog(item, this.datePickerDate);
      this.dialog = true;
    },
    closeDialog() {
      if (this.anyModsUnsaved) {
        this.snackbarUnsavedChanges = true;
      } else {
        this.dialog = false;
      }
    },
    setUpDate() {
      const d = new Date();
      d.setDate(0); //prev month
      d.setDate(1); // set first day of prev month
      this.datePickerDate = this.getDate || d.toISOString().substr(0, 7);
      this.setSelectedDate(this.datePickerDate);
    },
    fetchFinancialReporting() {
      this.fetchFinancialReportMain();
    },
    selectDPA(event) {
      this.setDPA(event);
      this.fetchFinancialReportMain();
    },
    selectDate(event) {
      this.setSelectedDate(event);
      this.datePicker = false;
      this.fetchFinancialReportMain();
    },
  },
};
</script>

<style scoped>
.table-bcg-color {
  background-color: #90caf9;
}
</style>
