import { eventBus } from '@/main';

const store = {
  namespaced: true,

  state: {
    warnings: [],
    actionWarnings: [],
    warningDialog: false,
    snackbar_info: {
      snackbar: false,
      color: 'info',
      text: 'Placeholder',
    },
  },

  getters: {
    getWarnings: (state) => state.warnings,
    getActionWarnings: (state) => state.actionWarnings,
    getSnackbarInfo: (state) => state.snackbar_info,
  },

  mutations: {
    addWarning(state, warning) {
      let new_warn = undefined;
      if (warning instanceof Array) {
        for (const warn of warning) {
          new_warn = {
            msg: warn,
            date: new Date().toISOString(),
            new: true,
          };
          state.warnings.push({ ...new_warn });
        }
      } else {
        new_warn = {
          msg: warning,
          date: new Date().toISOString(),
          new: true,
        };
        state.warnings.push({ ...new_warn });
      }
    },
    addActionWarning(state, warning) {
      let new_warn = undefined;
      state.actionWarnings = [];
      if (warning instanceof Array) {
        for (const warn of warning) {
          new_warn = {
            msg: warn,
            date: new Date().toISOString(),
          };
          state.actionWarnings.push({ ...new_warn });
        }
      } else {
        new_warn = {
          msg: warning,
          date: new Date().toISOString(),
        };
        state.actionWarnings.push({ ...new_warn });
      }
    },
    snackbarInfo(state, info) {
      state.snackbar_info.text = info;
      state.snackbar_info.color = 'info';
      state.snackbar_info.snackbar = true;
    },
    snackbarError(state, info) {
      state.snackbar_info.text = info;
      state.snackbar_info.color = 'error';
      state.snackbar_info.snackbar = true;
    },
    closeSnackbar(state) {
      state.snackbar_info = {
        snackbar: false,
        color: 'info',
        text: 'Placeholder',
      };
    },
    clearWarnings(state) {
      state.warnings = [];
    },
    clear_new_flag(state) {
      state.warnings.forEach((el) => (el.new = false));
    },
  },

  actions: {
    addWarning({ commit }, warn) {
      commit('addWarning', warn);
    },
    addActionWarning({ commit }, warn) {
      commit('addActionWarning', warn);
    },
    openDialog() {
      eventBus.$emit('warn');
    },
    closeDialog() {
      eventBus.$emit('close_warn');
    },
    clearWarnings({ commit }) {
      commit('clearWarnings');
    },
    warnings_clear_new_flag({ commit }) {
      commit('clear_new_flag');
    },
    closeSnackbar({ commit }) {
      commit('closeSnackbar');
    },
    showSnackbarInfo({ commit }, msg) {
      commit('snackbarInfo', msg);
    },
    showSnackbarError({ commit }, msg) {
      commit('snackbarError', msg);
    },
  },
};

export default store;
