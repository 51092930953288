export const baseValidation = {
  data() {
    return {
      baseValidation: {
        requiredConditional: (condition, v) => {
          if (condition) {
            return (v !== '' && v != null) || 'This field is required';
          }
          return true;
        },
        required: [(v) => (v !== '' && v != null) || 'This field is required'],
        requiredIfActive: (status) => [
          (v) =>
            status === 0 || (v !== '' && v != null) || 'This field is required',
        ],
        containsIntegerOnly(v) {
          for (let i = 0; i < v.length; i++) {
            if (/[^0-9]/g.test(v[i])) {
              return 'Insert numbers only';
            }
            v[i] = parseInt(v[i]);
          }
          return true;
        },
        decimal: [
          (v) =>
            v === '' ||
            v === null ||
            /^-?\d*$/.test(v) ||
            'This field needs to be number',
        ],
        intGreaterThanOne: [
          (v) => v >= 1 || 'This field need to be greater or equals to 1',
        ],
        integer: [
          (v) =>
            v === '' ||
            /^\d+$/.test(v) ||
            'This field needs to be integer number',
        ],
        nullable_integer: [
          (v) =>
            v === '' ||
            v === null ||
            /^\d+$/.test(v) ||
            'This field needs to be integer number',
        ],
        nullable_positive_integer: [
          (v) =>
            v === '' ||
            v === null ||
            (+v >= 0 && /^\d+$/.test(v)) ||
            'This field needs to be a positif integer number',
        ],
        email: [
          (v) =>
            v === '' ||
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              v,
            ) ||
            'E-mail must be valid',
        ],
        url: [
          (v) =>
            v === '' ||
            /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/.test(
              v,
            ) ||
            /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?$/.test(
              v,
            ) ||
            'URL must be valid',
        ],
        url_with_http: [
          (v) =>
            v === '' ||
            /(http(s)?:\/\/)[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/.test(
              v,
            ) ||
            'URL must be valid and starts with http...',
        ],
        domain: [
          (v) =>
            v === '' ||
            /^(([a-zA-Z]{1})|([a-zA-Z]{1}[a-zA-Z]{1})|([a-zA-Z]{1}[0-9]{1})|([0-9]{1}[a-zA-Z]{1})|([a-zA-Z0-9][a-zA-Z0-9-_]{1,61}[a-zA-Z0-9]))\.([a-zA-Z]{2,6}|[a-zA-Z0-9-]{2,30}\.[a-zA-Z]{2,3})$/.test(
              v,
            ) ||
            'Domain must be valid. Valid characters a-z or A-Z or 0-9 and hyphen (-), max 63 characters, cannot start or end with hyphen (-)',
        ],
        money: [
          (v) =>
            v === '' ||
            /^[0-9,. ]+$/.test(v) ||
            'Money can be described by digits 0-9 and "," "."',
        ],
        percentage_integer: [
          (v) =>
            /^[1-9][0-9]?$|^100$|^0$/.test(v) ||
            'Percentage value is between 0-100',
        ],
        percentage_decimal: [
          (v) =>
            /^(100([,.]0)?0?)?(\d{1,2}([,.]\d{1,2})?)?$/.test(v) ||
            'Percentage value is between 0.00-100.00',
        ],
        letters_and_numbers: [
          (v) =>
            /^[a-zA-Z0-9]+$/.test(v) || 'Only letters and numbers are allowed',
        ],
        letters_and_numbers_contain_underscore: [
          (v) =>
            /^[a-z0-9][a-z0-9_]*[a-z0-9]$/.test(v) ||
            "Only letters, '_' and numbers are allowed. (Cannot start or end with '_')",
        ],
        non_empty_array: [
          (v) =>
            v?.length > 0 || 'This field needs to contain at least one item',
        ],
        checkProductSelection: [
          (value) => {
            if (!Array.isArray(value) || value.length === 0) {
              return 'Product must be selected';
            }

            const aio = value.some((product) => product?.id === 1);
            const engage = value.some((product) => product?.id === 3);

            if (aio && engage) {
              return "All In One and Opti Engage can't be chosen at the same time.";
            }

            return true;
          },
        ],
        max_file_size: (file, max_size) =>
          !file ||
          file?.size < max_size ||
          `File size should be less than ${Math.floor(max_size / 1000)} KB!`,
      },
    };
  },
};
