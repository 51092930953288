<template>
  <!-- Pass through the listener used from the parent -->
  <!-- To watch carefully in Vue 3 cause -->
  <!-- $listeners is included into $attrs -->
  <!-- $listener is removed -->
  <span v-bind="{ ...$attrs }" v-on="$listeners">
    <o-icon v-if="isPrintableArrows" small>
      {{ sortDesc ? 'mdi-arrow-up' : 'mdi-arrow-down' }}
    </o-icon>
    {{ header['text'] }}
  </span>
</template>
<script>
export default {
  name: 'SortableSpan',
  props: {
    header: {
      type: Object,
      required: true,
      validator(_header) {
        return [
          !!_header,
          Object.prototype.hasOwnProperty.call(_header, 'text'),
          Object.prototype.hasOwnProperty.call(_header, 'value'),
        ].every((predicate) => predicate);
      },
    },
    sortBy: {
      type: Array,
      default() {
        return [];
      },
    },
    sortDesc: Boolean,
  },
  computed: {
    isSortable() {
      return this.header['sortable'] ?? true;
    },
    isPrintableArrows() {
      return this.isSortable && this.header['value'] === this.sortBy[0];
    },
  },
};
</script>
