import { VAlert } from 'vuetify/lib/components/VAlert';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VAlert,_vm._g(_vm._b({scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,name){return {key:name,fn:function(render){return [_vm._t(name,null,null,render)]}}})],null,true)},'v-alert',{
      ..._vm.$attrs,
      ..._vm.$props,
      class: _vm.classes,
    },false),_vm.$listeners),[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }