<template>
  <v-btn
    v-bind="{
      ...$attrs,
      ...$props,
      label: _label,
      class: classes,
    }"
    v-on="$listeners"
    @change="$emit('input', $event)"
  >
    <template v-for="(_, name) in $scopedSlots" #[name]="render">
      <slot :name="name" v-bind="render"></slot>
    </template>

    <!-- static slots definition -->
    <template v-if="description !== undefined" #label>
      <icon-tooltip :content="$t(label)" :description="$t(description)" />
    </template>
    <template v-else #label>
      <slot name="label"></slot>
    </template>

    <slot></slot>
  </v-btn>
</template>
<script>
import IconTooltip from '@/components/DesignSystem/OTooltip/IconTooltip.vue';

export default {
  name: 'OBtn',
  components: { IconTooltip },
  props: {
    label: {
      type: [String, undefined],
      default: undefined,
    },
    description: {
      type: [String, undefined],
      default: undefined,
    },
  },
  emits: ['input'],
  computed: {
    _label() {
      if (/^[a-z_0-9]+(\.[a-z_0-9]+)+$/.test(this.label)) {
        return this.$t(this.label);
      }
      return this.label;
    },
    classes() {
      // specific to Vue 2
      return this.$vnode.data.staticClass;
    },
  },
};
</script>

<style scoped></style>
