<template>
  <v-textarea
    ref="vInput"
    v-bind="{
      ...$attrs,
      ...$props,
      label: _label,
    }"
    v-on="$listeners"
    @change="$emit('input', $event)"
  >
    <template v-for="(_, name) in $scopedSlots" #[name]="render">
      <slot :name="name" v-bind="render"></slot>
    </template>

    <!-- static slots definition -->
    <template v-if="description !== undefined" #label>
      <icon-tooltip :content="$t(label)" :description="$t(description)" />
    </template>
    <template v-else #label>
      <slot name="label"></slot>
    </template>
  </v-textarea>
</template>
<script>
import IconTooltip from '@/components/DesignSystem/OTooltip/IconTooltip.vue';
import baseInput from '@/components/DesignSystem/OInput/base_input_script';

export default {
  name: 'OTextarea',
  components: { IconTooltip },
  props: {
    ...baseInput.props,
    itemText: {
      type: [String, Array, Function],
      default: 't',
    },
    itemValue: {
      type: [String, Array, Function],
      default: 'v',
    },
  },
  emits: ['input'],
  computed: {
    ...baseInput.computed,
  },
  methods: {
    ...baseInput.methods,
  },
};
</script>

<style scoped></style>
