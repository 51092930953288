var render = function render(){var _vm=this,_c=_vm._self._c;return _c('o-row',{attrs:{"align":"center","justify":"center"}},[_c('o-col',{attrs:{"cols":"12"}},[_c('o-row',{attrs:{"justify":"center","align":"center"}},[_c('o-col',{attrs:{"cols":"5"}},[_c('o-row',{attrs:{"justify":"center","align":"center"}},[_c('o-col',[_c('o-card-title',[_vm._v(" Auto-invoices Turnover Publisher ")])],1)],1),_c('o-row',[_c('o-col',{attrs:{"cols":"12"}},[_c('o-row',{staticClass:"pt-5 pb-5",attrs:{"align":"center","justify":"center"}},[_c('o-col',{attrs:{"cols":"6"}},[_c('o-row',{attrs:{"align":"center","justify":"center"}},[_c('o-card-title',[_vm._v("Net 30:")]),_c('o-chip',{attrs:{"color":_vm.mapFinancialPaymentColor(
                          _vm.getPaymentStatusInfo.opti_net30.status,
                        )}},[_vm._v(" "+_vm._s(_vm.getStatusName(_vm.getPaymentStatusInfo.opti_net30.status))+" ")])],1)],1),_c('o-col',{attrs:{"cols":"6"}},[_c('payment-info-action-buttons',{staticClass:"text-center",attrs:{"payment-info":_vm.getPaymentStatusInfo.opti_net30,"dpa_type":"optidigital","payment_term":"NET 30"}})],1)],1)],1)],1),_c('o-divider'),_c('o-row',[_c('o-col',{attrs:{"cols":"12"}},[_c('o-row',{staticClass:"pt-5 pb-5",attrs:{"align":"center","justify":"center"}},[_c('o-col',{attrs:{"cols":"6"}},[_c('o-row',{attrs:{"align":"center","justify":"center"}},[_c('o-card-title',[_vm._v("Net 60:")]),_c('o-chip',{attrs:{"color":_vm.mapFinancialPaymentColor(
                          _vm.getPaymentStatusInfo.opti_net60.status,
                        )}},[_vm._v(" "+_vm._s(_vm.getStatusName(_vm.getPaymentStatusInfo.opti_net60.status))+" ")])],1)],1),_c('o-col',{attrs:{"cols":"6"}},[_c('payment-info-action-buttons',{staticClass:"text-center",attrs:{"payment-info":_vm.getPaymentStatusInfo.opti_net60,"dpa_type":"optidigital","payment_term":"NET 60"}})],1)],1)],1)],1)],1),_c('o-divider',{attrs:{"vertical":""}}),_c('o-col',{attrs:{"cols":"5"}},[_c('o-row',{attrs:{"justify":"center","align":"center"}},[_c('o-col',[_c('o-card-title',[_vm._v(" Invoice commission Opti Digital ")])],1)],1),_c('o-row',[_c('o-col',{attrs:{"cols":"12"}},[_c('o-row',{staticClass:"pt-5 pb-5",attrs:{"align":"center","justify":"center"}},[_c('o-col',{attrs:{"cols":"6"}},[_c('o-row',{attrs:{"align":"center","justify":"center"}},[_c('o-card-title',[_vm._v("Net 30:")]),_c('o-chip',{attrs:{"color":_vm.mapFinancialPaymentColor(
                          _vm.getPaymentStatusInfo.pub_net30.status,
                        )}},[_vm._v(" "+_vm._s(_vm.getStatusName(_vm.getPaymentStatusInfo.pub_net30.status))+" ")])],1)],1),_c('o-col',{attrs:{"cols":"6"}},[_c('payment-info-action-buttons',{staticClass:"text-center",attrs:{"payment-info":_vm.getPaymentStatusInfo.pub_net30,"dpa_type":"publisher","payment_term":"NET 30"}})],1)],1)],1)],1),_c('o-divider'),_c('o-row',[_c('o-col',{attrs:{"cols":"12"}},[_c('o-row',{staticClass:"pt-5 pb-5",attrs:{"align":"center","justify":"center"}},[_c('o-col',{attrs:{"cols":"6"}},[_c('o-row',{attrs:{"align":"center","justify":"center"}},[_c('o-card-title',[_vm._v("Net 60:")]),_c('o-chip',{attrs:{"color":_vm.mapFinancialPaymentColor(
                          _vm.getPaymentStatusInfo.pub_net60.status,
                        )}},[_vm._v(" "+_vm._s(_vm.getStatusName(_vm.getPaymentStatusInfo.pub_net60.status))+" ")])],1)],1),_c('o-col',{attrs:{"cols":"6"}},[_c('payment-info-action-buttons',{staticClass:"text-center",attrs:{"payment-info":_vm.getPaymentStatusInfo.pub_net60,"dpa_type":"publisher","payment_term":"NET 60"}})],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }