<template>
  <o-data-table
    :loading="getLoading"
    :headers="getHeaders"
    :items="getPublisherFinancialFilteredRows"
    :footer-props="{
      'show-first-last-page': true,
      'items-per-page-options': [10, 20, 30, 50, 100],
    }"
  >
    <template #top>
      <o-row>
        <o-col cols="12">
          <o-row>
            <o-col cols="12">
              <o-form ref="pub_select">
                <o-row>
                  <o-col cols="2">
                    <o-menu
                      v-model="datePicker"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template #activator="{ on }">
                        <o-text-field
                          v-model="datePickerDate"
                          prepend-icon="event"
                          readonly
                          v-on="on"
                        />
                      </template>
                      <o-date-picker
                        v-model="datePickerDate"
                        type="month"
                        :rules="createRules([baseValidation.required])"
                        @input="selectMonth(datePickerDate)"
                      />
                    </o-menu>
                  </o-col>
                  <o-col class="d-flex" cols="auto">
                    <o-autocomplete
                      :items="getPublishers"
                      label="sites.select_label"
                      :v-model="publisher"
                      :value="publisher"
                      :rules="createRules([baseValidation.required])"
                      @input="selectPublisher($event)"
                    />
                  </o-col>
                  <o-col class="d-flex" cols="auto">
                    <o-select
                      :items="getProducts"
                      item-text="name"
                      item-value="id"
                      label="product.select_product"
                      :value="getProductId"
                      :rules="createRules([baseValidation.required])"
                      @change="selectProduct($event)"
                    />
                  </o-col>
                  <o-col class="d-flex" cols="auto">
                    <o-switch v-model="showSite" label="Show site" />
                  </o-col>
                  <o-col class="d-flex" cols="auto">
                    <o-autocomplete
                      v-model="filter.payment_term"
                      :items="getPaymentTerms"
                      item-value="name"
                      label="Payment Term"
                      @change="updateQueryPaymentTerm"
                    >
                      <template #append-outer>
                        <o-icon
                          icon
                          @click="
                            () => {
                              filter.payment_term = null;
                              updateQueryPaymentTerm(null);
                            }
                          "
                        >
                          delete
                        </o-icon>
                      </template>
                    </o-autocomplete>
                  </o-col>
                  <o-col class="d-flex" cols="auto">
                    <o-autocomplete
                      v-model="filter.dpa_type"
                      :items="dpa_types_list"
                      label="DPA Type"
                      @change="updateQueryDpaType"
                    >
                      <template #append-outer>
                        <o-icon
                          icon
                          @click="
                            () => {
                              filter.dpa_type = null;
                              updateQueryDpaType(null);
                            }
                          "
                        >
                          delete
                        </o-icon>
                      </template>
                    </o-autocomplete>
                  </o-col>
                  <o-col>
                    <o-btn
                      class="ma-4"
                      color="success"
                      @click="fetchPublisherFinancial"
                    >
                      Refresh
                    </o-btn>
                  </o-col>
                </o-row>
              </o-form>
            </o-col>
          </o-row>
          <o-row>
            <o-col cols="12">
              <o-divider />
              <payment-info />
              <o-divider />
            </o-col>
          </o-row>
        </o-col>
      </o-row>
    </template>
    <template #item.estimated_revenue="{ value }">
      {{ formatMoney(value) + ' €' }}
    </template>
    <template #item.final_revenue="{ value }">
      {{ formatMoney(value) + ' €' }}
    </template>
    <template #item.final_turnover_net="{ value }">
      {{ formatMoney(value) + ' €' }}
    </template>
    <template #item.final_margin_optidigital="{ value }">
      {{ formatMoney(value) + ' €' }}
    </template>
    <template #item.status="{ value }">
      <o-tooltip bottom :disabled="value.name === 'Estimated'">
        <template #activator="{ on }">
          <o-chip :color="mapFinancialStatusColor(value.name)" v-on="on">
            {{ value.name }}
          </o-chip>
        </template>
        <span v-if="value.name !== 'Estimated'"> {{ value.date }} </span>
      </o-tooltip>
    </template>
    <template #body.append>
      <tr>
        <th>Total:</th>
        <th v-if="showSite" />
        <th />
        <th />
        <td align="right">
          <b>
            {{
              formatMoney(getPublisherFinancial.total_estimated || 0) + ' €'
            }}</b
          >
        </td>
        <td align="right">
          <b>
            {{ formatMoney(getPublisherFinancial.total_final || 0) + ' €' }}</b
          >
        </td>
        <td align="right">
          <b> {{ getPublisherFinancial.correction_ratio_global }}</b>
        </td>
        <td align="right">
          <b>
            {{
              formatMoney(getPublisherFinancial.total_turnover_net || 0) + ' €'
            }}</b
          >
        </td>
        <td align="right">
          <b>
            {{
              formatMoney(getPublisherFinancial.total_margin_optidigital || 0) +
              ' €'
            }}</b
          >
        </td>
        <th />
        <th />
      </tr>
    </template>
    <o-row><o-divider /></o-row>
  </o-data-table>
</template>

<script>
import store from './../../../store/index';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { moneyMixin } from '@/helpers/moneyMixin';
import { baseValidation } from '@/validation/baseValidation';
import { validationHelper } from '@/validation/validationHelper';
import { mapStatusColor } from '@/helpers/mapStatusColorMixin';
import PaymentInfo from './PaymentInfo.vue';

export default {
  name: 'ReportingTurnoverFinalTable',
  mixins: [moneyMixin, baseValidation, validationHelper, mapStatusColor],
  data() {
    return {
      datePicker: false,
      datePickerDate: null,
      publisher: null,
      showSite: false,
      dpa_types_list: ['publisher', 'optidigital'],
      filter: {
        dpa_type: null,
        payment_term: null,
      },
      publisher_header: {
        text: 'Publisher Site',
        value: 'publisher_site',
      },
      headers: [
        {
          text: 'Publisher',
          value: 'publisher_name',
        },
        {
          text: 'Demand Partner Account',
          value: 'dpa_name',
        },
        {
          text: 'DPA Type',
          value: 'dpa_type',
        },
        {
          text: 'Estimated Revenue',
          value: 'estimated_revenue',
          align: 'right',
        },
        {
          text: 'Final Revenue',
          value: 'final_revenue',
          align: 'right',
        },
        {
          text: 'Correction Ratio',
          value: 'correction_ratio',
          align: 'right',
        },
        {
          text: 'Publisher Net',
          value: 'final_turnover_net',
          align: 'right',
        },
        {
          text: 'Opti Digital Net',
          value: 'final_margin_optidigital',
          align: 'right',
        },
        {
          text: 'Payment Term',
          value: 'payment_term',
          align: 'right',
        },
        {
          text: 'Status',
          value: 'status',
        },
      ],
    };
  },
  watch: {
    showSite(newValue) {
      this.setShowSite(newValue);
      store.dispatch('turnoverFinal/fetchPublisherFinancial');
    },
  },
  beforeDestroy() {
    this.datePickerDate = null;
    this.publisher = null;
    this.filter.dpa_type = null;
    this.setUpDate();
    this.setDate(null);
    this.setPublisherId(null);
    this.setPublisherFinancial({
      rows: [],
      estimated_revenue: 0,
      final_revenue: 0,
    });
  },
  computed: {
    ...mapGetters('turnoverFinal', [
      'getLoading',
      'getPublisherFinancial',
      'getPublisherId',
      'getProductId',
      'getDate',
    ]),
    ...mapGetters('product', {
      getProducts: 'getProducts',
      getProductsLoading: 'getLoading',
    }),
    ...mapGetters('paymentTerm', ['getPaymentTerms']),
    ...mapGetters('publishers', [
      'getPublishers',
      'getPennylaneMappingPublisher',
    ]),
    ...mapActions('publishers', ['fetchPublishers']),
    getHeaders() {
      if (this.showSite) {
        return [
          ...this.headers.slice(0, 1),
          this.publisher_header,
          ...this.headers.slice(1),
        ];
      }
      return this.headers;
    },
    getPublisherFinancialFilteredRows() {
      if (!this.getPublisherFinancial.rows) {
        return [];
      }
      return this.getPublisherFinancial.rows.filter((value) => {
        if (this.filter.dpa_type) {
          if (value.dpa_type !== this.filter.dpa_type) {
            return false;
          }
        }
        if (this.filter.payment_term) {
          if (value.payment_term !== this.filter.payment_term) {
            return false;
          }
        }
        return true;
      });
    },
  },
  created() {
    store.dispatch('publishers/fetchPublishers');
    store.dispatch('product/fetchProducts');
    store.dispatch('turnoverFinal/fetchPublisherFinancial');
    this.fetchPaymentTerms();
    this.setQueryFilters({ paymentTerm: '', DpaType: '' });
    this.setShowSite(this.showSite);
    this.setUpDate(this);
    this.publisher = this.getPublisherId || null;
    const urlSearchParams = new URLSearchParams(window.location.search);
    const queryStringParams = Object.fromEntries(urlSearchParams.entries());
    if (Object.entries(queryStringParams).length !== 0) {
      this.publisher = parseInt(queryStringParams.publisher_id);
      this.selectPublisher(parseInt(queryStringParams.publisher_id));
    }
  },
  mounted() {
    this.$refs.pub_select.validate();
  },
  methods: {
    ...mapActions('paymentTerm', ['fetchPaymentTerms']),
    ...mapActions('turnoverFinal', [
      'fetchPublisherFinancial',
      'setPublisher',
      'setSelectedDate',
    ]),
    ...mapMutations('turnoverFinal', [
      'setShowSite',
      'setQueryFilters',
      'setPublisherId',
      'setProductId',
      'setDate',
      'setPublisherFinancial',
    ]),
    setUpDate() {
      const d = new Date();
      d.setDate(0); //prev month
      d.setDate(1); // set first day of prev month
      this.datePickerDate = this.getDate || d.toISOString().substr(0, 7);
      store.dispatch('turnoverFinal/setSelectedDate', this.datePickerDate);
    },
    selectPublisher(event) {
      store.dispatch('turnoverFinal/setPublisher', event);
      store.dispatch('turnoverFinal/fetchPublisherFinancial');
      store.dispatch('publishers/getPennylaneClientMappingByPublisher', event);
    },
    selectProduct(event) {
      store.commit('turnoverFinal/setProductId', event);
      store.dispatch('turnoverFinal/fetchPublisherFinancial');
    },
    selectMonth(event) {
      store.dispatch('turnoverFinal/setSelectedDate', event);
      this.datePicker = false;
      store.dispatch('turnoverFinal/fetchPublisherFinancial');
    },
    updateQueryPaymentTerm(paymentTerm) {
      this.setQueryFilters({ paymentTerm, dpaType: this.filter.dpa_type });
      store.dispatch('turnoverFinal/fetchPublisherFinancial');
    },
    updateQueryDpaType(dpaType) {
      this.setQueryFilters({
        paymentTerm: this.filter.payment_term,
        dpaType,
      });
      store.dispatch('turnoverFinal/fetchPublisherFinancial');
    },
  },
  components: { PaymentInfo },
};
</script>

<style scoped></style>
