<template>
  <o-card min-height="50vh" class="scroll d-flex flex-column">
    <simple-loader v-model="pending_save" absolute :opacity="0.4" />
    <o-card-title>
      <o-row>
        <o-col>
          {{ $t('floor_manager.title.settings') }}
        </o-col>
        <o-col align="right">
          <o-btn icon @click="onClose">
            <o-icon>close</o-icon>
          </o-btn>
        </o-col>
      </o-row>
    </o-card-title>
    <o-card-text>
      <span v-if="edit_pf.updated_by">
        {{ $t('floor_manager.label.last_update', edit_pf) }}
      </span>
      <o-form
        v-if="dimensions"
        ref="dimension_card_form"
        v-model="valid"
        lazy-validation
      >
        <o-row class="ma-1">
          <o-col>
            <o-text-field
              v-model="price_name"
              :disabled="!isAbleToEdit"
              outlined
              dense
              hide-details="auto"
              :rules="[
                (v) => (v ? true : 'Cannot save an empty name'),
                (v) =>
                  v?.length < 255
                    ? true
                    : 'Rule name support only 255 characters',
              ]"
              @change="
                changeHandler({ field: 'name', value: price_name }, $event)
              "
            >
              <template #label>
                <icon-tooltip
                  :content="$t('floor_manager.label.pf.name')"
                  :description="$t('floor_manager.help.pf.name')"
                />
              </template>
            </o-text-field>
          </o-col>
          <o-col cols="auto">
            <o-text-field
              ref="price_floor_input"
              v-model="price_floor"
              :disabled="!isAbleToEdit"
              outlined
              dense
              hide-details="auto"
              label="Price floor"
              type="number"
              :rules="[
                (v) => (v ? true : 'Please fill a price floor before saving.'),
                (v) =>
                  v > 0 ? true : 'A price floor should be greater than 0',
              ]"
              @focus="price_floor_focus = true"
              @blur="price_floor_focus = false"
              @change="
                changeHandler({ field: 'price', value: price_floor }, $event)
              "
            >
              <template #label>
                <icon-tooltip
                  :content="$t('floor_manager.label.pf.value')"
                  :description="$t('floor_manager.help.pf.value')"
                />
              </template>
            </o-text-field>
          </o-col>
        </o-row>
        <o-row v-if="price_floor > 15">
          <o-col cols="12">
            <o-alert dismissible type="warning">
              {{
                $t('floor_manager.label.warning.high_price', {
                  value: price_floor,
                })
              }}
            </o-alert>
          </o-col>
        </o-row>
        <o-row>
          <o-expansion-panels :disabled="!isAbleToEdit">
            <o-expansion-panel
              v-for="dimension in activeDimension"
              :key="dimension.name"
            >
              <o-expansion-panel-header>
                <o-row justify="space-between">
                  <o-col>
                    <strong>
                      <icon-tooltip
                        :content="
                          $t(
                            `floor_manager.label.dimension.${dimension.name.toLowerCase()}`,
                          )
                        "
                        :description="
                          $t(
                            `floor_manager.help.dimension.${dimension.name.toLowerCase()}`,
                          )
                        "
                      />
                    </strong>
                  </o-col>
                  <o-col>
                    <strong>{{ customLabel(dimension) }}</strong>
                  </o-col>
                </o-row>
              </o-expansion-panel-header>
              <o-expansion-panel-content>
                <o-row>
                  <o-col>
                    <component
                      :is="
                        customElement(dimension.type?.component, { dimension })
                      "
                      v-bind="
                        customOptions(dimension.type?.options, { dimension })
                      "
                      ref="dimension"
                      v-model="dimension.value"
                      outlined
                      @change="
                        changeHandler(
                          {
                            field: 'dimension',
                            value: dimension,
                          },
                          $event,
                        )
                      "
                    />
                  </o-col>
                </o-row>
                <o-row>
                  <span v-if="dimension.updated_by">
                    {{ $t('floor_manager.label.last_update', edit_pf) }}
                  </span>
                </o-row>
              </o-expansion-panel-content>
            </o-expansion-panel>
          </o-expansion-panels>
        </o-row>
      </o-form>

      <div v-else>{{ $t('floor_manager.label.no_select') }}</div>
    </o-card-text>
    <v-footer v-if="isAbleToEdit" class="pa-2">
      <o-btn
        :disabled="!dimensions || (!hasChanged && !isAbleToSave)"
        color="primary"
        text
        :loading="pending_save"
        @click="onSave"
      >
        Save
      </o-btn>
      <o-btn v-if="onCancel" color="error" text @click="onCancel">
        Cancel
      </o-btn>
    </v-footer>
  </o-card>
</template>

<script>
import {
  VCombobox,
  VTextField,
  VSelect,
  VAutocomplete,
} from 'vuetify/lib/components';
import CountrySelector from '@/components/DesignSystem/OCountrySelector/CountrySelector.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { eventBus } from '@/main';
import store from '@/store';
import LoadingOverlay from '@/components/DesignSystem/OLoader/LoadingOverlay.vue';
import SimpleLoader from '@/components/DesignSystem/OLoader/SimpleLoader.vue';
import IconTooltip from '@/components/DesignSystem/OTooltip/IconTooltip.vue';

export default {
  name: 'DimensionCard',
  components: { IconTooltip, LoadingOverlay, CountrySelector, SimpleLoader },
  expose: ['hasChanged', 'onSave', 'resetSchema'],
  props: {
    dimensions: {
      type: Array,
    },
    onCancel: {
      type: Function,
    },
    onPostSave: {
      type: Function,
    },
    isAbleToEdit: {
      type: Boolean,
    },
  },
  data() {
    return {
      selectedDimensionToAdd: null,
      editMode: false,
      default_input: VTextField,
      pending_save: false,
      valid: true,
      price_floor_focus: false,
      dimension_handler: {
        [CountrySelector.name]: this.countrySelectorHandler,
        ['o-text-field']: () => VTextField,
        ['o-select']: () => VSelect,
        ['o-combobox']: () => VCombobox,
        ['v-autocomplete']: () => VAutocomplete,
      },
      loadingItems: {},
      schema: {
        price: {
          value: null,
          changed: false,
        },
        name: {
          value: null,
          changed: false,
        },
        dimension: {
          value: [],
          changed: false,
        },
        ableToSave: [],
      },
    };
  },
  computed: {
    ...mapGetters('floorRules', [
      'getDimensions',
      'getEditPriceFloor',
      'getDynamicItems',
    ]),

    activeDimension() {
      return this.dimensions?.filter((d) => d.soft_delete === false) ?? [];
    },
    edit_pf: {
      get() {
        if (!this.getEditPriceFloor) {
          return {};
        }
        return this.getEditPriceFloor;
      },
      set(value) {
        this.setEditPriceFloor(value);
      },
    },
    dynamicItems: {
      get() {
        return this.getDynamicItems;
      },
    },
    price_floor: {
      get() {
        return this.price_floor_focus
          ? this.edit_pf.floor
          : (+this.edit_pf.floor)
              .toLocaleString('en', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
              .replace(',', '.');
      },
      set(value) {
        this.edit_pf.floor = value;
      },
    },
    price_name: {
      get() {
        return this.edit_pf?.name;
      },
      set(value) {
        this.edit_pf.name = value;
      },
    },
    isAbleToSave() {
      return this.schema.ableToSave.includes(this.edit_pf.id);
    },
    hasChanged() {
      return (
        this.schema.dimension.changed ||
        this.schema.price.changed ||
        this.schema.name.changed
      );
    },
  },
  watch: {
    dimensions: {
      handler(newVal) {
        if (!newVal) {
          return;
        }
        const dimensions_registry = this.getDimensions;
        if (newVal.length === dimensions_registry.length) {
          return;
        }
        dimensions_registry.sort((a, b) => b.priority - a.priority);
        for (const dimension of dimensions_registry) {
          if (newVal.some(({ name }) => dimension.name === name)) {
            continue;
          }
          this.addDimensionToPriceFloor(dimension);
        }
      },
      immediate: true,
    },
  },
  methods: {
    changeHandler({ field, value }, event) {
      if (field === 'dimension' && this.schema['dimension'].changed === false) {
        this.schema.dimension.value.push(value);
        this.schema.dimension.changed = true;
        return;
      }

      if (this.schema[field].changed === false) {
        this.schema[field].value = value;
        this.schema[field].changed = true;
        return;
      }

      if (field !== 'dimension' && this.schema[field].value === event) {
        this.schema[field].value = null;
        this.schema[field].changed = false;
        return;
      }

      // dimension to no changes
      const [storedValue = { value: null }] =
        this.schema.dimension.value.filter(
          (dimension) => dimension.name === value.name,
        );

      if (storedValue.value === null) {
        return;
      }

      const isArray = Array.isArray(storedValue.value);
      if (
        (isArray &&
          JSON.stringify(storedValue.value) === JSON.stringify(event)) ||
        storedValue.value === event
      ) {
        this.schema.dimension.value.splice(
          this.schema.dimension.value.indexOf(storedValue),
          1,
        );
        this.schema.dimension.changed = false;
      }
    },
    ...mapMutations('floorRules', ['setEditPriceFloor']),
    ...mapActions('floorRules', [
      'removeDimensionToPriceFloor',
      'addDimensionToPriceFloor',
      'saveEditFloorRules',
    ]),
    customElement(value, { dimension }) {
      const handler = this.dimension_handler[value] ?? (() => VTextField);
      return handler(dimension);
    },
    handleReferenceItems(items) {
      const referenceName = items.substring(1);
      if (this.dynamicItems?.[referenceName]) {
        return this.dynamicItems[referenceName];
      }

      this.$set(this.loadingItems, referenceName, true);
      store
        .dispatch('floorRules/fetchReferenceItems', { items })
        .finally(() => this.$set(this.loadingItems, referenceName, false));
      return [];
    },
    customOptions(options) {
      if (!options) return {};

      const result = { ...options };
      const items = options.items;
      if (!items || typeof items !== 'string' || !items.startsWith('$')) {
        result.loading = false;
        return result;
      }

      const referenceName = items.substring(1);
      result.items = this.handleReferenceItems(items);
      result.loading = this.loadingItems[referenceName] || false;
      return result;
    },
    countrySelectorHandler(_) {
      return CountrySelector;
    },
    customLabel({ value, type }) {
      if (type?.component === CountrySelector.name) {
        return value?.length < 15
          ? value.map(({ name }) => name).join(', ')
          : `${value.length} selected countries`;
      }
      const items = type.options?.['items'];
      if (items && typeof items !== 'string') {
        return type.options.items
          .filter((item) => value.includes(item.v))
          .map((item) => item.t)
          .join(', ');
      }

      if (items && typeof items === 'string') {
        const reference = items.substring(1);
        return this.dynamicItems[reference]
          ?.filter((item) => value.includes(item.v))
          .map((item) => item.t)
          .join(', ');
      }

      if (Array.isArray(value)) {
        return value.join(', ');
      }
      return value;
    },
    async onSave(close = true) {
      if (!this.$refs.dimension_card_form?.validate?.()) {
        return;
      }

      this.pending_save = true;
      this.$refs.dimension?.forEach?.((el) => el?.blur?.());
      await this.$nextTick();

      await this.saveEditFloorRules();
      this.resetSchema(false);
      if (this.schema.ableToSave.includes(this.edit_pf.id)) {
        this.schema.ableToSave.splice(
          this.schema.ableToSave.indexOf(this.edit_pf.id),
          1,
        );
      }
      this.pending_save = false;
      if (close) {
        this.onClose();
      }
    },
    resetSchema(isAbleToSave = true) {
      this.schema.price.changed = false;
      this.schema.name.changed = false;
      this.schema.dimension.changed = false;
      this.schema.dimension.value.splice(0, this.schema.dimension.value.length);
      if (isAbleToSave) this.schema.ableToSave.push(this.edit_pf.id);
    },
    onClose() {
      if (this.onPostSave) {
        this.onPostSave();
      }
      eventBus.$emit('cancel_select_price_floor');
    },
  },
};
</script>

<style scoped>
.scroll {
  overflow-y: scroll;
}
.v-card {
  display: flex !important;
  flex-direction: column;
}
.v-card__text {
  flex-grow: 1;
  overflow: auto;
}
.v-footer {
  border-top: #c1bfbf 1px solid;
}
</style>
