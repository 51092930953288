<template>
  <v-tooltip
    v-bind="{
      ...$attrs,
      ...$props,
      class: classes,
    }"
    v-on="$listeners"
  >
    <template v-for="(_, name) in $scopedSlots" #[name]="render">
      <slot :name="name" v-bind="render"></slot>
    </template>
    <slot></slot>
  </v-tooltip>
</template>
<script>
export default {
  name: 'OTooltip',
  computed: {
    classes() {
      // specific to Vue 2
      return this.$vnode.data.staticClass;
    },
  },
};
</script>

<style scoped></style>
