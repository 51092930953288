<template>
  <o-card>
    <o-card-title>Redis Live</o-card-title>
    <o-card-text>
      <o-data-table
        height="80vh"
        :items="getRedisLive"
        :loading="getLoading"
        :headers="headers"
        :footer-props="{
          'items-per-page-options': [30],
          'show-first-last-page': true,
        }"
      >
        <template #[`item.config`]="{ item }">
          <p class="truncate">
            {{ item.config }}
          </p>
        </template>
        <template #[`item.action`]="{ item }">
          <o-btn depressed icon large class="mx-4" @click="viewItem(item)">
            <o-icon small> visibility </o-icon>
          </o-btn>
        </template>
      </o-data-table>
    </o-card-text>
    <o-dialog v-model="dialog" max-width="60%">
      <o-card>
        <o-card-title>
          <o-icon class="ma-4" small> visibility </o-icon>
          Config for {{ viewedItem.name }}
        </o-card-title>
        <o-card-text>
          <o-textarea
            v-model="viewedItem.config"
            no-resize
            rows="20"
            class="textarea"
            outlined
            readonly
            label="JSON"
          />
        </o-card-text>
        <o-card-actions>
          <o-spacer />
          <o-btn color="primary" @click="closeDialog()"> Close </o-btn>
        </o-card-actions>
      </o-card>
    </o-dialog>
  </o-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      viewedItem: {},
      dialog: false,
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Config', value: 'config' },
        { text: 'Action', value: 'action' },
      ],
    };
  },
  mounted() {
    this.fetchRedisLive();
  },
  computed: {
    ...mapGetters('releasesCrusader', ['getRedisLive', 'getLoading']),
  },
  methods: {
    ...mapActions('releasesCrusader', ['fetchRedisLive']),
    viewItem(item) {
      this.viewedItem = item;
      this.dialog = true;
    },
    closeDialog() {
      this.viewedItem = {};
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
.truncate {
  width: 25vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
