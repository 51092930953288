<template>
  <div class="main-container">
    <o-row>
      <o-card-title>Diff Tool</o-card-title>
      <o-spacer />
      <o-col cols="auto">
        <o-btn color="primary" @click="goBackToTM">
          {{ $t('common.button.manage_tags') }}
        </o-btn>
      </o-col>
    </o-row>
    <o-row dense>
      <o-col cols="auto">
        <o-form v-model="revisionAForm">
          <o-col cols="12">
            <o-row align="center" justify="start" dense>
              <o-col cols="auto">
                <o-autocomplete
                  v-model="siteAutocompleteModelA"
                  :items="getSiteEntities"
                  return-object
                  label="Site A"
                  :rules="baseValidation.required"
                  @change="onSiteSelect"
                />
              </o-col>
              <o-col cols="auto">
                <o-select
                  v-model="inventoryTypeSelectModelA"
                  :items="getInventoryTypeList()"
                  item-text="text"
                  item-value="value"
                  :rules="baseValidation.required"
                  label="Inventory Type"
                />
              </o-col>
              <o-col cols="auto">
                <o-autocomplete
                  v-model="revisionSelectModelA"
                  :items="_revisionSelectListA"
                  item-text="text"
                  item-value="value"
                  label="Revision A"
                  return-object
                  @change="onRevisionSelect('A', $event)"
                >
                  <template #selection="data">
                    <o-container dense class="ma-0 pa-0">
                      <o-row dense>
                        <o-col>
                          {{ data.item.number }}.{{ data.item.name }}
                        </o-col>
                        <o-chip
                          small
                          :color="mapStatusColor(data.item.status || '')"
                        >
                          {{ data.item.status }}
                        </o-chip>
                      </o-row>
                    </o-container>
                  </template>
                  <template #item="data">
                    <o-container dense class="ma-0 pa-0">
                      <o-row dense>
                        <o-col>
                          {{ data.item.number || 0 }}.{{ data.item.name || '' }}
                        </o-col>
                        <o-chip
                          small
                          :color="mapStatusColor(data.item.status || 'empty')"
                        >
                          {{ data.item.status || 'empty' }}
                        </o-chip>
                      </o-row>
                    </o-container>
                  </template>
                </o-autocomplete>
              </o-col>
            </o-row>
          </o-col>
        </o-form>
      </o-col>
    </o-row>
    <o-row dense>
      <o-col cols="auto">
        <o-form v-model="revisionBForm">
          <o-col cols="12">
            <o-row align="center" justify="start" dense>
              <o-col cols="auto">
                <o-autocomplete
                  v-model="siteAutocompleteModelB"
                  :items="getSiteEntities"
                  return-object
                  label="Site B"
                  :rules="baseValidation.required"
                  @change="onSiteSelect"
                />
              </o-col>
              <o-col cols="auto">
                <o-autocomplete
                  v-model="revisionSelectModelB"
                  :items="_revisionSelectListB"
                  label="Revision B"
                  item-text="text"
                  item-value="value"
                  return-object
                  @change="onRevisionSelect('B', $event)"
                >
                  <template #selection="data">
                    <o-container dense class="ma-0 pa-0">
                      <o-row dense>
                        <o-col>
                          {{ data.item.number }}.{{ data.item.name }}
                        </o-col>
                        <o-chip small :color="mapStatusColor(data.item.status)">
                          {{ data.item.status }}
                        </o-chip>
                      </o-row>
                    </o-container>
                  </template>
                  <template #item="data">
                    <o-container dense class="ma-0 pa-0">
                      <o-row dense>
                        <o-col>
                          {{ data.item.number || 0 }}.{{ data.item.name || '' }}
                        </o-col>
                        <o-chip
                          small
                          :color="mapStatusColor(data.item.status || 'empty')"
                        >
                          {{ data.item.status || 'empty' }}
                        </o-chip>
                      </o-row>
                    </o-container>
                  </template>
                </o-autocomplete>
              </o-col>
              <o-col cols="auto">
                <o-switch v-model="folding" label="Colapse no-diff" />
              </o-col>
            </o-row>
          </o-col>
        </o-form>
      </o-col>
    </o-row>
    <o-row dense>
      <div class="containerif">
        <iframe
          id="iframe-diff"
          ref="diffIframe"
          class="responsive-iframe"
          width="100%"
          height="70%"
          :src="getDiffToolURL"
        />
      </div>
    </o-row>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { mapStatusColor } from '@/helpers/mapStatusColorMixin';
import { eventBus } from '@/main';
import { baseValidation } from '@/validation/baseValidation';

export default {
  name: 'FileDiffTool',
  components: {},
  mixins: [mapStatusColor, baseValidation],
  data() {
    return {
      siteAutocompleteModelA: null,
      siteAutocompleteModelB: null,
      inventoryTypeSelectModelA: null,
      inventoryTypeSelectModelB: null,
      revisionSelectModelA: null,
      revisionSelectModelB: null,
      revisionListA: [],
      revisionListB: [],
      revisionAForm: null,
      revisionBForm: null,
      folding: true,
    };
  },
  computed: {
    _revisionSelectListA() {
      if (
        this.revisionListA &&
        Array.isArray(this.revisionListA) &&
        this.revisionListA.length > 0
      ) {
        return this.revisionListA;
      }

      if (this.revisionSelectModelA) {
        return [this.revisionSelectModelA];
      }

      return [];
    },
    _revisionSelectListB() {
      if (
        this.revisionListB &&
        Array.isArray(this.revisionListB) &&
        this.revisionListB.length > 0
      ) {
        return this.revisionListB;
      }

      if (this.revisionSelectModelB) {
        return [this.revisionSelectModelB];
      }

      return [];
    },
    ...mapGetters('siteWebConfig', ['getWebSetting', 'isDefaultConfig']),
    ...mapGetters('siteEntities', ['getSiteEntities']),
    ...mapGetters('tagsManager', [
      'getInventoryTypeList',
      'getSite',
      'getInventoryType',
      'getRevisionsList',
      'getSiteEntities',
    ]),
    ...mapGetters('revisionsDiff', [
      'getTextA',
      'getTextB',
      'getRevisionA',
      'getRevisionB',
      'getLoading',
      'getDiffToolURL',
    ]),
  },
  watch: {
    folding() {
      const frame = this.$refs.diffIframe;
      const payload = {
        folding: this.folding,
      };
      frame.contentWindow.postMessage(
        { call: 'sendFolding', value: payload },
        '*',
      );
    },
  },
  async mounted() {
    await this.fetchSiteEntities();
    if (
      this.getRevisionA.site &&
      this.getRevisionA.inventory_type &&
      this.getRevisionA.revision
    ) {
      this.siteAutocompleteModelA = this.getRevisionA.site;
      this.siteAutocompleteModelB = { ...this.getRevisionA.site };
      this.inventoryTypeSelectModelA = this.getRevisionA.inventory_type;
      this.revisionSelectModelA = this.getRevisionA.revision;
    } else if (
      this.$route.query.site &&
      this.$route.query.inventory_type &&
      this.$route.query.revision &&
      this.$route.query.revision_status
    ) {
      this.inventoryTypeSelectModelA = this.$route.query.inventory_type;
      this.siteAutocompleteModelA = this.getSiteEntities.find((item) => {
        return item.id === Number(this.$route.query.site);
      });
      this.siteAutocompleteModelB = this.siteAutocompleteModelA;
      this.revisionListA = await this.fetchRevisionsList({
        site_id: this.siteAutocompleteModelA.id,
        inventory_type: this.inventoryTypeSelectModelA,
      });
      this.revisionListB = await this.fetchRevisionsList({
        site_id: this.siteAutocompleteModelB.id,
        inventory_type: this.inventoryTypeSelectModelA,
      });
      let selectConfigData = {
        site: { ...this.siteAutocompleteModelA },
        inventoryType: this.$route.query.inventory_type,
      };
      await this.updateSiteAndInventoryInStore(selectConfigData);
      await this.fetchSiteConfigInfo();
      this.revisionSelectModelA = {
        ...this.revisionListA.find((item) => {
          return item.number === Number(this.$route.query.revision);
        }),
      };
      selectConfigData = {
        site: { ...this.siteAutocompleteModelA },
        inventory_type: this.inventoryTypeSelectModelA,
        revision: { ...this.revisionSelectModelA },
      };
      await this.selectRevisionInfoA(selectConfigData);
    }
    this.revisionListA = await this.fetchRevisionsList({
      site_id: this.siteAutocompleteModelA.id,
      inventory_type: this.inventoryTypeSelectModelA,
    });
    this.revisionListB = await this.fetchRevisionsList({
      site_id: this.siteAutocompleteModelB.id,
      inventory_type: this.inventoryTypeSelectModelA,
    });
    this.revisionSelectModelB =
      {
        ...this.revisionListB.find((item) => {
          return item.status.toLowerCase() === 'live';
        }),
      } || null;
    await this.onSiteSelect();
    await this.onRevisionSelect();
  },
  async created() {
    await this.fetchSiteEntities();
  },
  methods: {
    ...mapActions('revisionsDiff', [
      'selectRevisionInfoA',
      'selectRevisionInfoB',
      'fetchRevisionsList',
    ]),
    ...mapActions('siteEntities', ['fetchSiteEntities']),
    ...mapActions('tagsManager', [
      'updateSiteAndInventoryInStore',
      'fetchSiteConfigInfo',
    ]),
    async onSiteSelect() {
      if (!this.siteAutocompleteModelB) {
        return;
      }
      const selectConfigData = {
        site: { ...this.siteAutocompleteModelB },
        inventoryType: this.$route.query.inventory_type,
      };
      await this.updateSiteAndInventoryInStore(selectConfigData);

      this.revisionListA = await this.fetchRevisionsList({
        site_id: this.siteAutocompleteModelA.id,
        inventory_type: this.inventoryTypeSelectModelA,
      });
      this.revisionListB = await this.fetchRevisionsList({
        site_id: this.siteAutocompleteModelB.id,
        inventory_type: this.inventoryTypeSelectModelA,
      });
    },
    async onRevisionSelect(typeDiff = 'B') {
      const _reference = {
        A: {
          model: this.revisionSelectModelA,
          site: this.siteAutocompleteModelA,
          _selectMethod: this.selectRevisionInfoA,
        },
        B: {
          model: this.revisionSelectModelB,
          site: this.siteAutocompleteModelB,
          _selectMethod: this.selectRevisionInfoB,
        },
      }[typeDiff];

      if (!_reference.model) {
        return;
      }
      const revisionInfo = {
        site: { ..._reference.site },
        site_id: { ..._reference.site.id },
        inventory_type: this.$route.query.inventory_type,
        revision: _reference.model,
        revision_number: _reference.model?.number,
      };
      await _reference._selectMethod(revisionInfo);
      const frame = this.$refs.diffIframe;
      const payload = {
        textA: this.getTextA,
        textB: this.getTextB,
        language: this.getInventoryType.toLowerCase() === 'web' ? 'js' : 'json',
      };
      frame.contentWindow.postMessage(
        { call: 'sendTextAB', value: payload },
        '*',
      );
    },
    async goBackToTM() {
      await this.$router
        .push({
          name: 'TagsManager',
          query: {
            site: this.siteAutocompleteModelA.id,
            inventory_type: this.inventoryTypeSelectModelA,
            revision: this.revisionSelectModelA.number,
          },
        })
        .catch(() => {});
    },
    showWarning(warnings) {
      eventBus.$emit('action_warning', warnings);
    },
  },
};
</script>

<style scoped>
.containerif {
  position: relative;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  margin-bottom: 0;
  padding-bottom: 0;

  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  /*background-color: blue;*/
  overflow: hidden;
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  border: 0;

  flex-grow: 1;
  border: none;
  margin: 0;
  padding: 0;
}
body,
html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
.main-container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  /*background-color: blue;*/
  overflow: hidden;
}
</style>
