import { VFooter } from 'vuetify/lib/components/VFooter';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('o-card',{staticClass:"scroll d-flex flex-column",attrs:{"min-height":"50vh"}},[_c('simple-loader',{attrs:{"absolute":"","opacity":0.4},model:{value:(_vm.pending_save),callback:function ($$v) {_vm.pending_save=$$v},expression:"pending_save"}}),_c('o-card-title',[_c('o-row',[_c('o-col',[_vm._v(" "+_vm._s(_vm.$t('floor_manager.title.settings'))+" ")]),_c('o-col',{attrs:{"align":"right"}},[_c('o-btn',{attrs:{"icon":""},on:{"click":_vm.onClose}},[_c('o-icon',[_vm._v("close")])],1)],1)],1)],1),_c('o-card-text',[(_vm.edit_pf.updated_by)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('floor_manager.label.last_update', _vm.edit_pf))+" ")]):_vm._e(),(_vm.dimensions)?_c('o-form',{ref:"dimension_card_form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('o-row',{staticClass:"ma-1"},[_c('o-col',[_c('o-text-field',{attrs:{"disabled":!_vm.isAbleToEdit,"outlined":"","dense":"","hide-details":"auto","rules":[
                (v) => (v ? true : 'Cannot save an empty name'),
                (v) =>
                  v?.length < 255
                    ? true
                    : 'Rule name support only 255 characters',
              ]},on:{"change":function($event){return _vm.changeHandler({ field: 'name', value: _vm.price_name }, $event)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('icon-tooltip',{attrs:{"content":_vm.$t('floor_manager.label.pf.name'),"description":_vm.$t('floor_manager.help.pf.name')}})]},proxy:true}],null,false,461252511),model:{value:(_vm.price_name),callback:function ($$v) {_vm.price_name=$$v},expression:"price_name"}})],1),_c('o-col',{attrs:{"cols":"auto"}},[_c('o-text-field',{ref:"price_floor_input",attrs:{"disabled":!_vm.isAbleToEdit,"outlined":"","dense":"","hide-details":"auto","label":"Price floor","type":"number","rules":[
                (v) => (v ? true : 'Please fill a price floor before saving.'),
                (v) =>
                  v > 0 ? true : 'A price floor should be greater than 0',
              ]},on:{"focus":function($event){_vm.price_floor_focus = true},"blur":function($event){_vm.price_floor_focus = false},"change":function($event){return _vm.changeHandler({ field: 'price', value: _vm.price_floor }, $event)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('icon-tooltip',{attrs:{"content":_vm.$t('floor_manager.label.pf.value'),"description":_vm.$t('floor_manager.help.pf.value')}})]},proxy:true}],null,false,2017298239),model:{value:(_vm.price_floor),callback:function ($$v) {_vm.price_floor=$$v},expression:"price_floor"}})],1)],1),(_vm.price_floor > 15)?_c('o-row',[_c('o-col',{attrs:{"cols":"12"}},[_c('o-alert',{attrs:{"dismissible":"","type":"warning"}},[_vm._v(" "+_vm._s(_vm.$t('floor_manager.label.warning.high_price', { value: _vm.price_floor, }))+" ")])],1)],1):_vm._e(),_c('o-row',[_c('o-expansion-panels',{attrs:{"disabled":!_vm.isAbleToEdit}},_vm._l((_vm.activeDimension),function(dimension){return _c('o-expansion-panel',{key:dimension.name},[_c('o-expansion-panel-header',[_c('o-row',{attrs:{"justify":"space-between"}},[_c('o-col',[_c('strong',[_c('icon-tooltip',{attrs:{"content":_vm.$t(
                            `floor_manager.label.dimension.${dimension.name.toLowerCase()}`,
                          ),"description":_vm.$t(
                            `floor_manager.help.dimension.${dimension.name.toLowerCase()}`,
                          )}})],1)]),_c('o-col',[_c('strong',[_vm._v(_vm._s(_vm.customLabel(dimension)))])])],1)],1),_c('o-expansion-panel-content',[_c('o-row',[_c('o-col',[_c(_vm.customElement(dimension.type?.component, { dimension }),_vm._b({ref:"dimension",refInFor:true,tag:"component",attrs:{"outlined":""},on:{"change":function($event){return _vm.changeHandler(
                          {
                            field: 'dimension',
                            value: dimension,
                          },
                          $event,
                        )}},model:{value:(dimension.value),callback:function ($$v) {_vm.$set(dimension, "value", $$v)},expression:"dimension.value"}},'component',
                        _vm.customOptions(dimension.type?.options, { dimension })
                      ,false))],1)],1),_c('o-row',[(dimension.updated_by)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('floor_manager.label.last_update', _vm.edit_pf))+" ")]):_vm._e()])],1)],1)}),1)],1)],1):_c('div',[_vm._v(_vm._s(_vm.$t('floor_manager.label.no_select')))])],1),(_vm.isAbleToEdit)?_c(VFooter,{staticClass:"pa-2"},[_c('o-btn',{attrs:{"disabled":!_vm.dimensions || (!_vm.hasChanged && !_vm.isAbleToSave),"color":"primary","text":"","loading":_vm.pending_save},on:{"click":_vm.onSave}},[_vm._v(" Save ")]),(_vm.onCancel)?_c('o-btn',{attrs:{"color":"error","text":""},on:{"click":_vm.onCancel}},[_vm._v(" Cancel ")]):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }