<template>
  <o-dialog v-model="show" max-width="80%">
    <o-card style="overflow-x: hidden">
      <o-row align="center">
        <o-col cols="auto">
          <o-card-title class="mr-4">
            Publisher - Demand partner account
          </o-card-title>
        </o-col>
        <o-spacer />
        <o-col cols="auto">
          <o-btn color="blue darken-1" text @click="close">
            {{ $t('buttons.general.close') }}
          </o-btn>
        </o-col>
      </o-row>

      <o-card-text>
        <o-container>
          <o-data-table
            height="50vh"
            :loading="getLoading"
            :headers="headers"
            :items="getPDPAforPublisherList"
            item-key="demand_partner_account_id"
            :footer-props="{
              'show-first-last-page': true,
              'items-per-page-options': [10, 20, 30, 50, 100],
            }"
          >
            <template #top>
              <v-toolbar flat color="white">
                <v-toolbar-title>
                  <span class="headline">{{ getPublisherForDPA.name }}</span>
                </v-toolbar-title>
                <o-divider class="mx-4" inset vertical />
                <o-spacer />
                <o-dialog v-model="new_dialog" max-width="500px">
                  <template #activator="{ on }">
                    <o-row justify="end">
                      <o-col cols="auto">
                        <o-btn
                          color="primary"
                          dark
                          class="mb-2"
                          @click="addDefaultsDpa"
                        >
                          Load Defaults
                        </o-btn>
                      </o-col>
                      <o-col cols="auto">
                        <o-btn color="primary" dark class="mb-2" v-on="on">
                          {{
                            $t(
                              'buttons.publisher_demand_partner.add_connection',
                            )
                          }}
                        </o-btn>
                      </o-col>
                    </o-row>
                  </template>
                  <o-card>
                    <o-card-title>
                      <span class="headline">{{
                        $t('publisher_demand_partner.select_dpa')
                      }}</span>
                    </o-card-title>
                    <o-card-text>
                      <o-container>
                        <o-form ref="form_dpa_add" v-model="valid_dpa_add">
                          <o-autocomplete
                            v-model="dpa"
                            :items="getDemandPartnerAccounts"
                            label="publishers.select_demand_partner_account"
                            return-object
                            :rules="createRules([baseValidation.required])"
                          />
                        </o-form>
                      </o-container>
                    </o-card-text>
                    <o-card-actions>
                      <o-spacer />
                      <o-btn color="blue darken-1" text @click="close_inner">
                        {{ $t('buttons.general.close') }}
                      </o-btn>
                      <o-btn
                        color="blue darken-1"
                        :disabled="!valid_dpa_add"
                        text
                        @click="addDPA"
                      >
                        {{ $t('buttons.general.add') }}
                      </o-btn>
                    </o-card-actions>
                  </o-card>
                </o-dialog>
              </v-toolbar>
            </template>
            <template #item.action="{ item }">
              <o-btn
                color="blue darken-1"
                text
                @click="open_winners_dialog(item)"
              >
                <o-icon small> edit </o-icon>
              </o-btn>
              <o-btn
                :color="
                  item.is_spp_credentials_provided ? 'green' : 'orange darken-1'
                "
                text
                @click="open_credential_dialog(item)"
              >
                <o-icon small> mdi-key </o-icon>
              </o-btn>
              <o-btn
                depressed
                icon
                large
                class="mx-4"
                @click="open_confirm(item)"
              >
                <o-icon small> close </o-icon>
              </o-btn>
            </template>
          </o-data-table>
        </o-container>
      </o-card-text>
      <o-card-actions>
        <o-spacer />
        <o-btn color="blue darken-1" text @click="close">
          {{ $t('buttons.general.close') }}
        </o-btn>
      </o-card-actions>
    </o-card>
    <o-dialog v-model="winnerDialog" max-width="500px">
      <o-card>
        <o-container class="px-6">
          <o-card elevation="5" disabled>
            <o-card-title>Default config </o-card-title>
            <o-card-text class="ma-2" elevation="-5">
              {{ pdpa.default_match_config }}
            </o-card-text>
          </o-card>
        </o-container>
        <o-container class="px-6">
          <o-card elevation="5" disabled>
            <o-card-title>Current config</o-card-title>
            <o-card-text class="ma-2" elevation="-5">
              {{ currentDpa.match_config }}
            </o-card-text>
          </o-card>
        </o-container>
        <o-container>
          <o-card class="ma-4" elevation="5">
            <o-card-text>
              <o-list dense>
                <o-subheader>
                  {{ $t('publisher_demand_partner.winners_list') }}
                </o-subheader>

                <o-list-item
                  v-for="winnerFromDpa in currentDpa.winners"
                  :key="winnerFromDpa"
                >
                  <o-list-item-content>
                    <o-list-item-title> {{ winnerFromDpa }} </o-list-item-title>
                  </o-list-item-content>

                  <o-list-item-icon>
                    <o-btn
                      depressed
                      icon
                      large
                      class="mx-4"
                      @click="remove_winner(winnerFromDpa)"
                    >
                      <o-icon small> remove </o-icon>
                    </o-btn>
                  </o-list-item-icon>
                </o-list-item>
              </o-list>
              <o-row><o-divider class="my-4" /></o-row>
              <o-row>
                <o-col cols="9">
                  <o-combobox
                    ref="pdpd_combobox"
                    v-model="winner"
                    :items="getActiveWinners"
                    item-text="winner"
                    :label="$t('publisher_demand_partner.winner_input')"
                  />
                </o-col>
                <o-col cols="3">
                  <o-btn class="ma-4" color="primary" @click="push_win">
                    {{ $t('buttons.general.add') }}
                  </o-btn>
                </o-col>
              </o-row>
            </o-card-text>
          </o-card>
        </o-container>
        <o-container class="px-6">
          <o-card elevation="5" style="overflow-x: hidden">
            <o-list dense>
              <o-subheader>
                {{ $t('publisher_demand_partner.programmatic_channel_list') }}
              </o-subheader>
              <o-list-item
                v-for="pChannelFromDpa in currentDpa.programmatic_channels"
                :key="pChannelFromDpa"
              >
                <o-list-item-content>
                  <o-list-item-title> {{ pChannelFromDpa }} </o-list-item-title>
                </o-list-item-content>

                <o-list-item-icon>
                  <o-btn
                    depressed
                    icon
                    large
                    class="mx-4"
                    @click="remove_programmatic_channel(pChannelFromDpa)"
                  >
                    <o-icon small> remove </o-icon>
                  </o-btn>
                </o-list-item-icon>
              </o-list-item>
            </o-list>
            <o-row><o-divider class="my-4" /></o-row>
            <o-row class="mx-1">
              <o-col cols="9">
                <o-combobox
                  ref="dpat_programmatic_channel"
                  v-model="programmatic_channel"
                  :items="getProgrammaticChannels"
                  item-text="programmatic_channel"
                  :label="
                    $t('publisher_demand_partner.programmatic_channel_input')
                  "
                />
              </o-col>
              <o-col cols="3">
                <o-btn
                  class="ma-4"
                  color="primary"
                  depressed
                  @click="push_programmatic_channel"
                >
                  {{ $t('buttons.general.add') }}<o-icon>mdi-arrow</o-icon>
                </o-btn>
              </o-col>
            </o-row>
          </o-card>
        </o-container>
        <o-card-actions>
          <o-spacer />
          <o-btn
            color="blue darken-1"
            text
            @click="restoreDefaults(currentDpa)"
          >
            {{ $t('buttons.publisher_demand_partner.restore_defaults') }}
          </o-btn>
          <o-btn
            color="blue darken-1"
            :disabled="!configModified"
            text
            @click="saveWinner(currentDpa)"
          >
            {{ $t('buttons.publisher_demand_partner.save_winners') }}
          </o-btn>
          <o-btn color="blue darken-1" text @click="close_winner">
            {{ $t('buttons.general.close') }}
          </o-btn>
        </o-card-actions>
      </o-card>
    </o-dialog>
    <o-dialog v-model="confirm_dpa_remove_dialog" width="500">
      <o-card>
        <o-card-title class="headline grey lighten-2" primary-title>
          Are you sure?
        </o-card-title>
        <o-divider />
        <o-card-actions>
          <o-spacer />
          <o-btn color="primary" text @click="removeDPA(currentDpa)">
            Yes
          </o-btn>
          <o-btn color="primary" text @click="close_inner()"> No </o-btn>
        </o-card-actions>
      </o-card>
    </o-dialog>
    <o-dialog v-model="p_dpa_credentials_dialog" max-width="80%">
      <o-card>
        <o-card-title class="headline grey lighten-2" primary-title>
          Publisher - Demand Partner Accounts Credentials
        </o-card-title>
        <o-divider />
        <o-card-text>
          <o-row>
            <o-col>
              <o-btn
                color="primary"
                @click="edit_p_dpa_credential(getDefaultCredential())"
              >
                Add Credentials entry
              </o-btn>
            </o-col>
          </o-row>
          <o-data-table
            :items="getCredentials"
            :headers="p_dpa_credentials_headers"
            height="50vh"
            :loading="getLoading"
            item-key="key"
            :footer-props="{
              'show-first-last-page': true,
              'items-per-page-options': [10, 20, 30, 50, 100],
            }"
          >
            <template #item.value>
              <p>********</p>
            </template>
            <template #item.action="{ item }">
              <o-btn
                color="blue darken-1"
                text
                @click="edit_p_dpa_credential(item)"
              >
                <o-icon color="primary" small> edit </o-icon>
              </o-btn>
              <o-btn text @click="pre_remove_p_dpa_credential(item)">
                <o-icon color="error" small> delete </o-icon>
              </o-btn>
            </template>
            />
          </o-data-table>
        </o-card-text>
        <o-card-actions>
          <o-spacer />
          <o-btn color="primary" text @click="p_dpa_credentials_dialog = false">
            Close
          </o-btn>
        </o-card-actions>
      </o-card>
    </o-dialog>
    <o-dialog v-model="p_dpa_credential_dialog_edit" max-width="60%">
      <o-card>
        <o-card-title> Edit/Add Dpa Credential Entry </o-card-title>
        <o-card-text>
          <o-form ref="form_credential" v-model="credentials_validation">
            <o-row>
              <o-col>
                <o-text-field
                  v-model="p_dpa_credential_edit_item.key"
                  label="Key"
                  :rules="createRules([baseValidation.required])"
                />
              </o-col>
            </o-row>
            <o-row>
              <o-col>
                <o-text-field
                  v-model="p_dpa_credential_edit_item.value"
                  label="Value"
                  :rules="createRules([baseValidation.required])"
                />
              </o-col>
            </o-row>
          </o-form>
        </o-card-text>
        <o-card-actions>
          <o-spacer />
          <o-btn
            color="primary"
            :disabled="!credentials_validation"
            @click="pre_save_p_dpa_credental"
          >
            Add
          </o-btn>
          <o-btn @click="p_dpa_credential_dialog_edit = false"> Cancel </o-btn>
        </o-card-actions>
      </o-card>
    </o-dialog>
    <o-dialog
      v-model="p_dpa_credentials_dialog_confirmation_remove"
      width="40%"
    >
      <o-card>
        <o-card-title class="headline grey lighten-2" primary-title>
          Please, confirm deleting credential entry.
        </o-card-title>
        <o-divider />
        <o-card-actions>
          <o-spacer />
          <o-btn color="error" @click="remove_p_dpa_credential"> Remove </o-btn>
          <o-spacer />
          <o-btn
            color="primary"
            @click="p_dpa_credentials_dialog_confirmation_remove = false"
          >
            Cancel
          </o-btn>
          <o-spacer />
        </o-card-actions>
      </o-card>
    </o-dialog>
    <o-dialog
      v-model="p_dpa_credentials_dialog_confirmation_overwrite"
      width="40%"
    >
      <o-card>
        <o-card-title class="headline grey lighten-2" primary-title>
          Please, confirm overwriting credential entry.
        </o-card-title>
        <o-divider />
        <o-card-actions>
          <o-spacer />
          <o-btn color="warning" @click="save_p_dpa_credental">
            Overwrite
          </o-btn>
          <o-spacer />
          <o-btn
            color="primary"
            @click="p_dpa_credentials_dialog_confirmation_overwrite = false"
          >
            Cancel
          </o-btn>
          <o-spacer />
        </o-card-actions>
      </o-card>
    </o-dialog>
  </o-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import store from './../../../store/index';
import { baseValidation } from '../../../validation/baseValidation';
import { validationHelper } from '../../../validation/validationHelper';
import { eventBus } from '@/main';

export default {
  name: 'PublisherDemandPartnerDialog',
  mixins: [baseValidation, validationHelper],
  props: ['publisher'],
  data() {
    return {
      confirm_dpa_remove_dialog: false,
      show: false,
      readOnly: false,
      new_dialog: false,
      valid_dpa_add: false,
      disable_select: false,
      model: null,
      winnerDialog: false,
      configModified: false,
      dpa: null,
      pdpa: {},
      dpa_id: -1,
      winner: null,
      programmatic_channel: null,
      p_dpa_credentials_publisher: {},
      p_dpa_credentials_dialog: false,
      p_dpa_credential_dialog_edit: false,
      p_dpa_credential_edit_item: {},
      p_dpa_credentials_dialog_confirmation_overwrite: false,
      p_dpa_credentials_dialog_confirmation_remove: false,
      credentials_validation: true,
      p_dpa_credentials_headers: [
        { text: 'Key', value: 'key' },
        { text: 'Value', value: 'value' },
        {
          text: 'Action',
          value: 'action',
          sortable: false,
          filterable: false,
          align: 'center',
        },
      ],
      headers: [
        {
          text: 'ID',
          value: 'demand_partner_account_id',
        },
        {
          text: 'Name',
          value: 'demand_partner_account_name',
        },
        {
          text: 'Action',
          value: 'action',
          sortable: false,
          filterable: false,
          align: 'center',
        },
        { text: '', value: 'data-table-expand' },
      ],
    };
  },
  mounted() {
    store.dispatch('publishers/fetchPDPAForPublisher');
    store.dispatch('demandPartnerAccounts/fetchActiveWinners');
    store.dispatch('demandPartnerAccounts/fetchProgrammaticChannels');
    store.dispatch('demandPartnerAccounts/fetchDemandPartnerAccounts');
  },
  computed: {
    ...mapGetters('publishers', [
      'getLoading',
      'getPublishers',
      'getPDPAforPublisherList',
      'getPublisherForDPA',
      'getPDPAforPublisher',
    ]),
    ...mapGetters('demandPartnerAccounts', [
      'getDemandPartnerAccounts',
      'getProgrammaticChannels',
      'getActiveWinners',
    ]),
    ...mapGetters('publisherDPACredentials', [
      'getDefaultCredential',
      'getCredentials',
    ]),
    currentDpa: {
      get() {
        return (
          this.getPDPAforPublisherList.find(
            (el) => el.demand_partner_account_id === this.dpa_id,
          ) ?? {}
        );
      },
      set(input) {
        this.$set(this.getPDPAforPublisherList, this.dpa_id, input);
      },
    },
  },
  methods: {
    ...mapActions('publishers', ['addDefaultsDpa', 'fetchPDPAForPublisher']),
    ...mapActions('publisherDPACredentials', [
      'fetchCredentialsForPDPA',
      'removeCredential',
      'saveCredential',
    ]),
    removeDPA(item) {
      store
        .dispatch('publishers/delete_pdpa', item)
        .then()
        .catch((err) => console.log(err))
        .finally(() => this.close_inner());
    },
    push_win() {
      if (
        this.$refs.pdpd_combobox.internalSearch &&
        this.winner !== this.$refs.pdpd_combobox.internalSearch
      ) {
        this.winner = this.$refs.pdpd_combobox.internalSearch;
      }
      if (
        this.winner &&
        !this.currentDpa.winners.find((elem) => elem === this.winner)
      ) {
        this.currentDpa.winners.push(this.winner);
        this.configModified = true;
      }
    },
    remove_winner(winner) {
      this.currentDpa.winners = this.currentDpa.winners.filter(
        (el) => el !== winner,
      );
      this.configModified = true;
    },
    push_programmatic_channel() {
      if (
        this.$refs.dpat_programmatic_channel.internalSearch &&
        this.programmatic_channel !==
          this.$refs.dpat_programmatic_channel.internalSearch
      ) {
        this.programmatic_channel =
          this.$refs.dpat_programmatic_channel.internalSearch;
      }
      if (
        this.programmatic_channel &&
        !this.currentDpa.programmatic_channels.find(
          (elem) => elem === this.programmatic_channel,
        )
      ) {
        this.currentDpa.programmatic_channels.push(this.programmatic_channel);
        this.configModified = true;
      }
    },
    remove_programmatic_channel(programmatic_channel) {
      this.currentDpa.programmatic_channels =
        this.currentDpa.programmatic_channels.filter(
          (el) => el !== programmatic_channel,
        );
      this.configModified = true;
    },
    addDPA() {
      if (!this.$refs.form_dpa_add.validate()) {
        return;
      }
      store
        .dispatch('publishers/createDPA', { ...this.dpa })
        .then((response) => {
          if (response && 'warnings' in response) {
            eventBus.$emit('action_warning', response['warnings']);
          }
        })
        .catch((err) => console.log(err));
      this.close_inner();
    },
    restoreDefaults(item) {
      item.match_config = { ...item.default_match_config };

      store
        .dispatch('publishers/restore_dpa_config', { ...item })
        .then(() => store.dispatch('publishers/fetchPDPAForPublisher'))
        .catch((err) => console.log(err));
    },
    saveWinner(item) {
      store
        .dispatch('publishers/update_dpa', { ...item })
        .then(() => store.dispatch('publishers/fetchPDPAForPublisher'))
        .then(() => (this.configModified = false))
        .catch((err) => console.log(err));
    },
    open_winners_dialog(item) {
      this.winnerDialog = true;
      this.pdpa = item;
      this.dpa_id = item.demand_partner_account_id;
      this.configModified = false;
    },
    open_confirm(item) {
      this.confirm_dpa_remove_dialog = true;
      this.pdpa = item;
      this.dpa_id = item.demand_partner_account_id;
    },
    close() {
      this.show = false;
    },
    close_inner() {
      this.confirm_dpa_remove_dialog = false;
      this.new_dialog = false;
    },
    close_winner() {
      this.winnerDialog = false;
      store.dispatch('publishers/fetchPDPAForPublisher');
    },
    async open_credential_dialog(item) {
      this.p_dpa_credentials_publisher = { ...item };
      await this.fetchCredentialsForPDPA(item);
      this.p_dpa_credentials_dialog = true;
    },
    edit_p_dpa_credential(credentials_edit_item) {
      this.p_dpa_credential_edit_item = credentials_edit_item;
      this.p_dpa_credential_dialog_edit = true;
    },
    pre_save_p_dpa_credental() {
      if (!this.$refs.form_credential.validate()) {
        return;
      }
      if (
        this.getCredentials.find(
          (element) => element.key === this.p_dpa_credential_edit_item.key,
        ) // also I could use some
      ) {
        this.p_dpa_credentials_dialog_confirmation_overwrite = true;
      } else {
        this.save_p_dpa_credental();
      }
    },
    async save_p_dpa_credental() {
      await this.saveCredential(this.p_dpa_credential_edit_item);
      await this.open_credential_dialog(this.p_dpa_credentials_publisher);
      this.p_dpa_credential_dialog_edit = false;
      this.p_dpa_credentials_dialog_confirmation_overwrite = false;
      this.fetchPDPAForPublisher();
    },
    pre_remove_p_dpa_credential(credentials_edit_item) {
      this.p_dpa_credential_edit_item = credentials_edit_item;
      this.p_dpa_credentials_dialog_confirmation_remove = true;
    },
    async remove_p_dpa_credential() {
      await this.removeCredential(this.p_dpa_credential_edit_item);
      await this.open_credential_dialog(this.p_dpa_credentials_publisher);
      this.p_dpa_credentials_dialog_confirmation_remove = false;
      this.fetchPDPAForPublisher();
    },
  },
};
</script>

<style scoped></style>
