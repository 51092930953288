<template>
  <div class="pt-10">
    <o-card class="mx-auto" max-width="650">
      <v-img height="200px" :src="odLogo" />
      <o-card-title> Dashboard </o-card-title>
      <o-card-subtitle> Login to see more </o-card-subtitle>
      <o-card-actions>
        <div ref="googleLoginBtn" />
        <o-spacer />
        <o-btn icon @click="show = !show">
          <o-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</o-icon>
        </o-btn>
      </o-card-actions>
      <v-expand-transition>
        <div v-show="show">
          <o-divider />
          <o-card-text>
            This is portal to manage configuration and review own reports. It
            requires to have G-mail account accepted by Opti Digital support.
          </o-card-text>
        </div>
      </v-expand-transition>
    </o-card>
    <o-spacer class="ma-4" />
    <o-alert
      v-show="showAlertError"
      type="error"
      class="mx-auto pa-4"
      max-width="650"
    >
      Error: Connection to server refused.
    </o-alert>
    <o-alert
      v-show="showAlertNoAccess"
      type="warning"
      class="mx-auto pa-4"
      max-width="650"
    >
      Error: Access denied.
    </o-alert>
  </div>
</template>

<!--<router-link to="/login">Go to login</router-link>-->

<script>
import colors from 'vuetify/lib/util/colors';
// import axios from "axios";
// import { createNamespacedHelpers } from 'vuex';
import { mapActions } from 'vuex';

const odLogo = require('../../public/logo-optidigital_versionhorizontal_fullcolor-1.png');

export default {
  name: 'Login',
  data() {
    return {
      odLogo,
      show: false,
      googleLoginColor: colors.blue.lighten1,
      isInit: false,
      showAlertError: false,
      showAlertNoAccess: false,
    };
  },
  methods: {
    ...mapActions('context', ['loginGoogle']),
    handleCredentialResponse(response) {
      this.loginGoogle(response.credential).then((res) => {
        if (res[1] === true) {
          this.$router.push({ name: 'Home' });
        } else {
          this.showAlertNoAccess = true;
        }
      });
    },
  },
  computed: {},
  mounted() {
    const gClientId =
      '123391447784-9ebr33205qtm7pt4v85eu9gphjgr57ng.apps.googleusercontent.com';
    window.onload = () => {
      window.google.accounts.id.initialize({
        client_id: gClientId,
        callback: this.handleCredentialResponse,
        auto_select: true,
      });
      window.google.accounts.id.renderButton(this.$refs.googleLoginBtn, {
        text: 'signin',
        size: 'large',
        width: 200,
        theme: 'outline',
      });
    };
  },
  beforeCreate() {
    const authorizationToken = localStorage.getItem('authorization_token');
    if (authorizationToken) {
      this.$router.push({ name: 'Home' });
    }
  },

  created() {},
};
</script>
