<template>
  <o-card elevation="0">
    <!--    <o-card-title>SSP Sync reports</o-card-title>-->
    <o-row />
    <div style="white-space: nowrap">
      <o-btn @click="triggerSync"> Trigger Sync </o-btn>
      <o-btn @click="triggerInitBinding"> Init Binding </o-btn>
    </div>
    <o-card-text>
      <o-data-table
        height="80vh"
        :items="getSyncReports"
        :loading="getLoading"
        :headers="headers"
        :items-per-page="100"
        :footer-props="{
          'items-per-page-options': [20, 50, 100, 200],
          'show-first-last-page': true,
        }"
      >
        <template #item.view_report="{ item }">
          <o-btn depressed icon large class="mx-4" @click="viewItem(item)">
            <o-icon small> visibility </o-icon>
          </o-btn>
        </template>
        <template #[`item.report`]="{ item }">
          <p class="truncate">
            {{ JSON.stringify(item.report) }}
          </p>
        </template>
      </o-data-table>
      <o-dialog v-model="dialog" max-width="60%">
        <o-card>
          <o-card-title>
            <o-icon class="ma-4" small> visibility </o-icon>
            Report view for id: {{ viewedItem.id }} at
            {{ viewedItem.create_time }}
          </o-card-title>
          <o-card-text>
            <!--            <o-textarea-->
            <!--              no-resize-->
            <!--              rows="20"-->
            <!--              class="textarea"-->
            <!--              outlined-->
            <!--              readonly-->
            <!--              label="JSON"-->
            <!--              :value="JSON.stringify(viewedItem.report)"-->
            <!--            />-->
            <prism-code-editor
              language="json"
              :value="JSON.stringify(viewedItem.report, null, 2)"
              label="Report"
              :readonly="true"
            />
          </o-card-text>
          <o-card-actions>
            <o-spacer />
            <o-btn color="primary" @click="closeDialog()"> Close </o-btn>
          </o-card-actions>
        </o-card>
      </o-dialog>
    </o-card-text>
  </o-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PrismCodeEditor from '@/components/Settings/TagsManager/PrismCodeEditor.vue';

export default {
  name: 'SyncReports',
  components: { PrismCodeEditor },
  data() {
    return {
      viewedItem: {},
      dialog: false,
      headers: [
        { text: 'ID', value: 'id' },
        // { text: "Create uuid", value: "create_uuid" },
        { text: 'Create time', value: 'create_time' },
        { text: 'UserID', value: 'user_id' },
        { text: 'Report', value: 'report' },
        { text: 'View Report', value: 'view_report' },
      ],
    };
  },
  mounted() {
    this.fetchSyncReports();
  },
  computed: {
    ...mapGetters('syncAdslotSSP', ['getSyncReports', 'getLoading']),
  },
  methods: {
    ...mapActions('syncAdslotSSP', [
      'fetchSyncReports',
      'triggerSyncReports',
      'initBinding',
    ]),
    viewItem(item) {
      this.viewedItem = item;
      this.dialog = true;
    },
    async triggerSync() {
      await this.triggerSyncReports();
    },
    async triggerInitBinding() {
      await this.initBinding();
    },
    closeDialog() {
      this.viewedItem = {};
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
.truncate {
  width: 25vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
