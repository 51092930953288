<template>
  <o-row>
    <o-snackbar
      v-if="snackbar"
      v-model="snackbarTop"
      absolute
      top
      :color="snackbarColor"
    >
      {{ snackbarTop }}
      <o-btn color="white" text @click="snackbar = false"> Close </o-btn>
    </o-snackbar>
    <o-col cols="12">
      <o-data-table
        :loading="getLoading"
        :headers="headers"
        :items="getFilteredItems"
        :options.sync="options"
        :server-items-length="getLogCount"
        :items-per-page="getInfoItemsPerPage"
        sortable="false"
        height="calc(100vh - 250px)"
        :footer-props="{
          'show-first-last-page': true,
          'items-per-page-options': [10, 20, 30, 50, 100],
        }"
      >
        <template #top>
          <o-row><o-divider /></o-row>
          <o-row align="center" justify="space-around">
            <o-col cols="4">
              <o-row class="align-center">
                <o-col cols="10">
                  <o-menu
                    v-model="datePicker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template #activator="{ on, attrs }">
                      <o-text-field
                        v-model="datePickerDateRange"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        label="Date range"
                        v-on="on"
                      />
                    </template>
                    <o-date-picker
                      v-model="datePickerDate"
                      range
                      @input="selectDate(datePickerDate)"
                    />
                  </o-menu>
                </o-col>
                <o-col cols="2">
                  <o-icon
                    :disabled="!datePickerDate.length"
                    color="red darken-3"
                    icon
                    @click="resetDateRange"
                  >
                    mdi-close-circle
                  </o-icon>
                </o-col>
              </o-row>
            </o-col>
            <o-col cols="4">
              <o-row class="align-center">
                <o-col cols="10">
                  <o-autocomplete
                    v-model="publishers"
                    :items="defaultPfPublishers"
                    label="Publisher"
                    :search-input.sync="setPublisherNameSearch"
                    :menu-props="{ offsetY: true }"
                    multiple
                    @change="filterItems(publishers, 'setPublisherName')"
                  />
                </o-col>
                <o-col cols="2">
                  <o-icon
                    :disabled="!publishers.length"
                    color="red darken-3"
                    icon
                    @click="resetFiltering('setPublisherName', 'publishers')"
                  >
                    mdi-close-circle
                  </o-icon>
                </o-col>
              </o-row>
            </o-col>
          </o-row>
        </template>
        <template #item.calculator_version="{ item }">
          <span>{{
            item.publisher_calculator_version !== ''
              ? item.calculator_version
              : 'Default (&nbsp;' + item.calculator_version + '&nbsp;)'
          }}</span>
        </template>
        <template #item.start_date="{ item }">
          <span>{{ formatDate(item.start_date) }}</span>
        </template>
        <template #item.end_date="{ item }">
          <span>{{ formatDate(item.end_date) }}</span>
        </template>
        <template #item.modification_date="{ item }">
          <span>{{ formatDate(item.modification_date) }}</span>
        </template>
        <template #item.action="{ item }">
          <div style="white-space: nowrap">
            <o-tooltip bottom>
              <template #activator="{ on }">
                <o-btn depressed icon v-on="on" @click="editPfPublisher(item)">
                  <o-icon small color="blue"> settings </o-icon>
                </o-btn>
              </template>
              <span>Settings</span>
            </o-tooltip>
            <o-tooltip bottom>
              <template #activator="{ on }">
                <o-btn
                  depressed
                  icon
                  :href="`/priceFloor/configuration?publisher=${item.name}`"
                  target="_blank"
                  v-on="on"
                >
                  <o-icon small color="blue"> folder_copy </o-icon>
                </o-btn>
              </template>
              <span>PFUnit Manager</span>
            </o-tooltip>
            <o-tooltip bottom>
              <template #activator="{ on }">
                <o-btn
                  depressed
                  icon
                  :href="
                    item.pf_data_studio_link ? item.pf_data_studio_link : ''
                  "
                  target="_blank"
                  v-on="on"
                >
                  <o-icon
                    small
                    :color="linkColor(item.pf_data_studio_link || false)"
                  >
                    signal_cellular_alt
                  </o-icon>
                </o-btn>
              </template>
              <span>Data Studio</span>
            </o-tooltip>
          </div>
        </template>
      </o-data-table>
      <price-floor-publisher-dialog
        ref="pf_publishers_dialog"
        :publisher-info="publisherInfo"
        :close-callback="closeEditPublisher"
      />
      <price-floor-edit-publisher-dialog ref="pf_edit_publishers_dialog" />
    </o-col>
  </o-row>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { filterMixin } from '@/helpers/filterMixin';
import PriceFloorPublisherDialog from './PriceFloorPublisherDialog';
import PriceFloorEditPublisherDialog from './PriceFloorEditPublisherDialog';

export default {
  name: 'PriceFloorPublishers',
  components: {
    PriceFloorPublisherDialog,
    PriceFloorEditPublisherDialog,
  },
  mixins: [filterMixin],
  data() {
    return {
      options: {},
      readOnly: false,
      headers: [
        {
          text: 'Publisher',
          value: 'name',
          sortable: false,
        },
        {
          text: 'Start',
          value: 'start_date',
          sortable: false,
        },
        {
          text: 'End',
          value: 'end_date',
          sortable: false,
        },
        {
          text: 'Last modification',
          value: 'modification_date',
          sortable: false,
        },
        {
          text: 'Version',
          value: 'calculator_version',
          sortable: false,
        },
        {
          text: 'Enabled pfunit auto',
          value: 'enabled_pfunit_auto',
          sortable: false,
        },
        {
          text: 'Action',
          value: 'action',
          sortable: false,
          align: 'center',
        },
      ],
      datePicker: false,
      datePickerDate: [],
      publishers: [],
      setPublisherNameSearch: null,
      editedIndex: -1,
      editedItem: {},
      publisherInfo: {},
      snackbar: false,
      snackbarTop: '',
      snackbarColor: 'warning',
      defaultPfPublishers: [],
    };
  },
  watch: {
    options: {
      handler() {
        this.updatePaginationInfo({
          page: this.options.page,
          itemsPerPage: this.options.itemsPerPage,
        });
      },
      deep: true,
    },
    getFilteredItems(newVal) {
      if (newVal && this.defaultPfPublishers.length === 0) {
        this.defaultPfPublishers = this.getPfPublishers;
      }
    },
  },
  mounted() {
    this.setSearchData([]);
    this.setPublisherName([]);
    this.fetchPfPublishers();
  },
  computed: {
    getFilteredItems() {
      return this.getPfPublishers;
    },
    datePickerDateRange() {
      return this.datePickerDate.join(' - ');
    },
    ...mapGetters('publishers', ['getGAMNetworkName']),
    ...mapGetters('priceFloorPublishers', [
      'getLoading',
      'getPfPublishers',
      'getInfoItemsPerPage',
      'getLogCount',
      'getStats',
      'getPublisherCurrency',
      'getAllCountries',
      'getPublisherData',
    ]),
  },
  methods: {
    ...mapActions('priceFloorPublishers', [
      'setSearchDataAction',
      'updatePaginationInfo',
      'fetchFilter',
      'fetchPfPublishers',
      'fetchCurrencyForPublisher',
      'fetchCurrencyDashboardForPublisher',
      'fetchDataForPublisher',
      'fetchPublisherCountries',
      'fetchAllCountries',
    ]),
    ...mapMutations('priceFloorPublishers', [
      'setPublisherName',
      'setSearchData',
    ]),
    selectDate() {
      this.snackbar = false;
      if (
        this.datePickerDate.length === 2 &&
        this.datePickerDate[0] <= this.datePickerDate[1]
      ) {
        this.datePicker = false;
        this.options.page = 1;
        this.setSearchDataAction(this.datePickerDate);
      } else if (
        this.datePickerDate.length === 2 &&
        this.datePickerDate[0] > this.datePickerDate[1]
      ) {
        this.snackbarTop = "The end date can't be earlier than the start date";
        this.snackbarColor = 'error';
        this.snackbar = true;
      } else {
        this.snackbarTop = 'Please select the range of dates';
        this.snackbarColor = 'blue';
        this.snackbar = true;
      }
    },
    resetDateRange() {
      this.datePickerDate = [];
      this.options.page = 1;
      this.setSearchDataAction([]);
    },
    filterItems(item, name) {
      this[`${name}Search`] = null;
      this.options.page = 1;
      this.fetchFilter({ name, data: item });
    },
    resetFiltering(name, filter) {
      this[`${filter}`] = [];
      this.options.page = 1;
      this.fetchFilter({ name, data: [] });
    },
    linkColor(param = false) {
      if (param) return 'blue';
      return 'grey';
    },
    editPfPublisher(item) {
      this.editedIndex = this.getPfPublishers.indexOf(item);
      this.publisherInfo = { ...item };
      if (this.publisherInfo.publisher_calculator_version === '') {
        this.publisherInfo.calculator_version = null;
      }
      this.fetchCurrencyForPublisher(item.network_id);
      this.fetchCurrencyDashboardForPublisher(item.name);

      // If item.id is 0 then it's a new publisher dialog - there cannot be any existing countries
      if (item?.id > 0) {
        this.fetchPublisherCountries(item.name);
      }
      this.getAllCountries.length = 0;
      this.fetchAllCountries();
      this.$refs.pf_publishers_dialog.readOnly = false;
      this.$refs.pf_publishers_dialog.show = true;
    },
    closeEditPublisher() {
      this.$refs.pf_publishers_dialog.show = false;
      this.publisherInfo = {};
    },
    formatDate(item) {
      if (item) {
        return `${item.split('T')[0]} ${item.split('T')[1]}`;
      }
      return '-';
    },
  },
};
</script>

<style scoped></style>
