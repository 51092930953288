<template>
  <v-switch
    ref="vInput"
    v-model="internalValue"
    v-bind="{
      ...$attrs,
      ...$props,
      hideDetails: _hideDetails,
      label: _label,
    }"
    v-on="$listeners"
  >
    <template v-for="(_, name) in $scopedSlots" #[name]="render">
      <slot :name="name" v-bind="render"></slot>
    </template>

    <!-- static slots definition -->
    <template v-if="description !== undefined" #label>
      <icon-tooltip :content="$t(label)" :description="$t(description)" />
    </template>
    <template v-else #label>
      <slot name="label"></slot>
    </template>

    <template #append>
      <slot name="append"></slot>
    </template>
  </v-switch>
</template>
<script>
import IconTooltip from '@/components/DesignSystem/OTooltip/IconTooltip.vue';
import baseInput from '@/components/DesignSystem/OInput/base_input_script';

export default {
  name: 'OSwitch',
  components: { IconTooltip },
  props: {
    value: {
      type: [Boolean, String, Number],
      default: false,
    },
    invertConditions: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    showDetails: {
      type: [Boolean, String],
      default: 'auto',
    },
    hideDetails: {
      type: [Boolean, String],
      default: 'auto',
    },
    label: {
      type: [String, undefined],
      default: undefined,
    },
    description: {
      type: [String, undefined],
      default: undefined,
    },
    falseValue: {
      validator() {
        return true; // Any type
      },
      default: false,
    },
    trueValue: {
      validator() {
        return true; // Any type
      },
      default: true,
    },
  },
  emits: ['input', 'change'],
  computed: {
    ...baseInput.computed,
    internalValue: {
      get() {
        if (this.invertConditions) {
          return this.value === this.trueValue
            ? this.falseValue
            : this.trueValue;
        }

        return this.value;
      },
      set(val) {
        if (this.invertConditions) {
          const newValue =
            val === this.trueValue ? this.falseValue : this.trueValue;

          this.$emit('input', newValue);
          this.$emit('change', newValue);
          return;
        }

        const newValue = val;
        this.$emit('input', newValue);
        this.$emit('change', newValue);
      },
    },
  },
  methods: {
    blur() {
      this.$refs.vInput.blur();
    },
    focus(...args) {
      this.$refs.vInput.focus(...args);
    },
  },
};
</script>

<style scoped></style>
