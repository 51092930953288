import axios from 'axios';

const store = {
  namespaced: true,

  state: {
    loadingFetchReports: false,
  },

  getters: {
    getLoadingFetchReports: (state) => {
      return state.loadingFetchReports;
    },
  },

  mutations: {
    setLoadingFetchReports(state, loading) {
      state.loadingFetchReports = loading;
    },
  },

  actions: {
    async fetchReports({ commit }, payload) {
      return new Promise((resolve) => {
        commit('setLoadingFetchReports', true);
        axios
          .get('/reporting_financial/' + payload.year + '/' + payload.month)
          .then(() => {
            commit('setLoadingFetchReports', false);
          });
        resolve();
      });
    },
    async synchronizePennylane() {
      await axios.get('/pennylane_actions/run_synchronize');
    },
  },
};

export default store;
