import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VTreeview } from 'vuetify/lib/components/VTreeview';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('o-col',[_c(VAppBar,{staticClass:"stickyTop",attrs:{"id":"pfc-switcher","app":"","fixed":""}},[_c('o-row',{staticClass:"test",attrs:{"justify":"space-between","align":"center"}},[_c('o-col',{attrs:{"cols":"auto"}},[_c('o-card-title',[_vm._v("PFUnits Manager")])],1),_c('o-spacer'),_c('o-col',{attrs:{"cols":"auto"}},[_c('o-btn',{attrs:{"color":"orange"},on:{"click":_vm.openConfirmation}},[_vm._v(" Manual Deploy for all Publishers ")]),_c('confirmation-dialog',{ref:"confirmation",attrs:{"msg":"Are you sure you want to do manual release?"},on:{"confirm":_vm.onManualRelease,"close":_vm.closeConfirmation}})],1)],1),_c('o-row',{ref:"filterRow",attrs:{"align":"center"}},[_c('o-col',[_c('o-autocomplete',{attrs:{"items":_vm.getPublishersList,"return-object":"","label":"Publisher"},on:{"change":_vm.onPublisherSelected},model:{value:(_vm.publisher),callback:function ($$v) {_vm.publisher=$$v},expression:"publisher"}})],1),_c('o-divider',{directives:[{name:"show",rawName:"v-show",value:(_vm.treeLen !== 0 && _vm.publisher && _vm.publisher.id != null),expression:"treeLen !== 0 && publisher && publisher.id != null"}],attrs:{"vertical":""}}),_vm._l((_vm.getPublisherMapping.mapping),function(item){return _c('o-col',{key:item.id},[_c('o-autocomplete',{attrs:{"items":_vm.uniqueElementsObject[item.key],"item-text":"text","item-value":"value","label":item.label,"value":_vm.filters[item.key],"multiple":""},on:{"change":(value) => {
                _vm.filters[item.key] = value;
                _vm.generateTree();
              }},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('select-all-checkbox',{attrs:{"element":item.key,"filters":_vm.filters[item.key],"all":_vm.uniqueElementsObject[item.key]},on:{"toggle":_vm.selectAll}})]},proxy:true},{key:"append-outer",fn:function(){return [_c('o-icon',{attrs:{"icon":""},on:{"click":() => {
                    _vm.filters[item.key] = '';
                    _vm.generateTree();
                  }}},[_vm._v(" delete ")])]},proxy:true}],null,true)})],1)})],2),_c('o-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.treeLen !== 0 && _vm.publisher && _vm.publisher.id != null),expression:"treeLen !== 0 && publisher && publisher.id != null"}]},[_c('o-card-actions',[_c('o-row',{attrs:{"align":"center","justify":"start"}},[_c('o-col',[_c('o-btn',{staticClass:"my-4",attrs:{"color":"green","disabled":_vm.changedPfUnitsList.length === 0},on:{"click":_vm.onSave}},[_vm._v(" Save ")]),_c('o-btn',{staticClass:"ml-4 my-4",attrs:{"color":"blue"},on:{"click":_vm.showAllElementsInTree}},[_vm._v(" "+_vm._s(_vm.expandedTxt)+" ")]),_c('o-dialog',{model:{value:(_vm.saveDialog),callback:function ($$v) {_vm.saveDialog=$$v},expression:"saveDialog"}},[_c('o-card',[_c('o-card-title',[_vm._v(" Items to save ")]),_c('o-card-text',[_c('o-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Path")]),_c('th',{staticClass:"text-left"},[_vm._v("Original Status")]),_c('th',{staticClass:"text-left"},[_vm._v("New Status")])])]),_c('tbody',_vm._l((_vm.changedPfUnitsList),function(item){return _c('tr',{key:item.id},[_c('td',[_vm._v(_vm._s(_vm.generatePath(item)))]),_c('td',[(item.originalEnabledValue)?_c('o-chip',{attrs:{"color":"green"}},[_vm._v(" Enabled ")]):_c('o-chip',{attrs:{"color":"gray"}},[_vm._v(" Disabled ")])],1),_c('td',[(item.enabled)?_c('o-chip',{attrs:{"color":"green"}},[_vm._v(" Enabled ")]):_c('o-chip',{attrs:{"color":"gray"}},[_vm._v(" Disabled ")])],1)])}),0)]},proxy:true}])})],1),_c('o-card-actions',[_c('o-spacer'),_c('o-btn',{attrs:{"color":"primary"},on:{"click":_vm.savePfListModification}},[_vm._v(" Confirm and Save ")]),_c('o-btn',{on:{"click":() => {
                          _vm.saveDialog = false;
                        }}},[_vm._v(" Cancel ")])],1)],1)],1)],1)],1)],1)],1)],1),_c('o-divider'),_c('o-row',{style:(_vm.treeLen !== 0 && _vm.publisher && _vm.publisher.id != null
          ? `margin-top: ${_vm.filtersRowHeight}px`
          : `margin-top: ${_vm.filtersRowEmptyHeight}px`)},[_c('o-col',[_c('o-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.treeLen !== 0 && _vm.publisher && _vm.publisher.id != null),expression:"treeLen !== 0 && publisher && publisher.id != null"}]},[_c(VTreeview,{ref:"priceFloorTreeConfiguration",attrs:{"selectable":"","hoverable":"","return-object":"","items":_vm.treeItems},on:{"input":_vm.onStatusChanged},scopedSlots:_vm._u([{key:"label",fn:function({ item, leaf, selected }){return [_c('o-row',{attrs:{"align":"center"}},[_c('o-col',{attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('o-spacer'),(leaf)?_c('o-col',{attrs:{"cols":"auto"}},[(selected)?_c('o-chip',{attrs:{"color":"green"}},[_vm._v(" Enabled ")]):_c('o-chip',{attrs:{"color":"gray"}},[_vm._v(" Disabled ")])],1):_vm._e()],1)]}}]),model:{value:(_vm.selectedItemsInTree),callback:function ($$v) {_vm.selectedItemsInTree=$$v},expression:"selectedItemsInTree"}})],1),_c('o-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.treeLen === 0 && _vm.publisher && _vm.publisher.id != null),expression:"treeLen === 0 && publisher && publisher.id != null"}],staticClass:"ma-5"},[_c('o-card-title',{staticClass:"ma-4"},[_vm._v(" No PF units that are active (have traffic) or present in system ")])],1),_c('o-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.publisher && _vm.publisher.id == null),expression:"publisher && publisher.id == null"}],staticClass:"ma-5"},[_c('o-card-title',{staticClass:"ma-4"},[_vm._v(" Please select publisher ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }