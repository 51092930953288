import axios from 'axios';

const store = {
  namespaced: true,

  state: {
    loading: false,
  },

  getters: {
    getLoading: (state) => {
      return state.loading;
    },
  },

  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
  },

  actions: {
    async getAdsTxt({ commit }, dpa_id) {
      commit('setLoading', true);
      const response = await axios.get('/ads_txt/' + dpa_id);
      commit('setLoading', false);
      return response.data.ads_txt_lines;
    },
    async updateAdsTxt({ commit }, data) {
      commit('setLoading', true);
      const response = await axios.put('/ads_txt/' + data.dpa_id, {
        ads_txt_lines: data.ads_txt_lines,
      });
      commit('setLoading', false);
      return response.data.ads_txt_lines;
    },
    async compareAdsTxt({ commit }, site_id) {
      commit('setLoading', true);
      await axios.post('/ads_txt/compare/' + site_id);
    },
    async getCompareResultAdsTxt({ commit }, site_id) {
      commit('setLoading', true);
      const response = await axios.get('/ads_txt/compare/results/' + site_id);
      commit('setLoading', false);
      if (response.status === 200) {
        return response.data;
      }
      return null;
    },
  },
};

export default store;
