<template>
  <v-simple-table
    v-bind="{
      ...$attrs,
      ...$props,
    }"
    v-on="$listeners"
  >
    <template v-for="(_, name) in $scopedSlots" #[name]="render">
      <slot :name="name" v-bind="render"></slot>
    </template>

    <slot></slot>
  </v-simple-table>
</template>
<script>
export default {
  name: 'OSimpleTable',
};
</script>

<style scoped></style>
