<template>
  <o-row>
    <o-col cols="12">
      <o-row>
        <o-card-title>
          {{ $t('financial_reporting.financial_reporting') }}
        </o-card-title>
      </o-row>
      <financial-reporting-table />
    </o-col>
  </o-row>
</template>

<script>
import FinancialReportingTable from '../../components/Accounting/FinancialReporting/FinancialReportingTable';
export default {
  name: 'FinancialReporting',
  components: { FinancialReportingTable },
};
</script>

<style scoped></style>
