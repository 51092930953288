<template>
  <o-row>
    <o-col cols="12">
      <o-data-table
        height="calc(100vh - 250px)"
        :loading="getLoading"
        :headers="headers"
        :search="search"
        :items="getDemandPartnerAccountsWithRelations"
        :footer-props="{
          'show-first-last-page': true,
          'items-per-page-options': [10, 20, 30, 50, 100],
        }"
      >
        <template #top>
          <o-row>
            <o-divider />
          </o-row>
          <o-row align="center" justify="space-around" class="pb-5">
            <o-col cols="3" class="text-center">
              <o-btn
                color="primary"
                @click="
                  editDemandPartnerAccount(getDefaultDemandPartnerAccount())
                "
              >
                {{ $t('buttons.demand_partner_account.add') }}
                <o-icon dark right> add_box </o-icon>
              </o-btn>
            </o-col>
            <o-divider vertical inset />
            <o-col cols="6">
              <o-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('buttons.general.search')"
                single-line
                hide-details
              />
            </o-col>
          </o-row>
        </template>
        <template #item.name="{ item }">
          <p>{{ getDpaName(item) }}</p>
        </template>
        <template #item.action="{ item }">
          <div style="white-space: nowrap">
            <o-btn depressed icon @click="editDemandPartnerAccount(item)">
              <o-icon small> edit </o-icon>
            </o-btn>
            <o-btn depressed icon @click="open_config_dialog(item)">
              <o-icon small> settings </o-icon>
            </o-btn>
            <o-btn depressed icon @click="showDemandPartnerAccount(item)">
              <o-icon small> visibility </o-icon>
            </o-btn>
            <o-btn depressed icon @click="showDemandPartnerBidderConfig(item)">
              <o-icon small> more </o-icon>
            </o-btn>
            <o-btn depressed icon @click="onArchive(item)">
              <o-icon small color="error"> delete </o-icon>
            </o-btn>
          </div>
        </template>
      </o-data-table>
      <o-dialog v-model="dialog" max-width="80%">
        <o-card style="overflow-x: hidden">
          <o-row align="center">
            <o-col cols="auto">
              <o-card-title>
                <span v-if="!readOnly && editedIndex !== -1" class="headline"
                  >{{ $t('buttons.demand_partner_account.edit') }}:</span
                >
                <span
                  v-else-if="readOnly && editedIndex !== -1"
                  class="headline"
                  >{{ $t('buttons.demand_partner_account.show') }}:</span
                >
                <span v-else class="headline"
                  >{{ $t('buttons.demand_partner_account.add') }}:</span
                >
              </o-card-title>
            </o-col>
            <o-spacer />
            <o-col cols="auto">
              <o-btn
                v-if="!readOnly"
                :disabled="!valid"
                color="blue darken-1"
                text
                @click="save"
              >
                {{ $t('buttons.general.save') }}
              </o-btn>
              <o-btn color="blue darken-1" text @click="close">
                {{ $t('buttons.general.close') }}
              </o-btn>
            </o-col>
          </o-row>
          <o-card-text>
            <o-container>
              <o-form ref="form" v-model="valid" lazy-validation>
                <o-row justify="start">
                  <o-col cols="auto">
                    <o-switch
                      v-model="editedItem.default"
                      invert-conditions
                      label="Default Demand Partner Account"
                    />
                  </o-col>
                </o-row>
                <o-row>
                  <o-col cols="12" sm="6" md="4">
                    <o-text-field
                      v-model="editedItem.name"
                      :readonly="readOnly"
                      :filled="readOnly"
                      label="Name"
                      :rules="createRules([baseValidation.required])"
                    />
                  </o-col>
                  <o-col cols="12" sm="6" md="4">
                    <o-autocomplete
                      v-model="editedItem.demand_partner_id"
                      :readonly="readOnly"
                      :filled="readOnly"
                      :items="getDemandPartners"
                      label="demand_partner_accounts.select_demand_partner"
                      :rules="createRules([baseValidation.required])"
                    />
                  </o-col>
                  <o-col cols="12" sm="6" md="4">
                    <o-select
                      v-model="editedItem.type"
                      :readonly="readOnly"
                      required
                      :filled="readOnly"
                      :items="dpa_types_list"
                      label="Type"
                      :rules="createRules([baseValidation.required])"
                    />
                  </o-col>
                </o-row>
                <o-row>
                  <o-col cols="12" sm="6" md="4">
                    <o-text-field
                      v-model="editedItem.bidder_code"
                      :readonly="readOnly"
                      :filled="readOnly"
                      label="Bidder code"
                      :rules="createRules([baseValidation.required])"
                    />
                  </o-col>
                  <o-col cols="12" sm="6" md="4">
                    <o-text-field
                      v-model.number="editedItem.web_bidder_discount"
                      :readonly="readOnly"
                      :filled="readOnly"
                      label="Web discount"
                      :rules="createRules([baseValidation.required])"
                    />
                  </o-col>
                  <o-col cols="12" sm="6" md="4">
                    <o-text-field
                      v-model.number="editedItem.amp_bidder_discount"
                      :readonly="readOnly"
                      :filled="readOnly"
                      label="APP discount"
                      :rules="createRules([baseValidation.required])"
                    />
                  </o-col>
                </o-row>
                <o-row>
                  <o-col cols="12" sm="6" md="4">
                    <o-text-field
                      v-model="editedItem.bidder_alias"
                      :readonly="readOnly"
                      :filled="readOnly"
                      chips
                      label="Bidder alias"
                      no-filter
                      multiple
                    />
                  </o-col>
                  <o-col cols="12" sm="6" md="4">
                    <o-select
                      v-model="editedItem.consent_type_target"
                      :readonly="readOnly"
                      required
                      :filled="readOnly"
                      :items="getConsentTypeTargetOptions"
                      item-value="value"
                      item-text="name"
                      label="Consent Type Target"
                      :rules="createRules([baseValidation.required])"
                    />
                  </o-col>
                  <o-col cols="12" sm="6" md="4">
                    <o-select
                      v-model="editedItem.product_id"
                      :readonly="readOnly"
                      :filled="readOnly"
                      required
                      :items="getProducts"
                      item-value="id"
                      item-text="name"
                      label="product.select_product"
                      :rules="createRules([baseValidation.required])"
                    />
                  </o-col>
                </o-row>
                <o-row>
                  <o-col>
                    <o-textarea
                      v-model="ads_txt_text"
                      label="Ads.txt"
                      :loading="getAdsTxtLoading"
                      :rows="10"
                      no-resize
                    />
                  </o-col>
                </o-row>
              </o-form>
            </o-container>
          </o-card-text>
          <o-card-actions>
            <o-spacer />
            <o-btn
              v-if="!readOnly"
              :disabled="!valid"
              color="blue darken-1"
              text
              @click="save"
            >
              {{ $t('buttons.general.save') }}
            </o-btn>
            <o-btn color="blue darken-1" text @click="close">
              {{ $t('buttons.general.close') }}
            </o-btn>
          </o-card-actions>
        </o-card>
      </o-dialog>
      <o-dialog v-model="configDialog" max-width="500px">
        <o-card style="overflow-x: hidden">
          <o-container class="px-6">
            <o-card elevation="5" disabled>
              <o-card-title>Default config</o-card-title>
              <o-card-text class="ma-2" elevation="-5">
                {{ currentDpa.default_match_config }}
              </o-card-text>
            </o-card>
          </o-container>
          <o-container class="px-6">
            <o-card elevation="5" class="" style="overflow-x: hidden">
              <o-list dense>
                <o-subheader>
                  {{ $t('publisher_demand_partner.winners_list') }}
                </o-subheader>

                <o-list-item
                  v-for="winnerFromDPA in currentDpa.winners"
                  :key="winnerFromDPA"
                >
                  <o-list-item-content>
                    <o-list-item-title>{{ winnerFromDPA }}</o-list-item-title>
                  </o-list-item-content>

                  <o-list-item-icon>
                    <o-btn
                      depressed
                      icon
                      large
                      class="mx-4"
                      @click="remove_winner(winnerFromDPA)"
                    >
                      <o-icon small> remove </o-icon>
                    </o-btn>
                  </o-list-item-icon>
                </o-list-item>
              </o-list>
              <o-row><o-divider class="my-4" /></o-row>
              <o-row class="mx-1">
                <o-col cols="9">
                  <o-combobox
                    ref="dpa_winners_combobox"
                    v-model="winner"
                    :items="getActiveWinners"
                    item-text="winner"
                    :label="$t('publisher_demand_partner.winner_input')"
                  />
                </o-col>
                <o-col cols="3">
                  <o-btn
                    class="ma-4"
                    color="primary"
                    depressed
                    @click="push_win"
                  >
                    {{ $t('buttons.general.add') }}<o-icon>mdi-arrow</o-icon>
                  </o-btn>
                </o-col>
              </o-row>
            </o-card>
          </o-container>
          <o-container class="px-6">
            <o-card elevation="5" style="overflow-x: hidden">
              <o-list dense>
                <o-subheader>
                  {{ $t('publisher_demand_partner.programmatic_channel_list') }}
                </o-subheader>
                <o-list-item
                  v-for="pChannelFromDpa in currentDpa.programmatic_channels"
                  :key="pChannelFromDpa"
                >
                  <o-list-item-content>
                    <o-list-item-title>
                      {{ pChannelFromDpa }}
                    </o-list-item-title>
                  </o-list-item-content>

                  <o-list-item-icon>
                    <o-btn
                      depressed
                      icon
                      large
                      class="mx-4"
                      @click="remove_programmatic_channel(pChannelFromDpa)"
                    >
                      <o-icon small> remove </o-icon>
                    </o-btn>
                  </o-list-item-icon>
                </o-list-item>
              </o-list>
              <o-row><o-divider class="my-4" /></o-row>
              <o-row class="mx-1">
                <o-col cols="9">
                  <o-combobox
                    ref="dpat_programmatic_channel"
                    v-model="programmatic_channel"
                    :items="getProgrammaticChannels"
                    item-text="programmatic_channel"
                    :label="
                      $t('publisher_demand_partner.programmatic_channel_input')
                    "
                  />
                </o-col>
                <o-col cols="3">
                  <o-btn
                    class="ma-4"
                    color="primary"
                    depressed
                    @click="push_programmatic_channel"
                  >
                    {{ $t('buttons.general.add') }}<o-icon>mdi-arrow</o-icon>
                  </o-btn>
                </o-col>
              </o-row>
            </o-card>
          </o-container>
          <o-card-actions>
            <o-spacer />
            <o-btn
              color="blue darken-1"
              text
              @click="saveConfig(getDemandPartnerAccountById(dpa_id))"
            >
              {{ $t('buttons.publisher_demand_partner.save_winners') }}
            </o-btn>
            <o-btn color="blue darken-1" text @click="close_config_dialog">
              {{ $t('buttons.general.close') }}
            </o-btn>
          </o-card-actions>
        </o-card>
      </o-dialog>
      <o-dialog v-model="bidderDialog" max-width="80%">
        <o-card elevation="5" style="overflow-x: hidden">
          <o-row align="center">
            <o-col cols="auto">
              <o-card-title>
                {{ getDemandPartnerAccountById(dpa_id).name }}
              </o-card-title>
            </o-col>
            <o-spacer />
            <o-col cols="auto" class="mr-2">
              <o-spacer />
              <o-btn color="blue darken-1" text @click="save_bidder_dialog">
                {{ $t('buttons.general.save') }}
              </o-btn>
              <o-btn color="blue darken-1" text @click="close_bidder_dialog()">
                {{ $t('buttons.general.close') }}
              </o-btn>
            </o-col>
          </o-row>
          <o-row>
            <o-col cols="4" sm="6" md="4">
              <o-card-text class="ma-2" elevation="-5">
                <o-container>
                  <o-form
                    ref="adslot_form"
                    v-model="bidder_config_valid"
                    lazy-validation
                  >
                    <div v-if="custom_field_config.static.length === 0">
                      No dpa fields configured
                    </div>
                    <o-simple-table dense>
                      <tbody>
                        <tr
                          v-for="field in custom_field_config.static || []"
                          :key="field.key"
                        >
                          <td>{{ field.key }}</td>
                          <td>
                            <o-text-field
                              v-if="field.type === 'number'"
                              v-model.number="bidder_config[field.key]"
                              dense
                            /><o-text-field
                              v-else-if="field.type === 'text'"
                              v-model="bidder_config[field.key]"
                              dense
                              single-line
                            />
                          </td>
                        </tr>
                      </tbody>
                    </o-simple-table>
                  </o-form>
                </o-container>
              </o-card-text>
            </o-col>
            <o-col cols="8">
              <o-card-text class="ma-2" elevation="-5">
                <o-container>
                  <o-form
                    ref="adslot_form"
                    v-model="bidder_config_valid"
                    lazy-validation
                  >
                    <o-row>
                      <o-col>
                        <o-card-subtitle>DPA fields</o-card-subtitle>
                        <o-list dense>
                          <o-list-item
                            v-for="field in custom_field_config.static"
                            :key="field.key"
                          >
                            <o-list-item-subtitle>
                              {{ field.key }}
                            </o-list-item-subtitle>
                            {{ field.type }}
                            <o-list-item-action>
                              <o-btn
                                depressed
                                icon
                                class="mx-4"
                                @click="remove_custom_field('static', field)"
                              >
                                <o-icon small> remove </o-icon>
                              </o-btn>
                            </o-list-item-action>
                          </o-list-item>
                        </o-list>
                      </o-col>
                      <o-col>
                        <o-card-subtitle>Site fields</o-card-subtitle>
                        <o-list dense>
                          <o-list-item
                            v-for="field in custom_field_config.global"
                            :key="field.key"
                          >
                            <o-list-item-subtitle>
                              {{ field.key }}
                            </o-list-item-subtitle>
                            {{ field.type }}
                            <o-list-item-action>
                              <o-btn
                                depressed
                                icon
                                class="mx-4"
                                @click="remove_custom_field('global', field)"
                              >
                                <o-icon small> remove </o-icon>
                              </o-btn>
                            </o-list-item-action>
                          </o-list-item>
                        </o-list>
                      </o-col>
                      <o-col>
                        <o-card-subtitle>Adslot fields</o-card-subtitle>
                        <o-list dense>
                          <o-list-item
                            v-for="field in custom_field_config.by_adslot"
                            :key="field.key"
                          >
                            <o-list-item-subtitle>
                              {{ field.key }}
                            </o-list-item-subtitle>
                            {{ field.type }}
                            <o-list-item-action>
                              <o-btn
                                depressed
                                icon
                                class="mx-4"
                                @click="remove_custom_field('by_adslot', field)"
                              >
                                <o-icon small> remove </o-icon>
                              </o-btn>
                            </o-list-item-action>
                          </o-list-item>
                        </o-list>
                      </o-col>
                      <o-col />
                    </o-row>
                    <o-row>
                      <o-col>
                        <o-text-field
                          v-model="new_key_static"
                          label="New field key"
                        />
                        <o-select
                          v-model="new_type_static"
                          label="Type"
                          :items="types"
                        />
                        <o-btn
                          @click="
                            new_key_static && new_type_static
                              ? custom_field_config.static.push({
                                  key: new_key_static,
                                  type: new_type_static,
                                })
                              : null
                          "
                        >
                          Add
                        </o-btn>
                      </o-col>
                      <o-col>
                        <o-text-field
                          v-model="new_key_global"
                          label="New field key"
                        />
                        <o-select
                          v-model="new_type_global"
                          label="Type"
                          :items="types"
                        />
                        <o-btn
                          @click="
                            new_key_global && new_type_global
                              ? custom_field_config.global.push({
                                  key: new_key_global,
                                  type: new_type_global,
                                })
                              : null
                          "
                        >
                          Add
                        </o-btn>
                      </o-col>
                      <o-col>
                        <o-text-field
                          v-model="new_key_by_adslot"
                          label="New field key"
                        />
                        <o-select
                          v-model="new_type_by_adslot"
                          label="Type"
                          :items="types"
                        />
                        <o-btn
                          @click="
                            new_key_by_adslot && new_type_by_adslot
                              ? custom_field_config.by_adslot.push({
                                  key: new_key_by_adslot,
                                  type: new_type_by_adslot,
                                })
                              : null
                          "
                        >
                          Add
                        </o-btn>
                      </o-col>
                      <o-col />
                    </o-row>
                  </o-form>
                </o-container>
              </o-card-text>
              <o-card-actions>
                <o-spacer />
                <o-btn color="blue darken-1" text @click="save_bidder_dialog">
                  {{ $t('buttons.general.save') }}
                </o-btn>
                <o-btn
                  color="blue darken-1"
                  text
                  @click="close_bidder_dialog()"
                >
                  {{ $t('buttons.general.close') }}
                </o-btn>
              </o-card-actions>
            </o-col>
          </o-row>
        </o-card>
      </o-dialog>
      <confirmation-dialog
        ref="confirmation"
        msg="Do you want to archive DPA?"
        @confirm="confirmArchive"
        @close="closeArchive"
      />
    </o-col>
  </o-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { baseValidation } from '@/validation/baseValidation';
import { validationHelper } from '@/validation/validationHelper';
import store from '@/store';
import { eventBus } from '@/main';
import ConfirmationDialog from '@/components/DesignSystem/ODialog/ConfirmationDialog.vue';

export default {
  name: 'DemandPartnerAccountsTable',
  components: { ConfirmationDialog },
  mixins: [baseValidation, validationHelper],
  data() {
    return {
      dialog: false,
      configDialog: false,
      bidderDialog: false,
      itemToRemove: {},
      custom_field_config: {
        static: [
          { type: 'number', key: 'accountId' },
          { type: 'number', key: 'new' },
        ],
        global: [],
        by_adslot: [],
      },
      bidder_config: { accountId: 9585, new: 243084, zoneId: 1201454 },
      new_key_global: '',
      new_key_static: '',
      new_key_by_adslot: '',
      new_type_global: '',
      new_type_static: '',
      new_type_by_adslot: '',
      types: ['text', 'number', 'list'],
      dpa_bidder: {},
      dpa: {},
      ads_txt_text: '',
      dpa_id: -1,
      winner: '',
      programmatic_channel: '',
      readOnly: false,
      valid: true,
      bidder_config_valid: true,
      editedIndex: -1,
      editedItem: {},
      dpa_types_list: ['publisher', 'optidigital'],
      dpa_type: '',
      search: '',
      headers: [
        {
          text: 'ID',
          value: 'id',
        },
        {
          text: 'Name (* -> Default for new Publishers)',
          value: 'name',
        },
        {
          text: 'Demand Partner',
          value: 'demand_partner_name',
        },
        {
          text: '# Ads.txt lines',
          value: 'ads_txt_count',
          align: 'center',
        },
        {
          text: 'Action',
          value: 'action',
          sortable: false,
          filterable: false,
          align: 'center',
        },
      ],
    };
  },
  mounted() {
    store.dispatch('publishers/fetchPublishers');
    store.dispatch('demandPartnerAccounts/fetchDemandPartnerAccounts');
    store.dispatch('demandPartnerAccounts/fetchActiveWinners');
    store.dispatch('demandPartnerAccounts/fetchProgrammaticChannels');
    store.dispatch('demandPartners/fetchDemandPartners');
    store.dispatch('product/fetchProducts');
  },
  computed: {
    ...mapGetters('demandPartnerAccounts', [
      'getLoading',
      'getDemandPartnerAccounts',
      'getDemandPartnerAccountById',
      'getDefaultDemandPartnerAccount',
      'getActiveWinners',
      'getProgrammaticChannels',
      'getConsentTypeTargetOptions',
    ]),
    ...mapGetters('demandPartners', ['getDemandPartners']),
    ...mapGetters('product', {
      getProducts: 'getProducts',
      getProductsLoading: 'getLoading',
    }),
    ...mapGetters('adsTxt', { getAdsTxtLoading: 'getLoading' }),
    ...mapGetters('dpaBidder', ['getDPABidder']),
    getDemandPartnerAccountsWithRelations() {
      return this.getDemandPartnerAccounts.map((item) => {
        if (item) {
          item.demand_partner_name = this.getDemandPartnerName(item);
        }
        return item;
      });
    },
    currentDpa: {
      get() {
        return (
          this.getDemandPartnerAccounts.find((el) => el.id === this.dpa_id) ??
          {}
        );
      },
      set(input) {
        this.$set(this.getDemandPartnerAccounts, this.dpa_id, input);
      },
    },
  },
  methods: {
    ...mapActions('demandPartnerAccounts', [
      'fetchDemandPartnerAccounts',
      'createDemandPartnerAccount',
      'updateDemandPartnerAccount',
      'deleteDemandPartnerAccount',
      'updateDPABidder',
    ]),
    ...mapActions('demandPartners', ['fetchDemandPartners']),
    ...mapActions('adsTxt', ['getAdsTxt', 'updateAdsTxt']),
    ...mapActions('dpaBidder', [
      'fetchDPABidder',
      'updateDPABidder',
      'updateDPABidderCustomConfig',
    ]),
    getDemandPartnerName(item) {
      const demandPartner = this.getDemandPartners.find((dp) => {
        return dp.id === item.demand_partner_id;
      });
      if (demandPartner) {
        return demandPartner.name;
      }
    },
    async editDemandPartnerAccount(item) {
      this.editedIndex = this.getDemandPartnerAccounts.indexOf(item);
      this.editedItem = { ...item };
      this.ads_txt_text = '';
      this.readOnly = false;
      this.dialog = true;
      if (item.id !== 0) {
        this.ads_txt_text = await this.getAdsTxt(item.id);
      }
    },
    showDemandPartnerAccount(item) {
      this.editedIndex = this.getDemandPartnerAccounts.indexOf(item);
      this.editedItem = { ...item };
      this.readOnly = true;
      this.dialog = true;
    },
    push_win() {
      if (
        this.$refs.dpa_winners_combobox.internalSearch &&
        this.winner !== this.$refs.dpa_winners_combobox.internalSearch
      ) {
        this.winner = this.$refs.dpa_winners_combobox.internalSearch;
      }

      if (
        this.winner &&
        !this.currentDpa.winners.find((elem) => elem === this.winner)
      ) {
        this.currentDpa.winners.push(this.winner);
      }

      store.dispatch('demandPartnerAccounts/updateDemandPartnerAccount', {
        ...this.currentDpa,
      });
    },
    push_programmatic_channel() {
      if (
        this.$refs.dpat_programmatic_channel.internalSearch &&
        this.programmatic_channel !==
          this.$refs.dpat_programmatic_channel.internalSearch
      ) {
        this.programmatic_channel =
          this.$refs.dpat_programmatic_channel.internalSearch;
      }
      if (
        this.programmatic_channel &&
        !this.currentDpa.programmatic_channels.find(
          (elem) => elem === this.programmatic_channel,
        )
      ) {
        this.currentDpa.programmatic_channels.push(this.programmatic_channel);
      }
    },
    remove_winner(winner) {
      this.currentDpa.winners = this.currentDpa.winners.filter(
        (el) => el !== winner,
      );
    },
    remove_programmatic_channel(programmatic_channel) {
      this.currentDpa.programmatic_channels =
        this.currentDpa.programmatic_channels.filter(
          (el) => el !== programmatic_channel,
        );
    },
    saveConfig(item) {
      store
        .dispatch('demandPartnerAccounts/updateDemandPartnerAccount', {
          ...item,
        })
        .then((response) => {
          if (response && 'warnings' in response) {
            eventBus.$emit('action_warning', response['warnings']);
          }
          store.dispatch('demandPartnerAccounts/fetchDemandPartnerAccounts');
          this.close_config_dialog();
        })
        .catch((err) => console.log(err));
    },
    getDpaName(dpa) {
      if (dpa.default === true) {
        return dpa.name + ' (*)';
      }
      return dpa.name;
    },
    open_config_dialog(item) {
      this.configDialog = true;
      this.dpa_id = item.id;
    },
    showDemandPartnerBidderConfig(item) {
      this.bidderDialog = true;
      this.dpa_id = item.id;
      this.dpa = { ...item };
      this.custom_field_config = this.dpa.custom_fields_config;
      this.bidder_config = this.dpa.bidder_config_obj;
    },
    close_config_dialog() {
      this.configDialog = false;
      store.dispatch('demandPartnerAccounts/fetchDemandPartnerAccounts');
    },
    close_bidder_dialog() {
      this.bidderDialog = false;
      store.dispatch('demandPartnerAccounts/fetchDemandPartnerAccounts');
    },
    async save_bidder_dialog() {
      await store.dispatch('demandPartnerAccounts/updateDPABidder', this.dpa);
      await store.dispatch('demandPartnerAccounts/fetchDemandPartnerAccounts');
      this.bidderDialog = false;
    },
    close() {
      this.dialog = false;
      this.editedIndex = -1;
    },
    remove_custom_field(field_type, field) {
      this.custom_field_config[field_type] = this.custom_field_config[
        field_type
      ].filter((el) => el.key !== field.key);
    },
    save() {
      if (this.$refs.form.validate()) {
        const ads_txt_lines = this.ads_txt_text;
        if (this.editedIndex > -1) {
          this.updateDemandPartnerAccount(this.editedItem).then((response) => {
            if (response && 'warnings' in response) {
              eventBus.$emit('action_warning', response['warnings']);
            }
          });
          this.updateAdsTxt({
            dpa_id: this.editedItem.id,
            ads_txt_lines,
          });
        } else {
          this.createDemandPartnerAccount(this.editedItem).then((response) => {
            if (response && 'warnings' in response) {
              eventBus.$emit('action_warning', response['warnings']);
            }
            if (response.data.id) {
              this.updateAdsTxt({
                dpa_id: response.data.id,
                ads_txt_lines,
              });
            }
          });
        }
        this.close();
      }
    },
    onArchive(item) {
      this.itemToRemove = item;
      this.$refs.confirmation.open();
    },
    confirmArchive() {
      this.deleteDemandPartnerAccount(this.itemToRemove);
      this.$refs.confirmation.close();
    },
    closeArchive() {
      this.$refs.confirmation.close();
    },
  },
};
</script>

<style scoped></style>
