export const moneyMixin = {
  methods: {
    formatMoney(value) {
      return value
        .toString()
        .replace(/(\d)(?=(\d{3})+\.)/g, '$1 ')
        .replace('.', ',');
    },
    convertMoneyStringToFloat(value) {
      return parseFloat(
        value.toString().replace(' ', '').replace(',', '.'),
      ).toFixed(2);
    },
  },
};
