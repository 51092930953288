import Vue from 'vue';
import VueRouter from 'vue-router';
import AdminDashboard from './pages/Admin/AdminDashboard.vue';
import Login from './pages/Login.vue';
import Layout from './pages/Layout.vue';
import store from './store/index';
import AuditLog from './pages/Admin/AuditLog.vue';
import SubPagesRouter from './pages/SubPagesRouter.vue';
import Publishers from '@/pages/Accounting/Publishers.vue';
import AccountingDashboard from '@/pages/Accounting/AccountingDashboard.vue';
import DemandPartners from '@/pages/Accounting/DemandPartners';
import DemandPartnerAccounts from '@/pages/Accounting/DemandPartnerAccounts';
import FinancialReporting from '@/pages/Accounting/FinancialReporting';
import Payments from '@/pages/Accounting/Payments';
import FourOhFour from './components/FourOhFour';
import ReportingTurnoverFinal from '@/pages/Accounting/ReportingTurnoverFinal';
import AdConfigRender from './pages/Settings/AdConfigRender';
import SellersJson from '@/pages/PublicConfig/SellersJson';
import ReleasesAuditLog from './pages/Releases/ReleasesAuditLog.vue';
import ReleasesTable from './pages/Releases/ReleasesTable.vue';
import ReleasesRedis from './pages/Releases/ReleasesRedis.vue';
import FileDiffPage from './pages/Settings/FileDiffPage';
import SyncReportsPage from './pages/SSP/SyncReportsPage';
import PriceFloorConfiguration from './pages/PriceFloor/PriceFloorConfiguration.vue';
import PriceFloorPublishers from './pages/PriceFloor/PriceFloorPublishers.vue';
import PriceFloorSettings from './pages/PriceFloor/PriceFloorSettings.vue';
import FloorManager from '@/components/FloorManagement/FloorManager.vue';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: Login,
    },
    {
      path: '/',
      component: Layout,
      children: [
        {
          path: '/admin',
          component: SubPagesRouter,
          children: [
            {
              path: '/',
              name: 'Dashboard',
              component: AdminDashboard,
            },
            {
              path: 'audit-log',
              name: 'AuditLog',
              component: AuditLog,
            },
          ],
        },
        {
          path: '/publicConfig',
          component: SubPagesRouter,
          children: [
            {
              path: 'sellersJson',
              name: 'SellersJson',
              component: SellersJson,
            },
          ],
        },
        {
          path: '/accounting',
          component: SubPagesRouter,
          children: [
            {
              path: '/',
              name: 'AccountingDashboard',
              component: AccountingDashboard,
            },
            {
              path: 'publishers',
              name: 'AccountingPublishers',
              component: Publishers,
            },
            {
              path: 'floor_manager',
              name: 'FloorManager',
              component: FloorManager,
            },
            {
              path: 'sites',
              name: 'SiteEntities',
              component() {
                return import(
                  /* webpackChunkName: "ext-site" */ '@/pages/Accounting/Sites.vue'
                );
              },
            },
            {
              path: 'changeHistory',
              name: 'changeHistory',
              component() {
                return import(
                  /* webpackChunkName: "ext-history" */
                  '@/pages/Accounting/ChangeHistory.vue'
                );
              },
            },
            {
              path: 'abTests',
              name: 'abTests',
              component() {
                return import(
                  /* webpackChunkName: "ext-ab-test" */
                  '@/pages/Accounting/AbTests.vue'
                );
              },
            },
            {
              path: 'demandPartners',
              name: 'DemandPartners',
              component: DemandPartners,
            },
            {
              path: 'demandPartnerAccounts',
              name: 'DemandPartnerAccounts',
              component: DemandPartnerAccounts,
            },
            {
              path: 'financialReporting',
              name: 'FinancialReporting',
              component: FinancialReporting,
            },
            {
              path: 'publisherFinancial',
              name: 'PublisherFinancial',
              component: ReportingTurnoverFinal,
            },
            {
              path: 'payments',
              name: 'Payments',
              component: Payments,
            },
          ],
        },
        {
          path: '/',
          name: 'Home',
          component() {
            return import(
              /* webpackChunkName: "ext-home-page" */ '@/pages/Home.vue'
            );
          },
        },
        {
          path: '404',
          name: 'FourOhFour',
          component: FourOhFour,
        },
        {
          path: '/settings',
          component: SubPagesRouter,
          children: [
            {
              path: 'TagsManager',
              name: 'TagsManager',
              component() {
                return import('@/pages/Settings/TagsManager.vue');
              },
            },
            {
              path: 'AdConfigRender',
              name: 'AdConfigRender',
              component: AdConfigRender,
            },
            {
              path: 'FileDiff',
              name: 'FileDiff',
              component: FileDiffPage,
            },
          ],
        },
        {
          path: '/ssp',
          component: SubPagesRouter,
          children: [
            {
              path: 'sync_reports',
              name: 'SyncReports',
              component: SyncReportsPage,
            },
          ],
        },
        {
          path: '/releases',
          component: SubPagesRouter,
          children: [
            {
              path: 'web',
              name: 'ReleasesWeb',
              component: ReleasesTable,
              props: {
                inventoryType: 'WEB',
              },
            },
            {
              path: 'amp',
              name: 'ReleasesAmp',
              component: ReleasesTable,
              props: {
                inventoryType: 'AMP',
              },
            },
            {
              path: 'log',
              name: 'ReleasesAuditLog',
              component: ReleasesAuditLog,
            },
            {
              path: 'redis',
              name: 'ReleasesRedis',
              component: ReleasesRedis,
            },
          ],
        },
        {
          path: '/priceFloor',
          component: SubPagesRouter,
          children: [
            {
              path: 'configuration',
              name: 'PriceFloorConfiguration',
              component: PriceFloorConfiguration,
            },
            {
              path: 'publishers',
              name: 'PriceFloorPublishers',
              component: PriceFloorPublishers,
            },
            {
              path: 'settings',
              name: 'PriceFloorSettings',
              component: PriceFloorSettings,
            },
          ],
        },
      ],
    },
    {
      path: '*',
      redirect: '/404',
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  const loginPage = '/login';
  if (to.path === loginPage) {
    return next();
  }
  await store.dispatch('context/fetchContext');
  if (!store.getters['context/isAuthorizationToken']) {
    return next(loginPage);
  }

  if (to.path === '/admin' && store.getters['context/isUserExtern']) {
    return next('/404');
  }

  return next();
});

export default router;
