<template>
  <span>
    <o-dialog v-model="isOpen" persistent width="50vw">
      <o-card class="pa-3 text-center">
        <o-card-title>
          <o-col>{{ msg }}</o-col>
        </o-card-title>
        <o-card-actions justify="center">
          <o-spacer />
          <o-btn :color="confirmColor" @click="$emit('confirm', $event)">
            {{ confirmText }}
          </o-btn>
          <o-spacer />
          <o-btn :color="closeColor" @click="$emit('close', true)">
            {{ cancelText }}
          </o-btn>
          <o-spacer />
        </o-card-actions>
      </o-card>
    </o-dialog>
  </span>
</template>
<script>
export default {
  props: {
    msg: {
      type: String,
      required: true,
    },
    confirmColor: {
      type: String,
      required: false,
      default: 'error',
    },
    closeColor: {
      type: String,
      required: false,
      default: 'green',
    },
    closeButtonText: {
      type: String,
      required: false,
      default: '',
    },
    confirmButtonText: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    confirmText() {
      return '' !== this.confirmButtonText
        ? this.confirmButtonText
        : this.$t('buttons.general.yes');
    },
    cancelText() {
      return '' !== this.closeButtonText
        ? this.closeButtonText
        : this.$t('buttons.general.cancel');
    },
  },
  methods: {
    open() {
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },
  },
};
</script>

<style scoped></style>
