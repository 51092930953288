<template>
  <o-row align="center" justify="center">
    <o-col>
      <o-row align="center" class="pt-5 pb-5">
        <o-card-title>Sellers.json</o-card-title>
        <o-spacer />
        <o-switch
          v-model="obfuscated"
          :true-value="1"
          :false-value="0"
          label="Obfuscated"
        />
        <o-btn
          :disabled="!base_information_validation"
          class="mr-6 ml-6"
          color="primary"
          @click="onRenderSellersJson"
        >
          Render
        </o-btn>
        <o-btn
          class="mr-6 ml-6"
          color="primary"
          @click="importIdentifiersDialog = true"
        >
          Import Identifiers
        </o-btn>
        <o-btn class="mr-6 ml-6" color="primary" @click="onRefreshData">
          Refresh
        </o-btn>
        <o-btn
          :disabled="!base_information_validation"
          class="mr-6 ml-6"
          color="primary"
          @click="onSaveData"
        >
          Save
        </o-btn>
      </o-row>
      <o-divider />
      <o-col>
        <loading-overlay :overlay="getLoading">
          <template #default>
            <o-row>
              <o-card-title> Contact info </o-card-title>
            </o-row>
            <o-form
              ref="base_information_validation"
              v-model="base_information_validation"
              lazy-validation
            >
              <o-row>
                <o-col>
                  <o-text-field
                    v-model="contact_email"
                    label="Contact Email"
                    :rules="createRules([baseValidation.email])"
                  />
                </o-col>
                <o-col>
                  <o-text-field
                    v-model="contact_address"
                    label="Contact Adress"
                  />
                </o-col>
                <o-col>
                  <o-text-field
                    v-model="version"
                    readonly
                    disabled
                    label="Version"
                  />
                </o-col>
              </o-row>
            </o-form>
            <o-divider />
            <o-row>
              <o-card-title> Identifiers </o-card-title>
            </o-row>
            <o-row align="center" justify="center">
              <o-col cols="12">
                <o-data-table :headers="headers" :items="identifiers">
                  <template #top>
                    <o-btn
                      color="primary"
                      class="mx-4"
                      @click="openIdentifierDialog(getDefaultIdentifier())"
                    >
                      Add Identifier
                    </o-btn>
                  </template>
                  <template #item.action="{ item }">
                    <o-btn
                      color="primary"
                      icon
                      small
                      class="mx-1"
                      @click="openIdentifierDialog(item)"
                    >
                      <o-icon> edit </o-icon>
                    </o-btn>
                    <o-btn
                      color="red"
                      icon
                      small
                      class="mx-1"
                      @click="removeIdentifier(item)"
                    >
                      <o-icon> delete </o-icon>
                    </o-btn>
                  </template>
                  />
                </o-data-table>
              </o-col>
            </o-row>
          </template>
        </loading-overlay>
      </o-col>
      <o-dialog v-model="identifierDialog" width="50vw">
        <o-card>
          <o-card-title> Identifier </o-card-title>
          <o-card-text>
            <o-text-field v-model="editedIdentifier.name" label="Name" />
            <o-text-field v-model="editedIdentifier.value" label="Value" />
          </o-card-text>
          <o-card-actions>
            <o-spacer />
            <o-btn color="primary" @click="onAddOrUpdateIdentifier">
              Add
            </o-btn>
            <o-btn @click="identifierDialog = false"> Cancel </o-btn>
          </o-card-actions>
        </o-card>
      </o-dialog>
      <o-dialog v-model="renderDialog" width="80vw">
        <o-card style="overflow-x: hidden">
          <o-card-actions>
            <o-card-title>Render Sellers Json</o-card-title>
            <o-spacer />
            <o-col cols="auto">
              <o-btn
                :disabled="getRenderedSellersJson.length === 0"
                value="Copy Config"
                append
                @click="copySellersJson"
              >
                Copy
                <o-icon right> mdi-content-copy </o-icon>
              </o-btn>
            </o-col>
            <o-col cols="auto">
              <o-btn
                class="ma-4"
                color="primary"
                @click="downloadRenderedSellersJson"
              >
                Download
              </o-btn>
              <o-btn
                v-if="!getLoading"
                class="ma-4"
                color="primary"
                @click="upload"
              >
                Upload to Google Storage
              </o-btn>
              <o-btn v-if="getLoading" class="ma-4" color="primary">
                <v-progress-circular indeterminate />
              </o-btn>
            </o-col>
            <o-col cols="auto">
              <o-btn class="ma-4" color="primary" @click="renderDialog = false">
                Close
              </o-btn>
            </o-col>
          </o-card-actions>
          <o-card-text>
            <o-row>
              <o-textarea
                id="rendered_sellers_json"
                v-model="getRenderedSellersJson"
                readonly
                no-resize
                height="45vh"
                outlined
                rounded
              />
            </o-row>
            <o-row>
              <p>
                Note: Publishers that name starts with "QA-" or ends with "-QA"
                are ignored!!!
              </p>
            </o-row>
          </o-card-text>
          <o-card-actions>
            <o-spacer />
            <o-col cols="auto">
              <o-btn
                :disabled="getRenderedSellersJson.length === 0"
                value="Copy Config"
                append
                @click="copySellersJson"
              >
                Copy
                <o-icon right> mdi-content-copy </o-icon>
              </o-btn>
            </o-col>
            <o-col cols="auto">
              <o-btn
                class="ma-4"
                color="primary"
                @click="downloadRenderedSellersJson"
              >
                Download
              </o-btn>
            </o-col>
            <o-col cols="auto">
              <o-btn class="ma-4" color="primary" @click="renderDialog = false">
                Close
              </o-btn>
            </o-col>
          </o-card-actions>
        </o-card>
      </o-dialog>
      <o-dialog v-model="importIdentifiersDialog" width="50vw">
        <o-card>
          <o-card-title> Import Identifiers </o-card-title>
          <o-card-text>
            <o-text-field v-model="urlToImportIdentifiers" label="URL" />
          </o-card-text>
          <o-card-actions>
            <o-spacer />
            <o-btn color="primary" @click="importIdentifiers"> Import </o-btn>
            <o-btn @click="importIdentifiersDialog = false"> Cancel </o-btn>
          </o-card-actions>
        </o-card>
      </o-dialog>
      <o-snackbar
        v-model="snackbarDialog"
        top
        :timeout="5000"
        :color="snackbarColor"
      >
        {{ snackbarText }}
        <o-btn text @click="hideAlert()"> Close </o-btn>
      </o-snackbar>
    </o-col>
  </o-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import LoadingOverlay from '@/components/DesignSystem/OLoader/LoadingOverlay.vue';
import { baseValidation } from '@/validation/baseValidation';
import { validationHelper } from '@/validation/validationHelper';

export default {
  components: {
    LoadingOverlay,
  },
  mixins: [baseValidation, validationHelper],
  data() {
    return {
      identifiers: [],
      base_information_validation: false,
      importIdentifiersDialog: false,
      urlToImportIdentifiers: '',
      renderDialog: false,
      snackbarDialog: false,
      snackbarText: '',
      snackbarColor: 'error',
      identifierDialog: false,
      contact_email: '',
      contact_address: '',
      version: '',
      editedIdentifier: {},
      editedIdentifierIndex: -1,
      obfuscated: 0,
      headers: [
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Value',
          value: 'value',
        },
        {
          text: 'Action',
          value: 'action',
          align: 'end',
        },
      ],
    };
  },
  async mounted() {
    this.refreshData();
  },
  computed: {
    ...mapGetters('sellersJson', [
      'getDefaultIdentifier',
      'getIdentifiers',
      'getContactInfo',
      'getLoading',
      'getRenderedSellersJson',
    ]),
  },
  methods: {
    ...mapActions('sellersJson', [
      'fetchSellersJsonData',
      'updateSellersJsonData',
      'setSellersJsonData',
      'fetchRenderedSellersJson',
      'downloadRenderedSellersJson',
      'uploadRenderedSellersJson',
      'importExternalSellerJson',
    ]),
    async setDataFromRepository() {
      this.identifiers = [...this.getIdentifiers];
      const contactInfo = { ...this.getContactInfo };
      this.contact_email = contactInfo.contact_email;
      this.contact_address = contactInfo.contact_address;
      this.version = contactInfo.version;
    },
    async syncDataToStore() {
      const dataToSave = {
        contactInfo: {
          contact_email: this.contact_email,
          contact_address: this.contact_address,
          version: this.version,
        },
        identifiers: this.identifiers,
        obfuscated: this.obfuscated,
      };
      await this.setSellersJsonData(dataToSave);
    },
    async refreshData() {
      await this.fetchSellersJsonData();
      await this.setDataFromRepository();
    },
    async onRefreshData() {
      await this.refreshData();
      this.showInfo('Data Updated');
    },
    async saveSellersJsonData() {
      await this.syncDataToStore();
      await this.updateSellersJsonData();
      await this.setDataFromRepository();
    },
    async onSaveData() {
      await this.saveSellersJsonData();
      this.showInfo('Data Saved');
    },
    openIdentifierDialog(item) {
      this.editedIdentifier = item;
      this.identifierDialog = true;
    },
    onAddOrUpdateIdentifier() {
      this.identifiers.push(this.editedIdentifier);
      this.identifierDialog = false;
    },
    removeIdentifier(item) {
      const index = this.identifiers.indexOf(item);
      this.identifiers.splice(index, 1);
    },
    async onRenderSellersJson() {
      await this.saveSellersJsonData();
      await this.fetchRenderedSellersJson();
      this.renderDialog = true;
    },
    copySellersJson() {
      const textToCopy = document.querySelector('#rendered_sellers_json');
      textToCopy.select();

      try {
        const successful = document.execCommand('copy');
        const msg = successful ? 'successfully' : 'unsuccessfully';
        this.showInfo('Text was copied ' + msg);
      } catch (err) {
        this.showAlert('Oops, unable to copy');
      }

      window.getSelection().removeAllRanges();
    },
    async upload() {
      const result = await this.uploadRenderedSellersJson();
      if (result === false) {
        this.showAlert('Failed to upload');
      } else {
        this.showInfo('Uploaded Succesfully');
      }
    },
    async importIdentifiers() {
      const result = await this.importExternalSellerJson(
        this.urlToImportIdentifiers,
      );
      if (result === false) {
        this.showAlert('Failed to import');
      } else {
        this.showInfo('Imported Succesfully');
        this.setDataFromRepository();
      }
      this.importIdentifiersDialog = false;
    },
    showAlert(e) {
      this.snackbarDialog = true;
      this.snackbarColor = 'error';
      this.snackbarText = e.toString();
    },
    showInfo(e) {
      this.snackbarDialog = true;
      this.snackbarColor = 'info';
      this.snackbarText = e.toString();
    },
    hideAlert() {
      this.snackbarDialog = false;
    },
  },
};
</script>

<style></style>
