import { render, staticRenderFns } from "./FileDiffTool.vue?vue&type=template&id=643e63fd&scoped=true"
import script from "./FileDiffTool.vue?vue&type=script&lang=js"
export * from "./FileDiffTool.vue?vue&type=script&lang=js"
import style0 from "./FileDiffTool.vue?vue&type=style&index=0&id=643e63fd&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "643e63fd",
  null
  
)

export default component.exports