<template>
  <o-row>
    <o-col cols="12">
      <o-row>
        <o-card-title>SSP sync reports</o-card-title>
      </o-row>
      <sync-reports />
    </o-col>
  </o-row>
</template>

<script>
import SyncReports from '../../components/Settings/SSP/SyncReports';

export default {
  name: 'SyncReportsPage',
  components: { SyncReports },
};
</script>

<style scoped></style>
