<template>
  <v-icon
    v-bind="{
      ...$attrs,
      ...$props,
    }"
    v-on="$listeners"
  >
    <template v-for="(_, name) in $scopedSlots" #[name]="render">
      <slot :name="name" v-bind="render"></slot>
    </template>
    <slot>{{ type }}</slot>
  </v-icon>
</template>
<script>
export default {
  name: 'OIcon',
  props: {
    type: {
      type: [String, undefined],
      default: undefined,
    },
  },
};
</script>

<style scoped></style>
