<template>
  <o-dialog
    v-model="configDialog"
    max-width="500px"
    persistent
    no-click-animation
    @click:outside="closeConfigDialog"
    @keydown.esc="closeConfigDialog"
  >
    <o-card style="overflow-x: hidden">
      <o-container class="px-6">
        <o-card elevation="5" disabled>
          <o-card-title>
            Config for site -
            {{ site.name }}
          </o-card-title>
          <o-card-text class="ma-2" elevation="-5">
            {{ site.match_config }}
          </o-card-text>
        </o-card>
      </o-container>

      <o-card-text>
        <o-container>
          <o-list dense>
            <o-subheader>{{ $t('sites.media_list') }}</o-subheader>

            <o-list-item
              v-for="mediaFromSite in site.media"
              :key="mediaFromSite"
            >
              <o-list-item-content>
                <o-list-item-title>{{ mediaFromSite }}</o-list-item-title>
              </o-list-item-content>

              <o-list-item-icon>
                <o-btn
                  depressed
                  icon
                  large
                  class="mx-4"
                  @click="remove_media(mediaFromSite)"
                >
                  <o-icon small> remove </o-icon>
                </o-btn>
              </o-list-item-icon>
            </o-list-item>
          </o-list>
          <o-row><o-divider class="my-4" /></o-row>
          <o-row>
            <o-col cols="9">
              <o-combobox
                ref="scd_combobox"
                v-model="media"
                :items="getMediaList"
                :label="$t('sites.media_input')"
              />
            </o-col>
            <o-col cols="3">
              <o-btn class="ma-4" color="primary" depressed @click="push_media">
                {{ $t('buttons.general.add') }}
              </o-btn>
            </o-col>
          </o-row>
        </o-container>
      </o-card-text>
      <o-card-actions>
        <o-spacer />
        <o-btn
          color="blue darken-1"
          text
          @click="saveConfig(getSiteEntityById(editedIndex))"
        >
          {{ $t('buttons.sites.save_config') }}
        </o-btn>
        <o-btn color="blue darken-1" text @click="closeConfigDialog">
          {{ $t('buttons.general.close') }}
        </o-btn>
      </o-card-actions>
    </o-card>
  </o-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import store from '@/store';
import { baseValidation } from '@/validation/baseValidation';
import { validationHelper } from '@/validation/validationHelper';

export default {
  name: 'SiteConfigDialog',
  mixins: [baseValidation, validationHelper],
  props: ['editedIndex', 'closeConfigDialog', 'configDialog'],
  data() {
    return {
      valid: true,
      media: '',
      mediaModified: false,
    };
  },
  computed: {
    ...mapGetters('siteEntities', [
      'getSiteEntityById',
      'getMediaList',
      'getSiteEntities',
    ]),
    site: {
      get() {
        return (
          this.getSiteEntities.find((el) => el.id === this.editedIndex) ?? {}
        );
      },
      set(input) {
        this.$set(this.getSiteEntities, this.editedIndex, input);
      },
    },
  },
  mounted() {
    store.dispatch('siteEntities/fetchMediaList');
  },
  methods: {
    ...mapActions('siteEntities', ['updateSiteEntities']),
    onChange(event) {
      this.editedItem.publisher_id = event;
    },
    push_media() {
      if (
        this.$refs.scd_combobox.internalSearch &&
        this.media !== this.$refs.scd_combobox.internalSearch
      ) {
        this.media = this.$refs.scd_combobox.internalSearch;
      }
      if (this.media && !this.site.media.find((elem) => elem === this.media)) {
        this.site.media = [...this.site.media, this.media];
        this.mediaModified = true;
      }
    },
    remove_media(media) {
      this.site.media = this.site.media.filter((el) => el !== media);
      this.mediaModified = true;
    },
    saveConfig(item) {
      store
        .dispatch('siteEntities/updateSiteEntities', { ...item })
        .then(() => store.dispatch('siteEntities/fetchSiteEntities'))
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style scoped></style>
