<template>
  <o-row>
    <o-card height="80vh">
      <o-card-title>
        <router-link :to="{ name: 'AuditLog' }"> Audit Log </router-link>
      </o-card-title>
      <o-data-table
        height="55vh"
        dense
        :loading="getLoading"
        :headers="headers"
        :items="getAuditLogs"
        :server-items-length="getAuditLogCount"
        :items-per-page="getInfoItemsPerPage"
        :page="getInfoPage"
        :options.sync="options"
        show-expand
        :footer-props="{
          'show-first-last-page': true,
          'items-per-page-options': [10, 30, 50, 100],
        }"
      >
        <template #expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            {{ item.action_message }}
          </td>
        </template>
      </o-data-table>
      <o-card-actions class="justify-center">
        <o-btn color="primary" @click="fetchStartDate()">
          {{ $t('buttons.general.refresh') }}
        </o-btn>
      </o-card-actions>
    </o-card>
  </o-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'AuditLogSmall',
  computed: {
    ...mapGetters('auditLog', [
      'getLoading',
      'getInfoItemsPerPage',
      'getInfoPage',
      'getAuditLogs',
      'getAuditLogCount',
      'getAuditStartDate',
    ]),
  },
  data() {
    return {
      options: {},
      headers: [
        { text: 'Date', value: 'date', sortable: false },
        { text: 'User', value: 'user', sortable: false },
        { text: 'Action', value: 'action', sortable: false },
        { value: 'data-table-expand' },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        if (this.getAuditStartDate !== '') {
          this.updatePaginationInfo({
            page: this.options.page,
            itemsPerPage: this.options.itemsPerPage,
          });
        }
      },
      deep: true,
    },
  },
  beforeMount() {
    this.clearData();
    this.fetchStartDate();
  },
  methods: {
    ...mapActions('auditLog', [
      'clearData',
      'updatePaginationInfo',
      'fetchStartDate',
    ]),
  },
};
</script>

<style></style>
