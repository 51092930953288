<template>
  <o-dialog
    v-model="isOpen"
    persistent
    width="90vw"
    no-click-animation
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <o-card class="pa-6">
      <o-card-title>
        <o-col>{{ $t('financial_reporting.dialog.title') }}</o-col>
        <o-col class="text-right">
          <o-icon class="ma-3" @click="closeDialog"> mdi-close </o-icon>
        </o-col>
      </o-card-title>
      <o-row>
        <o-col>
          <o-menu
            v-model="datePicker"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template #activator="{ on }">
              <o-text-field
                v-model="datePickerDate"
                prepend-icon="event"
                readonly
                v-on="on"
              />
            </template>
            <o-date-picker
              v-model="datePickerDate"
              type="month"
              @change="refreshDialog"
              @input="datePicker = false"
            />
          </o-menu>
        </o-col>
        <o-col>
          <o-autocomplete
            v-model="demandPartnerAccountAutocomplete"
            label="Demand Partner Account"
            :loading="getLoadingDemandPartnerAccounts"
            :items="getDemandPartnerAccounts"
            return-object
            hide-no-data
            @change="refreshDialog"
          />
        </o-col>
      </o-row>
      <o-divider />
      <o-row align="center" class="mt-4">
        <o-col>
          <o-text-field
            :value="
              demandPartnerAccountAutocomplete
                ? demandPartnerAccountAutocomplete.name
                : ''
            "
            label="Demand Partner Account"
            readonly
            filled
          />
        </o-col>
        <o-divider vertical />
        <o-col>
          <o-text-field
            :value="formatMoney(getFinancialReportDialogDpa.total_estimated)"
            label="Total Estimated Revenue"
            prefix="€"
            readonly
            filled
          />
        </o-col>
        <o-divider vertical />
        <o-col>
          <o-form v-model="validTotalFinalRevenue">
            <o-text-field
              v-model="dpaFinal"
              label="Total Final Revenue"
              prefix="€"
              outlined
              :rules="
                createRules([baseValidation.required, baseValidation.money])
              "
            >
              <template #append>
                <o-icon
                  v-if="getFinancialReportDialogDpa.edited"
                  icon
                  class="remove_bucket"
                  @click="
                    confirmationFunction = removeDpaFinal;
                    confirmationDialog = true;
                  "
                >
                  delete
                </o-icon>
              </template>
              <template #append-outer />
            </o-text-field>
          </o-form>
        </o-col>
        <o-col md="auto">
          <o-btn
            class="ma-4"
            color="success"
            :disabled="!canTotalFinalRevenueApplied"
            @click="saveDpaFinal"
          >
            {{ $t('buttons.general.apply') }}
          </o-btn>
        </o-col>
        <o-divider vertical />
        <o-col md="auto">
          <o-btn
            v-if="isTotalFinalRevenueValidated"
            color="success"
            @click="validateDpaFinal"
          >
            Validate
          </o-btn>
          <o-btn v-else color="error" @click="deValidateDpaFinal">
            De-Validate
          </o-btn>
        </o-col>
        <o-divider vertical />
        <o-col>
          <o-text-field
            :value="getFinancialReportDialogDpa.correction_ratio_global"
            label="Correction Ratio"
            readonly
            filled
          />
        </o-col>
      </o-row>
      <o-divider />
      <o-row>
        <o-col cols="12">
          <o-data-table
            dense
            height="40vh"
            :loading="getLoadingFinancialReporting"
            :headers="headers"
            :items="getFinancialReportDialogTable.table"
            :footer-props="{
              'show-first-last-page': true,
              'items-per-page-options': [10, 20, 30, 50, 100],
            }"
          >
            <template #item="{ item }">
              <tr
                :key="item.index"
                :class="{
                  correction_input: item.correction_input,
                  correction_insert: item.correction_insert,
                }"
              >
                <td>{{ item.publisher_site }}</td>
                <td class="text-right">
                  {{ formatMoney(item.estimated_revenue) + ' €' }}
                </td>
                <td class="text-right">
                  <div v-if="!isItemEdited(item.index)">
                    {{ formatMoney(item.manual_revenue) + ' €' }}
                  </div>
                  <div v-else>
                    <o-form v-model="validFinalRevenueTable">
                      <o-text-field
                        v-model="isItemEditedArray[item.index].editInput"
                        class="mt-4"
                        label="Final Revenue"
                        outlined
                        prefix="€"
                        :rules="
                          createRules([
                            baseValidation.required,
                            baseValidation.money,
                          ])
                        "
                      />
                    </o-form>
                  </div>
                </td>
                <td>{{ item.correction_ratio }}</td>
                <td>
                  <div v-if="item.status === ''">Correction Calculated</div>
                  <div v-else>
                    {{
                      item.status
                        .toLowerCase()
                        .split(' ')
                        .map(
                          (word) =>
                            word.charAt(0).toUpperCase() + word.slice(1),
                        )
                        .join(' ')
                    }}
                  </div>
                </td>
                <td class="text-center">
                  <o-btn
                    v-if="!isItemEdited(item.index)"
                    class="ml-4 mr-4 mt-1 mb-1"
                    color="primary"
                    x-small
                    @click="editExistingRow(item)"
                  >
                    {{ $t('buttons.general.edit') }}
                  </o-btn>
                  <o-btn
                    v-if="isItemEdited(item.index)"
                    class="ml-4 mr-4 mt-2 mb-2"
                    color="success"
                    small
                    :disabled="!validFinalRevenueTable"
                    @click="saveExistingRow(item)"
                  >
                    {{ $t('buttons.general.apply') }}
                  </o-btn>
                  <o-btn
                    v-if="isItemEdited(item.index)"
                    class="ml-4 mr-4 mt-2 mb-2"
                    color="warning"
                    small
                    @click="cancelExistingRow(item)"
                  >
                    {{ $t('buttons.general.cancel') }}
                  </o-btn>
                  <o-btn
                    v-if="isItemEdited(item.index) && item.status !== ''"
                    class="ml-4 mr-4 mt-2 mb-2"
                    small
                    icon
                    @click="
                      confirmationFunction = function () {
                        removeModRow(item);
                      };
                      confirmationDialog = true;
                    "
                  >
                    <o-icon class="remove_bucket"> delete </o-icon>
                  </o-btn>
                </td>
              </tr>
            </template>
            <template slot="body.prepend">
              <tr class="font-weight-bold">
                <td v-for="(h, index) in headers" :key="index">
                  <div v-if="h.value === 'publisher_site'" class="mt-1 mb-1">
                    {{ $t('financial_reporting.dialog.table.total') }}
                  </div>
                  <div
                    v-if="h.value === 'estimated_revenue'"
                    class="text-right"
                  >
                    {{
                      formatMoney(
                        getFinancialReportDialogTable.total_estimated,
                      ) + ' €'
                    }}
                  </div>
                  <div v-if="h.value === 'manual_revenue'" class="text-right">
                    {{
                      formatMoney(getFinancialReportDialogTable.total_final) +
                      ' €'
                    }}
                  </div>
                  <div v-if="h.value === 'correction_ratio'">
                    {{ getFinancialReportDialogTable.correction_ratio_global }}
                  </div>
                </td>
              </tr>
            </template>
            <template #body.append>
              <tr v-if="insertRow" class="table-bcg-color">
                <td>
                  <o-autocomplete
                    v-model="insertSiteElement.site"
                    :loading="getLoadingSiteEntities"
                    class="mt-4"
                    label="Publisher Site"
                    :items="filteredSiteEntities"
                    item-text="url"
                    return-object
                  />
                </td>
                <td>
                  <o-text-field
                    class="mt-4"
                    label="Estimated Revenue"
                    readonly
                    value="0"
                    filled
                    prefix="€"
                  />
                </td>
                <td>
                  <o-form v-model="validFinalRevenueInsert">
                    <o-text-field
                      v-model="insertSiteElement.final_revenue"
                      class="mt-4"
                      label="Final Revenue"
                      outlined
                      prefix="€"
                      :rules="
                        createRules([
                          baseValidation.required,
                          baseValidation.money,
                        ])
                      "
                    />
                  </o-form>
                </td>
                <td>
                  <o-text-field
                    class="mt-4"
                    label="Correction Ratio"
                    readonly
                    value="-1"
                    filled
                  />
                </td>
                <td>
                  <o-text-field
                    class="mt-4"
                    label="Status"
                    readonly
                    value="Correction Insert"
                    filled
                  />
                </td>
                <td>
                  <o-btn
                    class="ma-4"
                    color="success"
                    small
                    :disabled="!validFinalRevenueInsert"
                    @click="saveInsertRow"
                  >
                    {{ $t('buttons.general.add') }}
                  </o-btn>
                  <o-btn
                    class="ma-4"
                    color="warning"
                    small
                    @click="cancelInsertRow"
                  >
                    {{ $t('buttons.general.cancel') }}
                  </o-btn>
                </td>
              </tr>
              <o-row v-if="!insertRow">
                <o-btn class="ma-4" color="primary" @click="activateInsertRow">
                  {{ $t('buttons.general.insert') }}
                </o-btn>
              </o-row>
            </template>
          </o-data-table>
        </o-col>
      </o-row>
      <o-row justify="start">
        <o-col>
          <o-btn
            class="ma-4"
            :disabled="!anyModsUnsaved || !areMoneyModsCorrect"
            color="success"
            @click="onSaveSession"
          >
            {{ $t('buttons.general.save') }}
          </o-btn>
          <o-btn
            class="maa-4"
            :disabled="!anyModsUnsaved"
            color="error"
            @click="onCancelSession"
          >
            {{ $t('buttons.general.cancel') }}
          </o-btn>
        </o-col>
      </o-row>
    </o-card>
    <o-snackbar
      absolute
      :value="!areMoneyModsCorrect"
      :timeout="0"
      bottom
      color="warning"
      multi-line
    >
      {{ $t('financial_reporting.warning_incorrect_money') }}
    </o-snackbar>
    <o-dialog v-model="confirmationDialog" width="60vw">
      <o-card class="pa-3 text-center">
        <o-card-title>
          <o-col>
            {{ $t('financial_reporting.dialog.confirmation_remove') }}
          </o-col>
        </o-card-title>
        <o-card-actions justify="center">
          <o-spacer />
          <o-btn
            color="error"
            @click="
              confirmationFunction();
              confirmationDialog = false;
            "
          >
            {{ $t('buttons.general.yes') }}
          </o-btn>
          <o-spacer />
          <o-btn color="green" @click="confirmationDialog = false">
            {{ $t('buttons.general.cancel') }}
          </o-btn>
          <o-spacer />
        </o-card-actions>
      </o-card>
    </o-dialog>
  </o-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { moneyMixin } from '../../../helpers/moneyMixin';
import { validationHelper } from '../../../validation/validationHelper';
import { baseValidation } from '../../../validation/baseValidation';
export default {
  name: 'FinancialReportingDialog',
  mixins: [moneyMixin, validationHelper, baseValidation],
  props: {
    closeDialog: {
      type: Function,
      required: true,
    },
    refreshParent: {
      type: Function,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    confirmationDialog: false,
    confirmationFunction: null,
    datePicker: false,
    datePickerDate: new Date().toISOString().substr(0, 7),
    demandPartnerAccountAutocomplete: {},
    insertRow: false,
    demandPartnerId: -1,
    dpaFinal: 0,
    isItemEditedArray: [],
    validTotalFinalRevenue: false,
    validFinalRevenueTable: false,
    validFinalRevenueInsert: false,
    insertSiteElement: {
      site: {},
      final_revenue: '',
    },
    headers: [
      {
        text: 'Publisher Site',
        value: 'publisher_site',
      },
      {
        text: 'Estimated Revenue',
        value: 'estimated_revenue',
        align: 'right',
      },
      {
        text: 'Final Revenue',
        value: 'manual_revenue',
        align: 'right',
      },
      {
        text: 'Correction Ratio',
        value: 'correction_ratio',
      },
      {
        text: 'Status',
        value: 'status',
      },
      {
        text: 'Action',
        value: 'action',
        sortable: false,
        filterable: false,
        align: 'center',
      },
    ],
  }),
  computed: {
    ...mapGetters('demandPartnerAccounts', ['getDemandPartnerAccounts']),
    ...mapGetters('siteEntities', ['getSiteEntities']),
    ...mapGetters('siteEntities', {
      getLoadingSiteEntities: 'getLoading',
    }),
    ...mapGetters('demandPartnerAccounts', {
      getLoadingDemandPartnerAccounts: 'getLoading',
    }),
    ...mapGetters('financialReporting', [
      'getFinancialReportDialogDpa',
      'getFinancialReportDialogTable',
      'getDefaultModObject',
      'areMoneyModsCorrect',
      'anyModsUnsaved',
      'getFinancialReportMain',
    ]),
    ...mapGetters('financialReporting', {
      getLoadingFinancialReporting: 'getLoading',
    }),
    canTotalFinalRevenueApplied() {
      return (
        this.convertMoneyStringToFloat(
          this.getFinancialReportDialogDpa.total_final,
        ).toString() !==
          this.convertMoneyStringToFloat(this.dpaFinal).toString() &&
        this.validTotalFinalRevenue
      );
    },
    isTotalFinalRevenueValidated() {
      const dpa_id = this.demandPartnerAccountAutocomplete
        ? this.demandPartnerAccountAutocomplete.id
        : '';
      const report =
        this.getFinancialReportMain.table.find((e) => e.dpa_id === dpa_id) ||
        '';
      if (report.status) {
        return report.status.name !== 'Validated';
      }
      return false;
    },
    filteredSiteEntities() {
      if (this.getFinancialReportDialogTable.table.length === 0)
        return this.getSiteEntities;
      return this.getSiteEntities.filter((item) => {
        return this.getFinancialReportDialogTable.table.every((tableValue) => {
          return item.id !== tableValue.se_id;
        });
      });
    },
  },
  methods: {
    ...mapActions('demandPartnerAccounts', ['fetchDemandPartnerAccounts']),
    ...mapActions('siteEntities', ['fetchSiteEntities']),
    ...mapActions('financialReporting', [
      'fetchFinancialReportDialog',
      'sendMod',
      'removeMod',
      'fetchFinancialReportMain',
      'getSession',
      'cancelSession',
      'countSessionMods',
      'saveSession',
      'validateReport',
      'deValidateReport',
    ]),
    setDialog(row, datePickerDateFromParent) {
      this.getSession();
      this.fetchSiteEntities();
      this.datePickerDate = datePickerDateFromParent;
      this.demandPartnerId = row.dp_id;
      this.fetchDemandPartnerAccounts({ dp_id: this.demandPartnerId }).then(
        () => {
          this.demandPartnerAccountAutocomplete =
            this.getDemandPartnerAccounts.find((dpa) => {
              return dpa.id === row.dpa_id;
            });
          this.refreshDialog();
        },
      );
    },
    refreshDialog() {
      const payload = {
        year: this.datePickerDate.substr(0, 4),
        month: this.datePickerDate.substr(5, 7),
        demand_partner_id: this.demandPartnerId,
        demand_partner_account_id: this.demandPartnerAccountAutocomplete
          ? this.demandPartnerAccountAutocomplete.id
          : '',
      };
      this.fetchFinancialReportDialog(payload).then(() => {
        this.isItemEditedArray.length =
          this.getFinancialReportDialogTable.table.length;
        let step;
        for (step = 0; step < this.isItemEditedArray.length; step++)
          this.$set(this.isItemEditedArray, step, {
            isEdited: false,
            editInput: '',
          });
        this.insertRow = false;
        this.dpaFinal = this.getFinancialReportDialogDpa.total_final;
        this.refreshParent();
      });
    },
    isItemEdited(index) {
      if (this.isItemEditedArray[index]) {
        return this.isItemEditedArray[index].isEdited;
      }
      return false;
    },
    onSaveSession() {
      this.saveSession().then(() => {
        this.refreshDialog();
      });
    },
    onCancelSession() {
      this.cancelSession().then(() => {
        this.refreshDialog();
      });
    },
    saveDpaFinal() {
      const payload = this.getDefaultModObject();

      payload.year = this.datePickerDate.substr(0, 4);
      payload.month = this.datePickerDate.substr(5, 7);
      payload.demand_partner_id = this.demandPartnerId;
      payload.demand_partner_account = this.demandPartnerAccountAutocomplete.id;
      payload.turnover_manual = this.convertMoneyStringToFloat(this.dpaFinal);
      this.sendMod(payload).then(() => {
        this.refreshDialog();
      });
    },
    async validateDpaFinal() {
      const payload = {};
      payload.year = this.datePickerDate.substr(0, 4);
      payload.month = this.datePickerDate.substr(5, 7);
      payload.demand_partner_id = this.demandPartnerId;
      payload.demand_partner_account_id =
        this.demandPartnerAccountAutocomplete.id;
      if (this.anyModsUnsaved) {
        await this.saveSession();
      }
      this.validateReport(payload).then(() => {
        this.refreshDialog();
      });
    },
    deValidateDpaFinal() {
      const payload = {};
      payload.year = this.datePickerDate.substr(0, 4);
      payload.month = this.datePickerDate.substr(5, 7);
      payload.demand_partner_id = this.demandPartnerId;
      payload.demand_partner_account_id =
        this.demandPartnerAccountAutocomplete.id;
      this.deValidateReport(payload).then(() => {
        this.refreshDialog();
      });
    },
    removeDpaFinal() {
      const payload = this.getDefaultModObject();
      payload.year = this.datePickerDate.substr(0, 4);
      payload.month = this.datePickerDate.substr(5, 7);
      payload.demand_partner_id = this.demandPartnerId;
      payload.demand_partner_account = this.demandPartnerAccountAutocomplete.id;
      payload.turnover_manual = this.convertMoneyStringToFloat(this.dpaFinal);

      this.removeMod(payload).then(() => {
        this.refreshDialog();
      });
    },
    activateInsertRow() {
      this.insertRow = true;
    },
    cancelInsertRow() {
      this.insertRow = false;
    },
    saveInsertRow() {
      const payload = this.getDefaultModObject();

      payload.year = this.datePickerDate.substr(0, 4);
      payload.month = this.datePickerDate.substr(5, 7);
      payload.publisher_id = this.insertSiteElement.site.publisher_id;
      payload.demand_partner_id = this.demandPartnerId;
      payload.demand_partner_account = this.demandPartnerAccountAutocomplete.id;
      payload.turnover_manual = this.convertMoneyStringToFloat(
        this.insertSiteElement.final_revenue,
      );
      payload.site_entity_id = this.insertSiteElement.site.id;

      this.sendMod(payload).then(() => {
        this.refreshDialog();
        this.insertRow = false;
      });
    },
    editExistingRow(item) {
      this.$set(this.isItemEditedArray[item.index], 'isEdited', true);
    },
    cancelExistingRow(item) {
      this.$set(this.isItemEditedArray[item.index], 'isEdited', false);
    },
    removeModRow(item) {
      const payload = this.getDefaultModObject();

      payload.year = this.datePickerDate.substr(0, 4);
      payload.month = this.datePickerDate.substr(5, 7);
      payload.publisher_id = item.publisher_id;
      payload.demand_partner_id = this.demandPartnerId;
      payload.demand_partner_account = this.demandPartnerAccountAutocomplete.id;
      payload.turnover_manual = this.convertMoneyStringToFloat(
        this.isItemEditedArray[item.index].editInput,
      );
      payload.site_entity_id = item.se_id;
      this.removeMod(payload).then(() => {
        this.refreshDialog();
      });
    },
    saveExistingRow(item) {
      const payload = this.getDefaultModObject();

      payload.year = this.datePickerDate.substr(0, 4);
      payload.month = this.datePickerDate.substr(5, 7);
      payload.publisher_id = item.publisher_id;
      payload.demand_partner_id = this.demandPartnerId;
      payload.demand_partner_account = this.demandPartnerAccountAutocomplete.id;
      payload.turnover_manual = this.convertMoneyStringToFloat(
        this.isItemEditedArray[item.index].editInput,
      );
      payload.site_entity_id = item.se_id;

      this.sendMod(payload).then(() => {
        this.refreshDialog();
      });
    },
  },
};
</script>

<style scoped>
.correction_input {
  background-color: orange;
}

.correction_insert {
  background-color: #cc1919;
}

.remove_bucket {
  color: rgb(95, 12, 12) !important;
}
</style>
