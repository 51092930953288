import axios from 'axios';
import fileDownload from 'js-file-download';

const store = {
  namespaced: true,
  identifiersToImport: [],
  state: {
    loading: true,
    obfuscated: 0,
    contactInfo: {
      contact_email: '',
      contact_address: '',
      version: '',
    },
    identifiers: [],
    renderedSellersJson: '',
  },

  getters: {
    getLoading: (state) => {
      return state.loading;
    },
    getContactInfo: (state) => {
      return state.contactInfo;
    },
    getIdentifiers: (state) => {
      return state.identifiers;
    },
    getRenderedSellersJson: (state) => {
      return state.renderedSellersJson;
    },
    getObfuscated: (state) => {
      return state.identifiers;
    },
    getDefaultIdentifier: () => () => {
      return {
        name: '',
        value: '',
      };
    },
  },

  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
    setObfuscated(state, obfuscated) {
      state.obfuscated = obfuscated;
    },
    setContactInfo(state, contactInfo) {
      state.contactInfo = contactInfo;
    },
    setIdentifiers(state, identifiers) {
      state.identifiers = identifiers;
    },
    setRenderedSellersJson(state, renderedSellersJson) {
      state.renderedSellersJson = renderedSellersJson;
    },
  },
  actions: {
    async setSellersJsonData({ commit }, data) {
      commit('setContactInfo', data.contactInfo);
      commit('setIdentifiers', data.identifiers);
      commit('setObfuscated', data.obfuscated);
    },
    async fetchSellersJsonData({ commit }) {
      commit('setLoading', true);
      const response = await axios.get('/sellers_json/');
      commit('setContactInfo', response.data.contact_info);
      commit('setIdentifiers', response.data.identifiers);
      commit('setLoading', false);
    },
    async updateSellersJsonData({ commit, getters }) {
      commit('setLoading', true);
      const sellers_json_data = {
        contact_info: getters.getContactInfo,
        identifiers: getters.getIdentifiers,
      };
      const response = await axios.put('/sellers_json/', sellers_json_data);
      commit('setContactInfo', response.data.contact_info);
      commit('setIdentifiers', response.data.identifiers);
      commit('setLoading', false);
    },
    async importExternalSellerJson({ commit, getters }, url) {
      try {
        commit('setLoading', true);
        const response = await axios.post('/sellers_json/import_identifiers', {
          url,
        });
        const identifiersArray = getters.getIdentifiers;
        identifiersArray.push(...response.data);
        commit('setIdentifiers', identifiersArray);
        commit('setLoading', false);
        return true;
      } catch (error) {
        commit('setLoading', false);
        return false;
      }
    },
    async fetchRenderedSellersJson({ commit, state }) {
      commit('setLoading', true);
      commit('setRenderedSellersJson', '');
      const response = await axios.get('/sellers_json/render', {
        params: {
          obfuscated: state.obfuscated,
        },
      });
      commit('setRenderedSellersJson', response.data.sellers_json_render);
      commit('setLoading', false);
    },
    async downloadRenderedSellersJson({ commit, state }) {
      commit('setLoading', true);
      const response = await axios.get('/sellers_json/render/download', {
        params: {
          obfuscated: state.obfuscated,
        },
      });
      const suggestedFileName = response.headers['x-suggested-filename'];
      fileDownload(JSON.stringify(response.data, null, 3), suggestedFileName);
      commit('setLoading', false);
    },
    async uploadRenderedSellersJson({ commit, state }) {
      commit('setLoading', true);
      try {
        await axios.get('/sellers_json/render/upload', {
          params: {
            obfuscated: state.obfuscated,
          },
        });
        commit('setLoading', false);
        return true;
      } catch (error) {
        commit('setLoading', false);
        return false;
      }
    },
  },
};

export default store;
