import { VCol } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCol,_vm._g(_vm._b({ref:"vCol"},'v-col',{
      ..._vm.$attrs,
      ..._vm.$props,
      class: _vm.classes,
    },false),_vm.$listeners),[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }