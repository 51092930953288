<template>
  <v-tooltip
    v-model="shouldOpen"
    bottom
    persistent
    :opacity="100"
    :open-on-hover="false"
    :open-on-click="false"
  >
    <template #activator="{ on }">
      <span :style="computedStyle ?? contentStyle">
        {{ content }}
      </span>
      <o-icon
        light
        small
        :class="iconClass"
        :style="computedStyle ?? iconStyle"
        end
        :active="shouldOpen"
        :color="color"
        v-on="on"
        @mouseleave="delayedClose"
        @mouseover="stopDelayedClose"
        @click="makePersistent"
      >
        {{ icon }}
      </o-icon>
    </template>
    <o-card
      class="ma-0 pa-0"
      @mouseleave="delayedClose"
      @mouseover="stopDelayedClose"
    >
      <o-card-text>
        <span
          v-html="
            description.trim() ||
            'description not available: work in progress...'
          "
        />
      </o-card-text>
    </o-card>
  </v-tooltip>
</template>
<script>
import { eventBus } from '@/main';

export default {
  name: 'IconTooltip',
  props: {
    icon: {
      type: String,
      default: 'mdi-help-circle-outline',
    },
    color: {
      type: String,
      default: undefined,
    },
    content: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: 'description not available: work in progress..',
    },
    iconClass: {
      type: [String, Array, Object],
      default: 'help-mark',
    },
    iconStyle: {
      type: [String, Array, Object],
      default: undefined,
    },
    contentStyle: {
      type: [String, Array, Object],
      default: undefined,
    },
    panelStyle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      open_tooltip: false,
      force_persistence: false,
    };
  },
  computed: {
    shouldOpen: {
      get() {
        return this.open_tooltip || this.force_persistence;
      },
      set(value) {
        this.open_tooltip = value;
      },
    },
    computedStyle() {
      if (this.panelStyle) {
        return {
          '-webkit-box-flex': 1,
          '-ms-flex': 'none',
          flex: 'none',
        };
      }
      return undefined;
    },
  },
  mounted() {
    eventBus.$on('close-other-tooltip', () => {
      this.open_tooltip = false;
      this.force_persistence = false;
    });
  },
  methods: {
    makePersistent(event) {
      event.preventDefault();
      this.force_persistence = !this.force_persistence;
      if (!this.force_persistence) {
        this.open_tooltip = false;
      }
    },
    delayedClose() {
      this.close_timeout = setTimeout(() => (this.open_tooltip = false), 500);
    },
    stopDelayedClose() {
      eventBus.$emit('close-other-tooltip');
      this.open_tooltip = true; // order is important since the event will close all tooltips
      if (this.close_timeout) {
        clearTimeout(this.close_timeout);
      }
    },
  },
};
</script>
<style scoped>
span {
  margin-right: 2px;
}
.v-icon {
  color: #666666;
}
.v-icon[active='true'] {
  color: #448aff !important;
}
.v-icon.v-icon::after {
  background-color: transparent;
  color: #448aff;
}

.v-icon:hover,
.v-icon:hover {
  color: #448aff !important;
}
.v-tooltip__content {
  pointer-events: auto;
  background-color: transparent;
}
.v-tooltip__content.menuable__content__active {
  opacity: 100%;
}
.v-card__text {
  color: black;
}
</style>
