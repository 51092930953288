<template>
  <o-row>
    <o-col cols="12">
      <o-row justify="space-between">
        <o-card-title> Config render </o-card-title>
        <o-spacer />
        <o-col cols="auto">
          <o-btn color="primary" @click="routeTM">
            {{ $t('common.button.manage_tags') }}
          </o-btn>
        </o-col>
      </o-row>
      <ad-config-render-view />
    </o-col>
  </o-row>
</template>

<script>
import AdConfigRenderView from '../../components/Settings/AdConfigFileManager/AdConfigRenderView';

export default {
  name: 'AdConfigLoader',
  components: { AdConfigRenderView },
  methods: {
    async routeTM() {
      const { query } = this.$route;
      await this.$router
        .push({
          name: 'TagsManager',
          query,
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped></style>
