<template>
  <div>
    <o-col>
      <o-row class="ma-9">
        <o-col cols="12">
          <o-form v-model="siteAutocompleteAndInventoryTypeValid">
            <o-container>
              <o-row align="center" justify="center" class="text-center">
                <o-col cols="12">
                  <o-row>
                    <o-col cols="4">
                      <o-autocomplete
                        v-model="siteAutocompleteModel"
                        :items="getSiteEntities"
                        return-object
                        label="Site"
                        :rules="baseValidation.required"
                        @change="onConfigSelectsUpdate"
                      />
                    </o-col>
                    <o-col cols="4">
                      <o-select
                        v-model="inventoryTypeSelectModel"
                        :items="getInventoryTypeList()"
                        item-text="text"
                        item-value="value"
                        :rules="baseValidation.required"
                        label="Inventory Type"
                        @change="onConfigSelectsUpdate"
                      />
                    </o-col>
                    <o-col cols="4">
                      <o-select
                        v-model="revisionSelectModel"
                        :items="getRevisionsList"
                        label="Revision"
                        :disabled="!isSiteAndInventoryTypeSelected"
                        no-data-text="No revision for this site and inventory version"
                        return-object
                        @change="onRevisionUpdate"
                      >
                        <template #selection="{ item }">
                          {{ item.number }}
                          <o-chip small :color="mapStatusColor(item.status)">
                            {{ item.status }}
                          </o-chip>
                          {{ item.name }}
                        </template>
                        <template #item="{ item }">
                          {{ item.number }}
                          <o-chip small :color="mapStatusColor(item.status)">
                            {{ item.status }}
                          </o-chip>
                          {{ item.name }}
                        </template>
                      </o-select>
                    </o-col>
                  </o-row>
                  <o-row align="center" justify="center">
                    <o-col cols="auto">
                      <o-btn
                        :disabled="getRenderedText.length === 0"
                        value="Copy Config"
                        append
                        @click="copyText"
                      >
                        Copy
                        <o-icon right> mdi-content-copy </o-icon>
                      </o-btn>
                    </o-col>
                    <o-col cols="auto" hidden>
                      <o-btn
                        :disabled="
                          getRenderedText.length === 0 || !isConfigRendered
                        "
                        color="primary"
                        append
                        @click="onDownload"
                      >
                        Download Render
                        <o-icon right> mdi-download </o-icon>
                      </o-btn>
                    </o-col>
                    <o-col cols="auto">
                      <o-btn
                        :disabled="!isRenderDataSelected"
                        color="primary"
                        @click="onRender"
                      >
                        Render
                      </o-btn>
                    </o-col>
                    <o-col cols="auto">
                      <o-btn
                        v-if="
                          getUserProfile.role === 'admin' &&
                          (getRendererDataInventoryType || '').toLowerCase() ===
                            'web'
                        "
                        :disabled="!isRenderDataSelected"
                        color="primary"
                        @click="onODASRender"
                      >
                        ODAS Render
                      </o-btn>
                    </o-col>
                    <o-col cols="auto">
                      <o-menu
                        v-model="ad_unit_menu"
                        :close-on-content-click="false"
                        bottom
                        offset-y
                      >
                        <template #activator="{ on, attrs }">
                          <o-btn
                            :disabled="getAdUnits.length === 0"
                            color="primary"
                            v-bind="attrs"
                            v-on="on"
                          >
                            Generate Tags
                          </o-btn>
                        </template>

                        <o-card class="justify-center">
                          <o-list one-line flat dense>
                            <o-list-item-group v-model="ad_unit_selection">
                              <o-list-item
                                v-for="ad_unit in getAdUnits"
                                :key="ad_unit.id"
                              >
                                <template #default="{ active }">
                                  <o-list-item-action>
                                    <o-checkbox
                                      :input-value="active"
                                      color="primary"
                                    />
                                  </o-list-item-action>

                                  <o-list-item-content>
                                    <o-list-item-title>
                                      {{ ad_unit.name }}
                                    </o-list-item-title>
                                  </o-list-item-content>
                                </template>
                              </o-list-item>
                            </o-list-item-group>
                          </o-list>

                          <o-card-actions>
                            <o-btn
                              :disabled="
                                !isRenderDataSelected ||
                                ad_unit_selection === undefined
                              "
                              color="primary"
                              text
                              @click="generateTags(false)"
                            >
                              Generate
                            </o-btn>
                            <o-btn
                              :disabled="
                                !isRenderDataSelected ||
                                ad_unit_selection === undefined
                              "
                              color="primary"
                              text
                              @click="generateTags(true)"
                            >
                              Download tags
                              <o-icon right> mdi-download </o-icon>
                            </o-btn>
                            <o-btn text @click="ad_unit_menu = false">
                              Cancel
                            </o-btn>
                          </o-card-actions>
                        </o-card>
                      </o-menu>
                    </o-col>
                  </o-row>
                </o-col>
              </o-row>
            </o-container>
          </o-form>
        </o-col>
      </o-row>
      <o-row class="ma-9 align-center justify-center">
        <o-textarea
          v-if="getRenderedText.length !== 0"
          id="exported_file"
          readonly
          solo
          auto-grow
          filled
          :value="getRenderedText"
        >
          <template #prepend />
        </o-textarea>
      </o-row>
    </o-col>

    <o-snackbar v-model="snackbar" top :timeout="5000" :color="snackbar_color">
      {{ snackbar_text }}
      <o-btn text @click="hideAlert()"> Close </o-btn>
    </o-snackbar>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import store from './../../../store/index';
import { baseValidation } from '@/validation/baseValidation';
import { validationHelper } from '@/validation/validationHelper';
import { mapStatusColor } from '@/helpers/mapStatusColorMixin';
import { eventBus } from '@/main';
export default {
  name: 'AdConfigRenderView',
  mixins: [baseValidation, validationHelper, mapStatusColor],
  data() {
    return {
      ad_unit_menu: false,
      ad_unit_selection: [],
      ad_unit_id: undefined,
      ad_config_id: null,
      snackbar: false,
      snackbar_text: '',
      snackbar_color: 'error',
      siteAutocompleteAndInventoryTypeValid: false,
      siteAutocompleteModel: null,
      inventoryTypeSelectModel: null,
      revisionSelectModel: null,
    };
  },
  async mounted() {
    await this.fetchSiteEntities();
    this.setRenderedText('');
    if (
      this.$route.query.site &&
      this.$route.query.inventory_type &&
      'ad_unit' in this.$route.query &&
      this.$route.query.ad_unit
    ) {
      this.ad_unit_id = this.$route.query.ad_unit.id;
      this.siteAutocompleteModel = this.getSiteEntities.find((item) => {
        return item.id === Number(this.$route.query.site);
      });
      this.inventoryTypeSelectModel = this.$route.query.inventory_type;

      await this.fetchSiteConfigInfo({
        site: { ...this.siteAutocompleteModel },
        inventoryType: this.inventoryTypeSelectModel,
        setNewestRevision: false,
      });
      if (this.$route.query.revision) {
        this.revisionSelectModel = this.getRevisionsList.find((item) => {
          return item.number === Number(this.$route.query.revision);
        });
      } else {
        if (this.getRevisionsList.length !== 0) {
          this.revisionSelectModel = this.getNewestRevision();
        }
      }
      await this.onRevisionUpdate();
      this.onTagGenerate();
    } else if (this.$route.query.site && this.$route.query.inventory_type) {
      this.siteAutocompleteModel = this.getSiteEntities.find((item) => {
        return item.id === Number(this.$route.query.site);
      });
      this.inventoryTypeSelectModel = this.$route.query.inventory_type;

      const selectConfigData = {
        site: { ...this.siteAutocompleteModel },
        inventoryType: this.inventoryTypeSelectModel,
        setNewestRevision: false,
      };
      await this.fetchSiteConfigInfo(selectConfigData);
      if (this.$route.query.revision) {
        this.revisionSelectModel = this.getRevisionsList.find((item) => {
          return item.number === Number(this.$route.query.revision);
        });
      } else {
        if (this.getRevisionsList.length !== 0) {
          this.revisionSelectModel = this.getNewestRevision();
        }
      }
      await this.onRevisionUpdate();
      this.onRender();
    }
  },
  computed: {
    ...mapGetters('siteEntities', ['getLoading', 'getSiteEntities']),
    ...mapGetters('adUnits', ['getAdUnits']),
    ...mapGetters('context', ['getUserProfile']),
    ...mapGetters('configLoaderRenderer', [
      'getRenderedText',
      'getRevisionsList',
      'isSiteAndInventoryTypeSelected',
      'getNewestRevision',
      'getRendererDataRevision',
      'isRenderDataSelected',
      'getRendererDataSite',
      'getRendererDataInventoryType',
      'isConfigRendered',
    ]),
    ...mapGetters('tagsManager', ['getInventoryTypeList', 'getNewestRevision']),
  },
  methods: {
    ...mapMutations('configLoaderRenderer', [
      'setRenderedText',
      'setRendererConfigData',
    ]),
    ...mapActions('configLoaderRenderer', [
      'fetchSiteConfigInfo',
      'downloadAdConfig',
      'downloadTags',
    ]),
    ...mapActions('siteEntities', ['fetchSiteEntities']),
    ...mapActions('adUnits', ['fetchAdUnits']),
    copyText() {
      const textToCopy = document.querySelector('#exported_file');
      textToCopy.select();

      try {
        const successful = document.execCommand('copy');
        const msg = successful ? 'successfully' : 'unsuccessfully';
        this.showInfo('Text was copied ' + msg);
      } catch (err) {
        this.showAlert('Oops, unable to copy');
      }

      window.getSelection().removeAllRanges();
    },
    async onConfigSelectsUpdate() {
      if (this.inventoryTypeSelectModel && this.siteAutocompleteModel) {
        const selectConfigData = {
          site: { ...this.siteAutocompleteModel },
          inventoryType: this.inventoryTypeSelectModel,
          setNewestRevision: true,
        };
        await this.fetchSiteConfigInfo(selectConfigData).then(() => {
          this.revisionSelectModel = this.getRendererDataRevision;
        });
        await this.fetchAdUnits(true);
      }
      let revision = null;
      if (this.revisionSelectModel) {
        revision = this.revisionSelectModel.number;
      }
      await this.$router
        .push({
          query: {
            site: this.siteAutocompleteModel.id,
            inventory_type: this.inventoryTypeSelectModel,
            revision,
          },
        })
        .catch(() => {});
    },
    async onRevisionUpdate() {
      if (this.revisionSelectModel) {
        const selectConfigData = {
          revision: this.revisionSelectModel,
          site: { ...this.siteAutocompleteModel },
          inventoryType: this.inventoryTypeSelectModel,
          setNewestRevision: false,
        };
        await this.fetchSiteConfigInfo(selectConfigData).then(() => {
          this.revisionSelectModel = this.getRendererDataRevision;
        });
      }
      let revision = null;
      if (this.revisionSelectModel) {
        revision = this.revisionSelectModel.number;
      }
      await this.fetchAdUnits(true);
      await this.$router
        .push({
          query: {
            site: this.siteAutocompleteModel.id,
            inventory_type: this.inventoryTypeSelectModel,
            revision,
            ad_unit: this.$route.query.ad_unit,
          },
        })
        .catch(() => {});
    },
    onRender() {
      store
        .dispatch('configLoaderRenderer/renderAdConfig')
        .then(() => this.showInfo('Successfully rendered config'))
        .catch((e) => this.showAlert(e));
    },
    onODASRender() {
      store
        .dispatch('configLoaderRenderer/renderODASStoredRequest')
        .then(() => this.showInfo('Successfully rendered odas sr'))
        .catch((e) => this.showAlert(e));
    },
    onDownload() {
      this.downloadAdConfig()
        .then(() => this.showInfo('Successfully downloaded config'))
        .catch((e) => this.showAlert(e));
    },
    onDownloadTags() {
      this.downloadTags(this.ad_unit_id)
        .then(() => this.showInfo('Successfully downloaded tags'))
        .catch((e) => this.showAlert(e));
    },
    async generateTags(download = false) {
      const unit = { ...this.getAdUnits[this.ad_unit_selection] } || undefined;
      if (!unit) return (this.ad_unit_menu = false);
      this.ad_unit_id = unit.id;
      this.onTagGenerate();
      if (download) {
        this.onDownloadTags();
      }

      this.ad_unit_menu = false;
    },
    onTagGenerate() {
      store
        .dispatch('configLoaderRenderer/generateTags', this.ad_unit_id)
        .then(() => this.showInfo('Successfully rendered config'))
        .catch((e) => {
          this.showAlert(e);
          eventBus.$emit('action_warning', e);
        });
    },
    showAlert(e) {
      this.snackbar = true;
      this.snackbar_color = 'error';
      this.snackbar_text = e.toString();
    },
    showInfo(e) {
      this.snackbar = true;
      this.snackbar_color = 'info';
      this.snackbar_text = e.toString();
    },
    hideAlert() {
      this.snackbar = false;
    },
  },
};
</script>

<style scoped></style>
