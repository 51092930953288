<template>
  <o-dialog v-model="show" max-width="80%">
    <o-card style="overflow-x: hidden">
      <o-row align="center">
        <o-col cols="auto">
          <o-card-title>
            <span v-if="!readOnly && editedIndex !== -1" class="headline"
              >{{ $t('buttons.publishers.edit') }}:</span
            >
            <span v-else-if="readOnly && editedIndex !== -1" class="headline"
              >{{ $t('buttons.publishers.show') }}:</span
            >
            <span v-else class="headline"
              >{{ $t('buttons.publishers.add') }}:</span
            >
          </o-card-title>
        </o-col>
        <o-spacer />
        <o-col cols="auto">
          <o-btn
            v-if="!readOnly"
            :disabled="!valid || getPublisherCountriesLoading"
            color="blue darken-1"
            text
            @click="save"
          >
            {{ $t('buttons.general.save') }}
          </o-btn>
          <o-btn color="blue darken-1" text @click="close">
            {{ $t('buttons.general.close') }}
          </o-btn>
        </o-col>
      </o-row>
      <o-card-text>
        <o-container>
          <o-form ref="form" v-model="valid" lazy-validation>
            <o-row>
              <o-row>
                <o-col cols="12">
                  <o-menu
                    v-model="datePicker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on }">
                      <o-text-field
                        v-model="editedItem.start_date"
                        prepend-icon="event"
                        readonly
                        :label="$t('general.start_date') + ' (*)'"
                        :rules="createRules([baseValidation.required])"
                        v-on="on"
                      />
                    </template>
                    <o-date-picker
                      v-model="editedItem.start_date"
                      type="date"
                      @input="selectDate(editedItem.start_date)"
                    />
                  </o-menu>
                </o-col>
                <o-col cols="auto">
                  <o-switch
                    v-model="editedItem.limited_ads"
                    :readonly="readOnly"
                    :filled="readOnly"
                    label="Use Limited Ads"
                  />
                </o-col>
                <o-col cols="auto">
                  <o-switch
                    v-model="editedItem.active"
                    :readonly="readOnly"
                    :filled="readOnly"
                    label="Publisher reports"
                  />
                </o-col>
                <o-col cols="auto">
                  <o-switch
                    v-model="editedItem.mcm_manage_inventory"
                    :readonly="readOnly"
                    :filled="readOnly"
                    label="MCM Manage Inventory"
                  />
                </o-col>
                <o-col cols="auto">
                  <o-switch
                    v-model="editedItem.saas_access"
                    :readonly="readOnly"
                    :filled="readOnly"
                    label="SaaS access"
                  />
                </o-col>
              </o-row>
              <o-row>
                <o-expansion-panels v-model="panel">
                  <o-expansion-panel
                    :class="validGeneralForm ? [] : ['invalid']"
                  >
                    <o-expansion-panel-header>
                      <o-card-title>
                        {{ $t('publishers.general') }}
                      </o-card-title>
                    </o-expansion-panel-header>
                    <o-expansion-panel-content eager>
                      <o-form
                        ref="generalForm"
                        v-model="validGeneralForm"
                        lazy-validation
                      >
                        <o-row>
                          <o-col cols="3">
                            <o-text-field
                              v-model="editedItem.name"
                              :readonly="readOnly"
                              :filled="readOnly"
                              label="Name (*)"
                              :rules="createRules([baseValidation.required])"
                              show-details
                            />
                            <o-autocomplete
                              v-model="editedItem.invoice_type_id"
                              :readonly="readOnly"
                              :filled="readOnly"
                              :items="getInvoiceTypes"
                              label="publishers.select_invoice_type"
                              :rules="createRules([baseValidation.required])"
                              show-details
                            />
                            <o-autocomplete
                              v-model="editedItem.currency_id"
                              :readonly="readOnly"
                              :filled="readOnly"
                              :items="getCurrencies"
                              item-text="code"
                              :label="$t('publishers.select_currency') + ' (*)'"
                              :rules="createRules([baseValidation.required])"
                              show-details
                            />
                            <o-autocomplete
                              v-model="editedItem.seller_type"
                              :readonly="readOnly"
                              :filled="readOnly"
                              :items="getSellerTypes"
                              label="Seller type (*)"
                              show-details
                              :rules="
                                createRules([baseValidation.non_empty_array])
                              "
                            />
                          </o-col>

                          <o-col cols="3">
                            <o-text-field
                              v-model.number="editedItem.gam_network_id"
                              :readonly="readOnly"
                              :filled="readOnly"
                              label="GAM Network ID (*)"
                              show-details
                              :rules="createRules([baseValidation.decimal])"
                            />
                            <o-text-field
                              v-model="gam_network_name"
                              readonly
                              :filled="readOnly"
                              label="GAM Network Name"
                              show-details
                              :loading="getGAMNetworkFetchLoading"
                            />
                            <o-text-field
                              v-model="editedItem.data_studio_url"
                              :readonly="readOnly"
                              :filled="readOnly"
                              show-details
                              :rules="
                                createRules([baseValidation.url_with_http])
                              "
                              label="Data Studio Link (*)"
                            />
                            <o-select
                              v-model="editedItem.default_schain"
                              :readonly="readOnly"
                              :filled="readOnly"
                              show-details
                              :items="[
                                {
                                  t: 'Opti digital',
                                  v: 'optidigital',
                                },
                                {
                                  t: 'Direct',
                                  v: 'direct',
                                },
                              ]"
                              label="web_setting.label.schain"
                              description="web_setting.help.schain"
                            />
                          </o-col>
                          <o-col cols="3">
                            <o-text-field
                              v-model="editedItem.odas_account_name"
                              readonly
                              show-details
                              :filled="readOnly"
                              label="ODAS Account Name"
                            />
                            <o-text-field
                              v-model="editedItem.odas_account_id"
                              readonly
                              show-details
                              :filled="readOnly"
                              label="ODAS Account ID"
                            />
                            <o-autocomplete
                              v-model="editedItem.products"
                              :readonly="readOnly"
                              :filled="readOnly"
                              :items="getProducts"
                              label="Product (*)"
                              return-object
                              multiple
                              show-details
                              :rules="
                                createRules([
                                  baseValidation.non_empty_array,
                                  baseValidation.checkProductSelection,
                                ])
                              "
                            />
                          </o-col>
                          <o-col cols="3">
                            <o-text-field
                              v-model="editedItem.url"
                              :readonly="readOnly"
                              :filled="readOnly"
                              label="URL"
                              show-details
                              :rules="createRules([baseValidation.domain])"
                            />
                            <o-text-field
                              v-model="editedItem.ownerdomain"
                              :readonly="readOnly"
                              :filled="readOnly"
                              label="OWNERDOMAIN"
                              show-details
                              :rules="createRules([baseValidation.domain])"
                            />
                            <o-text-field
                              v-model="editedItem.seller_domain"
                              :readonly="readOnly"
                              :filled="readOnly"
                              label="Seller Domain (*)"
                              show-details
                              :rules="[
                                ...baseValidation.domain,
                                baseValidation.requiredConditional(
                                  editedItem.mcm_manage_inventory,
                                  editedItem.seller_domain,
                                ),
                              ]"
                            />
                          </o-col>
                        </o-row>
                      </o-form>
                    </o-expansion-panel-content>
                  </o-expansion-panel>

                  <o-expansion-panel
                    :class="validCompanyForm ? [] : ['invalid']"
                  >
                    <o-expansion-panel-header>
                      <o-card-title>
                        {{ $t('publishers.company_info') }}
                      </o-card-title>
                    </o-expansion-panel-header>
                    <o-expansion-panel-content eager>
                      <o-form
                        ref="companyForm"
                        v-model="validCompanyForm"
                        lazy-validation
                      >
                        <o-row>
                          <o-col cols="12" sm="6" md="4">
                            <o-text-field
                              v-model="editedItem.company_name"
                              :readonly="readOnly"
                              :filled="readOnly"
                              label="Company Name"
                            />
                          </o-col>
                          <o-col cols="12" sm="6" md="4">
                            <o-text-field
                              v-model="editedItem.company_address"
                              :readonly="readOnly"
                              :filled="readOnly"
                              label="Company Address"
                            />
                          </o-col>
                          <o-col cols="12" sm="6" md="4">
                            <o-text-field
                              v-model="editedItem.company_country"
                              :readonly="readOnly"
                              :filled="readOnly"
                              label="Company Country"
                            />
                          </o-col>
                          <o-col cols="12" sm="6" md="4">
                            <o-text-field
                              v-model="editedItem.company_iban"
                              :readonly="readOnly"
                              :filled="readOnly"
                              label="Company IBAN"
                              :rules="createRules([publisherValidation.iban])"
                            />
                          </o-col>
                          <o-col cols="12" sm="6" md="4">
                            <o-text-field
                              v-model="editedItem.company_bic"
                              :readonly="readOnly"
                              :filled="readOnly"
                              label="Company BIC"
                              :rules="createRules([publisherValidation.bic])"
                            />
                          </o-col>
                          <o-col cols="auto">
                            <o-switch
                              v-model="editedItem.vat_present"
                              :readonly="readOnly"
                              :filled="readOnly"
                              label="Use VAT"
                            />
                          </o-col>
                          <o-col cols="12" sm="6" md="4">
                            <o-text-field
                              v-model="editedItem.company_vat"
                              :readonly="readOnly"
                              :filled="readOnly"
                              :label="`Company VAT ${editedItem.vat_present ? '(*)' : ''}`"
                              :rules="[
                                baseValidation.requiredConditional(
                                  editedItem.vat_present,
                                  editedItem.company_vat,
                                ),
                              ]"
                            />
                          </o-col>
                          <o-col cols="12" sm="6" md="4">
                            <o-text-field
                              v-model="editedItem.sales_contact_name"
                              :readonly="readOnly"
                              :filled="readOnly"
                              label="Sales Contact Name"
                            />
                          </o-col>
                          <o-col cols="12" sm="6" md="4">
                            <o-text-field
                              v-model="editedItem.sales_contact_email"
                              :readonly="readOnly"
                              :filled="readOnly"
                              label="Sales Contact Email"
                              :rules="createRules([baseValidation.email])"
                            />
                          </o-col>
                          <o-col cols="5">
                            <o-text-field
                              v-model="editedItem.invoicing_contact_name"
                              :readonly="readOnly"
                              :filled="readOnly"
                              label="Invoice Name (*)"
                              :rules="createRules([baseValidation.required])"
                            />
                          </o-col>
                          <o-col cols="12">
                            <div
                              v-for="(contact, i) in invoiceContactsList"
                              :key="i"
                              class="text-fields-row"
                            >
                              <o-row>
                                <o-col cols="5">
                                  <o-text-field
                                    v-model="contact.name"
                                    :readonly="readOnly"
                                    :filled="readOnly"
                                    label="Invoicing Contact Name (*)"
                                    :rules="
                                      createRules([baseValidation.required])
                                    "
                                  />
                                </o-col>
                                <o-col cols="5">
                                  <o-text-field
                                    v-model="contact.email"
                                    :readonly="readOnly"
                                    :filled="readOnly"
                                    label="Invoicing Contact Email (*)"
                                    :rules="
                                      createRules([
                                        baseValidation.required,
                                        baseValidation.email,
                                      ])
                                    "
                                  />
                                </o-col>
                                <o-col
                                  cols="1"
                                  class="d-flex align-center justify-center"
                                >
                                  <o-col cols="6">
                                    <o-tooltip bottom>
                                      <template #activator="{ on, attrs }">
                                        <o-btn icon v-bind="attrs" v-on="on">
                                          <o-icon
                                            color="red"
                                            :disabled="
                                              !(invoiceContactsList.length > 1)
                                            "
                                            @click="removeRow(i)"
                                          >
                                            remove_circle_outline
                                          </o-icon>
                                        </o-btn>
                                      </template>
                                      <span>Remove row</span>
                                    </o-tooltip>
                                  </o-col>
                                  <o-col cols="6">
                                    <o-tooltip
                                      v-if="
                                        invoiceContactsList.length === i + 1 &&
                                        contact.name &&
                                        contact.email
                                      "
                                      bottom
                                    >
                                      <template #activator="{ on, attrs }">
                                        <o-btn icon v-bind="attrs" v-on="on">
                                          <o-icon
                                            color="blue"
                                            @click="addRow(contact)"
                                          >
                                            add_circle_outline
                                          </o-icon>
                                        </o-btn>
                                      </template>
                                      <span>Add row</span>
                                    </o-tooltip>
                                  </o-col>
                                </o-col>
                              </o-row>
                            </div>
                          </o-col>
                          <o-col cols="12" sm="6" md="4">
                            <o-autocomplete
                              v-model="editedItem.agent_user_id"
                              :readonly="readOnly"
                              :filled="readOnly"
                              :items="getAgents"
                              item-text="email"
                              label="publishers.select_agent"
                            />
                          </o-col>
                          <o-col cols="12" sm="6" md="4">
                            <o-autocomplete
                              v-model="editedItem.sales_manager_user_id"
                              :readonly="readOnly"
                              :filled="readOnly"
                              :items="getAgents"
                              item-text="email"
                              label="publishers.select_sales_manager"
                            />
                          </o-col>
                        </o-row>
                        <o-row>
                          <o-col cols="12" sm="6" md="4">
                            <o-text-field
                              v-model.number="
                                editedItem.share_publisher_percentage
                              "
                              :readonly="readOnly"
                              :filled="readOnly"
                              label="Share Publisher Percentage"
                              :rules="
                                createRules([
                                  baseValidation.required,
                                  baseValidation.percentage_integer,
                                ])
                              "
                              prefix="%"
                            />
                          </o-col>
                          <o-col cols="12" sm="6" md="4">
                            <o-text-field
                              v-model="editedItem.traffective_percentage"
                              :readonly="true"
                              :filled="readOnly"
                              label="Traffective Percentage"
                              :rules="
                                createRules([
                                  baseValidation.required,
                                  baseValidation.percentage_integer,
                                ])
                              "
                              prefix="%"
                            />
                          </o-col>
                        </o-row>
                        <o-row>
                          <o-col cols="12" sm="6" md="4">
                            <o-card-title> Technical :</o-card-title>
                          </o-col>
                          <o-col cols="12" sm="6" md="4">
                            <o-text-field
                              v-model="editedItem.publisher_code"
                              readonly
                              filled
                              label="Publisher code"
                            />
                          </o-col>
                        </o-row>
                      </o-form>
                    </o-expansion-panel-content>
                  </o-expansion-panel>

                  <o-expansion-panel>
                    <o-expansion-panel-header>
                      <o-card-title> Custom Dimensions Mapping </o-card-title>
                    </o-expansion-panel-header>
                    <o-expansion-panel-content eager>
                      <o-simple-table dense class="border">
                        <template #default>
                          <thead>
                            <tr>
                              <th>Target</th>
                              <th>Name</th>
                              <th>Value</th>
                              <th
                                v-if="!readOnly && unusedTargetKeys.length > 0"
                              >
                                <o-btn icon @click="addTargetDialog = true">
                                  <o-icon color="blue">
                                    add_circle_outline
                                  </o-icon>
                                </o-btn>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="key in Object.keys(
                                editedItem.custom_targeting,
                              )"
                              :key="key"
                            >
                              <td>
                                {{ key }}
                              </td>
                              <td>
                                <o-text-field
                                  v-model="
                                    editedItem.custom_targeting[key]['name']
                                  "
                                  dense
                                  outlined
                                  hide-details="auto"
                                  :rules="[
                                    (v) =>
                                      v && v.trim().length > 0
                                        ? true
                                        : 'Custom target name should not be empty.',
                                    forbidden_double_quote,
                                  ]"
                                />
                              </td>
                              <td>
                                <o-text-field
                                  v-if="key !== 'adslot'"
                                  v-model="
                                    editedItem.custom_targeting[key]['value']
                                  "
                                  dense
                                  outlined
                                  hide-details="auto"
                                  :rules="[forbidden_double_quote]"
                                />
                              </td>
                              <td v-if="!readOnly">
                                <o-btn icon>
                                  <o-icon
                                    color="red"
                                    @click="removeTargetLine({ key })"
                                  >
                                    delete_outline
                                  </o-icon>
                                </o-btn>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </o-simple-table>
                      <o-dialog
                        v-model="addTargetDialog"
                        persistent
                        width="300"
                        @click:outside="addTargetDialog = false"
                        @keydown.esc="addTargetDialog = false"
                      >
                        <o-card>
                          <o-card-title>
                            <span>Add New Custom Targeting</span>
                          </o-card-title>
                          <o-card-text>
                            <o-select
                              ref="new_custom_target"
                              v-model="newTargetKey"
                              :items="unusedTargetKeys"
                              label="Target"
                              :rules="[
                                (v) =>
                                  !addTargetDialog || (v && v.trim().length > 0)
                                    ? true
                                    : 'Custom target key should not be empty.',
                              ]"
                            />
                            <o-text-field
                              ref="new_custom_target_name"
                              v-model="newTargetName"
                              label="Name"
                              outlined
                              dense
                              :rules="[
                                (v) =>
                                  !addTargetDialog || (v && v.trim().length > 0)
                                    ? true
                                    : 'Custom target name should not be empty.',
                                forbidden_double_quote,
                              ]"
                            />
                            <o-text-field
                              v-if="targetValueAvailable"
                              ref="new_custom_target_value"
                              v-model="newTargetValue"
                              label="Value"
                              outlined
                              dense
                              :rules="[forbidden_double_quote]"
                            />
                          </o-card-text>
                          <o-card-actions>
                            <o-spacer />
                            <o-btn color="blue" @click="addTargetLine">
                              Apply
                            </o-btn>
                            <o-btn @click="addTargetDialog = false">
                              Cancel
                            </o-btn>
                          </o-card-actions>
                        </o-card>
                      </o-dialog>
                    </o-expansion-panel-content>
                  </o-expansion-panel>

                  <o-expansion-panel
                    :class="validPennylaneForm ? [] : ['invalid']"
                  >
                    <o-expansion-panel-header>
                      <o-card-title> Pennylane Mapping </o-card-title>
                    </o-expansion-panel-header>
                    <o-expansion-panel-content eager>
                      <o-form
                        ref="pennylaneForm"
                        v-model="validPennylaneForm"
                        lazy-validation
                      >
                        <o-row>
                          <o-col cols="4">
                            <o-autocomplete
                              :loading="getLoadingPennylane"
                              :readonly="readOnly"
                              :filled="readOnly"
                              :items="getPennylaneCustomers"
                              :value="getPennylaneMappingPublisher.customer"
                              item-value="pennylane_source_id"
                              label="Customer (*)"
                              :rules="createRules([baseValidation.required])"
                              @change="onCustomerUpdate"
                            />
                          </o-col>
                          <o-col cols="4">
                            <o-autocomplete
                              :loading="getLoadingPennylane"
                              :readonly="readOnly"
                              :filled="readOnly"
                              :items="getPennylaneSuppliers"
                              :value="getPennylaneMappingPublisher.supplier"
                              item-value="pennylane_source_id"
                              label="Supplier (*)"
                              :rules="createRules([baseValidation.required])"
                              @change="onSupplierUpdate"
                            />
                          </o-col>
                        </o-row>
                      </o-form>
                    </o-expansion-panel-content>
                  </o-expansion-panel>

                  <o-expansion-panel>
                    <o-expansion-panel-header>
                      <o-card-title> Country Management </o-card-title>
                    </o-expansion-panel-header>
                    <o-expansion-panel-content eager>
                      <loading-overlay :overlay="getPublisherCountriesLoading">
                        <template #default>
                          <o-container style="height: auto">
                            <o-row style="width: 100%">
                              <o-row
                                class="rounded countryContainerBorders"
                                style="width: 100%"
                              >
                                <o-col
                                  cols="6"
                                  class="pa-0 countryContainerBorderRight"
                                  style="width: 100%"
                                >
                                  <o-container
                                    class="countryContainerBorderBottom countryContainerRowHeight"
                                    style="width: 100%"
                                  >
                                    <o-text-field
                                      v-model="search"
                                      flat
                                      solo
                                      type="text"
                                      class="ma-0 pa-0"
                                      hide-details
                                      append-icon="mdi-magnify"
                                      label="Search"
                                    />
                                  </o-container>
                                  <o-container
                                    class="pa-2 countryContainerBorderBottom countryContainerRowHeight"
                                    style="width: 100%"
                                  >
                                    <o-card-title
                                      v-if="
                                        getAllCountries.length !== 0 &&
                                        !getPublisherCountriesLoading
                                      "
                                      class="font-weight-bold text-body-2"
                                    >
                                      {{ searchCountry.length }} items
                                    </o-card-title>
                                    <o-card-title
                                      v-else
                                      class="font-weight-bold text-body-2"
                                    >
                                      No items
                                    </o-card-title>
                                  </o-container>
                                  <o-container
                                    class="pt-1 pb-1 overflow-x-hidden"
                                    style="height: 376px"
                                  >
                                    <o-list
                                      v-if="
                                        getAllCountries.length !== 0 &&
                                        !getPublisherCountriesLoading
                                      "
                                      flat
                                    >
                                      <o-list-item-group>
                                        <o-list-item
                                          v-for="item in searchCountry"
                                          :key="item.id"
                                          :title="item.name"
                                          class="pb-0 text-body-2 font-weight-regular"
                                          @click="addCountry(item)"
                                        >
                                          {{ capitalize(item.name) }}
                                        </o-list-item>
                                      </o-list-item-group>
                                    </o-list>
                                  </o-container>
                                </o-col>

                                <o-col
                                  cols="6"
                                  class="pa-0"
                                  style="width: 100%"
                                >
                                  <o-container
                                    class="pa-2 countryContainerBorderBottom countryContainerRowHeight"
                                    style="width: 100%"
                                  >
                                    <o-row>
                                      <o-col cols="6">
                                        <o-card-title
                                          v-if="
                                            getPublisherCountries?.countries
                                              ?.length > 0 &&
                                            !getPublisherCountriesLoading
                                          "
                                          class="font-weight-bold text-body-2"
                                        >
                                          {{
                                            getPublisherCountries.countries
                                              .length
                                          }}
                                          items
                                        </o-card-title>
                                        <o-card-title
                                          v-else
                                          class="font-weight-bold text-body-2"
                                        >
                                          None selected
                                        </o-card-title>
                                      </o-col>
                                      <o-col
                                        cols="6"
                                        class="pt-5"
                                        align="right"
                                      >
                                        <o-btn
                                          v-model="setAllCountries"
                                          class="text-capitalize"
                                          color="black"
                                          plain
                                          @click="deleteAllCountries"
                                        >
                                          Clear All
                                        </o-btn>
                                      </o-col>
                                    </o-row>
                                  </o-container>
                                  <o-container
                                    class="pt-1 pb-1 overflow-x-hidden"
                                    style="height: 442px"
                                  >
                                    <o-list class="pt-1 pb-1" flat>
                                      <o-list-item-group
                                        v-for="(
                                          item, index
                                        ) in orderPublisherCountries"
                                        :key="item.name"
                                      >
                                        <o-list-item
                                          v-if="
                                            getPublisherCountries &&
                                            getPublisherCountries.countries &&
                                            getPublisherCountries.countries
                                              .length > 0 &&
                                            !getPublisherCountriesLoading
                                          "
                                          :key="index"
                                          :title="item.name"
                                          class="pb-0 text-body-2 font-weight-regular"
                                          return
                                          obj
                                          @click="removeCountry(index, item)"
                                        >
                                          {{ capitalize(item.name) }}
                                        </o-list-item>
                                        <o-list-item
                                          v-else-if="
                                            !getPublisherCountries.countries
                                              .length
                                          "
                                          class="pa-2 text-body-1 font-weight-regular"
                                        >
                                          No countries selected.
                                        </o-list-item>
                                      </o-list-item-group>
                                    </o-list>
                                  </o-container>
                                </o-col>
                              </o-row>
                            </o-row>
                          </o-container>
                        </template>
                      </loading-overlay>
                    </o-expansion-panel-content>
                  </o-expansion-panel>
                </o-expansion-panels>
              </o-row>
            </o-row>
          </o-form>
        </o-container>
      </o-card-text>
      <o-card-actions>
        <o-spacer />
        <o-btn
          v-if="!readOnly"
          :disabled="!valid || getPublisherCountriesLoading"
          color="blue darken-1"
          text
          @click="save"
        >
          {{ $t('buttons.general.save') }}
        </o-btn>
        <o-btn color="blue darken-1" text @click="close">
          {{ $t('buttons.general.close') }}
        </o-btn>
      </o-card-actions>
    </o-card>
  </o-dialog>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import store from '@/store';
import { baseValidation } from '@/validation/baseValidation';
import { validationHelper } from '@/validation/validationHelper';
import { publisherValidation } from '@/validation/publisherValidation';
import LoadingOverlay from '@/components/DesignSystem/OLoader/LoadingOverlay.vue';

export default {
  name: 'PublisherDialog',
  components: { LoadingOverlay },
  mixins: [baseValidation, validationHelper, publisherValidation],
  props: ['editedItem', 'editedIndex', 'gam_network_name'],
  data() {
    return {
      show: false,
      readOnly: false,
      valid: true,
      validGeneralForm: true,
      validCompanyForm: true,
      validPennylaneForm: true,
      disable_select: false,
      datePicker: false,
      invoiceContacts: [],
      search: '',
      schain: 'optidigital',
      panel: 0,
      addTargetDialog: false,
      newTargetKey: '',
      newTargetName: '',
      newTargetValue: '',
      targetKeyItems: ['auto_refresh', 'test_group', 'adslot'],
    };
  },
  watch: {
    getAllCountries(newVal) {
      this.setAllCountries = newVal;
    },
    getPublisherCountries(newVal) {
      this.countriesList();
      this.setPublisherCountries = newVal;
    },
  },
  mounted() {
    store.dispatch('context/fetchAgents');
    store.dispatch('context/fetchInvoiceTypes');
    store.dispatch('context/fetchCurrencies');
    store.dispatch('product/fetchProducts');
    store.dispatch('publishers/fetchSellerTypes');
    if (this.editedItem) {
      this.editedItem.allowExternConfigure ??= false;
    }
  },
  computed: {
    targetValueAvailable() {
      return (
        this.newTargetKey === 'auto_refresh' ||
        this.newTargetKey === 'test_group'
      );
    },
    unusedTargetKeys() {
      return (
        this.targetKeyItems?.filter(
          (target_key_item) =>
            !Object.keys(this.editedItem.custom_targeting ?? {}).includes(
              target_key_item,
            ),
        ) ?? []
      );
    },
    orderPublisherCountries() {
      if (this.getPublisherCountries.countries) {
        this.orderCountries(this.getPublisherCountries.countries);
      }
      return this.getPublisherCountries.countries;
    },
    searchCountry() {
      this.orderCountries(this.getAllCountries);
      return this.getAllCountries.filter((country) => {
        return country.name.toLowerCase().includes(this.search.toLowerCase());
      });
    },
    ...mapGetters('publishers', [
      'getLoading',
      'getLoadingPennylane',
      'getPublishers',
      'getGAMNetworkFetchLoading',
      'getPennylaneCustomers',
      'getPennylaneSuppliers',
      'getPennylaneMappingPublisher',
      'getAllCountries',
      'getPublisherCountries',
      'getSellerTypes',
      'getPublisherCountriesLoading',
    ]),
    ...mapGetters('context', ['getAgents', 'getInvoiceTypes', 'getCurrencies']),
    ...mapGetters('product', ['getProducts']),
    invoiceContactsList() {
      return this.updateContactList();
    },
  },
  methods: {
    ...mapActions('publishers', [
      'fetchPublishers',
      'updatePublisher',
      'createPublisher',
      'updatePennylaneCustomerMapping',
      'updatePennylaneSupplierMapping',
    ]),
    ...mapMutations('publishers', ['setAllCountries', 'setPublisherCountries']),
    close() {
      this.show = false;
      this.search = '';
    },
    async save() {
      const [isGeneralValid, isCompanyValid, isPennylaneValid] = [
        this.$refs.generalForm.validate(),
        this.$refs.companyForm.validate(),
        this.$refs.pennylaneForm.validate(),
      ];

      if (
        isGeneralValid &&
        isCompanyValid &&
        isPennylaneValid &&
        this.$refs.form.validate()
      ) {
        const countriesList = this.getPublisherCountries.countries?.map(
          ({ gam_id }) => ({
            gam_id,
          }),
        );

        const publisher_countries = {
          network_id: this.editedItem.gam_network_id || null,
          publisher_code: this.editedItem.publisher_code,
          countries: countriesList,
        };

        if (this.editedIndex > -1) {
          await this.updatePublisher({
            publisher: {
              ...this.editedItem,
              gam_network_id: this.editedItem.gam_network_id || null,
              allowExternConfigure:
                this.editedItem.allowExternConfigure ?? false,
              default_schain: this.editedItem.default_schain ?? 'optidigital',
              saas_access: this.editedItem.saas_access ?? false,
            },
            publisher_countries,
          });
        } else {
          await this.createPublisher({
            publisher: this.editedItem,
            publisher_countries,
          });
        }

        this.close();
      }
    },
    selectDate() {
      this.datePicker = false;
    },
    updateContactList() {
      this.invoiceContacts = [];
      if (
        this.editedItem.invoice_contacts &&
        this.editedItem.invoice_contacts.length === 0
      ) {
        const obj = {
          id: null,
          name: '',
          email: '',
        };
        this.editedItem.invoice_contacts.push(obj);
        this.invoiceContacts = this.editedItem.invoice_contacts;
      } else {
        this.invoiceContacts = this.editedItem.invoice_contacts;
      }
      return this.invoiceContacts;
    },
    addRow(item) {
      if (item.name && item.email) {
        const obj = {
          id: null,
          name: '',
          email: '',
        };
        this.invoiceContacts.push(obj);
      }
    },
    removeRow(index) {
      this.invoiceContacts.splice(index, 1);
    },
    onSupplierUpdate(val) {
      if (val == null) {
        return;
      }
      const payload = {
        publisher_id: this.editedItem.id,
        pennylane_source_id: val,
      };
      this.updatePennylaneSupplierMapping(payload);
    },
    onCustomerUpdate(val) {
      if (val == null) {
        return;
      }
      const payload = {
        publisher_id: this.editedItem.id,
        pennylane_source_id: val,
      };
      this.updatePennylaneCustomerMapping(payload);
    },
    async deleteAllCountries() {
      await this.getPublisherCountries.countries.forEach((country) => {
        this.getAllCountries.push(country);
      });
      this.getPublisherCountries.countries = [];
      await this.getAllCountries;
    },

    async addCountry(item) {
      const objWithIdIndex = this.getAllCountries.findIndex(
        (obj) => obj.gam_id === item.gam_id,
      );
      const newCountry = {
        name: this.formatCountryName(item.name),
        gam_id: item.gam_id,
      };

      await this.getPublisherCountries.countries.push(newCountry);
      await this.getAllCountries.splice(objWithIdIndex, 1);
      this.search = '';
    },

    async removeCountry(index, item) {
      this.getPublisherCountries.countries.splice(index, 1);
      const newCountry = {
        name: item.name.toLowerCase(),
        gam_id: item.gam_id,
      };
      await this.getAllCountries.push(newCountry);
    },

    formatCountryName([firstLetter, ...tail]) {
      return [
        firstLetter.toUpperCase(),
        ...tail.map((w) => w.toLowerCase()),
      ].join('');
    },

    orderCountries(countries_list) {
      return countries_list.sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
      );
    },
    removeTargetLine({ key }) {
      this.$delete(this.editedItem.custom_targeting, key);
    },
    async addTargetLine() {
      if (
        !this.$refs.new_custom_target.validate() ||
        !this.$refs.new_custom_target_name.validate()
      ) {
        return;
      }

      if (this.newTargetKey.trim() !== '') {
        this.$set(this.editedItem.custom_targeting, this.newTargetKey, {
          name: this.newTargetName,
          value: this.newTargetValue,
        });
        this.$refs.new_custom_target.resetValidation();
        this.$refs.new_custom_target_name.resetValidation();
        this.newTargetKey = '';
        this.newTargetName = '';
        this.newTargetValue = '';
        this.addTargetDialog = false;
      }
    },
    async countriesList() {
      this.getPublisherCountries.countries ??= [];
      if (
        this.getAllCountries?.length > 0 &&
        this.getPublisherCountries?.countries?.length > 0 &&
        this.getAllCountries.length !==
          this.getPublisherCountries.countries.length
      ) {
        for (let i = this.getAllCountries.length - 1; i >= 0; i--) {
          if (this.getAllCountries !== undefined) {
            for (
              let k = 0;
              k < this.getPublisherCountries?.countries?.length;
              k++
            ) {
              if (
                this.getAllCountries[i] !== undefined &&
                this.getAllCountries[i].gam_id ===
                  this.getPublisherCountries.countries[k].gam_id
              ) {
                await this.getAllCountries.splice(i, 1);
              }
            }
          }
        }
      }

      if (
        this.getPublisherCountries.countries?.length > 0 &&
        this.getAllCountries.length ===
          this.getPublisherCountries.countries.length
      ) {
        this.getAllCountries.length = 0;
      }

      if (this.getPublisherCountries.publisher_code === null) {
        return;
      }
    },
    capitalize(countryName) {
      return countryName.toUpperCase();
    },
    forbidden_double_quote(v) {
      return v.includes('"')
        ? 'A key value cannot include a double-quote (").'
        : true;
    },
  },
};
</script>

<style scoped>
.invalid {
  border: 1px solid red;
}
.v-expansion-panel-header {
  font-weight: bold;
}
.v-input--selection-controls.v-input--is-disabled:not(.v-input--is-readonly) {
  pointer-events: auto;
}
</style>
