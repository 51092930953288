<template>
  <v-container
    ref="vContainer"
    v-bind="{
      ...$attrs,
      ...$props,
      class: classes,
    }"
    v-on="$listeners"
  >
    <slot></slot>
  </v-container>
</template>
<script>
export default {
  name: 'OContainer',
  data() {
    return {
      clientTop: null,
      clientWidth: null,
      clientLeft: null,
      clientHeight: null,
    };
  },
  computed: {
    classes() {
      // specific to Vue 2
      return this.$vnode.data.staticClass;
    },
  },
  mounted() {
    this.clientTop = this.$refs.vContainer.clientTop;
    this.clientWidth = this.$refs.vContainer.clientWidth;
    this.clientLeft = this.$refs.vContainer.clientLeft;
    this.clientHeight = this.$refs.vContainer.clientHeight;
  },
};
</script>

<style scoped></style>
