<template>
  <o-row dense>
    <o-col cols="12">
      <audit-log-full-page />
    </o-col>
  </o-row>
</template>

<script>
import AuditLogFullPage from '../../components/Admin/AuditLog/AuditLogFull.vue';

export default {
  name: 'AuditLog',
  components: {
    AuditLogFullPage,
  },
};
</script>

<style scoped />
