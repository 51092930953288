var render = function render(){var _vm=this,_c=_vm._self._c;return _c('o-card',[_c('o-card-title',[_vm._v("Releases Audit Log")]),_c('o-row',{attrs:{"align":"center","justify":"space-around"}},[_c('o-col',{attrs:{"cols":"4"}},[_c('o-row',[_c('o-text-field',{attrs:{"label":"Search action","loading":_vm.getLoading},on:{"change":() => {
                _vm.setAuditLogSearch(_vm.search_action);
                _vm.options.page = 1;
              }},model:{value:(_vm.search_action),callback:function ($$v) {_vm.search_action=$$v},expression:"search_action"}}),_c('o-icon',{attrs:{"disabled":!_vm.search_action,"color":"red darken-3","icon":""},on:{"click":() => {
                _vm.search_action = '';
                _vm.options.page = 1;
                _vm.setAuditLogSearch(_vm.search_action);
              }}},[_vm._v(" mdi-close-circle ")])],1)],1)],1),_c('o-card-text',[_c('o-data-table',{attrs:{"height":"80vh","items":_vm.getAuditLog,"loading":_vm.getLoading,"headers":_vm.headers,"server-items-length":_vm.getAuditLogCount,"items-per-page":100,"page":_vm.getAuditLogPage,"options":_vm.options,"footer-props":{
          'items-per-page-options': [100],
          'show-first-last-page': true,
        }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:`item.view_context`,fn:function({ item }){return [_c('o-btn',{staticClass:"mx-4",attrs:{"depressed":"","icon":"","large":""},on:{"click":function($event){return _vm.viewItem(item)}}},[_c('o-icon',{attrs:{"small":""}},[_vm._v(" visibility ")])],1)]}},{key:`item.context`,fn:function({ item }){return [_c('p',{staticClass:"truncate"},[_vm._v(" "+_vm._s(item.context)+" ")])]}}],null,true)}),_c('o-dialog',{attrs:{"max-width":"60%"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('o-card',[_c('o-card-title',[_c('o-icon',{staticClass:"ma-4",attrs:{"small":""}},[_vm._v(" visibility ")]),_vm._v(" Context view for action "+_vm._s(_vm.viewedItem.action)+" at "+_vm._s(_vm.viewedItem.date)+" ")],1),_c('o-card-text',[_c('o-textarea',{staticClass:"textarea",attrs:{"no-resize":"","rows":"20","outlined":"","readonly":"","label":"JSON"},model:{value:(_vm.viewedItem.context),callback:function ($$v) {_vm.$set(_vm.viewedItem, "context", $$v)},expression:"viewedItem.context"}})],1),_c('o-card-actions',[_c('o-spacer'),_c('o-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v(" Close ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }