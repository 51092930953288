import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',[_c('o-dialog',{attrs:{"width":"80%","transition":"dialog-right-transition"},model:{value:(_vm.warning_dialog),callback:function ($$v) {_vm.warning_dialog=$$v},expression:"warning_dialog"}},[_c('o-card',[_c(VToolbar,{attrs:{"dark":"","color":"primary"}},[_c('o-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.warning_dialog = false}}},[_c('o-icon',[_vm._v("mdi-close")])],1),_c(VToolbarTitle,[_vm._v("Dashboard Response Log")]),_c('o-spacer'),_c('o-btn',{attrs:{"dark":""},on:{"click":_vm.clearWarnings}},[_vm._v(" Clear ")])],1),_c('o-divider'),_c('o-data-table',{attrs:{"items":_vm.getWarnings,"headers":_vm.headers,"height":"60vh","items-per-page":50,"sort-by":"date","sort-desc":"","aria-multiselectable":"true","single-select":"","footer-props":{
            'show-first-last-page': true,
            'items-per-page-options': [10, 20, 30, 50, 100, 200],
          }},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('tr',{class:item.new ? 'orange' : null},[_c('td',[_vm._v(_vm._s(item.msg))]),_c('td',[_vm._v(_vm._s(item.date))])])]}}])}),_c('o-divider')],1)],1),_c('o-dialog',{attrs:{"width":"80%","transition":"dialog-right-transition"},model:{value:(_vm.temp_warning_dialog),callback:function ($$v) {_vm.temp_warning_dialog=$$v},expression:"temp_warning_dialog"}},[_c('o-card',[_c(VToolbar,{attrs:{"dark":"","color":"primary"}},[_c('o-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.temp_warning_dialog = false}}},[_c('o-icon',[_vm._v("mdi-close")])],1),_c(VToolbarTitle,[_vm._v("Action Log")]),_c('o-spacer')],1),_c('o-data-table',{attrs:{"items":_vm.getActionWarnings,"headers":_vm.headers,"height":"60vh","items-per-page":20,"sort-by":"date","sort-desc":"","aria-multiselectable":"true","single-select":"","footer-props":{
            'show-first-last-page': true,
            'items-per-page-options': [10, 20, 30, 50, 100, 200],
          }}})],1)],1),_c('o-snackbar',{attrs:{"top":"","timeout":10000,"color":_vm.getSnackbarInfo.color},model:{value:(_vm.getSnackbarInfo.snackbar),callback:function ($$v) {_vm.$set(_vm.getSnackbarInfo, "snackbar", $$v)},expression:"getSnackbarInfo.snackbar"}},[_vm._v(" "+_vm._s(_vm.getSnackbarInfo.text)+" "),_c('o-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.closeSnackbar()}}},[_vm._v(" Close ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }