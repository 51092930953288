import axios from 'axios';
import store from './store/index';

axios.defaults.baseURL = process.env.VUE_APP_SERVER_HOST;

const token = localStorage.getItem('authorization_token');
if (token) {
  axios.defaults.headers.common.Authorization = `${token}`;
}

async function refreshToken() {
  const access_token = store.getters['context/authorization_token'];
  const refresh_token = store.getters['context/refresh_authorization_token'];
  const axios_refresh_instance = axios.create();
  try {
    const response = await axios_refresh_instance.post('/auth/refresh_token', {
      access_token,
      refresh_token,
    });
    if (response.status !== 200) {
      return false;
    }
    store.commit('context/setAuthorizationToken', response.data.Authorization);
    store.commit(
      'context/setRefreshAuthorizationToken',
      response.data.RefreshAuthorization,
    );
    return true;
  } catch (_) {
    return false;
  }
}

async function refreshTokenHandler() {
  if (!store.getters['context/isAuthorizationToken']) {
    return false;
  }
  if (store.getters['context/getRefreshingToken'] != null) {
    return store.getters['context/getRefreshingToken'];
  }
  const refreshPromise = refreshToken();
  store.commit('context/setRefreshingToken', refreshPromise);
  const isRefreshResultSuccess = await refreshPromise;
  store.commit('context/setRefreshingToken', null);
  return isRefreshResultSuccess;
}

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response.status === 401) {
      const result = await refreshTokenHandler();
      if (result) {
        error.config.headers['Authorization'] =
          store.getters['context/authorization_token'];
        return axios.request(error.config);
      }
      return store.dispatch('context/logout');
    }
  },
);

export default axios;
