import { checkVAT, countries } from 'jsvat';
import * as ibantools from 'ibantools';

export const publisherValidation = {
  data() {
    return {
      publisherValidation: {
        iban: [
          (v) => {
            const iban = ibantools.electronicFormatIBAN(v);
            return (
              v === '' || ibantools.isValidIBAN(iban) || 'IBAN must be valid'
            );
          },
        ],
        bic: [
          (v) => {
            return (
              v === '' || ibantools.isValidBIC(v) || 'BIC number incorrect'
            );
          },
        ],
        companyVat: [
          (v) => {
            const checkVATResult = checkVAT(v, countries);
            return (
              v === '' || checkVATResult.isValid || 'Company VAT incorrect'
            );
          },
        ],
      },
    };
  },
};
