<template>
  <span>
    <o-dialog
      v-model="warning_dialog"
      width="80%"
      transition="dialog-right-transition"
    >
      <o-card>
        <v-toolbar dark color="primary">
          <o-btn icon dark @click="warning_dialog = false">
            <o-icon>mdi-close</o-icon>
          </o-btn>
          <v-toolbar-title>Dashboard Response Log</v-toolbar-title>
          <o-spacer />
          <o-btn dark @click="clearWarnings"> Clear </o-btn>
        </v-toolbar>
        <o-divider />
        <o-data-table
          :items="getWarnings"
          :headers="headers"
          height="60vh"
          :items-per-page="50"
          sort-by="date"
          sort-desc
          aria-multiselectable="true"
          single-select
          :footer-props="{
            'show-first-last-page': true,
            'items-per-page-options': [10, 20, 30, 50, 100, 200],
          }"
        >
          <template #item="{ item }">
            <tr :class="item.new ? 'orange' : null">
              <td>{{ item.msg }}</td>
              <td>{{ item.date }}</td>
            </tr>
          </template>
        </o-data-table>
        <o-divider />
      </o-card>
    </o-dialog>
    <o-dialog
      v-model="temp_warning_dialog"
      width="80%"
      transition="dialog-right-transition"
    >
      <o-card>
        <v-toolbar dark color="primary">
          <o-btn icon dark @click="temp_warning_dialog = false">
            <o-icon>mdi-close</o-icon>
          </o-btn>
          <v-toolbar-title>Action Log</v-toolbar-title>
          <o-spacer />
        </v-toolbar>
        <o-data-table
          :items="getActionWarnings"
          :headers="headers"
          height="60vh"
          :items-per-page="20"
          sort-by="date"
          sort-desc
          aria-multiselectable="true"
          single-select
          :footer-props="{
            'show-first-last-page': true,
            'items-per-page-options': [10, 20, 30, 50, 100, 200],
          }"
        />
      </o-card>
    </o-dialog>
    <o-snackbar
      v-model="getSnackbarInfo.snackbar"
      top
      :timeout="10000"
      :color="getSnackbarInfo.color"
    >
      {{ getSnackbarInfo.text }}
      <o-btn text @click="closeSnackbar()"> Close </o-btn>
    </o-snackbar>
  </span>
</template>
<script>
import { eventBus } from '@/main';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'WarningsDialog',
  data() {
    return {
      warning_dialog: false,
      temp_warning_dialog: false,
      headers: [
        {
          text: 'Message',
          value: 'msg',
        },
        {
          text: 'Date',
          value: 'date',
        },
      ],
    };
  },
  watch: {
    warning_dialog() {
      if (!this.warning_dialog) this.warnings_clear_new_flag();
    },
  },
  created() {
    eventBus.$on('warn', this.open_warnings);
    eventBus.$on('close_warn', this.close_warnings);
    eventBus.$on('action_warning', this.open_action_warnings);
    eventBus.$on('close_action_warning', this.close_action_warnings);
  },
  computed: {
    ...mapGetters('warnings', [
      'getWarnings',
      'getActionWarnings',
      'getSnackbarInfo',
    ]),
  },
  methods: {
    ...mapActions('warnings', [
      'addWarning',
      'addActionWarning',
      'clearWarnings',
      'warnings_clear_new_flag',
      'closeSnackbar',
    ]),
    open_warnings(warnings) {
      if (warnings) {
        this.addWarning(warnings);
      }
      this.warning_dialog = true;
    },
    close_warnings() {
      this.warning_dialog = false;
    },
    open_action_warnings(warnings) {
      if (warnings) {
        this.addActionWarning(warnings);
      }
      this.temp_warning_dialog = true;
    },
    close_action_warnings() {
      this.temp_warning_dialog = false;
    },
  },
};
</script>

<style scoped></style>
