<template>
  <v-expansion-panel-header
    v-bind="{
      ...$attrs,
      ...$props,
    }"
    v-on="$listeners"
  >
    <template v-for="(_, name) in $scopedSlots" #[name]="render">
      <slot :name="name" v-bind="render"></slot>
    </template>
    <slot></slot>
  </v-expansion-panel-header>
</template>
<script>
export default {
  name: 'OExpansionPanelHeader',
};
</script>

<style scoped>
.v-expansion-panel-header {
  font-weight: bold;
}
</style>
