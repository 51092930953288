<template>
  <o-row>
    <o-col cols="12">
      <o-row>
        <o-card-title>
          {{ $tc('demand_partner_accounts.demand_partner_account', 2) }}
        </o-card-title>
      </o-row>
      <demand-partner-accounts-table />
    </o-col>
  </o-row>
</template>

<script>
import DemandPartnerAccountsTable from '../../components/Accounting/DemandPartnerAccounts/DemandPartnerAccountsTable';

export default {
  name: 'DemandPartnerAccounts',
  components: { DemandPartnerAccountsTable },
};
</script>

<style scoped></style>
