<template>
  <v-row
    ref="vRow"
    v-bind="{
      ...$attrs,
      ...$props,
      class: classes,
    }"
    v-on="$listeners"
  >
    <slot></slot>
  </v-row>
</template>
<script>
export default {
  name: 'ORow',
  data() {
    return {
      clientTop: null,
      clientWidth: null,
      clientLeft: null,
      clientHeight: null,
    };
  },
  computed: {
    classes() {
      // specific to Vue 2
      return this.$vnode.data.staticClass;
    },
  },
  mounted() {
    this.clientTop = this.$refs.vRow.clientTop;
    this.clientWidth = this.$refs.vRow.clientWidth;
    this.clientLeft = this.$refs.vRow.clientLeft;
    this.clientHeight = this.$refs.vRow.clientHeight;
  },
};
</script>

<style scoped></style>
