<template>
  <o-card>
    <o-card-title>Released {{ inventoryType }}</o-card-title>
    <o-card-text>
      <o-data-table
        height="80vh"
        :headers="getHeaders"
        :items="tableItems"
        :loading="getLoading"
        :search="search"
        sort-by="date"
        sort-desc
        :footer-props="{
          'items-per-page-options': [30],
          'show-first-last-page': true,
        }"
      >
        <template #top>
          <o-row><o-divider /></o-row>
          <o-row align="center" justify="space-between">
            <o-col cols="6">
              <o-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('buttons.general.search')"
                single-line
                hide-details
              />
            </o-col>
          </o-row>
          <o-row><o-divider /></o-row>
        </template>
        <template #[`item.action`]="{ item }">
          <o-btn depressed icon large class="mx-4" @click="viewItem(item)">
            <o-icon small> visibility </o-icon>
          </o-btn>
        </template>
        <template #[`item.config`]="{ item }">
          <p class="truncate">
            {{ item.config }}
          </p>
        </template>
        <template #[`item.sr`]="{ item }">
          <p class="truncate">
            {{ item.sr }}
          </p>
        </template>
      </o-data-table>
      <o-dialog v-model="dialog" max-width="60%">
        <o-card>
          <o-card-title>
            <o-icon class="ma-4" small> visibility </o-icon>
            {{ inventoryType == 'AMP' ? 'Store Request' : 'Config' }} for
            {{ inventoryType == 'AMP' ? viewedItem.sr_id : viewedItem.name }}
          </o-card-title>
          <o-card-text>
            <o-textarea
              v-model="getEditedItemConfig"
              no-resize
              rows="20"
              class="textarea"
              outlined
              readonly
              label="JSON"
            />
          </o-card-text>
          <o-card-actions>
            <o-spacer />
            <o-btn color="primary" @click="closeDialog()"> Close </o-btn>
          </o-card-actions>
        </o-card>
      </o-dialog>
    </o-card-text>
  </o-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  props: {
    inventoryType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      search: '',
      tableItems: [],
      viewedItem: {},
      headers_amp: [
        { text: 'ID', value: 'id' },
        {
          text: 'Date',
          value: 'date',
          // sort: (a, b) => new Date(a) > new Date(b),
        },
        { text: 'Config ID', value: 'config_id' },
        { text: 'SR ID', value: 'sr_id' },
        // { text: "Datetime", value: "date" },
        { text: 'Revision', value: 'revision' },
        { text: 'Store Request', value: 'sr' },
        { text: 'Action', value: 'action' },
      ],
      headers_web: [
        { text: 'ID', value: 'id' },
        { text: 'Date', value: 'date' },
        { text: 'Prerelease', value: 'prerelease' },
        { text: 'Name', value: 'name' },
        // { text: "Datetime", value: "date" },
        { text: 'Revision', value: 'revision' },
        { text: 'Config', value: 'config' },
        { text: 'Action', value: 'action' },
      ],
    };
  },
  watch: {
    inventoryType() {
      this.fetchComponentData();
    },
  },
  async mounted() {
    await this.fetchComponentData();
  },
  computed: {
    ...mapGetters('releasesCrusader', [
      'getAmpStoreRequests',
      'getWebConfigs',
      'getLoading',
    ]),
    getEditedItemConfig() {
      if (this.inventoryType === 'WEB') {
        return this.viewedItem.config;
      }
      return this.viewedItem.sr;
    },
    getHeaders() {
      if (this.inventoryType === 'WEB') {
        return this.headers_web;
      }
      return this.headers_amp;
    },
    isTypeWeb() {
      return this.inventoryType === 'WEB';
    },
  },
  methods: {
    ...mapActions('releasesCrusader', [
      'fetchWebConfigs',
      'fetchAmpStoreRequests',
    ]),
    async fetchComponentData() {
      if (this.inventoryType === 'WEB') {
        await this.fetchWebConfigs();
        this.tableItems = this.getWebConfigs;
      } else {
        await this.fetchAmpStoreRequests();
        this.tableItems = this.getAmpStoreRequests;
      }
    },
    viewItem(item) {
      this.viewedItem = item;
      this.dialog = true;
    },
    closeDialog() {
      this.viewedItem = {};
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
.truncate {
  width: 25vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
