<template>
  <o-row>
    <o-col cols="12">
      <o-row>
        <o-card-title>
          {{ $tc('price_floor.price_floor_publishers', 1) }}
        </o-card-title>
      </o-row>
      <price-floor-publishers />
    </o-col>
  </o-row>
</template>

<script>
import PriceFloorPublishers from '@/components/PriceFloor/Publishers/PriceFloorPublishers.vue';

export default {
  components: {
    PriceFloorPublishers,
  },
};
</script>

<style></style>
