import axios from 'axios';

const store = {
  namespaced: true,

  state: {
    loading: false,
    auditLog: [],
    auditLogCount: 0,
    webConfigs: [],
    ampStoreRequests: [],
    redisLive: [],
    search_action: '',
    info: {
      itemsPerPage: 100,
      page: 1,
      auditLogCount: 0,
    },
  },

  getters: {
    getLoading: (state) => {
      return state.loading;
    },
    getAuditLog: (state) => {
      return state.auditLog;
    },
    getAuditLogInfo: (state) => {
      return state.info;
    },
    getWebConfigs: (state) => {
      return state.webConfigs;
    },
    getAmpStoreRequests: (state) => {
      return state.ampStoreRequests;
    },
    getRedisLive: (state) => {
      return state.redisLive;
    },
    getAuditLogCount: (state) => {
      return state.auditLogCount;
    },
    getAuditLogPage: (state) => {
      return state.info.page;
    },
  },

  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
    setAuditLog(state, auditLog) {
      state.auditLog = auditLog;
    },
    setWebConfigs(state, webConfigs) {
      state.webConfigs = webConfigs;
    },
    setAmpStoreRequests(state, ampStoreRequests) {
      state.ampStoreRequests = ampStoreRequests;
    },
    setRedisLive(state, redisLive) {
      state.redisLive = redisLive;
    },
    setAuditLogCount(state, auditLogCount) {
      state.auditLogCount = auditLogCount;
      state.info.auditLogCount = auditLogCount;
    },
    setSearchData(state, payload) {
      state.search_action = payload.search_action;
    },
    setPaginationInfo(state, payload) {
      state.info.page = payload.page;
    },
  },

  actions: {
    async fetchAuditLogCount({ commit, dispatch, state }) {
      commit('setLoading', true);
      const queryParams = {};
      if (state.search_action && state.search_action !== '') {
        queryParams.search_action = state.search_action;
      }
      await axios
        .get(`/crusader_proxy/auditlogs_pagination_info`, {
          params: queryParams,
        })
        .then((response) => {
          commit('setAuditLogCount', response.data.logs_count);
          commit('setLoading', false);
        });
      dispatch('fetchAuditLog');
    },
    async fetchAuditLog({ commit, state }) {
      commit('setLoading', true);
      const page = state.info.page;
      const queryParams = {
        page: state.info.page,
        itemsPerPage: state.info.itemsPerPage,
      };
      if (state.search_action && state.search_action !== '') {
        queryParams.search_action = state.search_action;
      }
      await axios
        .get(`/crusader_proxy/auditlogs_pagination_info`, {
          params: queryParams,
        })
        .then((response) => {
          commit('setAuditLogCount', response.data.logs_count);
        });
      await axios
        .get(`/crusader_proxy/auditlogs/${page - 1}`, { params: queryParams })
        .then((response) => {
          commit('setAuditLog', response.data);
          commit('setLoading', false);
        });
    },
    async setAuditLogSearch({ commit, dispatch }, search_action) {
      await commit('setSearchData', { search_action });
      dispatch('fetchAuditLogCount');
    },
    async updatePaginationInfo({ commit, dispatch }, data) {
      return new Promise((resolve) => {
        commit('setLoading', true);
        commit('setPaginationInfo', {
          page: data.page,
        });
        dispatch('fetchAuditLog');
        resolve();
      });
    },
    async fetchRedisLive({ commit }) {
      commit('setLoading', true);
      await axios.get('/crusader_proxy/web/configs/redis').then((response) => {
        commit('setRedisLive', response.data);
        commit('setLoading', false);
      });
    },
    async fetchWebConfigs({ commit }) {
      commit('setLoading', true);
      await axios.get('/crusader_proxy/web/configs').then((response) => {
        commit('setWebConfigs', response.data);
        commit('setLoading', false);
      });
    },
    async fetchAmpStoreRequests({ commit }) {
      commit('setLoading', true);
      await axios.get('/crusader_proxy/amp/storedrequests').then((response) => {
        commit('setAmpStoreRequests', response.data);
        commit('setLoading', false);
      });
    },
  },
};

export default store;
