import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VTextarea,_vm._g(_vm._b({ref:"vInput",on:{"change":function($event){return _vm.$emit('input', $event)}},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,name){return {key:name,fn:function(render){return [_vm._t(name,null,null,render)]}}}),(_vm.description !== undefined)?{key:"label",fn:function(){return [_c('icon-tooltip',{attrs:{"content":_vm.$t(_vm.label),"description":_vm.$t(_vm.description)}})]},proxy:true}:{key:"label",fn:function(){return [_vm._t("label")]},proxy:true}],null,true)},'v-textarea',{
      ..._vm.$attrs,
      ..._vm.$props,
      label: _vm._label,
    },false),_vm.$listeners))
}
var staticRenderFns = []

export { render, staticRenderFns }