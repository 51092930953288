<template>
  <v-app id="app">
    <link
      href="https://fonts.googleapis.com/css?family=Material+Icons"
      rel="stylesheet"
    />
    <link
      href="https://cdn.jsdelivr.net/npm/@mdi/font@7.x/css/materialdesignicons.min.css"
      rel="stylesheet"
    />
    <router-view />
    <opti-digital-footer />
  </v-app>
</template>

<script>
import OptiDigitalFooter from './components/OptiDigitalFooter';
export default {
  components: {
    'opti-digital-footer': OptiDigitalFooter,
  },
};
</script>
<style>
*[class$='--outlined']:not(.compact),
.v-text-field--outlined:not(.compact),
.v-autocomplete--outlined:not(.compact),
.v-select--outlined:not(.compact),
.v-combobox--outlined:not(.compact),
.v-combobox--outlined:not(.compact),
.v-textarea--outlined:not(.compact) {
  margin-bottom: 12px !important;
  margin-top: 10px !important;
}
</style>
