export const mapStatusColor = {
  methods: {
    mapStatusColor(status) {
      const sanitized = status?.toLowerCase().trim();
      return (
        {
          ['imported']: 'orange',
          ['live']: '#4CAF50',
          ['prerelease']: '#FBC02D',
          ['modified']: '#448AFF',
        }[sanitized] ?? 'gray'
      );
    },
    mapFinancialStatusColor(status) {
      const sanitized = status?.toLowerCase().trim();
      return (
        {
          ['modified']: 'yellow',
          ['validated']: 'green',
          ['revalidate']: 'red',
        }[sanitized] ?? 'orange'
      );
    },
    mapFinancialPaymentColor(status) {
      const sanitized = status?.toLowerCase().trim();
      return (
        {
          ['pending_validation']: 'yellow',
          ['validated']: 'teal',
          ['invoiced']: 'lime',
          ['invoiced_draft']: 'cyan',
          ['payment_done']: 'green',
          ['invoice_sent']: 'blue',
          ['overdue']: 'red',
        }[sanitized] ?? 'blue-grey lighten-3'
      );
    },
  },
};
