import axios from 'axios';
import { eventBus } from '@/main';

const store = {
  namespaced: true,

  state: {
    loading: true,
    adslotWebConfig: [],
    enabledAdslotWebConfigCount: 0,
    zonesImportResult: {},
    slot_id: 0,
  },

  getters: {
    getLoading: (state) => {
      return state.loading;
    },
    getZonesImportResult: (state) => {
      return state.zonesImportResult;
    },
    getAdslotWebConfig: (state) => {
      return state.adslotWebConfig;
    },
    getEnabledAdslotWebConfigCount: (state) => {
      return state.enabledAdslotWebConfigCount;
    },
    getDefaultAdslotWebConfig: (state, getters, rootState, rootGetters) => {
      let sizesList = {};
      let sizesEnable = {};

      const webSettings = rootGetters['siteWebConfig/getWebSetting'];

      if (rootGetters['tagsManager/getInventoryType'] === 'WEB') {
        (webSettings.adSlotsConfig_widthBreakpoints ?? []).forEach(
          (breakPoint) => {
            sizesList['breakpoint_' + breakPoint] = [];
            sizesEnable['breakpoint_' + breakPoint] = 0;
          },
        );
      } else {
        sizesList = {
          breakpoint_0: [
            [300, 250],
            [336, 280],
            [250, 250],
          ],
        };
        sizesEnable = { breakpoint_0: 0 };
      }

      return {
        id: 0,
        site_config_id: 0,
        name: '',
        ad_declaration: null,
        ad_declaration_status: 0,
        load_type: 0,
        lazy_load_px: 900,
        sizes: sizesList,
        status: 1,
        infinite_scroll: 0,
        automatic_refresh: 0,
        automatic_refresh_timelapse: 30_000,
        sizes_enable: sizesEnable,
        odas_zone_id: null,
        odas_size: null,
        cls_max_height: 'dynamic',
        cls_vertical_position: 'center',
        cls_extra_content: 0,
        minMaxSizes: null,
        device_target: 'mobile',
        ad_template: 'default',
        allow_ad_overflow: 10,
        cd_value: '',
        div_target: [],
        ad_unit_path_suffix: '',
        group: null,
      };
    },
  },

  mutations: {
    setSlotEditedSlotId(state, slot_id) {
      state.slot_id = slot_id;
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
    setAdslotWebConfig(state, payload) {
      state.adslotWebConfig = payload;
      state.enabledAdslotWebConfigCount =
        state.adslotWebConfig.filter((item) => item.status === 1).length || 0;
      state.adslotWebConfig.forEach((item) => {
        item.engage_backfill ??= 1;
      });
    },
    setAdslotWebConfigFromModel(state, payload) {
      state.adslotWebConfig = payload;
    },
    setAdslotWebConfigFromModelById(state, payload) {
      const index = state.adslotWebConfig.findIndex(
        (adslot) => adslot.id === payload.id,
      );
      state.adslotWebConfig[index] = payload;
    },
    setZonesImportResult(state, payload) {
      state.zonesImportResult = payload;
    },
  },

  actions: {
    async fetchAdslotWebConfig({ commit, rootState }) {
      commit('setLoading', true);
      const payload = {
        se_id: rootState.tagsManager.site.id,
        revision: rootState.tagsManager.revision.number,
        inventory_type: rootState.tagsManager.inventoryType,
      };
      if (!payload.revision) return null;
      return new Promise((resolve) => {
        axios
          .get('/adslot_web_config/', { params: payload })
          .then((response) => {
            commit('setAdslotWebConfig', response.data.data);
            eventBus.$emit('tm-update-adslot-settings-model');
            commit('setLoading', false);
            resolve();
          });
      });
    },
    async updateAdSlot({ commit, state, rootState }, data) {
      commit('setLoading', true);

      await axios.post('/tags_manager/config/ad_slot', data, {
        params: {
          slot_id: state.slot_id,
          inventory_type: rootState.tagsManager.inventoryType,
        },
      });
      commit('setLoading', false);
    },
    async createAdslotWebConfig({ commit, dispatch, rootState }, data) {
      const params = {
        se_id: rootState.tagsManager.site.id,
        revision: rootState.tagsManager.revision.number,
        inventory_type: rootState.tagsManager.inventoryType,
      };

      commit('setLoading', true);
      return new Promise((resolve) => {
        axios
          .put('/adslot_web_config/', data, { params })
          .then((response) => {
            params.revision = response.data.revision;
            dispatch('fetchAdslotWebConfig');
          })
          .then(() => resolve())
          .catch((err) => {
            if (err.response.status === 409) {
              const msg =
                'message' in err.response.data && err.response.data.message
                  ? err.response.data.message
                  : err.toString();
              commit('warnings/snackbarError', msg, {
                root: true,
              });
              commit('warnings/addWarning', msg, {
                root: true,
              });
            }
          });
      });
    },
    async importOdasZones({ commit, dispatch, rootState }, zones_string) {
      const data = {
        site_config_id: rootState.siteWebConfig.webConfig.id,
        zones_string,
      };
      commit('setLoading', true);
      const response = await axios.post('/adslot_web_config/zonesImport', data);
      dispatch('warnings/addWarning', response.data.warnings, {
        root: true,
      });
      commit('setZonesImportResult', response.data);
      commit('setLoading', false);
    },
  },
};

export default store;
