export const filterMixin = {
  computed: {
    getFiltersList() {
      return this.filterOptions.filter(function (element) {
        return !element.enabled;
      });
    },
  },
  methods: {
    filterItem(item) {
      for (const element of this.filterOptions) {
        if (element['custom_filter']) {
          if (element.enabled) {
            return element.custom_filter({ item, element });
          }
        } else if (element.filterField.includes('.')) {
          const filterFieldKey = element.filterField.split('.');
          if (
            element.enabled &&
            item[filterFieldKey[0]][filterFieldKey[1]] &&
            item[filterFieldKey[0]][filterFieldKey[1]]
              .toString()
              .toLowerCase()
              .indexOf(element.search.toString().toLowerCase()) === -1
          ) {
            return false;
          }
          if (element.enabled && !item[filterFieldKey[0]][filterFieldKey[1]]) {
            return false;
          }
        } else {
          if (
            element.enabled &&
            item[element.filterField]
              .toString()
              .toLowerCase()
              .indexOf(element.search.toString().toLowerCase()) === -1
          ) {
            return false;
          }
        }
      }
      return true;
    },
    addFilterItem(item) {
      item.enabled = true;
      this.filterList.push(item);
    },
    removeFilterItem(item, index) {
      item.enabled = false;
      item.search = '';
      this.filterList.splice(index, 1);
    },
  },
};
