<template>
  <v-form
    ref="vForm"
    v-bind="{
      ...$attrs,
      ...$props,
    }"
    v-on="$listeners"
  >
    <template v-for="(_, name) in $scopedSlots" #[name]="render">
      <slot :name="name" v-bind="render"></slot>
    </template>

    <slot></slot>
  </v-form>
</template>
<script>
export default {
  name: 'OForm',
  methods: {
    // expose v-form function output to parent
    reset(...args) {
      return this.$refs.vForm.reset(...args);
    },
    resetValidation(...args) {
      return this.$refs.vForm.resetValidation(...args);
    },
    validate(...args) {
      return this.$refs.vForm.validate(...args);
    },
  },
};
</script>

<style scoped></style>
