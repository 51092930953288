<template>
  <o-row>
    <o-col cols="12">
      <o-row>
        <o-card-title>
          {{ $tc('price_floor.price_floor_settings', 1) }}
        </o-card-title>
      </o-row>
      <price-floor-settings />
    </o-col>
  </o-row>
</template>

<script>
import PriceFloorSettings from '@/components/PriceFloor/Settings/PriceFloorSettings.vue';

export default {
  components: {
    PriceFloorSettings,
  },
};
</script>

<style></style>
