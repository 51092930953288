import Vue from 'vue';

import OAlert from '@/components/DesignSystem/OAlert/OAlert.vue';
Vue.component('OAlert', OAlert);
import OBtn from '@/components/DesignSystem/OBtn/OBtn.vue';
Vue.component('OBtn', OBtn);
import OCheckbox from '@/components/DesignSystem/OBtn/OCheckbox.vue';
Vue.component('OCheckbox', OCheckbox);
import ORadio from '@/components/DesignSystem/OBtn/ORadio.vue';
Vue.component('ORadio', ORadio);
import ORadioGroup from '@/components/DesignSystem/OBtn/ORadioGroup.vue';
Vue.component('ORadioGroup', ORadioGroup);
import OCard from '@/components/DesignSystem/OCard/OCard.vue';
Vue.component('OCard', OCard);
import OCardActions from '@/components/DesignSystem/OCard/OCardActions.vue';
Vue.component('OCardActions', OCardActions);
import OCardSubtitle from '@/components/DesignSystem/OCard/OCardSubtitle.vue';
Vue.component('OCardSubtitle', OCardSubtitle);
import OCardText from '@/components/DesignSystem/OCard/OCardText.vue';
Vue.component('OCardText', OCardText);
import OCardTitle from '@/components/DesignSystem/OCard/OCardTitle.vue';
Vue.component('OCardTitle', OCardTitle);
import OChip from '@/components/DesignSystem/OChip/OChip.vue';
Vue.component('OChip', OChip);
import ODataTable from '@/components/DesignSystem/ODataTable/ODataTable.vue';
Vue.component('ODataTable', ODataTable);
import ODataTableHeader from '@/components/DesignSystem/ODataTable/ODataTableHeader.vue';
Vue.component('ODataTableHeader', ODataTableHeader);
import OCardDialog from '@/components/DesignSystem/ODialog/OCardDialog.vue';
Vue.component('OCardDialog', OCardDialog);
import ODialog from '@/components/DesignSystem/ODialog/ODialog.vue';
Vue.component('ODialog', ODialog);
import OExpansionPanel from '@/components/DesignSystem/OExpansionPanels/OExpansionPanel.vue';
Vue.component('OExpansionPanel', OExpansionPanel);
import OExpansionPanelContent from '@/components/DesignSystem/OExpansionPanels/OExpansionPanelContent.vue';
Vue.component('OExpansionPanelContent', OExpansionPanelContent);
import OExpansionPanelHeader from '@/components/DesignSystem/OExpansionPanels/OExpansionPanelHeader.vue';
Vue.component('OExpansionPanelHeader', OExpansionPanelHeader);
import OExpansionPanels from '@/components/DesignSystem/OExpansionPanels/OExpansionPanels.vue';
Vue.component('OExpansionPanels', OExpansionPanels);
import OForm from '@/components/DesignSystem/OForm/OForm.vue';
Vue.component('OForm', OForm);
import OCol from '@/components/DesignSystem/OGrid/OCol.vue';
Vue.component('OCol', OCol);
import OContainer from '@/components/DesignSystem/OGrid/OContainer.vue';
Vue.component('OContainer', OContainer);
import ODivider from '@/components/DesignSystem/OGrid/ODivider.vue';
Vue.component('ODivider', ODivider);
import ORow from '@/components/DesignSystem/OGrid/ORow.vue';
Vue.component('ORow', ORow);
import OSpacer from '@/components/DesignSystem/OGrid/OSpacer.vue';
Vue.component('OSpacer', OSpacer);
import OIcon from '@/components/DesignSystem/OIcon/OIcon.vue';
Vue.component('OIcon', OIcon);
import OInfiniteScroll from '@/components/DesignSystem/OInfiniteScroll/OInfiniteScroll.vue';
Vue.component('OInfiniteScroll', OInfiniteScroll);
import OAutocomplete from '@/components/DesignSystem/OInput/OAutocomplete.vue';
Vue.component('OAutocomplete', OAutocomplete);
import OCombobox from '@/components/DesignSystem/OInput/OCombobox.vue';
Vue.component('OCombobox', OCombobox);
import ODatePicker from '@/components/DesignSystem/OInput/ODatePicker.vue';
Vue.component('ODatePicker', ODatePicker);
import OSelect from '@/components/DesignSystem/OInput/OSelect.vue';
Vue.component('OSelect', OSelect);
import OSwitch from '@/components/DesignSystem/OInput/OSwitch.vue';
Vue.component('OSwitch', OSwitch);
import OTextField from '@/components/DesignSystem/OInput/OTextField.vue';
Vue.component('OTextField', OTextField);
import OTextarea from '@/components/DesignSystem/OInput/OTextarea.vue';
Vue.component('OTextarea', OTextarea);
import OList from '@/components/DesignSystem/OList/OList.vue';
Vue.component('OList', OList);
import OListGroup from '@/components/DesignSystem/OList/OListGroup.vue';
Vue.component('OListGroup', OListGroup);
import OListItem from '@/components/DesignSystem/OList/OListItem.vue';
Vue.component('OListItem', OListItem);
import OListItemAction from '@/components/DesignSystem/OList/OListItemAction.vue';
Vue.component('OListItemAction', OListItemAction);
import OListItemActionText from '@/components/DesignSystem/OList/OListItemActionText.vue';
Vue.component('OListItemActionText', OListItemActionText);
import OListItemAvatar from '@/components/DesignSystem/OList/OListItemAvatar.vue';
Vue.component('OListItemAvatar', OListItemAvatar);
import OListItemContent from '@/components/DesignSystem/OList/OListItemContent.vue';
Vue.component('OListItemContent', OListItemContent);
import OListItemGroup from '@/components/DesignSystem/OList/OListItemGroup.vue';
Vue.component('OListItemGroup', OListItemGroup);
import OListItemIcon from '@/components/DesignSystem/OList/OListItemIcon.vue';
Vue.component('OListItemIcon', OListItemIcon);
import OListItemSubtitle from '@/components/DesignSystem/OList/OListItemSubtitle.vue';
Vue.component('OListItemSubtitle', OListItemSubtitle);
import OListItemTitle from '@/components/DesignSystem/OList/OListItemTitle.vue';
Vue.component('OListItemTitle', OListItemTitle);
import OSubheader from '@/components/DesignSystem/OList/OSubheader.vue';
Vue.component('OSubheader', OSubheader);
import OMenu from '@/components/DesignSystem/OMenu/OMenu.vue';
Vue.component('OMenu', OMenu);
import OSimpleTable from '@/components/DesignSystem/OSimpleTable/OSimpleTable.vue';
Vue.component('OSimpleTable', OSimpleTable);
import OSnackbar from '@/components/DesignSystem/OSnackbar/OSnackbar.vue';
Vue.component('OSnackbar', OSnackbar);
import OStepper from '@/components/DesignSystem/OStepper/OStepper.vue';
Vue.component('OStepper', OStepper);
import OStepperContent from '@/components/DesignSystem/OStepper/OStepperContent.vue';
Vue.component('OStepperContent', OStepperContent);
import OStepperHeader from '@/components/DesignSystem/OStepper/OStepperHeader.vue';
Vue.component('OStepperHeader', OStepperHeader);
import OStepperItems from '@/components/DesignSystem/OStepper/OStepperItems.vue';
Vue.component('OStepperItems', OStepperItems);
import OStepperStep from '@/components/DesignSystem/OStepper/OStepperStep.vue';
Vue.component('OStepperStep', OStepperStep);
import OTooltip from '@/components/DesignSystem/OTooltip/OTooltip.vue';
Vue.component('OTooltip', OTooltip);
