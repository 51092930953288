<template>
  <o-container>
    <o-row dense align="center">
      <o-col cols="6">
        <o-row align-content="stretch" justify="center">
          <o-col cols="12">
            <o-card style="height: 40vh" class="table">
              <o-row>
                <o-card-title class="headline mb-1">
                  Action Button Table
                </o-card-title>
              </o-row>
              <o-row>
                <o-col>
                  <o-row align="center" justify="center">
                    <o-col class="text-center">
                      <o-btn
                        :loading="loadingPushAllOdasSr"
                        small
                        color="primary"
                        @click="onPushAllOdasSr"
                      >
                        Push ODAS SR - All sites
                      </o-btn>
                    </o-col>
                  </o-row>
                  <o-row align="center" justify="center">
                    <o-col class="text-center">
                      <o-btn
                        :loading="loadingSynchPennylane"
                        small
                        color="primary"
                        @click="onSynchronizePennylane"
                      >
                        Synchronize Pennylane
                      </o-btn>
                    </o-col>
                  </o-row>
                  <o-spacer />
                </o-col>
              </o-row>
            </o-card>
          </o-col>
        </o-row>
        <o-row>
          <o-col cols="12">
            <o-card style="height: 40vh">
              <o-card-title>Fetch reports</o-card-title>
              <loading-overlay :overlay="getLoadingFetchReports">
                <template #default>
                  <o-menu
                    v-model="datePicker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on }">
                      <o-text-field
                        v-model="datePickerDate"
                        prepend-icon="event"
                        readonly
                        v-on="on"
                      />
                    </template>
                    <o-date-picker
                      v-model="datePickerDate"
                      type="month"
                      @input="datePicker = false"
                    />
                  </o-menu>
                  <o-btn @click="onFetchReports"> Fetch Reports </o-btn>
                </template>
              </loading-overlay>
            </o-card>
          </o-col>
        </o-row>
      </o-col>
      <o-col cols="6">
        <o-row align="stretch" justify="center">
          <o-col cols="12">
            <audit-log />
          </o-col>
        </o-row>
      </o-col>
    </o-row>

    <o-row align="center">
      <o-expansion-panels v-model="panel">
        <o-expansion-panel>
          <o-expansion-panel-header> Manage users </o-expansion-panel-header>
          <o-expansion-panel-content>
            <o-data-table
              outlined
              :headers="user_header"
              :items="getAgents"
              :search="search"
              :items-per-page="10"
              :footer-props="{
                'show-first-last-page': true,
                'items-per-page-options': [10, 20, 30, 50, 100],
              }"
            >
              <template #item.action="{ item }">
                <o-btn color="primary" text small @click="connect(item)">
                  Connect as {{ item.email }}
                </o-btn>
              </template>
            </o-data-table>
          </o-expansion-panel-content>
        </o-expansion-panel>
      </o-expansion-panels>
    </o-row>
  </o-container>
</template>

<script>
import AuditLog from '../../components/Admin/AuditLog/AuditLogSmall.vue';
import LoadingOverlay from '@/components/DesignSystem/OLoader/LoadingOverlay.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'AdminDashboard',
  components: { LoadingOverlay, AuditLog },
  data() {
    return {
      search: '',
      panel: 0,
      homeState: true,
      datePickerDate: null,
      datePicker: false,
      loadingPushAllOdasSr: false,
      loadingSynchPennylane: false,
      user_header: [
        {
          text: 'ID',
          value: 'id',
        },
        {
          text: 'Email',
          value: 'email',
        },
        {
          text: 'Role',
          value: 'role',
        },
        {
          text: 'Action',
          value: 'action',
          sortable: false,
          filterable: false,
        },
      ],
    };
  },
  async mounted() {
    await this.fetchAgents();
    this.setUpReportsFetchDate();
  },
  methods: {
    ...mapActions('admin', ['fetchReports', 'synchronizePennylane']),
    ...mapActions('siteWebConfig', ['pushAllOdasSr']),
    ...mapActions('context', ['fetchAgents', 'connectAs']),
    onFetchReports() {
      const payload = {
        year: this.datePickerDate.substring(0, 4),
        month: this.datePickerDate.substring(5, 5 + 7),
      };
      this.fetchReports(payload);
    },
    async onPushAllOdasSr() {
      this.loadingPushAllOdasSr = true;
      await this.pushAllOdasSr();
      this.loadingPushAllOdasSr = false;
    },
    async onSynchronizePennylane() {
      this.loadingSynchPennylane = true;
      await this.synchronizePennylane();
      this.loadingSynchPennylane = false;
    },
    async connect({ id }) {
      const response = await this.connectAs({ user_id: id });
      if (response) {
        await this.$router.push({ name: 'Home' });
      }
    },
    setUpReportsFetchDate() {
      const d = new Date();
      d.setDate(0); //prev month
      d.setDate(1); // set first day of prev month
      this.datePickerDate = this.getDate || d.toISOString().substring(0, 7);
    },
  },
  computed: {
    ...mapGetters('admin', ['getLoadingFetchReports']),
    ...mapGetters('context', ['getAgents']),
  },
};
</script>

<style scoped>
.table {
  background: url('./table.jpg');
  background-size: 100% 90%;
  background-repeat: no-repeat;
  background-position: 0px 50px;
}
.v-expansion-panel-header {
  font-weight: bold;
}
</style>
