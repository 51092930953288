<template>
  <o-dialog v-model="visible" width="auto" @click:outside="onCancel">
    <o-card>
      <o-card-title>
        {{ titleText }}
      </o-card-title>
      <o-card-text>
        <o-date-picker v-model="selectedDate" />
      </o-card-text>
      <o-card-actions>
        <o-spacer />
        <o-btn color="primary" @click="onConfirm"> Confirm </o-btn>
        <o-btn @click="onCancel"> Cancel </o-btn>
      </o-card-actions>
    </o-card>
  </o-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    dpaType: {
      type: String,
      required: true,
    },
    titleText: {
      type: String,
      required: true,
    },
    onConfirmCallback: {
      type: Function,
      required: true,
    },
    onCancelCallback: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      selectedDate: '',
    };
  },
  watch: {
    visible(newValue) {
      if (newValue === true) {
        if (this.dpaType === 'publisher') {
          const date = new Date(this.getYear, this.getMonth, 1);
          const formattedDate = new Date(
            date.getTime() - date.getTimezoneOffset() * 60000,
          );
          this.selectedDate = formattedDate.toISOString().slice(0, 10);
        } else if (this.dpaType === 'optidigital') {
          const date_day = this.getProductId === 3 ? 1 : 15;
          const date = new Date(this.getYear, this.getMonth, date_day);
          const formattedDate = new Date(
            date.getTime() - date.getTimezoneOffset() * 60000,
          );
          this.selectedDate = formattedDate.toISOString().slice(0, 10);
        } else {
          this.selectedDate = new Date().toISOString().slice(0, 10);
        }
      }
    },
  },
  computed: {
    ...mapGetters('turnoverFinal', ['getYear', 'getMonth', 'getProductId']),
  },
  methods: {
    onConfirm() {
      this.onConfirmCallback(this.selectedDate);
    },
    onCancel() {
      this.onCancelCallback(this.selectedDate);
    },
  },
};
</script>

<style></style>
