<template>
  <o-row>
    <o-col cols="12">
      <o-row>
        <o-card-title>
          {{ $tc('demand_partners.demand_partner', 2) }}
        </o-card-title>
      </o-row>
      <demand-partners-table />
    </o-col>
  </o-row>
</template>

<script>
import DemandPartnersTable from '../../components/Accounting/DemandPartners/DemandPartnersTable';

export default {
  name: 'DemandPartners',
  components: { DemandPartnersTable },
};
</script>

<style scoped></style>
