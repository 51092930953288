<template>
  <o-dialog v-model="show" max-width="50%" @click:outside="close">
    <o-card style="overflow-x: hidden">
      <o-card-text>
        <o-container>
          <o-form ref="form" v-model="valid" lazy-validation>
            <o-row>
              <o-row>
                <o-col cols="12">
                  <o-card-title>Edit:</o-card-title>
                </o-col>
                <o-col cols="12">
                  <o-alert v-if="error" dense outlined type="error">
                    Check the code for Price bucket. It should has format
                    <code>[{}, {}, ....]</code>
                  </o-alert>
                </o-col>
                <o-col cols="12">
                  <o-text-field
                    v-model="editedItem.name"
                    :readonly="readOnly"
                    :filled="readOnly"
                    label="Name"
                    :rules="createRules([baseValidation.required])"
                  />
                </o-col>
                <o-col cols="12">
                  <o-autocomplete
                    v-model="editedItem.code"
                    :readonly="readOnly"
                    :filled="readOnly"
                    :items="getCurrencyCodes"
                    label="Currency"
                    :rules="createRules([baseValidation.required])"
                  />
                </o-col>
                <o-col cols="12">
                  <prism-code-editor
                    v-model="priceBucket"
                    language="json"
                    label="Price bucket"
                    :readonly="!editable"
                    :rules="createRules([baseValidation.required])"
                  />
                </o-col>
              </o-row>
            </o-row>
          </o-form>
        </o-container>
      </o-card-text>
      <o-card-actions>
        <o-spacer />
        <o-btn
          v-if="!readOnly"
          :disabled="!valid"
          color="blue darken-1"
          text
          @click="save"
        >
          {{ $t('buttons.general.save') }}
        </o-btn>
        <o-btn color="blue darken-1" text @click="close">
          {{ $t('buttons.general.close') }}
        </o-btn>
      </o-card-actions>
    </o-card>
  </o-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { baseValidation } from '@/validation/baseValidation';
import { validationHelper } from '@/validation/validationHelper';
import { publisherValidation } from '@/validation/publisherValidation';
import PrismCodeEditor from '@/components/Settings/TagsManager/PrismCodeEditor.vue';

export default {
  name: 'PriceFloorCurrencyDialog',
  components: { PrismCodeEditor },
  mixins: [baseValidation, validationHelper, publisherValidation],
  props: ['editedItem', 'editedIndex'],
  data() {
    return {
      show: false,
      readOnly: false,
      valid: true,
      disable_select: false,
      datePicker: false,
      invoiceContacts: [],
      editable: true,
      priceBucket: '[{}]',
      error: false,
    };
  },
  watch: {
    editedItem() {
      this.priceBucket = '[{}]';
      this.formatEditedItems();
    },
  },
  mounted() {
    this.fetchAllCurrencies();
    this.fetchCurrencyCodes();
  },
  computed: {
    ...mapGetters('priceFloorSettings', [
      'getAllCurrencies',
      'getCurrencyCodes',
    ]),
  },
  methods: {
    ...mapActions('priceFloorSettings', [
      'fetchAllCurrencies',
      'fetchCurrencyCodes',
      'editCurrency',
      'addCurrency',
    ]),
    formatEditedItems() {
      if (JSON.stringify(this.editedItem.bucket_definition)) {
        this.priceBucket = JSON.stringify(this.editedItem.bucket_definition);
      }
    },
    close() {
      this.show = false;
      this.error = false;
    },
    save() {
      if (this.$refs.form.validate()) {
        const parsedPriceBucket = JSON.parse(this.priceBucket);
        if (
          !Array.isArray(parsedPriceBucket) ||
          typeof parsedPriceBucket !== 'object'
        ) {
          this.error = true;
        } else if (parsedPriceBucket.length === 0) {
          this.error = true;
        } else if (
          parsedPriceBucket.length &&
          !parsedPriceBucket.every((value) => typeof value === 'object')
        ) {
          this.error = true;
        } else {
          if (this.editedIndex === -1) {
            this.addCurrency({
              ...this.editedItem,
              bucket_definition: parsedPriceBucket,
            });
          } else {
            this.editCurrency({
              ...this.editedItem,
              bucket_definition: parsedPriceBucket,
            });
          }
          this.error = false;
          this.close();
        }
      }
    },
  },
};
</script>

<style scoped>
.country-extra {
  display: flex;
  flex-flow: column wrap;
  max-height: 650px;
  gap: 0 50px;
}
</style>
