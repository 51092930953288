import axios from 'axios';

class FloorApiEndpoints {
  static GeneralFloors = new FloorApiEndpoints(
    '/floor_manager/floors/<product>',
  );
  static ChangeFloorStatus = new FloorApiEndpoints(
    '/floor_manager/floor/<floor_id>/status',
  );
  static GetProductDimensions = new FloorApiEndpoints(
    '/floor_manager/dimensions/<product>',
  );
  static PushGeneralFloors = new FloorApiEndpoints(
    '/floor_manager/push/<product>/general',
  );
  static ConfigGeneralFloors = new FloorApiEndpoints(
    '/floor_manager/latest_time/<product>/general',
  );
  static PublishersFloor = new FloorApiEndpoints(
    '/floor_manager/publishers/<product>',
  );

  static PushFloors = new FloorApiEndpoints(
    '/floor_manager/push/<product>/<publisher_code>',
    {
      fallback: FloorApiEndpoints.PushGeneralFloors,
    },
  );
  static ConfigFloors = new FloorApiEndpoints(
    '/floor_manager/latest_time/<product>/<publisher_code>',
    { fallback: FloorApiEndpoints.ConfigGeneralFloors },
  );
  static Floors = new FloorApiEndpoints(
    '/floor_manager/floors/<product>/<publisher_code>',
    {
      fallback: FloorApiEndpoints.GeneralFloors,
    },
  );
  static GetProductPublisherDimensions = new FloorApiEndpoints(
    '/floor_manager/dimensions/<product>/<publisher_code>',
    { fallback: FloorApiEndpoints.GetProductDimensions },
  );
  static GetReference = new FloorApiEndpoints(
    '/floor_manager/reference/<product>/<reference_name>',
  );
  static GetFloorVersion = new FloorApiEndpoints(
    '/floor_manager/floor/version/<product>/<floor_id>',
  );

  constructor(name, { fallback } = {}) {
    if (fallback) {
      this.fallback = fallback;
    }

    this.name = name;
  }

  toString() {
    return `${this.name}`;
  }

  format({ product, publisher_code, floor_id, reference_name }) {
    if (!publisher_code && this.fallback) {
      return this.fallback
        .toString()
        .replace('<product>', product)
        .replace('<floor_id>', floor_id)
        .replace('<reference_name>', reference_name);
    }

    return this.toString()
      .replace('<product>', product)
      .replace('<publisher_code>', publisher_code)
      .replace('<floor_id>', floor_id)
      .replace('<reference_name>', reference_name);
  }
}

const generateFloorApiEndpoint = function (
  endpoint,
  product,
  publisher_code,
  { floor_id, reference_name } = {},
) {
  const endpoint_registry = Object.values(FloorApiEndpoints).map(
    ({ name }) => name,
  );
  if (endpoint_registry.includes(endpoint.toString())) {
    return endpoint.format({
      product,
      publisher_code,
      floor_id,
      reference_name,
    });
  }

  throw 'Error in generateFloorApiEndpoint';
};

const store = {
  namespaced: true,
  state: {
    product: null,
    publisher_code: null,
    edit_pf: null,
    price_floors: [],
    frozen_price_floors: [],
    dimensions: [],
    publisher_list: [],
    price_floor_filter: '',
    last_config_pushed: '',
    timeout_registry: {},
    conflict: {
      status: false,
      message: null,
    },
    dynamicItems: {},
  },
  getters: {
    getFrozenPriceFloors: (state) => state.frozen_price_floors,
    getPriceFloors: (state) => {
      return state.price_floors;
    },
    getDimensions: (state) => {
      return state.dimensions;
    },
    getPublisherList: (state) => {
      return state.publisher_list;
    },
    getEditPriceFloor: (state) => {
      return state.edit_pf;
    },
    getLastConfigPushed: (state) => {
      return state.last_config_pushed;
    },
    getConflict: (state) => state.conflict,
    getPriceFloorFilter: (state) => state.price_floor_filter,
    getDynamicItems: (state) => state.dynamicItems,
  },
  mutations: {
    addDynamicItems(state, { reference, data }) {
      state.dynamicItems[reference] = data;
    },
    setPriceFloorFilter(state, price_floor_filter) {
      state.price_floor_filter = price_floor_filter;
    },
    setPublisherList(state, publisher_list) {
      state.publisher_list = publisher_list;
    },
    setLastConfigPushed(state, last_config_pushed) {
      state.last_config_pushed = last_config_pushed;
    },
    setProduct(state, product) {
      state.product = product;
    },
    setPublisher(state, publisher_code) {
      state.publisher_code = publisher_code;
    },
    async setPriceFloorData(state, pf_data) {
      state.price_floors = pf_data;

      const seal = (pf) =>
        new Promise((resolve) => {
          resolve(
            Object.seal({
              ...Object.seal(pf),
              dimensions: Object.seal(
                pf.dimensions.map((dimension) => Object.seal(dimension)),
              ),
            }),
          );
        });
      state.frozen_price_floors = await Promise.all(
        window.structuredClone(pf_data).map(seal),
      );
    },
    setDimensions(state, dimensions) {
      state.dimensions = dimensions;
    },
    setEditPriceFloor(state, edit_pf) {
      state.edit_pf = edit_pf;
    },
    addDimensionToPriceFloor(state, dimension) {
      state.edit_pf.dimensions ??= [];
      state.edit_pf.dimensions.push({
        name: dimension.name,
        dimension_type_id: dimension.id,
        value: [],
        soft_delete: false,
        type: {
          component: dimension.component,
          options: dimension.options,
        },
      });
    },
    removeDimensionToPriceFloor(state, dimension) {
      const index = state.edit_pf.dimensions.indexOf(dimension);
      state.edit_pf.dimensions.splice(index, 1);
    },
    addPriceFloor(state) {
      state.price_floors.push({
        id:
          state.price_floors.reduce(
            function (prev, curr) {
              return prev.id < curr.id ? prev : curr;
            },
            { id: 0 },
          ).id - 1,
        insert: true,
        editable: true,
        status: true,
        name: '',
        site_code: '',
        currency: 'EUR',
        visibility: true,
        publisher_code: state.publisher_code,
        product_target: state.product,
        floor: '0.00',
        dimensions: [],
      });
    },
    removeLastPriceFloor(state) {
      const unsaved = state.price_floors.findIndex((pf) => pf.id < 0);
      state.price_floors.splice(unsaved, 1);
    },
    setConflict(state, { status, message = null }) {
      state.conflict = { status, message };
    },
  },
  actions: {
    async fetchReferenceItems({ commit, dispatch }, { items }) {
      const referenceName = items.substring(1);
      try {
        const data = await dispatch('fetchReference', referenceName);
        commit('addDynamicItems', { reference: referenceName, data });
      } catch (error) {
        commit('addDynamicItems', { reference: referenceName, data: [] });
      }
    },
    async fetchFloorRules({ commit, state }) {
      const endpoint = generateFloorApiEndpoint(
        FloorApiEndpoints.Floors,
        state.product,
        state.publisher_code,
      );
      const response = await axios.get(endpoint, {
        params: {
          q: state.price_floor_filter,
        },
      });
      commit('setPriceFloorData', response.data);
    },
    async fetchFloorDimensions({ commit, state }) {
      const endpoint = generateFloorApiEndpoint(
        FloorApiEndpoints.GetProductPublisherDimensions,
        state.product,
        state.publisher_code,
      );
      const response = await axios.get(endpoint);
      commit('setDimensions', response.data);
    },
    async fetchFloorPublisher({ commit, state }) {
      const endpoint = generateFloorApiEndpoint(
        FloorApiEndpoints.PublishersFloor,
        state.product,
        state.publisher_code,
      );
      const response = await axios.get(endpoint);
      commit('setPublisherList', response.data);
    },
    async lastConfigFloors({ commit, state }) {
      const endpoint = generateFloorApiEndpoint(
        FloorApiEndpoints.ConfigFloors,
        state.product,
        state.publisher_code,
      );
      const response = await axios.get(endpoint);
      commit('setLastConfigPushed', response.data);
    },
    async pushFloors({ state, dispatch }) {
      const endpoint = generateFloorApiEndpoint(
        FloorApiEndpoints.PushFloors,
        state.product,
        state.publisher_code,
      );

      await axios.post(endpoint);
      dispatch('lastConfigFloors');
    },
    changeStatus({ state, dispatch }, pf) {
      if (!pf?.id) return;
      const floor_id = pf.id;
      const endpoint = generateFloorApiEndpoint(
        FloorApiEndpoints.ChangeFloorStatus,
        state.product,
        state.publisher_code,
        { floor_id },
      );
      if (state.timeout_registry[`status#${floor_id}`]) {
        clearTimeout(state.timeout_registry[`status#${floor_id}`]);
      }
      state.timeout_registry[`status#${floor_id}`] = setTimeout(
        () =>
          axios
            .post(endpoint, { status: pf.status })
            .then(() => dispatch('fetchFloorRules')),
        500,
      );
    },
    async saveEditFloorRules({ dispatch, commit, state }) {
      if (!state.edit_pf) {
        return;
      }

      const endpoint = generateFloorApiEndpoint(
        FloorApiEndpoints.Floors,
        state.product,
        state.publisher_code,
      );

      try {
        await axios.post(endpoint, {
          price_floors: [state.edit_pf],
        });
        await dispatch('fetchFloorRules');
      } catch (error) {
        if (error.response?.status === 409) {
          commit('setConflict', {
            status: true,
            message: error.response.data,
          });
          return;
        }
        throw error;
      }
    },
    async setPriceFloor({ commit, dispatch }, pf) {
      commit('setPriceFloorData', pf);
      void dispatch('fetchFloorDimensions');
      void dispatch('fetchFloorRules');
    },
    setPublisher({ commit, dispatch }, publisher_code) {
      commit('setPublisher', publisher_code);
      void dispatch('fetchFloorDimensions');
      void dispatch('fetchFloorRules');
      void dispatch('lastConfigFloors');
    },
    setProduct({ commit, dispatch }, product) {
      commit('setProduct', product);
      void dispatch('fetchFloorPublisher');
      void dispatch('fetchFloorDimensions');
      void dispatch('fetchFloorRules');
      void dispatch('lastConfigFloors');
    },
    async initPriceFloorManagement(
      { commit, dispatch },
      { product, publisher_code },
    ) {
      commit('setProduct', product);
      commit('setPublisher', publisher_code);
      await Promise.all([
        dispatch('fetchReferenceItems', { items: '$publisher_ref' }),
        dispatch('fetchFloorPublisher'),
        dispatch('fetchFloorDimensions'),
        dispatch('fetchFloorRules'),
        dispatch('lastConfigFloors'),
      ]);
    },
    addPriceFloor({ commit }) {
      commit('addPriceFloor');
    },
    removeLastPriceFloor({ commit }) {
      commit('removeLastPriceFloor');
    },
    addDimensionToPriceFloor({ commit }, dimension) {
      commit('addDimensionToPriceFloor', dimension);
    },
    removeDimensionToPriceFloor({ commit }, dimension) {
      commit('removeDimensionToPriceFloor', dimension);
    },
    selectPriceFloorToEdit({ commit }, pf) {
      if (pf === null) {
        commit('setEditPriceFloor', null);
        return;
      }
      commit('setEditPriceFloor', pf);
    },
    async fetchReference({ state }, reference_name) {
      const endpoint = generateFloorApiEndpoint(
        FloorApiEndpoints.GetReference,
        state.product,
        state.publisher_code,
        { reference_name },
      );
      const response = await axios.get(endpoint);
      return response.data;
    },
    resolveConflict({ commit }) {
      commit('setConflict', { status: false });
    },
    async fetchServerVersion({ state }, floorId) {
      const endpoint = generateFloorApiEndpoint(
        FloorApiEndpoints.GetFloorVersion,
        state.product,
        state.publisher_code,
        { floor_id: floorId },
      );
      const response = await axios.get(endpoint);
      return response.data;
    },
    async mergeAndSaveFloorRules(
      { dispatch, commit, state },
      mergedData,
      { force = true } = {},
    ) {
      const endpoint = generateFloorApiEndpoint(
        FloorApiEndpoints.Floors,
        state.product,
        state.publisher_code,
      );

      try {
        await axios.post(endpoint, {
          price_floors: [mergedData],
          force,
        });
        await dispatch('fetchFloorRules');
      } catch (error) {
        if (error.response?.status === 409 && !force) {
          commit('setConflict', {
            status: true,
            message: error.response.data,
          });
          return;
        }
        throw error;
      }
    },
  },
};

export default store;
