<template>
  <div>
    <left-side-menu v-if="!isExtern()" />
    <extern-left-side-menu v-else />
    <v-main>
      <o-container fluid>
        <router-view />
      </o-container>
    </v-main>
    <warnings-dialog />
  </div>
</template>

<script>
import LeftSideMenu from '../components/LeftSideMenu.vue';
import WarningsDialog from '../components/DesignSystem/ODialog/WarningsDialog.vue';
import { mapGetters } from 'vuex';
import ExternLeftSideMenu from '@/components/Extern/ExternLeftSideMenu.vue';

export default {
  name: 'Layout',
  components: {
    ExternLeftSideMenu,
    LeftSideMenu,
    WarningsDialog,
  },
  computed: {
    ...mapGetters('context', ['isUserExtern']),
  },
  methods: {
    isExtern() {
      return this.isUserExtern;
    },
  },
};
</script>

<style scoped></style>
