import Vue from 'vue';
import Vuex from 'vuex';
import publishers from './modules/publishers';
import context from './modules/context';
import auditLog from './modules/auditLog';
import siteEntities from './modules/siteEntities';
import demandPartners from './modules/demandPartners';
import demandPartnerAccounts from './modules/demandPartnerAccounts';
import financialReporting from './modules/financialReporting';
import turnoverFinal from './modules/turnoverFinal';
import configLoaderRenderer from './modules/tagsManager/configLoaderRenderer';
import tagsManager from './modules/tagsManager/tagsManager';
import siteWebConfig from './modules/tagsManager/siteWebConfig';
import adslotWebConfig from './modules/tagsManager/adslotWebConfig';
import adslotWebBidder from './modules/tagsManager/adslotWebBidder';
import adUnits from './modules/tagsManager/adUnits';
import warnings from './modules/warnings';
import releasesCrusader from './modules/tagsManager/releasesCrusader';
import admin from './modules/admin';
import sellersJson from './modules/sellersJson';
import publisherDPACredentials from './modules/publisherDPACredentials';
import syncAdslotSSP from './modules/sspAutomator/syncAdslotSSP';
import revisionsDiff from './modules/tagsManager/revisionsDiff';
import adsTxt from './modules/adsTxt';
import paymentTerm from './modules/paymentTerm';
import priceFloorConfiguration from './modules/priceFloor/priceFloorConfiguration';
import revisionHistory from './modules/revisionHistory';
import payments from './modules/payments';
import abTests from './modules/abTests';
import priceFloorPublishers from './modules/priceFloor/priceFloorPublishers';
import priceFloorSettings from './modules/priceFloor/priceFloorSettings';
import product from './modules/product';
import countrySelector from './modules/tagsManager/countrySelector';
import floorRules from './modules/floorManager/floorRules';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    publishers,
    context,
    auditLog,
    siteEntities,
    demandPartners,
    demandPartnerAccounts,
    financialReporting,
    configLoaderRenderer,
    tagsManager,
    siteWebConfig,
    adslotWebConfig,
    adslotWebBidder,
    adUnits,
    turnoverFinal,
    warnings,
    admin,
    releasesCrusader,
    sellersJson,
    publisherDPACredentials,
    syncAdslotSSP,
    revisionsDiff,
    adsTxt,
    paymentTerm,
    priceFloorConfiguration,
    revisionHistory,
    payments,
    abTests,
    priceFloorPublishers,
    priceFloorSettings,
    product,
    countrySelector,
    floorRules,
  },
});

export default store;
