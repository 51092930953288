<template>
  <v-overlay
    v-bind="{
      ...$attrs,
      ...$props,
    }"
    v-on="$listeners"
    @change="$emit('input', $event)"
  >
    <v-progress-circular size="64" indeterminate color="primary" />
  </v-overlay>
</template>
<script>
export default {
  name: 'OSimpleLoader',
};
</script>
<style scoped></style>
