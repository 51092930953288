<template>
  <o-dialog
    v-model="show"
    persistent
    max-width="80%"
    @click:outside="close"
    @keydown.esc="close"
  >
    <o-card style="overflow-x: hidden">
      <o-row align="center">
        <o-col cols="auto">
          <o-card-title>
            <span class="headline">Opti Yield settings:</span>
          </o-card-title>
        </o-col>
        <o-spacer />
        <o-col cols="auto">
          <o-btn
            v-if="!readOnly"
            :disabled="!valid || getPublisherCountriesLoading"
            color="blue darken-1"
            text
            @click="save"
          >
            {{ $t('buttons.general.save') }}
          </o-btn>
          <o-btn color="blue darken-1" text @click="close">
            {{ $t('buttons.general.close') }}
          </o-btn>
        </o-col>
      </o-row>
      <o-card-text>
        <o-container>
          <o-form ref="form" v-model="valid" lazy-validation class="d-flex">
            <o-container style="width: 40%">
              <o-row no-gutters>
                <o-row style="width: 100%">
                  <o-col cols="12">
                    <o-card-title> Version: </o-card-title>
                  </o-col>
                  <o-col cols="10">
                    <o-autocomplete
                      :readonly="readOnly"
                      :filled="readOnly"
                      :items="getCalculatorVersions"
                      item-text="code"
                      label="Version"
                      :value="publisherInfo.publisher_calculator_version"
                      return-object
                      @change="updateVersion"
                    />
                  </o-col>
                </o-row>
                <o-row style="width: 100%">
                  <o-col cols="12">
                    <o-card-title> General: </o-card-title>
                  </o-col>
                  <o-col cols="10">
                    <o-text-field
                      v-model="publisherInfo.pf_data_studio_link"
                      :readonly="readOnly"
                      :filled="readOnly"
                      label="Data Studio Link Opti Yield"
                    />
                  </o-col>
                </o-row>
                <o-row style="width: 100%">
                  <o-col cols="12">
                    <o-card-title> Enabled pfunit auto: </o-card-title>
                  </o-col>
                  <o-col cols="10">
                    <o-switch
                      :input-value="getEnabledPfUnitAuto"
                      @change="updateEnabledPfUnitAuto"
                    >
                      <template #label>
                        <span class="o-switch-label-color"
                          >Warning: If you enable Pfunit-auto all pfunits of
                          this publisher will be this publisher will be
                          enabled</span
                        >
                      </template>
                    </o-switch>
                  </o-col>
                </o-row>
              </o-row>
              <o-row>
                <o-col cols="12">
                  <o-card-title> Currency management: </o-card-title>
                </o-col>
                <o-row style="width: 100%">
                  <o-col cols="10">
                    <o-autocomplete
                      v-model="getPublisherCurrency.gam_currency"
                      :readonly="readOnly"
                      :filled="readOnly"
                      :items="getAllCurrencies"
                      label="Currency used in the GAM"
                      return-object
                      @change="
                        (item) => {
                          getPublisherCurrency.gam_currency_id = item.id;
                        }
                      "
                    />
                  </o-col>
                </o-row>
                <o-row style="width: 100%">
                  <o-col cols="10">
                    <o-autocomplete
                      v-model="getPublisherCurrency.prebid_currency"
                      :readonly="readOnly"
                      :filled="readOnly"
                      :items="getAllCurrencies"
                      label="Currency used in Prebid"
                      return-object
                      @change="
                        (item) => {
                          getPublisherCurrency.prebid_currency_id = item.id;
                        }
                      "
                    />
                  </o-col>
                </o-row>
                <o-row style="width: 100%">
                  <o-col cols="10">
                    <o-autocomplete
                      v-model="getPublisherCurrencyDashboard"
                      :readonly="readOnly"
                      :filled="readOnly"
                      :items="getAllCurrencies"
                      item-text="code"
                      item-value="code"
                      label="Currency Dashboard"
                      return-object
                      @change="
                        (item) => {
                          setPublisherCurrencyDashboard(item.code);
                        }
                      "
                    />
                  </o-col>
                </o-row>
                <o-row style="width: 100%">
                  <o-col cols="10">
                    <o-text-field
                      value="USD"
                      label="Currency used in Amazon"
                      disabled
                    />
                  </o-col>
                </o-row>
                <o-row style="width: 100%">
                  <o-col cols="10">
                    <o-text-field
                      value="EUR"
                      label="Currency used in reporting"
                      disabled
                    />
                  </o-col>
                </o-row>
              </o-row>
            </o-container>
            <loading-overlay :overlay="getPublisherCountriesLoading">
              <template #default>
                <o-container style="width: 75%; height: auto">
                  <o-row style="width: 100%">
                    <o-col cols="12">
                      <o-card-title> Country management: </o-card-title>
                    </o-col>

                    <o-row
                      class="rounded countryContainerBorders"
                      style="width: 100%"
                    >
                      <o-col
                        cols="6"
                        class="pa-0 countryContainerBorderRight"
                        style="width: 100%"
                      >
                        <o-container
                          class="countryContainerBorderBottom countryContainerRowHeight"
                          style="width: 100%"
                        >
                          <o-text-field
                            v-model="search"
                            flat
                            solo
                            type="text"
                            class="ma-0 pa-0"
                            hide-details
                            append-icon="mdi-magnify"
                            label="Search"
                          />
                        </o-container>
                        <o-container
                          class="pa-2 countryContainerBorderBottom countryContainerRowHeight"
                          style="width: 100%"
                        >
                          <o-card-title
                            v-if="
                              getAllCountries.length != 0 &&
                              !getPublisherCountriesLoading
                            "
                            class="font-weight-bold text-body-2"
                          >
                            {{ searchCountry.length }} items
                          </o-card-title>
                          <o-card-title
                            v-else
                            class="font-weight-bold text-body-2"
                          >
                            No items
                          </o-card-title>
                        </o-container>
                        <o-container
                          class="pt-1 pb-1 overflow-x-hidden"
                          style="height: 376px"
                        >
                          <o-list
                            v-if="
                              getAllCountries.length != 0 &&
                              !getPublisherCountriesLoading
                            "
                            flat
                          >
                            <o-list-item-group>
                              <o-list-item
                                v-for="item in searchCountry"
                                :key="item.id"
                                :title="item.name"
                                class="pb-0 text-body-2 font-weight-regular"
                                @click="addCountry(item)"
                              >
                                {{ capitalize(item.name) }}
                              </o-list-item>
                            </o-list-item-group>
                          </o-list>
                        </o-container>
                      </o-col>

                      <o-col cols="6" class="pa-0" style="width: 100%">
                        <o-container
                          class="pa-2 countryContainerBorderBottom countryContainerRowHeight"
                          style="width: 100%"
                        >
                          <o-row>
                            <o-col cols="6">
                              <o-card-title
                                v-if="
                                  getPublisherCountries &&
                                  getPublisherCountries.countries &&
                                  getPublisherCountries.countries.length > 0 &&
                                  !getPublisherCountriesLoading
                                "
                                class="font-weight-bold text-body-2"
                              >
                                {{ getPublisherCountries.countries.length }}
                                items
                              </o-card-title>
                              <o-card-title
                                v-else
                                class="font-weight-bold text-body-2"
                              >
                                None selected
                              </o-card-title>
                            </o-col>
                            <o-col cols="6" class="pt-5" align="right">
                              <o-btn
                                v-model="setAllCountries"
                                class="text-capitalize"
                                color="black"
                                plain
                                @click="deleteAllCountries"
                              >
                                Clear All
                              </o-btn>
                            </o-col>
                          </o-row>
                        </o-container>
                        <o-container
                          class="pt-1 pb-1 overflow-x-hidden"
                          style="height: 442px"
                        >
                          <o-list class="pt-1 pb-1" flat>
                            <o-list-item-group
                              v-for="(item, index) in orderPublisherCountries"
                              :key="item.name"
                            >
                              <o-list-item
                                v-if="
                                  getPublisherCountries &&
                                  getPublisherCountries.countries &&
                                  getPublisherCountries.countries.length > 0 &&
                                  !getPublisherCountriesLoading
                                "
                                :key="index"
                                :title="item.name"
                                class="pb-0 text-body-2 font-weight-regular"
                                return
                                obj
                                @click="removeCountry(index, item)"
                              >
                                {{ capitalize(item.name) }}
                              </o-list-item>
                              <o-list-item
                                v-else-if="
                                  !getPublisherCountries.countries.length
                                "
                                class="pa-2 text-body-1 font-weight-regular"
                              >
                                No countries selected.
                              </o-list-item>
                            </o-list-item-group>
                          </o-list>
                        </o-container>
                      </o-col>
                    </o-row>
                  </o-row>
                </o-container>
              </template>
            </loading-overlay>
          </o-form>
        </o-container>
      </o-card-text>
      <o-card-actions>
        <o-spacer />
        <o-btn
          v-if="!readOnly"
          :disabled="!valid || getPublisherCountriesLoading"
          color="blue darken-1"
          text
          @click="save"
        >
          {{ $t('buttons.general.save') }}
        </o-btn>
        <o-btn color="blue darken-1" text @click="close">
          {{ $t('buttons.general.close') }}
        </o-btn>
      </o-card-actions>
    </o-card>
  </o-dialog>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { baseValidation } from '../../../validation/baseValidation';
import { validationHelper } from '../../../validation/validationHelper';
import { publisherValidation } from '../../../validation/publisherValidation';
import LoadingOverlay from '@/components/DesignSystem/OLoader/LoadingOverlay.vue';

export default {
  name: 'PriceFloorPublisherDialog',
  components: { LoadingOverlay },
  mixins: [baseValidation, validationHelper, publisherValidation],
  props: ['publisherInfo', 'closeCallback'],
  data() {
    return {
      options: {},
      show: false,
      readOnly: false,
      valid: true,
      search: '',
    };
  },
  watch: {
    getAllCountries(newVal) {
      this.setAllCountries = newVal;
    },
    getPublisherCountries(newVal) {
      this.countriesList();
      this.setPublisherCountries = newVal;
    },
  },
  mounted() {
    this.fetchCalculatorVersions();
    this.fetchAllCurrencies();
  },
  computed: {
    searchCountry() {
      this.orderCountries(this.getAllCountries);
      return this.getAllCountries.filter((country) => {
        return country.name.toLowerCase().includes(this.search.toLowerCase());
      });
    },
    getEnabledPfUnitAuto() {
      if (typeof this.publisherInfo.enabled_pfunit_auto === 'boolean') {
        return this.publisherInfo.enabled_pfunit_auto;
      }
      return false;
    },
    orderPublisherCountries() {
      if (this.getPublisherCountries.countries) {
        this.orderCountries(this.getPublisherCountries.countries);
      }
      return this.getPublisherCountries.countries;
    },
    ...mapGetters('priceFloorPublishers', [
      'getCalculatorVersions',
      'getAllCurrencies',
      'getPublisherCurrency',
      'getAllCountries',
      'getPublisherCountries',
      'getLoading',
      'getPublisherCurrencyDashboard',
      'getPublisherCountriesLoading',
    ]),
  },
  methods: {
    ...mapActions('priceFloorPublishers', [
      'updatePublisher',
      'createPublisher',
      'fetchCalculatorVersions',
      'fetchAllCurrencies',
      'updatePublisherCurrency',
      'updatePublisherCurrencyDashboard',
      'updatePublisherCountries',
    ]),
    ...mapMutations('priceFloorPublishers', [
      'setAllCountries',
      'setPublisherCountries',
      'setPublisherCurrencyDashboard',
    ]),
    close() {
      this.closeCallback();
      this.search = '';
    },
    async save() {
      if (this.$refs.form.validate()) {
        const currencyObj = {
          gam_currency_id: this.getPublisherCurrency.gam_currency_id,
          prebid_currency_id: this.getPublisherCurrency.prebid_currency_id,
          network_id: this.publisherInfo.network_id,
        };

        const countriesList = [];

        for (let i = 0; i < this.getPublisherCountries.countries.length; i++) {
          const objElement = {
            gam_id: this.getPublisherCountries.countries[i].gam_id,
          };
          countriesList.push(objElement);
        }
        const publisher_countries = {
          network_id: this.publisherInfo.network_id,
          publisher_code: this.publisherInfo.name,
          countries: countriesList,
        };

        this.updatePublisher(this.publisherInfo);
        this.updatePublisherCurrency(currencyObj);
        this.updatePublisherCurrencyDashboard({
          pub_name: this.publisherInfo.name,
          currency: this.getPublisherCurrencyDashboard,
        });
        await this.updatePublisherCountries(publisher_countries);
        this.close();
      }
    },
    async countriesList() {
      if (
        this.getAllCountries &&
        this.getPublisherCountries.countries &&
        this.getAllCountries.length > 0 &&
        this.getPublisherCountries.countries.length > 0 &&
        this.getAllCountries.length !==
          this.getPublisherCountries.countries.length
      ) {
        for (let i = this.getAllCountries.length - 1; i >= 0; i--) {
          if (this.getAllCountries !== undefined) {
            for (
              let k = 0;
              k < this.getPublisherCountries.countries.length;
              k++
            ) {
              if (
                this.getAllCountries[i] !== undefined &&
                this.getAllCountries[i].gam_id ===
                  this.getPublisherCountries.countries[k].gam_id
              ) {
                await this.getAllCountries.splice(i, 1);
              }
            }
          }
        }
      }

      if (
        this.getPublisherCountries.countries.length > 0 &&
        this.getAllCountries.length ===
          this.getPublisherCountries.countries.length
      ) {
        this.getAllCountries.length = 0;
      }

      if (this.getPublisherCountries.publisher_code === null) {
        return;
      }
    },
    async addCountry(item) {
      const objWithIdIndex = this.getAllCountries.findIndex(
        (obj) => obj.gam_id === item.gam_id,
      );
      const newCountry = {
        name: this.formatCountryName(item.name),
        gam_id: item.gam_id,
      };

      await this.getPublisherCountries.countries.push(newCountry);
      await this.getAllCountries.splice(objWithIdIndex, 1);
      this.search = '';
    },
    async removeCountry(index, item) {
      this.getPublisherCountries.countries.splice(index, 1);
      const newCountry = {
        name: item.name.toLowerCase(),
        gam_id: item.gam_id,
      };
      await this.getAllCountries.push(newCountry);
    },
    async deleteAllCountries() {
      await this.getPublisherCountries.countries.forEach((country) => {
        this.getAllCountries.push(country);
      });
      this.getPublisherCountries.countries = [];
      await this.getAllCountries;
    },
    updateVersion(item) {
      if (item.id !== '') {
        this.publisherInfo.calculator_version = item.id;
      } else {
        this.publisherInfo.calculator_version = null;
      }
    },
    updateEnabledPfUnitAuto() {
      if (typeof this.publisherInfo.enabled_pfunit_auto === 'boolean') {
        this.publisherInfo.enabled_pfunit_auto =
          !this.publisherInfo.enabled_pfunit_auto;
      }
    },
    formatCountryName(name) {
      return name[0].toUpperCase() + name.slice(1).toLowerCase();
    },
    orderCountries(countries_list) {
      return countries_list.sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
      );
    },
    capitalize(countryName) {
      return countryName.toUpperCase();
    },
  },
};
</script>

<style>
.countryContainerBorders {
  border: 1px solid #bcbcbc;
}
.countryContainerBorderRight {
  border-right: 1px solid #bcbcbc;
}
.countryContainerBorderBottom {
  border-bottom: 1px solid #bcbcbc;
}
.countryContainerRowHeight {
  max-height: 65px;
}
.v-switch-label-color {
  color: red;
}
</style>
