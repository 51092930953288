<template>
  <o-card>
    <o-row align="center">
      <o-col cols="auto">
        <o-card-title v-if="!hideControl">
          <span v-if="!readOnly && isSiteNew" class="headline"
            >{{ $t('buttons.sites.add_site') }}:</span
          >
          <span v-else-if="readOnly && !isSiteNew" class="headline"
            >{{ $t('buttons.sites.show_site') }}:</span
          >
          <span v-else class="headline"
            >{{ $t('buttons.sites.edit_site') }}:</span
          >
        </o-card-title>
      </o-col>
      <o-spacer />
      <o-col v-if="!hideControl" cols="auto">
        <o-btn
          v-if="!readOnly"
          :disabled="!valid"
          color="blue darken-1"
          text
          @click="save"
        >
          {{ $t('buttons.general.save') }}
        </o-btn>
        <o-btn color="blue darken-1" text @click="close">
          {{ $t('buttons.general.close') }}
        </o-btn>
      </o-col>
    </o-row>
    <o-card-text>
      <o-container>
        <o-form ref="form" v-model="valid" lazy-validation>
          <o-row v-if="!isSiteNew && !isExtern">
            <o-col>
              <o-btn text color="primary" @click="openConfigDialog">
                Media <o-icon>mdi-video-account</o-icon>
              </o-btn>
            </o-col>
          </o-row>
          <o-row v-if="multiplePublishers" justify="center">
            <o-col cols="12">
              <o-card-title> {{ $t('sites.publisher_select') }}: </o-card-title>
            </o-col>
            <o-col class="d-flex" cols="12" sm="6">
              <o-select
                v-model="editedSite.publisher_id"
                :readonly="readOnly"
                :filled="readOnly"
                :items="getPublishers"
                item-text="name"
                item-value="id"
                label="sites.select_label"
                :disabled="disablePublisherSelect"
                :rules="createRules([baseValidation.required])"
                @change="changePublisher"
              />
            </o-col>
          </o-row>
          <o-row>
            <o-col cols="12">
              <o-card-title> {{ $t('sites.general') }}: </o-card-title>
            </o-col>
            <o-col cols="12" sm="6" md="4">
              <o-text-field
                v-model="editedSite.name"
                :readonly="readOnly"
                :filled="readOnly"
                label="Name"
                :rules="createRules([baseValidation.required])"
                @change="trimName"
              />
            </o-col>
            <o-col cols="12" sm="6" md="4">
              <o-text-field
                v-model="editedSite.url"
                :readonly="readOnly"
                :filled="readOnly"
                label="URL"
                :rules="
                  createRules([baseValidation.required, baseValidation.domain])
                "
                @change="propagateUrlToGamName"
              />
            </o-col>
          </o-row>
          <o-row>
            <o-col class="d-flex" cols="auto" sm="6" md="6">
              <o-switch
                v-model="editedSite.is_external"
                :filled="readOnly"
                :readonly="readOnly"
                label="External Site"
              />
            </o-col>
            <o-col>
              <o-text-field
                v-model="editedSite.gam_ad_unit_name"
                dense
                underlined
                :readonly="is_gam_ad_unit_name_editable"
                label="GAM adUnit name"
                :loading="getLoading"
              />
            </o-col>
            <o-col>
              <o-select
                v-model="schain"
                :readonly="readOnly"
                :disabled="isSiteNew"
                :filled="readOnly"
                :items="[
                  {
                    t: 'Opti digital',
                    v: 'optidigital',
                  },
                  {
                    t: 'Direct',
                    v: 'direct',
                  },
                ]"
                label="web_setting.label.schain"
                description="web_setting.help.schain"
                @change="changeSchain"
              />
            </o-col>
          </o-row>
          <o-row>
            <o-col v-if="isAllowedConfigure()" cols="12" sm="6" md="4">
              <o-text-field
                v-model.number="editedSite.odas_site_id"
                readonly
                :filled="readOnly"
                label="ODAS Site ID"
                :rules="createRules([baseValidation.nullable_integer])"
              />
            </o-col>
            <o-col cols="auto">
              <o-autocomplete
                v-model="editedSite.odas_site_category_id"
                :readonly="readOnly"
                :items="getIabCategoryList"
                :filled="readOnly"
                label="Site Category"
                :rules="createRules([baseValidation.required])"
              >
                <template #append-outer>
                  <o-icon
                    :disabled="readOnly"
                    icon
                    @click="editedSite.odas_site_category_id = null"
                  >
                    delete
                  </o-icon>
                </template>
              </o-autocomplete>
            </o-col>
          </o-row>

          <o-row v-if="isAllowedConfigure()">
            <o-col cols="12">
              <o-card-title> Technical:</o-card-title>
            </o-col>
            <o-col class="d-flex" cols="auto" sm="6" md="6">
              <o-text-field
                v-model="editedSite.site_code"
                :filled="readOnly"
                :readonly="readOnly"
                label="Site code"
              />
            </o-col>
            <o-col class="d-flex" cols="auto" sm="6" md="6">
              <o-select
                v-model="editedSite.sellers_json_visible"
                :readonly="readOnly"
                :filled="readOnly"
                :items="sellersJsonStatuses"
                item-text="name"
                item-value="value"
                label="Sellers Status"
                :rules="createRules([baseValidation.required])"
              />
            </o-col>
            <o-col v-if="isAdmin" class="d-flex" cols="auto" sm="6" md="6">
              <o-switch
                v-model="editedSite.tm_deploy_enabled"
                :filled="readOnly"
                :readonly="readOnly"
                label="TagManager - enable push live"
              />
            </o-col>
            <o-col>
              <o-select
                v-model="editedSite.site_config_renderer_version"
                :readonly="readOnly"
                :filled="readOnly"
                :items="tagManagerRendererVersions"
                item-text="name"
                item-value="value"
                label="Tag Manager renderer version"
                :rules="createRules([baseValidation.required])"
              />
            </o-col>
          </o-row>
        </o-form>
      </o-container>
    </o-card-text>
    <site-config-dialog
      ref="site_config_dialog"
      :edited-index="editedSite.id"
      :close-config-dialog="closeConfigDialog"
      :config-dialog="configDialog"
    />
  </o-card>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import store from '@/store';
import { baseValidation } from '@/validation/baseValidation';
import { validationHelper } from '@/validation/validationHelper';
import { eventBus } from '@/main';
import SiteConfigDialog from '@/components/Accounting/Sites/SiteConfigDialog.vue';

export default {
  name: 'SiteDialog',
  components: { SiteConfigDialog },
  mixins: [baseValidation, validationHelper],
  props: {
    editedSite: {
      type: Object,
      required: true,
    },
    isSiteNew: {
      type: Boolean,
      required: true,
    },
    readOnly: {
      type: Boolean,
      required: true,
    },
    close: {
      type: Function,
      default: () => {},
    },
    disablePublisherSelect: {
      type: Boolean,
      default: false,
    },
    hideControl: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      valid: true,
      datePicker: false,
      schain: 'optidigital',
      configDialog: false,
    };
  },
  mounted() {
    this.fetchIabCategories();
    if (!this.disablePublisherSelect) {
      store.dispatch('publishers/fetchEditablePublisherName');
    }
    eventBus.$on('tm-update-web-settings-model', this.updateSchain);

    this.schain = this.getSchainSetting;
    eventBus.$on('tm-save-site-form', () => {
      this.save();
      setTimeout(
        () => store.commit('tagsManager/setLoadingSiteForm', false),
        500,
      );
    });
  },
  computed: {
    ...mapGetters('publishers', [
      'getLoading',
      'getPublishers',
      'getDefaultSchain',
    ]),
    ...mapGetters('siteEntities', ['getIabCategoryList']),
    ...mapGetters('context', ['getUserProfile', 'isUserExtern']),
    ...mapGetters('siteWebConfig', ['getSchainSetting']),
    multiplePublishers() {
      return this.getPublishers?.length > 1;
    },
    isAdmin() {
      return this.getUserProfile.role === 'admin';
    },
    is_gam_ad_unit_name_editable() {
      return !this.readOnly && !this.editedSite.is_external;
    },
    isExtern() {
      return this.isUserExtern;
    },
    sellersJsonStatuses() {
      return [
        { name: 'Visible', value: 'VISIBLE' },
        { name: 'Obfuscated', value: 'OBFUSCATED' },
      ];
    },
    tagManagerRendererVersions() {
      return [
        { name: 'Launcher V2', value: 2 },
        { name: 'Launcher V3', value: 3 },
      ];
    },
  },
  methods: {
    ...mapActions('siteEntities', [
      'updateSiteEntities',
      'createSiteEntities',
      'fetchIabCategories',
    ]),
    ...mapActions('publishers', [
      'fetchEditablePublishers',
      'fetchDefaultSchain',
    ]),
    ...mapMutations('siteWebConfig', ['setSchainSetting']),
    save() {
      this.setSchainSetting(this.schain);
      this.editedSite.site_config_renderer_version ??= 3;
      this.editedSite.start_date ??= new Date().toISOString().split('T')[0];
      this.editedSite.sellers_json_visible ??= 'OBFUSCATED';

      if (this.$refs.form?.validate?.()) {
        if (this.editedSite.odas_site_id === '') {
          this.editedSite.odas_site_id = null;
        }
        if (!this.isSiteNew) {
          this.updateSiteEntities(this.editedSite);
        } else {
          this.createSiteEntities(this.editedSite);
        }
        this.close();
      }
    },
    closeConfigDialog() {
      this.configDialog = false;
      store.dispatch('siteEntities/fetchSiteEntities');
    },
    openConfigDialog() {
      this.configDialog = true;
    },
    trimName() {
      this.editedSite.name = this.editedSite.name.trim();
    },
    propagateUrlToGamName() {
      if (this.isSiteNew && this.editedSite.url && this.editedSite.url !== '') {
        this.editedSite.url = this.editedSite.url.trim();
        const clean_url = this.editedSite.url
          .replace('http://', '')
          .replace('https://', '');
        const rootDomain = new URL('https://' + clean_url).hostname.replace(
          'www.',
          '',
        );
        this.editedSite.gam_ad_unit_name = 'od.' + rootDomain;
      }
    },
    isAllowedConfigure() {
      if (!this.isUserExtern) {
        return true;
      }

      if (this.getPublishers?.length === 1) {
        return this.getPublishers[0]?.allowExternConfigure ?? false;
      }

      const publisher = this.getPublishers.find(
        ({ id }) => id === this.editedSite?.publisher_id,
      );
      return publisher?.allowExternConfigure ?? false;
    },
    updateSchain() {
      this.schain = this.getSchainSetting;
    },
    changeSchain() {
      this.setSchainSetting(this.schain);
    },
    async changePublisher() {
      if (this.isSiteNew && this.editedSite?.publisher_id) {
        await this.fetchDefaultSchain(this.editedSite?.publisher_id);
        this.setSchainSetting(this.getDefaultSchain);
        this.updateSchain();
      }
    },
  },
};
</script>

<style scoped></style>
