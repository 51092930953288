<template>
  <loading-overlay :overlay="loading">
    <template #default>
      <o-row class="rounded countryContainerBorders ma-0" style="width: 100%">
        <o-col
          cols="6"
          class="pa-0 countryContainerBorderRight"
          style="width: 100%"
        >
          <o-container
            class="countryContainerBorderBottom countryContainerRowHeight"
            style="width: 100%"
          >
            <o-text-field
              v-model="search"
              flat
              solo
              type="text"
              class="ma-0 pa-0"
              hide-details
              append-icon="mdi-magnify"
              label="Search"
            />
          </o-container>
          <o-container
            class="pa-2 countryContainerBorderBottom countryContainerRowHeight"
            style="width: 100%"
          >
            <o-row>
              <o-col>
                <o-card-title
                  v-if="countriesRegistry.length !== 0 && !loading"
                  class="font-weight-bold text-body-2"
                >
                  {{ searchCountry.length }} items
                </o-card-title>
                <o-card-title v-else class="font-weight-bold text-body-2">
                  No items
                </o-card-title>
              </o-col>
              <o-col align-self="center" align="right">
                <o-btn
                  v-model="countriesRegistry"
                  class="text-capitalize"
                  color="primary"
                  plain
                  @click="includeAllCountries"
                >
                  <o-icon small> mdi-check </o-icon>
                  Include All
                </o-btn>
              </o-col>
            </o-row>
          </o-container>
          <o-container
            class="pt-1 pb-1 overflow-x-hidden"
            style="height: 376px"
          >
            <o-list v-if="countriesRegistry.length !== 0 && !loading" flat>
              <o-list-item-group>
                <o-list-item
                  v-for="item in searchCountry"
                  :key="item.id"
                  :title="item.name"
                  class="pb-0 text-body-2 font-weight-regular"
                  @click="addCountry(item)"
                >
                  <o-icon v-if="item.name in (value ?? [])" small>
                    mdi-check-circle
                  </o-icon>
                  {{ capitalize(item.name) }}
                </o-list-item>
              </o-list-item-group>
            </o-list>
          </o-container>
        </o-col>

        <o-col cols="6" class="pa-0" style="width: 100%">
          <o-container
            class="pa-2 countryContainerBorderBottom countryContainerRowHeight"
            style="width: 100%"
          >
            <o-row>
              <o-col cols="6">
                <o-card-title
                  v-if="value?.length > 0 && !loading"
                  class="font-weight-bold text-body-2"
                >
                  {{ value.length }}
                  items
                </o-card-title>
                <o-card-title v-else class="font-weight-bold text-body-2">
                  None selected
                </o-card-title>
              </o-col>
              <o-col cols="6" class="pt-5" align="right">
                <o-btn
                  class="text-capitalize"
                  color="black"
                  plain
                  @click="deleteAllCountries"
                >
                  Clear All
                </o-btn>
              </o-col>
            </o-row>
          </o-container>
          <o-container
            class="pt-1 pb-1 overflow-x-hidden"
            style="height: 442px"
          >
            <o-list class="pt-1 pb-1" flat>
              <o-list-item-group
                v-for="(item, index) in orderPublisherCountries"
                :key="item.name"
              >
                <o-list-item
                  v-if="value.length > 0 && !loading"
                  :key="index"
                  :title="item.name"
                  class="pb-0 text-body-2 font-weight-regular"
                  return
                  obj
                  @click="removeCountry(index)"
                >
                  {{ capitalize(item.name) }}
                </o-list-item>
              </o-list-item-group>
            </o-list>
          </o-container>
        </o-col>
      </o-row>
    </template>
  </loading-overlay>
</template>
<script>
import LoadingOverlay from '@/components/DesignSystem/OLoader/LoadingOverlay.vue';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'CountrySelector',
  components: { LoadingOverlay },
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    value: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  emits: ['change', 'input'],
  data() {
    return { search: '' };
  },
  computed: {
    ...mapGetters('publishers', ['getAllCountries']),
    orderPublisherCountries() {
      if (this.value) {
        this.orderCountries(this.value);
      }
      return this.value;
    },
    searchCountry() {
      this.orderCountries(this.countriesRegistry);
      return this.countriesRegistry.filter((country) => {
        return country.name.toLowerCase().includes(this.search.toLowerCase());
      });
    },
    countriesRegistry: {
      get() {
        const avoid_cc = this.value.map(({ country_code }) => country_code);
        return this.getAllCountries.filter(
          ({ country_code }) => !avoid_cc.includes(country_code),
        );
      },
      set() {
        /* do nothing */
      },
    },
  },
  mounted() {
    if (this.value.length === this.countriesRegistry?.length) {
      this.onChange([]);
    }
  },
  methods: {
    ...mapMutations('publishers', ['setAllCountries']),
    orderCountries(countries_list) {
      return countries_list.sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
      );
    },
    async deleteAllCountries() {
      if (this.value?.length > 0) {
        this.onChange([]);
      }
    },
    includeAllCountries() {
      this.onChange([...this.countriesRegistry]);
    },
    addCountry(item) {
      this.onChange([
        ...this.value,
        {
          name: this.formatCountryName(item.name),
          gam_id: item.gam_id,
          country_code: item.country_code,
        },
      ]);

      this.search = '';
    },
    removeCountry(index) {
      const { [index]: _, ...keep } = this.value;
      this.onChange(Object.values(keep).filter((v) => v));
    },
    capitalize(str) {
      return str?.toUpperCase?.();
    },
    formatCountryName([firstLetter, ...tail]) {
      return [
        firstLetter.toUpperCase(),
        ...tail.map((w) => w.toLowerCase()),
      ].join('');
    },
    onChange(value) {
      console.log(value);
      this.$emit('input', value);
      this.$emit('change', value);
    },
  },
};
</script>
<style scoped>
.countryContainerBorders {
  border: 1px solid #bcbcbc;
}
.countryContainerBorderRight {
  border-right: 1px solid #bcbcbc;
}
.countryContainerBorderBottom {
  border-bottom: 1px solid #bcbcbc;
}
.countryContainerRowHeight {
  max-height: 65px;
}
</style>
