<template>
  <file-diff-tool />
</template>

<script>
import FileDiffTool from '../../components/DesignSystem/OFile/FileDiffTool.vue';

export default {
  name: 'FileDiffPage',
  components: { FileDiffTool },
  data() {
    return {};
  },
  mounted() {},
};
</script>

<style scoped></style>
