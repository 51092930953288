<template>
  <o-card height="100%" min-height="90vh">
    <o-card-title>
      <o-row>
        <o-col cols="auto">
          {{ $t('nav_menu.floor_manager') }}
        </o-col>
        <o-col cols="auto">
          <o-btn
            v-if="isAbleToEdit"
            color="primary"
            @click="openCreatePriceFloor"
          >
            New pricing rule
          </o-btn>
        </o-col>
        <o-col>
          <o-btn
            v-if="isAbleToEdit"
            color="success"
            :loading="pending_push"
            @click="onPush"
          >
            <icon-tooltip
              :content="$t('floor_manager.label.push')"
              :description="
                $tc('floor_manager.help.push', isExtern ? 1 : 0, {
                  target: publisher.name,
                })
              "
            />
          </o-btn>
        </o-col>
        <o-col v-if="!isExtern" cols="2">
          <o-autocomplete
            v-model="publisher"
            :items="publishersList"
            item-text="name"
            item-value="publisher_code"
            return-object
            label="floor_manager.label.publisher"
            description="floor_manager.help.publisher"
            @change="onPublisherChange"
          />
        </o-col>
      </o-row>
    </o-card-title>
    <o-card-text>
      <o-row>
        <o-col>
          <o-combobox
            ref="search"
            v-model="filter.query"
            outlined
            dense
            hide-details
            label="Search"
            placeholder="Looking for something..."
            style="width: 50%"
            append-icon=""
            :items="filter.items"
            item-text="name"
            item-value="id"
            chips
            multiple
            small-chips
            deletable-chips
            clearable
            :disabled="$refs.dimension_card?.hasChanged"
            @change="onChange"
            @focus="hideCriteriaOpts"
            @keydown.tab="onTabAutocomplete"
          >
            <template #item="{ item, props }">
              <o-list-item v-bind="props" @click="showCriteriaOpts(item)">
                {{ item.name }}
              </o-list-item>
            </template>
          </o-combobox>
          <o-card
            v-if="filter.open"
            style="width: 15%; position: absolute; z-index: 100"
          >
            <o-card-title>
              <o-row>
                <o-col>
                  {{ filter.field }}
                </o-col>
                <o-col align="right">
                  <o-btn icon>
                    <o-icon @click="hideCriteriaOpts">close</o-icon>
                  </o-btn>
                </o-col>
              </o-row>
            </o-card-title>
            <o-card-text class="d-flex flex-column">
              <o-select
                v-model="filter.ops"
                :items="filter.operators"
                placeholder="Selectionner"
              />
              <o-text-field
                v-model="filter.value"
                @keydown.enter="applyFilter"
              />
              <o-btn
                text
                small
                color="primary"
                class="flex-row-reverse"
                @click="applyFilter"
              >
                Apply
              </o-btn>
            </o-card-text>
          </o-card>
        </o-col>
      </o-row>
      <o-row>
        <div :style="{ width: `${dimension ? dividerPosition : 100}%` }">
          <floor-table
            ref="floor_table"
            :price_floor="getFrozenPriceFloors"
            :name_filter="filter.pf_name_filter"
            :is-able-to-edit="isAbleToEdit"
            :width-rate="dimension ? dividerPosition : 100"
            @select_price_floor="confirmCloseDimension"
          />
        </div>
        <div
          v-if="dimension"
          :class="{
            'resize-bar': true,
            disapear: dividerPosition > 80,
          }"
          :style="{
            left: `${dividerPosition}%`,
          }"
          @mousedown.prevent="startDragging"
          @mouseover.prevent
        />
        <div
          v-if="dimension"
          :class="{
            disapear: dividerPosition > 80,
          }"
          :style="{ width: ` ${99 - dividerPosition}%` }"
        >
          <dimension-card
            ref="dimension_card"
            :dimensions="sorted_dimensions"
            style="max-height: 70vh"
            :is-able-to-edit="isAbleToEdit"
          />
        </div>
      </o-row>
    </o-card-text>
    <o-card-actions>
      <o-col class="d-flex flex-row-reverse">
        <template v-if="getLastConfigPushed">
          Last push: {{ getLastConfigPushed }} | Rules amount:
          {{ getPriceFloors.filter((pf) => pf.status).length }}
        </template>
      </o-col>
    </o-card-actions>
    <o-dialog
      v-model="create_price_floor"
      fullscreen
      transition="slide-x-reverse-transition"
      @keydown.esc="cancelCreatePriceFloor"
    >
      <o-card class="d-flex flex-row-reverse opacity">
        <dimension-card
          :dimensions="dimension"
          style="min-width: 70vw; max-width: 70vw"
          :on-cancel="cancelCreatePriceFloor"
          :on-post-save="closeCreatePriceFloor"
          :is-able-to-edit="isAbleToEdit"
        />
        <o-col @click="cancelCreatePriceFloor" />
      </o-card>
    </o-dialog>
    <conflict-dialog />
    <o-dialog
      v-model="confirm_close.dialog"
      persistent
      width="300"
      @click:outside="stayOnDimension"
      @keydown.esc="stayOnDimension"
    >
      <o-card>
        <o-card-title> Confirmation </o-card-title>
        <o-card-text>
          Changes detected without save. If you leave changes will be saved
          automatically, are you sure to leave ?
        </o-card-text>
        <o-card-actions>
          <o-btn text @click="stayOnDimension">Stay</o-btn>
          <o-btn text @click="saveAndLeaveDimension">Leave</o-btn>
        </o-card-actions>
        <o-card-actions>
          <o-btn text color="warning" @click="resetAndLeaveDimension">
            Leave without save
          </o-btn>
        </o-card-actions>
      </o-card>
    </o-dialog>
  </o-card>
</template>

<script>
import FloorTable from '@/components/FloorManagement/FloorTable.vue';
import DimensionCard from '@/components/FloorManagement/DimensionCard.vue';
import IconTooltip from '@/components/DesignSystem/OTooltip/IconTooltip.vue';
import ConflictDialog from '@/components/DesignSystem/ODialog/ConflictDialog.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import store from '@/store';
import { eventBus } from '@/main';

export default {
  name: 'FloorManager',
  components: { IconTooltip, DimensionCard, FloorTable, ConflictDialog },
  props: {
    product: {
      type: Number,
      default: 1,
    },
  },
  emits: ['cancel_select_price_floor'],
  async mounted() {
    await store.dispatch('context/fetchContext');
    void store.dispatch('publishers/fetchAllCountries');
    store.commit('floorRules/setProduct', this.product);
    await store.dispatch('floorRules/fetchFloorPublisher');

    if (this.isExtern && (this.getPublisherList?.length ?? 0) === 0) {
      await this.$router.push({ name: 'FourOhFour' });
      return;
    }

    if (this.isExtern) {
      this.publisher = this.publishersList[0];
    }

    await this.initPriceFloorManagement({
      product: this.product,
      publisher_code: this.publisher?.publisher_code,
    });
    this.initial_state = window.structuredClone(this.getPriceFloors);
    const dimension_registry = this.getDimensions;
    dimension_registry.sort((a, b) => b.priority - a.priority);
    this.filter.items.push(...dimension_registry);
    if (store.getters['floorRules/getPriceFloorFilter']?.length > 0) {
      this.filter.query.push(
        ...store.getters['floorRules/getPriceFloorFilter'].split(';'),
      );
    }
  },
  computed: {
    ...mapGetters('floorRules', [
      'getPriceFloors',
      'getFrozenPriceFloors',
      'getPublisherList',
      'getDimensions',
      'getLastConfigPushed',
    ]),
    ...mapGetters('context', ['isUserExtern']),
    isExtern() {
      return this.isUserExtern;
    },
    isAbleToEdit() {
      return !this.isExtern || this.publisher?.can_edit;
    },
    publishersList() {
      if (this.isExtern) {
        return [...this.getPublisherList];
      }
      return [this.general_publisher, ...this.getPublisherList];
    },
    sorted_dimensions() {
      if (this.dimension === null) {
        return null;
      }
      const cdim = this.dimension ?? [];
      return cdim.sort((a, b) => b.type_priority - a.type_priority);
    },
  },
  methods: {
    ...mapMutations('floorRules', ['setPriceFloorFilter', 'setProduct']),
    ...mapActions('floorRules', [
      'setPriceFloor',
      'setPublisher',
      'initPriceFloorManagement',
      'addPriceFloor',
      'removeLastPriceFloor',
      'selectPriceFloorToEdit',
      'pushFloors',
    ]),
    handleDragging(e) {
      const percentage = (e.pageX / window.innerWidth) * 100;
      if (percentage >= 40 && percentage <= 85) {
        this.dividerPosition = percentage.toFixed(2);
      }
    },
    startDragging() {
      document.addEventListener('mousemove', this.handleDragging);
      document.addEventListener('mouseup', this.endDragging);
    },
    endDragging() {
      if (this.dividerPosition >= 80) {
        eventBus.$emit('cancel_select_price_floor');
        this.dividerPosition = 50;
      }

      document.removeEventListener('mousemove', this.handleDragging);
      document.removeEventListener('mouseup', this.endDragging);
    },
    async onPublisherChange(publisher) {
      const publisher_code = publisher?.publisher_code || null;
      this.dimension = null;
      this.setPublisher(publisher_code);
      await store.dispatch('floorRules/fetchFloorDimensions');
      this.filter.items.splice(1, Infinity);
      const dimension_registry = this.getDimensions;
      dimension_registry.sort((a, b) => b.priority - a.priority);
      this.filter.items.push(...dimension_registry);
    },
    async onPush() {
      this.pending_push = true;
      await this.pushFloors();
      this.pending_push = false;
    },
    stayOnDimension() {
      this.$refs.floor_table.resetSelection(this.confirm_close.params.old);
      this.confirm_close.dialog = false;
    },
    async saveAndLeaveDimension() {
      await this.$refs.dimension_card.onSave(false);
      this.persistDimension(this.confirm_close.params.item);
      this.confirm_close.dialog = false;
    },
    resetAndLeaveDimension() {
      this.$refs.dimension_card.resetSchema();
      this.persistDimension(this.confirm_close.params.item);
      this.confirm_close.dialog = false;
    },
    confirmCloseDimension(pf, { old } = {}) {
      if (this.$refs.dimension_card?.hasChanged) {
        this.confirm_close.dialog = true;
        this.confirm_close.params.item = pf;
        this.confirm_close.params.old = old;
        return;
      }
      this.persistDimension(pf);
    },
    persistDimension(pf) {
      if (pf === null) {
        this.lock_selection = false;
        this.dimension = null;
        return;
      }
      this.lock_selection = true;
      this.dimension = pf.dimensions;
      this.selectPriceFloorToEdit(pf);
    },
    openCreatePriceFloor() {
      this.addPriceFloor();
      const price_floors = this.getPriceFloors;
      const last_added_pf = price_floors[price_floors.length - 1];
      this.persistDimension(last_added_pf);
      this.create_price_floor = true;
    },
    cancelCreatePriceFloor() {
      this.closeCreatePriceFloor();
      this.removeLastPriceFloor();
      this.dimension = null;
    },
    closeCreatePriceFloor() {
      this.create_price_floor = false;
      this.dimension = null;
      eventBus.$emit('cancel_select_price_floor');
    },
    showCriteriaOpts(item) {
      this.filter.field = item.name;
      this.filter.open = true;
      this.$refs.search?.blur?.();
      return item;
    },
    hideCriteriaOpts() {
      this.filter.open = false;
      this.filter.field = null;
      this.filter.ops = 'contains#';
      this.filter.value = null;
    },
    async applyFilter() {
      this.persistDimension(null);
      eventBus.$emit('cancel_select_price_floor');

      this.filter.query.push(
        `${this.filter.field}:${this.filter.ops}${this.filter.value}`,
      );
      await this.onChange();
      this.hideCriteriaOpts();
    },
    async onChange() {
      const last_term = this.filter.query[this.filter.query.length - 1];

      const onTabChange = () => {
        const result = this.getDimensions.filter(({ name }) =>
          name.toLowerCase().includes(last_term?.toLowerCase()),
        );
        if (result.length === 0) return;
        this.filter.query.pop();
        if (this.filter.open) return;
        this.showCriteriaOpts(result[0]);
        this.filter.isTabChange = false;
      };

      if (this.filter.isTabChange || this.filter.open) {
        onTabChange();
      }

      if (this.filter.field?.includes(last_term)) {
        this.filter.query.pop();
      }
      this.setPriceFloorFilter(
        this.filter.query.filter((v) => v.includes(':')).join(';'),
      );
      await this.initPriceFloorManagement({
        product: this.product,
        publisher_code: this.publisher?.publisher_code,
      });

      this.filter.pf_name_filter ??= [];
      this.filter.pf_name_filter.splice(0, this.filter.pf_name_filter.length);
      this.filter.pf_name_filter.push(
        ...this.filter.query.filter((v) => !v.includes(':')),
      );
    },
    async onTabAutocomplete() {
      this.persistDimension(null);
      eventBus.$emit('cancel_select_price_floor');

      this.filter.isTabChange = true;
    },
  },
  data() {
    const general_publisher = {
      name: 'General',
      publisher_code: null,
    };
    return {
      dimension: null,
      dividerPosition: 50,
      general_publisher,
      publisher: general_publisher,
      pending_push: false,
      lock_selection: false,
      create_price_floor: false,
      confirm_close: {
        dialog: false,
        params: {
          item: null,
          old: null,
        },
      },
      filter: {
        items: [{ name: 'User', id: -1 }],
        open: false,
        field: null,
        value: null,
        ops: 'contains#',
        query: [],
        isTabChange: false,
        pf_name_filter: [],
        operators: [
          { v: '', t: 'is equal to' },
          { v: 'contains#', t: 'contains' },
        ],
      },
    };
  },
};
</script>

<style scoped>
.v-application--wrap {
  min-height: unset;
}

.opacity {
  background-color: #fdfdfd96;
}

.disapear {
  opacity: 0.5;
}

.resize-bar {
  cursor: ew-resize;
  border: 4px solid transparent;
}

.resize-bar:active,
.resize-bar:hover {
  background-color: #666666;
}
</style>
