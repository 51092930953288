<template>
  <v-stepper
    v-bind="{
      ...$attrs,
      ...$props,
    }"
    v-on="_listeners"
    @change="$emit('input', $event)"
  >
    <template v-for="(_, name) in $scopedSlots" #[name]="render">
      <slot :name="name" v-bind="render"></slot>
    </template>
    <slot></slot>
  </v-stepper>
</template>
<script>
export default {
  name: 'OStepper',
  emits: ['input'],
  computed: {
    _listeners() {
      const entries = Object.entries(this.$listeners).flatMap(
        ([key, value]) => {
          if (['input'].includes(key)) {
            return [];
          }
          return [{ [key]: value }];
        },
      );
      return Object.assign({}, ...entries);
    },
  },
};
</script>

<style scoped></style>
