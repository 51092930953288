<template>
  <o-row class="sites-grid">
    <o-snackbar v-model="snackbar" top color="blue">
      {{ snackbar_message }}
      <o-btn color="white" text @click="snackbar = false"> Close </o-btn>
    </o-snackbar>
    <o-col cols="12">
      <o-data-table
        :headers="headers"
        :items="getPaymentList"
        :footer-props="{
          'show-first-last-page': true,
          'items-per-page-options': [30, 50, 80],
        }"
      >
        <template #top>
          <o-row align="center" justify="space-around">
            <o-col cols="4">
              <o-row>
                <o-menu
                  v-model="datePicker"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template #activator="{ on, attrs }">
                    <o-text-field
                      v-model="datePickerDate"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      label="Select date"
                      v-on="on"
                    />
                  </template>
                  <o-date-picker
                    v-model="datePickerDate"
                    type="month"
                    @input="selectDate(datePickerDate)"
                  />
                </o-menu>
                <o-icon
                  :disabled="!datePickerDate"
                  color="red darken-3"
                  icon
                  @click="resetFilter('setPublisherName', 'datePickerDate')"
                >
                  mdi-close-circle
                </o-icon>
              </o-row>
            </o-col>
            <o-col cols="6">
              <o-row>
                <o-autocomplete
                  v-model="publisher_name"
                  :items="getPublishers"
                  label="Publisher name"
                />
                <o-icon
                  :disabled="!publisher_name"
                  color="red darken-3"
                  icon
                  @click="resetFilter('setPublisherName', 'publisher_name')"
                >
                  mdi-close-circle
                </o-icon>
              </o-row>
            </o-col>
          </o-row>
        </template>
        <template #item.payment_status_info.pub_net30.status="{ item }">
          <o-tooltip
            v-if="item.payment_status_info.pub_net30.status === 'invoiced'"
            bottom
          >
            <template #activator="{ on }">
              <o-chip
                :color="
                  mapFinancialPaymentColor(
                    item.payment_status_info.pub_net30.status,
                  )
                "
                v-on="on"
              >
                {{ getStatusName(item.payment_status_info.pub_net30.status) }}
                <o-icon
                  v-if="item.payment_status_info.pub_net30.invoice_sent_date"
                  color="black"
                  class="ml-3"
                >
                  mark_email_read
                </o-icon>
              </o-chip>
            </template>
            <div class="d-flex flex-column">
              <span>
                Invoice date:
                {{ item.payment_status_info.pub_net30.invoice_date }}
              </span>
              <span>
                Due date:
                {{ item.payment_status_info.pub_net30.invoice_due_date }}
              </span>
              <span v-if="item.payment_status_info.pub_net30.invoice_sent_date">
                Invoice sent date:
                {{ item.payment_status_info.pub_net30.invoice_sent_date }}
              </span>
              <span>
                Value: {{ item.payment_status_info.pub_net30.value }} €
              </span>
            </div>
          </o-tooltip>
          <o-tooltip
            v-else-if="
              item.payment_status_info.pub_net30.status === 'payment_done'
            "
            bottom
          >
            <template #activator="{ on }">
              <o-chip
                :color="
                  mapFinancialPaymentColor(
                    item.payment_status_info.pub_net30.status,
                  )
                "
                v-on="on"
              >
                {{ getStatusName(item.payment_status_info.pub_net30.status) }}
                <o-icon
                  v-if="item.payment_status_info.pub_net30.invoice_sent_date"
                  color="black"
                  class="ml-3"
                >
                  mark_email_read
                </o-icon>
              </o-chip>
            </template>
            <div class="d-flex flex-column">
              <span
                v-if="item.payment_status_info.pub_net30.invoice_payment_date"
              >
                Invoice payment date:
                {{ item.payment_status_info.pub_net30.invoice_payment_date }}
              </span>
              <span v-if="item.payment_status_info.pub_net30.invoice_sent_date">
                Invoice sent date:
                {{ item.payment_status_info.pub_net30.invoice_sent_date }}
              </span>
              <span>
                Value: {{ item.payment_status_info.pub_net30.value }} €
              </span>
            </div>
          </o-tooltip>
          <o-chip
            v-else
            :color="
              mapFinancialPaymentColor(
                item.payment_status_info.pub_net30.status,
              )
            "
          >
            {{ getStatusName(item.payment_status_info.pub_net30.status) }}
          </o-chip>
          <template
            v-if="item.payment_status_info.pub_net30.status === 'invoiced'"
          >
            <o-tooltip bottom>
              <template #activator="{ on }">
                <o-btn depressed icon v-on="on" @click="onDownloadInvoice">
                  <o-icon small> download </o-icon>
                </o-btn>
              </template>
              <span>Download Invoice</span>
            </o-tooltip>
            <!--<o-tooltip bottom>
              <template v-slot:activator="{ on }">
                <o-btn depressed icon v-on="on">
                  <o-icon small color="blue"> email </o-icon>
                </o-btn>
              </template>
              <span>Send Invoice</span>
            </o-tooltip>
            <o-tooltip bottom>
              <template v-slot:activator="{ on }">
                <o-btn depressed icon v-on="on">
                  <o-icon small color="warning"> autorenew </o-icon>
                </o-btn>
              </template>
              <span>Regenerate Invoice</span>
            </o-tooltip>-->
            <o-tooltip bottom>
              <template #activator="{ on }">
                <o-btn depressed icon v-on="on" @click="onValidatePayment">
                  <o-icon small> approval </o-icon>
                </o-btn>
              </template>
              <span>Payment Validated</span>
            </o-tooltip>
          </template>
          <template
            v-if="item.payment_status_info.pub_net30.status === 'payment_done'"
          >
            <o-tooltip bottom>
              <template #activator="{ on }">
                <o-btn depressed icon v-on="on" @click="onDownloadInvoice">
                  <o-icon small> download </o-icon>
                </o-btn>
              </template>
              <span>Download Invoice</span>
            </o-tooltip>
          </template>
          <template
            v-if="item.payment_status_info.pub_net30.status === 'validated'"
          >
            <o-tooltip bottom>
              <template #activator="{ on }">
                <o-btn depressed icon v-on="on" @click="onGenerateInvoice">
                  <o-icon small> description </o-icon>
                </o-btn>
              </template>
              <span>Generate Payment</span>
            </o-tooltip>
          </template>
        </template>
        <template #item.payment_status_info.pub_net60.status="{ item }">
          <o-tooltip
            v-if="item.payment_status_info.pub_net60.status === 'overdue'"
            bottom
          >
            <template #activator="{ on }">
              <o-chip
                :color="
                  mapFinancialPaymentColor(
                    item.payment_status_info.pub_net60.status,
                  )
                "
                v-on="on"
              >
                {{ getStatusName(item.payment_status_info.pub_net60.status) }}
              </o-chip>
            </template>
            <div class="d-flex flex-column">
              <span>
                Invoice date:
                {{ item.payment_status_info.pub_net60.invoice_date }}
              </span>
              <span>
                Due date:
                {{ item.payment_status_info.pub_net60.invoice_due_date }}
              </span>
              <span>
                Value:
                {{ item.payment_status_info.pub_net60.value }} €
              </span>
            </div>
          </o-tooltip>
          <o-chip
            v-else
            :color="
              mapFinancialPaymentColor(
                item.payment_status_info.pub_net60.status,
              )
            "
          >
            {{ getStatusName(item.payment_status_info.pub_net60.status) }}
          </o-chip>
          <template
            v-if="item.payment_status_info.pub_net60.status === 'overdue'"
          >
            <o-tooltip bottom>
              <template #activator="{ on }">
                <o-btn depressed icon v-on="on" @click="onDownloadInvoice">
                  <o-icon small> download </o-icon>
                </o-btn>
              </template>
              <span>Download Invoice</span>
            </o-tooltip>
            <o-tooltip bottom>
              <template #activator="{ on }">
                <o-btn depressed icon v-on="on" @click="onSendInvoice">
                  <o-icon small> email </o-icon>
                </o-btn>
              </template>
              <span>Send Invoice</span>
            </o-tooltip>
            <o-tooltip bottom>
              <template #activator="{ on }">
                <o-btn depressed icon v-on="on" @click="onValidatePayment">
                  <o-icon small> approval </o-icon>
                </o-btn>
              </template>
              <span>Payment Validated</span>
            </o-tooltip>
          </template>
        </template>
        <template #item.payment_status_info.opti_net30.status="{ item }">
          <o-chip
            :color="
              mapFinancialPaymentColor(
                item.payment_status_info.opti_net30.status,
              )
            "
          >
            {{ getStatusName(item.payment_status_info.opti_net30.status) }}
          </o-chip>
        </template>
        <template #item.payment_status_info.opti_net60.status="{ item }">
          <o-tooltip
            v-if="item.payment_status_info.opti_net60.status === 'invoiced'"
            bottom
          >
            <template #activator="{ on }">
              <o-chip
                :color="
                  mapFinancialPaymentColor(
                    item.payment_status_info.opti_net60.status,
                  )
                "
                v-on="on"
              >
                {{ getStatusName(item.payment_status_info.opti_net60.status) }}
              </o-chip>
            </template>
            <div class="d-flex flex-column">
              <span>
                Invoice date:
                {{ item.payment_status_info.opti_net60.invoice_date }}
              </span>
              <span>
                Due date:
                {{ item.payment_status_info.opti_net60.invoice_due_date }}
              </span>
              <span>
                Value:
                {{ item.payment_status_info.opti_net60.value }} €
              </span>
            </div>
          </o-tooltip>
          <o-chip
            v-else
            :color="
              mapFinancialPaymentColor(
                item.payment_status_info.opti_net60.status,
              )
            "
          >
            {{ getStatusName(item.payment_status_info.opti_net60.status) }}
          </o-chip>
          <template
            v-if="item.payment_status_info.opti_net60.status === 'invoiced'"
          >
            <o-tooltip bottom>
              <template #activator="{ on }">
                <o-btn depressed icon v-on="on" @click="onDownloadInvoice">
                  <o-icon small> download </o-icon>
                </o-btn>
              </template>
              <span>Download Invoice</span>
            </o-tooltip>
            <o-tooltip bottom>
              <template #activator="{ on }">
                <o-btn depressed icon v-on="on" @click="onSendInvoice">
                  <o-icon small> email </o-icon>
                </o-btn>
              </template>
              <span>Send Invoice</span>
            </o-tooltip>
            <!-- <o-tooltip bottom>
              <template v-slot:activator="{ on }">
                <o-btn depressed icon v-on="on" @click="onGenerateInvoice">
                  <o-icon small> autorenew </o-icon>
                </o-btn>
              </template>
              <span>Regenerate Invoice</span>
            </o-tooltip> -->
            <o-tooltip bottom>
              <template #activator="{ on }">
                <o-btn depressed icon v-on="on" @click="onValidatePayment">
                  <o-icon small> approval </o-icon>
                </o-btn>
              </template>
              <span>Payment Validated</span>
            </o-tooltip>
          </template>
        </template>
        <template #item.action="{ item }">
          <o-tooltip bottom>
            <template #activator="{ on }">
              <o-btn
                depressed
                icon
                :href="`/accounting/publisherFinancial/?&publisher_id=${item.publisher_id}`"
                v-on="on"
              >
                <o-icon small color="blue"> euro </o-icon>
              </o-btn>
            </template>
            <span>Publisher Financial</span>
          </o-tooltip>
        </template>
      </o-data-table>
    </o-col>
    <payment-date-dialog-vue
      :visible="dateDialog"
      :title-text="dateDialogTitle"
      :on-confirm-callback="dateDialogCallback"
      :on-cancel-callback="onHideDateDialog"
      :dpa-type="dpa_type"
    />
  </o-row>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { mapStatusColor } from '@/helpers/mapStatusColorMixin';
import PaymentDateDialogVue from '../../Accounting/ReportingTurnoverFinal/PaymentDateDialog.vue';

export default {
  name: 'PaymentsTable',
  components: {
    PaymentDateDialogVue,
  },
  mixins: [mapStatusColor],
  data() {
    return {
      publisher_name: null,
      datePicker: false,
      startDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      datePickerDate: null,
      dateDialog: false,
      dateDialogCallback: new Function(),
      dateDialogTitle: '',
      dpa_type: '',
      snackbar: false,
      snackbar_message: '',
      headers: [
        {
          text: 'Month',
          value: 'month',
        },
        {
          text: 'Publisher',
          value: 'publisher_name',
        },
        {
          text: 'Auto-invoices Turnover Publisher NET 30',
          value: 'payment_status_info.pub_net30.status',
        },
        {
          text: 'Auto-invoices Turnover Publisher NET 60',
          value: 'payment_status_info.pub_net60.status',
        },
        {
          text: 'Invoice commission Opti Digital NET 30',
          value: 'payment_status_info.opti_net30.status',
        },
        {
          text: 'Invoice commission Opti Digital NET 60',
          value: 'payment_status_info.opti_net60.status',
        },
        {
          text: 'Action',
          value: 'action',
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters('publishers', ['getPublishers']),
    getPaymentList() {
      return [
        {
          month: '2022-05',
          publisher_name: 'abroparaguas.com',
          publisher_id: 121,
          payment_status_info: {
            pub_net30: {
              status: 'invoiced',
              invoice_date: '2022-04-29 00:00:00',
              invoice_due_date: '2022-05-29 00:00:00',
              invoice_payment_date: null,
              invoice_sent_date: '2022-05-25 00:00:00',
              value: 12345.02,
            },
            pub_net60: {
              status: 'not_available',
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: null,
              invoice_sent_date: null,
            },
            opti_net30: {
              status: 'not_available',
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: null,
              invoice_sent_date: null,
            },
            opti_net60: {
              status: 'pending_validation',
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: null,
              invoice_sent_date: null,
            },
          },
        },
        {
          month: '2022-05',
          publisher_name: 'acontresens',
          publisher_id: 36,
          payment_status_info: {
            pub_net30: {
              status: 'validated',
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: null,
              invoice_sent_date: null,
            },
            pub_net60: {
              status: 'not_available',
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: null,
              invoice_sent_date: null,
            },
            opti_net30: {
              status: 'not_available',
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: null,
              invoice_sent_date: null,
            },
            opti_net60: {
              status: 'invoiced',
              invoice_date: '2022-02-15 00:00:00',
              invoice_due_date: '2022-04-16 00:00:00',
              invoice_payment_date: null,
              invoice_sent_date: null,
              value: 3332.01,
            },
          },
        },
        {
          month: '2022-05',
          publisher_name: 'ADS4ALL',
          publisher_id: 157,
          payment_status_info: {
            pub_net30: {
              status: 'payment_done',
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: '2022-05-26 14:00:00',
              invoice_sent_date: '2022-05-25 00:00:00',
              value: 7332.01,
            },
            pub_net60: {
              status: 'overdue',
              invoice_date: '2022-05-01 00:00:00',
              invoice_due_date: '2022-05-26 14:00:00',
              invoice_payment_date: null,
              invoice_sent_date: null,
              value: 4441.98,
            },
            opti_net30: {
              status: 'not_available',
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: null,
              invoice_sent_date: null,
            },
            opti_net60: {
              status: 'invoiced',
              invoice_date: '2022-02-15 00:00:00',
              invoice_due_date: '2022-04-16 00:00:00',
              invoice_payment_date: null,
              invoice_sent_date: null,
              value: 3332.01,
            },
          },
        },
        {
          month: '2022-05',
          publisher_name: 'Adweb Outremer',
          publisher_id: 155,
          payment_status_info: {
            pub_net30: {
              status: 'invoiced',
              invoice_date: '2022-04-29 00:00:00',
              invoice_due_date: '2022-05-29 00:00:00',
              invoice_payment_date: null,
              invoice_sent_date: '2022-05-25 00:00:00',
              value: 12345.02,
            },
            pub_net60: {
              status: 'not_available',
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: null,
              invoice_sent_date: null,
            },
            opti_net30: {
              status: 'not_available',
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: null,
              invoice_sent_date: null,
            },
            opti_net60: {
              status: 'pending_validation',
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: null,
              invoice_sent_date: null,
            },
          },
        },
        {
          month: '2022-05',
          publisher_name: 'aide_sociale',
          publisher_id: 58,
          payment_status_info: {
            pub_net30: {
              status: 'validated',
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: null,
              invoice_sent_date: null,
            },
            pub_net60: {
              status: 'not_available',
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: null,
              invoice_sent_date: null,
            },
            opti_net30: {
              status: 'not_available',
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: null,
              invoice_sent_date: null,
            },
            opti_net60: {
              status: 'invoiced',
              invoice_date: '2022-02-15 00:00:00',
              invoice_due_date: '2022-04-16 00:00:00',
              invoice_payment_date: null,
              invoice_sent_date: null,
              value: 3332.01,
            },
          },
        },
        {
          month: '2022-05',
          publisher_name: 'Aujardin',
          publisher_id: 88,
          payment_status_info: {
            pub_net30: {
              status: 'payment_done',
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: '2022-05-26 14:00:00',
              invoice_sent_date: '2022-05-25 00:00:00',
              value: 7332.01,
            },
            pub_net60: {
              status: 'overdue',
              invoice_date: '2022-05-01 00:00:00',
              invoice_due_date: '2022-05-26 14:00:00',
              invoice_payment_date: null,
              invoice_sent_date: null,
              value: 4441.98,
            },
            opti_net30: {
              status: 'not_available',
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: null,
              invoice_sent_date: null,
            },
            opti_net60: {
              status: 'invoiced',
              invoice_date: '2022-02-15 00:00:00',
              invoice_due_date: '2022-04-16 00:00:00',
              invoice_payment_date: null,
              invoice_sent_date: null,
              value: 3332.01,
            },
          },
        },
        {
          month: '2022-05',
          publisher_name: 'betaseries',
          publisher_id: 103,
          payment_status_info: {
            pub_net30: {
              status: 'invoiced',
              invoice_date: '2022-04-29 00:00:00',
              invoice_due_date: '2022-05-29 00:00:00',
              invoice_payment_date: null,
              invoice_sent_date: '2022-05-25 00:00:00',
              value: 12345.02,
            },
            pub_net60: {
              status: 'not_available',
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: null,
              invoice_sent_date: null,
            },
            opti_net30: {
              status: 'not_available',
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: null,
              invoice_sent_date: null,
            },
            opti_net60: {
              status: 'pending_validation',
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: null,
              invoice_sent_date: null,
            },
          },
        },
        {
          month: '2022-05',
          publisher_name: 'CambiumMedia',
          publisher_id: 118,
          payment_status_info: {
            pub_net30: {
              status: 'validated',
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: null,
              invoice_sent_date: null,
            },
            pub_net60: {
              status: 'not_available',
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: null,
              invoice_sent_date: null,
            },
            opti_net30: {
              status: 'not_available',
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: null,
              invoice_sent_date: null,
            },
            opti_net60: {
              status: 'invoiced',
              invoice_date: '2022-02-15 00:00:00',
              invoice_due_date: '2022-04-16 00:00:00',
              invoice_payment_date: null,
              invoice_sent_date: null,
              value: 3332.01,
            },
          },
        },
        {
          month: '2022-05',
          publisher_name: 'Chien.com',
          publisher_id: 131,
          payment_status_info: {
            pub_net30: {
              status: 'payment_done',
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: '2022-05-26 14:00:00',
              invoice_sent_date: '2022-05-25 00:00:00',
              value: 7332.01,
            },
            pub_net60: {
              status: 'overdue',
              invoice_date: '2022-05-01 00:00:00',
              invoice_due_date: '2022-05-26 14:00:00',
              invoice_payment_date: null,
              invoice_sent_date: null,
              value: 4441.98,
            },
            opti_net30: {
              status: 'not_available',
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: null,
              invoice_sent_date: null,
            },
            opti_net60: {
              status: 'invoiced',
              invoice_date: '2022-02-15 00:00:00',
              invoice_due_date: '2022-04-16 00:00:00',
              invoice_payment_date: null,
              invoice_sent_date: null,
              value: 3332.01,
            },
          },
        },
        {
          month: '2022-05',
          publisher_name: '	clubic',
          publisher_id: 29,
          payment_status_info: {
            pub_net30: {
              status: 'invoiced',
              invoice_date: '2022-04-29 00:00:00',
              invoice_due_date: '2022-05-29 00:00:00',
              invoice_payment_date: null,
              invoice_sent_date: '2022-05-25 00:00:00',
              value: 12345.02,
            },
            pub_net60: {
              status: 'not_available',
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: null,
              invoice_sent_date: null,
            },
            opti_net30: {
              status: 'not_available',
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: null,
              invoice_sent_date: null,
            },
            opti_net60: {
              status: 'pending_validation',
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: null,
              invoice_sent_date: null,
            },
          },
        },
        {
          month: '2022-05',
          publisher_name: 'CMI Media',
          publisher_id: 136,
          payment_status_info: {
            pub_net30: {
              status: 'validated',
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: null,
              invoice_sent_date: null,
            },
            pub_net60: {
              status: 'not_available',
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: null,
              invoice_sent_date: null,
            },
            opti_net30: {
              status: 'not_available',
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: null,
              invoice_sent_date: null,
            },
            opti_net60: {
              status: 'invoiced',
              invoice_date: '2022-02-15 00:00:00',
              invoice_due_date: '2022-04-16 00:00:00',
              invoice_payment_date: null,
              invoice_sent_date: null,
              value: 3332.01,
            },
          },
        },
        {
          month: '2022-05',
          publisher_name: '	commenteconomiser',
          publisher_id: 62,
          payment_status_info: {
            pub_net30: {
              status: 'payment_done',
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: '2022-05-26 14:00:00',
              invoice_sent_date: '2022-05-25 00:00:00',
              value: 7332.01,
            },
            pub_net60: {
              status: 'overdue',
              invoice_date: '2022-05-01 00:00:00',
              invoice_due_date: '2022-05-26 14:00:00',
              invoice_payment_date: null,
              invoice_sent_date: null,
              value: 4441.98,
            },
            opti_net30: {
              status: 'not_available',
              invoice_date: null,
              invoice_due_date: null,
              invoice_payment_date: null,
              invoice_sent_date: null,
            },
            opti_net60: {
              status: 'invoiced',
              invoice_date: '2022-02-15 00:00:00',
              invoice_due_date: '2022-04-16 00:00:00',
              invoice_payment_date: null,
              invoice_sent_date: null,
              value: 3332.01,
            },
          },
        },
      ];
    },
  },
  created() {
    this.setPublisherName('');
    this.fetchPublishers();
    if (!this.datePickerDate) {
      const lastMonth = new Date(this.startDate);
      lastMonth.setMonth(lastMonth.getMonth() - 1);
      this.datePickerDate = lastMonth.toISOString().substr(0, 7);
    }
  },
  methods: {
    ...mapMutations('payments', ['setPublisherName']),
    ...mapActions('publishers', ['fetchPublishers']),
    ...mapActions('turnoverFinal', [
      'generateInvoice',
      'downloadInvoice',
      'validatePayment',
      'sendInvoice',
    ]),
    selectDate(date) {
      console.log(date);
      this.datePicker = false;
    },
    setUpDate() {
      const d = new Date();
      d.setDate(0); //prev month
      d.setDate(1); // set first day of prev month
      this.datePickerDate = this.getDate || d.toISOString().substr(0, 7);
      // store.dispatch("turnoverFinal/setSelectedDate", this.datePickerDate);
    },
    getStatusName(status) {
      if (status.toLowerCase() === 'pending_validation')
        return 'Pending Validation';
      if (status.toLowerCase() === 'validated') return 'Validated';
      if (status.toLowerCase() === 'invoiced') return 'Invoiced';
      if (status.toLowerCase() === 'payment_done') return 'Paid';
      if (status.toLowerCase() === 'invoice_sent') return 'Invoice Sent';
      if (status.toLowerCase() === 'overdue') return 'Overdue';
      return 'Not Available';
    },
    onShowDateDialog(title, callback) {
      this.dateDialogTitle = title;
      this.dateDialogCallback = callback;
      this.dateDialog = true;
    },
    onHideDateDialog() {
      this.dateDialog = false;
    },
    onValidatePayment() {
      this.onShowDateDialog('Select Payment Date', (paymentDate) => {
        this.validatePayment({
          dpa_type: this.dpa_type,
          payment_term: this.payment_term,
          payment_date: paymentDate,
        });
        this.onHideDateDialog();
      });
    },
    onGenerateInvoice() {
      this.onShowDateDialog('Select Invoice Date', (generateDate) => {
        this.generateInvoice({
          dpa_type: this.dpa_type,
          payment_term: this.payment_term,
          invoice_date: generateDate,
        });
        this.onHideDateDialog();
      });
    },
    resetFilter(a, b) {
      this[`${b}`] = '';
    },
    onDownloadInvoice() {
      this.snackbar = true;
      this.snackbar_message = 'The invoice was downloaded';
    },
    onSendInvoice() {
      this.snackbar = true;
      this.snackbar_message = 'The invoice was sent via e-mail';
    },
  },
};
</script>

<style scoped></style>
