<template>
  <o-row>
    <o-col cols="12">
      <o-data-table
        height="calc(100vh - 250px)"
        :loading="getLoading"
        :headers="headers"
        :items="getDemandPartners"
        :search="search"
        :footer-props="{
          'show-first-last-page': true,
          'items-per-page-options': [10, 20, 30, 50, 100],
        }"
      >
        <template #top>
          <o-row><o-divider /></o-row>
          <o-row align="center" justify="space-around" class="pb-5">
            <o-col cols="3" class="text-center">
              <o-btn
                color="primary"
                @click="editDemandPartner(getDefaultDemandPartner())"
              >
                {{ $t('buttons.demand_partners.add') }}
                <o-icon dark right> add_box </o-icon>
              </o-btn>
            </o-col>
            <o-divider vertical inset />
            <o-col cols="6">
              <o-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('buttons.general.search')"
                single-line
                hide-details
              />
            </o-col>
          </o-row>
        </template>
        <template #item.url="{ item }">
          <o-list-item :href="'//' + item.url" target="_blank">
            {{ item.url }}
          </o-list-item>
        </template>
        <template #item.action="{ item }">
          <div style="white-space: nowrap">
            <o-btn depressed icon @click="editDemandPartner(item)">
              <o-icon small> edit </o-icon>
            </o-btn>
            <o-btn depressed icon @click="showDemandPartner(item)">
              <o-icon small> visibility </o-icon>
            </o-btn>
            <o-btn depressed icon @click="onArchive(item)">
              <o-icon small color="error"> delete </o-icon>
            </o-btn>
          </div>
        </template>
      </o-data-table>
      <o-dialog v-model="dialog" max-width="80%">
        <o-card style="overflow-x: hidden">
          <o-row align="center">
            <o-col cols="auto">
              <o-card-title>
                <span v-if="!readOnly && editedIndex !== -1" class="headline"
                  >{{ $t('buttons.demand_partners.edit') }}:</span
                >
                <span
                  v-else-if="readOnly && editedIndex !== -1"
                  class="headline"
                  >{{ $t('buttons.demand_partners.show') }}:</span
                >
                <span v-else class="headline"
                  >{{ $t('buttons.demand_partners.add') }}:</span
                >
              </o-card-title>
            </o-col>
            <o-spacer />
            <o-col cols="auto">
              <o-btn
                v-if="!readOnly"
                :disabled="!valid"
                color="blue darken-1"
                text
                @click="save"
              >
                {{ $t('buttons.general.save') }}
              </o-btn>
              <o-btn class="mr-2" color="blue darken-1" text @click="close">
                {{ $t('buttons.general.close') }}
              </o-btn>
            </o-col>
          </o-row>
          <o-card-text>
            <o-container>
              <o-form ref="form" v-model="valid" lazy-validation>
                <o-row>
                  <o-col cols="12" sm="6" md="4">
                    <o-text-field
                      v-model="editedItem.name"
                      :readonly="readOnly"
                      :filled="readOnly"
                      label="Name"
                      :rules="createRules([baseValidation.required])"
                    />
                  </o-col>
                  <o-col cols="12" sm="6" md="4">
                    <o-text-field
                      v-model="editedItem.url"
                      :readonly="readOnly"
                      :filled="readOnly"
                      label="URL"
                      :rules="createRules([baseValidation.domain])"
                    />
                  </o-col>
                  <o-col cols="12" sm="6" md="4">
                    <o-text-field
                      v-model.number="editedItem.contact_email"
                      :readonly="readOnly"
                      :filled="readOnly"
                      label="Contact Email"
                      :rules="createRules([baseValidation.email])"
                    />
                  </o-col>
                </o-row>
                <o-row>
                  <o-col cols="3" sm="6" md="4">
                    <o-select
                      v-model="editedItem.payment_term_id"
                      :readonly="readOnly"
                      :filled="readOnly"
                      item-text="name"
                      item-value="id"
                      :items="getPaymentTerms"
                      label="Payment Term"
                      :rules="createRules([baseValidation.required])"
                    />
                  </o-col>
                </o-row>
                <o-row>
                  <o-col cols="3" sm="6" md="4">
                    <o-switch
                      v-model="editedItem.prebidserver"
                      :readonly="readOnly"
                      :filled="readOnly"
                      label="PrebidServer enabled"
                      @change="() => (editedItem.client_only = false)"
                    />
                  </o-col>
                  <o-col cols="3" sm="6" md="4">
                    <o-switch
                      v-model="editedItem.client_only"
                      :readonly="readOnly"
                      :filled="readOnly"
                      label="Client only"
                      @change="() => (editedItem.prebidserver = false)"
                    />
                  </o-col>
                  <o-col cols="3" sm="6" md="4">
                    <o-switch
                      v-model="editedItem.deliver_in_safeframe"
                      :readonly="readOnly"
                      :filled="readOnly"
                      label="Deliver in SafeFrame"
                    />
                  </o-col>
                </o-row>
              </o-form>
            </o-container>
          </o-card-text>
          <o-card-actions>
            <o-spacer />
            <o-btn
              v-if="!readOnly"
              :disabled="!valid"
              color="blue darken-1"
              text
              @click="save"
            >
              {{ $t('buttons.general.save') }}
            </o-btn>
            <o-btn class="mr-2" color="blue darken-1" text @click="close">
              {{ $t('buttons.general.close') }}
            </o-btn>
          </o-card-actions>
        </o-card>
      </o-dialog>

      <confirmation-dialog
        ref="confirmation"
        msg="Do you want to archive DP?"
        @confirm="confirmArchive"
        @close="closeArchive"
      />
    </o-col>
  </o-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { baseValidation } from '@/validation/baseValidation';
import { validationHelper } from '@/validation/validationHelper';
import ConfirmationDialog from '@/components/DesignSystem/ODialog/ConfirmationDialog.vue';

export default {
  name: 'DemandPartnersTable',
  components: { ConfirmationDialog },
  mixins: [baseValidation, validationHelper],
  data() {
    return {
      dialog: false,
      readOnly: false,
      valid: true,
      editedIndex: -1,
      editedItem: {},
      itemToRemove: {},
      search: '',
      filterList: [],
      headers: [
        {
          text: 'ID',
          value: 'id',
        },
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'URL',
          value: 'url',
        },
        {
          text: 'Contact Email',
          value: 'contact_email',
        },
        {
          text: 'Action',
          value: 'action',
          sortable: false,
          filterable: false,
          align: 'center',
        },
      ],
    };
  },
  mounted() {
    this.fetchDemandPartners();
    this.fetchPaymentTerms();
  },
  computed: {
    ...mapGetters('demandPartners', [
      'getLoading',
      'getDemandPartners',
      'getDefaultDemandPartner',
    ]),
    ...mapGetters('paymentTerm', ['getLoading', 'getPaymentTerms']),
  },
  methods: {
    ...mapActions('demandPartners', [
      'fetchDemandPartners',
      'createDemandPartner',
      'updateDemandPartner',
      'deleteDemandPartner',
    ]),
    ...mapActions('paymentTerm', ['fetchPaymentTerms']),
    editDemandPartner(item) {
      this.editedIndex = this.getDemandPartners.indexOf(item);
      this.editedItem = { ...item };
      this.readOnly = false;
      this.dialog = true;
    },
    showDemandPartner(item) {
      this.editedIndex = this.getDemandPartners.indexOf(item);
      this.editedItem = { ...item };
      this.readOnly = true;
      this.dialog = true;
    },
    onArchive(item) {
      this.itemToRemove = item;
      this.$refs.confirmation.open();
    },
    confirmArchive() {
      this.deleteDemandPartner(this.itemToRemove);
      this.$refs.confirmation.close();
    },
    closeArchive() {
      this.$refs.confirmation.close();
    },
    close() {
      this.dialog = false;
      this.editedIndex = -1;
    },
    save() {
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          this.updateDemandPartner(this.editedItem);
        } else {
          this.createDemandPartner(this.editedItem);
        }
        this.close();
      }
    },
  },
};
</script>

<style scoped></style>
