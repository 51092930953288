<template>
  <o-col>
    <o-row>
      <o-col> <price-floor-switcher /> </o-col>
    </o-row>
  </o-col>
</template>

<script>
import PriceFloorSwitcher from '@/components/PriceFloor/Configuration/PriceFloorSwitcher.vue';

export default {
  components: {
    PriceFloorSwitcher,
  },
};
</script>

<style></style>
