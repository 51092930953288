<template>
  <v-data-table
    v-bind="{
      ...$attrs,
      ...$props,
      ...footerPropsInjector,
    }"
    v-on="$listeners"
  >
    <!-- Dynamically create slots for each slots passed from usage -->
    <!-- In Vue 2 the order is important so keep $scopedSlots under $slots -->
    <!-- In Vue 3 scopedSlots are included into $slots -->

    <template v-for="(_, name) in $scopedSlots" #[name]="render">
      <slot :name="name" v-bind="render"></slot>
    </template>

    <template
      v-if="hideDefaultHeader"
      #header="{
        props: {
          headers,
          options: { sortBy, sortDesc },
        },
        on: { sort },
      }"
    >
      <o-data-table-header
        :headers="headers"
        :sort-desc="sortDesc"
        :sort-by="sortBy"
        :sort="sort"
      />
    </template>
  </v-data-table>
</template>

<script>
import ODataTableHeader from '@/components/DesignSystem/ODataTable/ODataTableHeader.vue';

export default {
  name: 'ODataTable',
  components: { ODataTableHeader },
  props: {
    fixedHeader: {
      type: Boolean,
      default: true,
    },
    itemsPerPage: {
      type: Number,
      default: 30,
    },
    hideDefaultHeader: {
      type: Boolean,
      default: false,
    },
    itemsPerPageOptions: {
      type: Array,
      default() {
        return [10, 20, 30, 50, 100];
      },
    },
  },
  computed: {
    slots() {
      return Object.keys(this.$slots).filter(
        (key) => !Object.keys(this.$scopedSlots.includes(key)),
      );
    },
    footerPropsInjector() {
      if (!this.itemsPerPageOptions) {
        return {};
      }
      return {
        footerProps: {
          'show-first-last-page': true,
          'items-per-page-options': this.itemsPerPageOptions,
        },
      };
    },
  },
};
</script>
