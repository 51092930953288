<template>
  <o-row dense align="center">
    <o-col cols="6">
      <o-row align-content="stretch" justify="center">
        <o-col cols="12">
          <o-card style="height: 40vh">
            <o-list-item-content>
              <o-list-item-title class="headline mb-1">
                ??????
              </o-list-item-title>
              ?????
            </o-list-item-content>
          </o-card>
        </o-col>
      </o-row>
      <o-row>
        <o-col cols="12">
          <o-card style="height: 40vh">
            <o-list-item-content>
              <o-list-item-title class="headline mb-1">
                ??????
              </o-list-item-title>
              ?????
            </o-list-item-content>
          </o-card>
        </o-col>
      </o-row>
    </o-col>
    <o-col cols="6">
      <o-row align="stretch" justify="center">
        <o-col cols="12">
          <o-card style="height: 40vh">
            <o-list-item-content>
              <o-list-item-title class="headline mb-1">
                ??????
              </o-list-item-title>
              ?????
            </o-list-item-content>
          </o-card>
        </o-col>
      </o-row>
    </o-col>
  </o-row>
</template>

<script>
export default {
  name: 'AccountingDashboard',
  data() {
    return {
      homeState: true,
    };
  },
  computed: {},
  created() {},
  methods: {},
};
</script>
