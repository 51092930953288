<template>
  <v-navigation-drawer v-model="drawer" :mini-variant.sync="mini" app permanent>
    <o-list-item class="px-2">
      <img
        v-if="!mini"
        src="../../public/logo-optidigital_versionhorizontal_fullcolor-1.png"
        alt="optidigital-logo"
        class="logo"
      />
      <img
        src="../../public/logo.png"
        alt="optidigital-logo-mini"
        class="logo-mini"
        :style="mini ? 'display: block;' : 'display:none'"
      />
      <o-btn icon @click.stop="mini = !mini">
        <o-icon>mdi-chevron-left</o-icon>
      </o-btn>
    </o-list-item>

    <o-divider />

    <o-list dense>
      <o-list-item
        v-for="item in nav_items_wo_children"
        :key="item.title"
        :to="item.path"
      >
        <o-list-item-icon>
          <o-icon>{{ item.icon }}</o-icon>
        </o-list-item-icon>
        <o-list-item-content>
          <o-list-item-title v-text="$t('nav_menu.' + item.title)" />
        </o-list-item-content>
      </o-list-item>

      <o-list-group
        v-for="item in nav_items_with_children"
        :key="item.title"
        class="px-0"
        :prepend-icon="item.icon"
        no-action
        link
        :to="item.path"
      >
        <template #activator>
          <o-list-item-content>
            <o-list-item-title v-text="$t('nav_menu.' + item.title)" />
          </o-list-item-content>
        </template>

        <o-list-item
          v-for="sub_item in item.sub_items"
          :key="sub_item.title"
          link
          :to="sub_item.path"
        >
          <o-list-item-content>
            <o-list-item-title v-text="$t('nav_menu.' + sub_item.title)" />
          </o-list-item-content>
        </o-list-item>
      </o-list-group>
    </o-list>
    <template #append>
      <o-list-item
        v-if="!mini && isEligibleForDemo"
        key="connect-as"
        class="px-2"
      >
        <o-list-item-content>
          <o-btn text color="primary" @click="connect"> Connect as Demo </o-btn>
        </o-list-item-content>
      </o-list-item>
      <o-list-item key="profile" class="px-2">
        <o-list-item-avatar class="py-5">
          <v-img :src="getUserProfileImg" />
        </o-list-item-avatar>
        <o-list-item-title>{{ getUserProfile.google_name }}</o-list-item-title>
      </o-list-item>
      <o-list-item key="logout" to="/" @click="logout">
        <o-list-item-icon>
          <o-icon>mdi-logout</o-icon>
        </o-list-item-icon>
        <o-list-item-content>
          <o-list-item-title v-text="$t('nav_menu.logout')" />
        </o-list-item-content>
      </o-list-item>
    </template>
  </v-navigation-drawer>
</template>

<script>
import store from '../store';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'LeftSideMenu',
  data() {
    return {
      drawer: true,
      mini: true,
      nav_items_wo_children: {},
      nav_items_with_children: {},
    };
  },
  created() {
    store.dispatch('context/fetchContext');
    store
      .dispatch('context/fetchNavMenu')
      .then(
        () =>
          (this.nav_items_wo_children = this.getNavMenuRoutes?.filter(
            (el) => !el.sub_items,
          )),
      )
      .then(
        () =>
          (this.nav_items_with_children = this.getNavMenuRoutes?.filter(
            (el) => el.sub_items != null,
          )),
      );
  },
  computed: {
    ...mapGetters('context', [
      'getNavMenuRoutes',
      'getUserProfile',
      'getUserProfileImg',
    ]),
    isEligibleForDemo() {
      return [
        'olly@optidigital.fr',
        'laure@optidigital.fr',
        'jean-cesar@optidigital.fr',
        'nadia@optidigital.fr',
        'emmanuel@optidigital.fr',
        'florian@optidigital.fr',
        'sebastien@optidigital.fr',
        'magali@optidigital.fr',
        'juan-pablo@optidigital.fr',
        'ivana@optidigital.fr',
        'stephen@optidigital.fr',
      ].includes(this.getUserProfile.email);
    },
  },
  async mounted() {
    await store.dispatch('context/fetchContext');
  },
  methods: {
    ...mapActions('context', ['fetchNavMenu', 'fetchContext', 'connectAs']),
    ...mapActions('warnings', ['openDialog']),
    async connect() {
      const response = await this.connectAs({ user_id: 78 });
      if (response) {
        await this.$router.push({ name: 'Home' });
      }
    },
    logout() {
      this.$store
        .dispatch('context/logout')
        .then()
        .catch((err) => window.console.log(err));
    },
  },
};
</script>
<style scoped>
.logo {
  width: 85%;
  max-width: 200px;
  min-width: 180px;
  animation: fadeon 0.5s linear forwards;
}

.logo-mini {
  max-width: 40px;
  animation: animatemini 0.5s linear forwards;
}

@keyframes fadeon {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100;
  }
}

@keyframes animatemini {
  0% {
    opacity: 0;
    transform: rotate(220deg);
  }
  100% {
    opacity: 100;
    transform: rotate(0deg);
  }
}
.v-icon {
  margin-left: 2px;
}
</style>
