import axios from 'axios';

const store = {
  namespaced: true,

  state: {
    loading: true,
    adUnits: [],
    defaultUnit: { name: '', id: -1, site_config_id: -1 },
    selectedAdUnits: undefined,
    adUnitsData: {
      gam_ad_unit_id: '',
      gam_placement_id: '',
      gam_network_id: '',
    },
  },

  getters: {
    getLoading: (state) => {
      return state.loading;
    },
    getAdUnits: (state) => {
      return state.adUnits;
    },
    getDefaultAdUnit: (state) => {
      return state.defaultUnit;
    },
    getSelectedAdUnits: (state) => {
      return state.defaultUnit;
    },
    getAdUnitsData: (state) => {
      return state.adUnitsData;
    },
  },

  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
    setAdUnits(state, payload) {
      state.adUnits = payload.sort((a, b) => a.id - b.id);
    },
    setSelectedAdUnits(state, selectedAdUnits) {
      state.selectedAdUnits = selectedAdUnits;
    },
    setAdUnitsData(state, data) {
      state.adUnitsData = data;
    },
  },

  actions: {
    async fetchAdUnits({ commit, rootState }, in_render = false) {
      commit('setLoading', true);
      commit('setAdUnitsData', {
        gam_ad_unit_id: '',
        gam_ad_unit_name: '',
        gam_placement_id: '',
        gam_network_id: '',
      });
      const payload = in_render
        ? {
            site_id: rootState.configLoaderRenderer.rendererData.site.id,
            revision:
              rootState.configLoaderRenderer.rendererData.revision.number,
            inventory_type:
              rootState.configLoaderRenderer.rendererData.inventoryType,
          }
        : {
            site_id: rootState.tagsManager.site.id,
            revision: rootState.tagsManager.revision.number,
            inventory_type: rootState.tagsManager.inventoryType,
          };

      if (!payload.revision) return null;
      return new Promise((resolve) => {
        axios.get('/ad_unit/', { params: payload }).then((response) => {
          commit('setAdUnits', response.data.data.ad_units);
          commit('setAdUnitsData', {
            gam_ad_unit_id: response.data.data.gam_ad_unit_id,
            gam_ad_unit_name: response.data.data.gam_ad_unit_name,
            gam_placement_id: response.data.data.gam_placement_id,
            gam_network_id: response.data.data.gam_network_id,
          });
          commit('setLoading', false);
          resolve();
        });
      });
    },
    async updateAdUnits({ commit, rootState }, data) {
      commit('setLoading', true);
      const payload = {
        site_id: rootState.tagsManager.site.id,
        revision: rootState.tagsManager.revision.number,
        inventory_type: rootState.tagsManager.inventoryType,
      };
      return new Promise((resolve) => {
        axios
          .put('/ad_unit/', data, {
            params: payload,
          })
          .then((response) => {
            if (response.status === 200) {
              if (response.data.status === 'warning') {
                commit('warnings/addWarning', response.data.warnings, {
                  root: true,
                });
              }
              commit('setLoading', false);
              resolve(response.data);
            }
          });
      });
    },
    async createAdUnit({ commit, rootState }, data) {
      commit('setLoading', true);
      const payload = {
        site_id: rootState.tagsManager.site.id,
        revision: rootState.tagsManager.revision.number,
        inventory_type: rootState.tagsManager.inventoryType,
      };
      return new Promise((resolve) => {
        axios.post('/ad_unit/', data, { params: payload }).then((response) => {
          if (response.data.status === 'warning') {
            commit('warnings/addWarning', response.data.warnings, {
              root: true,
            });
          }
          commit('setLoading', false);
          resolve(response.data);
        });
      });
    },
    async deleteAdUnit({ commit, rootState }, data) {
      commit('setLoading', true);
      const payload = {
        site_id: rootState.tagsManager.site.id,
        revision: rootState.tagsManager.revision.number,
        inventory_type: rootState.tagsManager.inventoryType,
      };
      return new Promise((resolve) => {
        axios.delete('/ad_unit/', { data, params: payload }).then(() => {
          commit('setLoading', false);
          resolve();
        });
      });
    },
    selectAdUnits({ commit }, data) {
      commit('setSelectedAdUnits', data);
    },
  },
};

export default store;
