<template>
  <v-expansion-panel-content
    v-bind="{
      ...$attrs,
      ...$props,
    }"
    v-on="$listeners"
  >
    <template v-for="(_, name) in $scopedSlots" #[name]="render">
      <slot :name="name" v-bind="render"></slot>
    </template>
    <slot></slot>
  </v-expansion-panel-content>
</template>
<script>
export default {
  name: 'OExpansionPanelContent',
};
</script>

<style scoped></style>
