<template>
  <o-card>
    <o-card-title>Releases Audit Log</o-card-title
    ><o-row align="center" justify="space-around">
      <o-col cols="4">
        <o-row>
          <o-text-field
            v-model="search_action"
            label="Search action"
            :loading="getLoading"
            @change="
              () => {
                setAuditLogSearch(search_action);
                options.page = 1;
              }
            "
          />
          <o-icon
            :disabled="!search_action"
            color="red darken-3"
            icon
            @click="
              () => {
                search_action = '';
                options.page = 1;
                setAuditLogSearch(search_action);
              }
            "
          >
            mdi-close-circle
          </o-icon>
        </o-row>
      </o-col>
    </o-row>
    <o-card-text>
      <o-data-table
        height="80vh"
        :items="getAuditLog"
        :loading="getLoading"
        :headers="headers"
        :server-items-length="getAuditLogCount"
        :items-per-page="100"
        :page="getAuditLogPage"
        :options.sync="options"
        :footer-props="{
          'items-per-page-options': [100],
          'show-first-last-page': true,
        }"
      >
        <template #[`item.view_context`]="{ item }">
          <o-btn depressed icon large class="mx-4" @click="viewItem(item)">
            <o-icon small> visibility </o-icon>
          </o-btn>
        </template>
        <template #[`item.context`]="{ item }">
          <p class="truncate">
            {{ item.context }}
          </p>
        </template>
      </o-data-table>
      <o-dialog v-model="dialog" max-width="60%">
        <o-card>
          <o-card-title>
            <o-icon class="ma-4" small> visibility </o-icon>
            Context view for action {{ viewedItem.action }} at
            {{ viewedItem.date }}
          </o-card-title>
          <o-card-text>
            <o-textarea
              v-model="viewedItem.context"
              no-resize
              rows="20"
              class="textarea"
              outlined
              readonly
              label="JSON"
            />
          </o-card-text>
          <o-card-actions>
            <o-spacer />
            <o-btn color="primary" @click="closeDialog()"> Close </o-btn>
          </o-card-actions>
        </o-card>
      </o-dialog>
    </o-card-text>
  </o-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      viewedItem: {},
      dialog: false,
      options: {},
      search_action: '',
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Datetime', value: 'date' },
        { text: 'Action', value: 'action' },
        { text: 'Context', value: 'context' },
        { text: 'View Context', value: 'view_context' },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        this.updatePaginationInfo({
          page: this.options.page,
        });
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchAuditLog(this.options.page);
  },
  computed: {
    ...mapGetters('releasesCrusader', [
      'getAuditLog',
      'getLoading',
      'getAuditLogCount',
      'getAuditLogPage',
    ]),
  },
  methods: {
    ...mapActions('releasesCrusader', [
      'fetchAuditLog',
      'setAuditLogSearch',
      'updatePaginationInfo',
    ]),
    viewItem(item) {
      this.viewedItem = item;
      this.dialog = true;
    },
    closeDialog() {
      this.viewedItem = {};
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
.truncate {
  width: 25vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
