<template>
  <o-row>
    <o-col cols="12">
      <o-data-table
        height="calc(100vh - 350px)"
        :loading="getLoading"
        :headers="headers"
        :search="search"
        :items="getFilteredPublishers"
        :footer-props="{
          'show-first-last-page': true,
          'items-per-page-options': [10, 20, 30, 50, 100],
        }"
        :class="archived ? 'grey lighten-2' : 'white'"
        hide-default-header
        :custom-filter="customFilter"
      >
        <template #top>
          <o-row><o-divider /></o-row>
          <o-row align="center" justify="space-around" class="white">
            <o-col cols="3" class="text-center">
              <o-btn
                color="primary"
                @click="addPublisher(getDefaultPublisher())"
              >
                {{ $t('buttons.publishers.add') }}
                <o-icon dark right> add_box </o-icon>
              </o-btn>
            </o-col>
            <o-divider vertical inset />
            <o-col cols="6">
              <o-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('buttons.general.search')"
                single-line
                hide-details
              />
            </o-col>
            <o-col cols="2" class="d-flex justify-end">
              <o-switch
                v-model="archived"
                label="Archived"
                @change="toggleArchived"
              />
            </o-col>
          </o-row>
          <o-row><o-divider /></o-row>
          <o-row align="center" justify="space-between" class="white">
            <o-col cols="2">
              <o-card-title>
                {{ $tc('buttons.general.filter', 2) }}:
              </o-card-title>
            </o-col>
            <o-col cols="9">
              <o-row>
                <o-col
                  v-for="(filter, index) in filterList"
                  :key="index"
                  cols="3"
                >
                  <o-chip color="primary">
                    <o-text-field
                      v-model="filter.search"
                      dark
                      :label="filter.label"
                      single-line
                    />
                    <o-icon @click="removeFilterItem(filter, index)">
                      close
                    </o-icon>
                  </o-chip>
                </o-col>
              </o-row>
            </o-col>
            <o-col cols="1">
              <o-menu bottom left offset-x>
                <template #activator="{ on }">
                  <o-btn color="primary" v-on="on">
                    <o-icon dark> filter_list </o-icon>
                  </o-btn>
                </template>
                <o-list color="primary" dark>
                  <o-list-item
                    v-for="(item, index) in getFiltersList"
                    :key="index"
                    @click="addFilterItem(item)"
                  >
                    <o-list-item-title>{{ item.label }}</o-list-item-title>
                  </o-list-item>
                </o-list>
              </o-menu>
            </o-col>
          </o-row>
          <o-row><o-divider /></o-row>
        </template>
        <template #item.sales_manager_user_id="{ item }">
          {{ formatAgentEmailById({ id: item.sales_manager_user_id }) }}
        </template>
        <template #item.agent_user_id="{ item }">
          {{ formatAgentEmailById({ id: item.agent_user_id }) }}
        </template>
        <template #item.active="{ item }">
          <o-tooltip bottom>
            <template #activator="{ on }">
              <o-icon v-if="item.active === true" color="green" v-on="on">
                mdi-check
              </o-icon>
              <o-icon v-else-if="item.active === false" color="gray" v-on="on">
                mdi-close-octagon-outline
              </o-icon>
            </template>
            <span>{{ item.active ? 'Active' : 'Inactive' }}</span>
          </o-tooltip>
        </template>
        <template #item.mcm_manage_inventory="{ item }">
          <o-tooltip bottom>
            <template #activator="{ on }">
              <o-icon
                v-if="item.mcm_manage_inventory === true"
                color="green"
                v-on="on"
              >
                mdi-check
              </o-icon>
              <o-icon
                v-else-if="item.mcm_manage_inventory === false"
                color="gray"
                v-on="on"
              >
                mdi-close-octagon-outline
              </o-icon>
            </template>
            <span>{{ item.mcm_manage_inventory ? 'Active' : 'Inactive' }}</span>
          </o-tooltip>
        </template>
        <template #item.action="{ item }">
          <div style="white-space: nowrap">
            <o-tooltip bottom>
              <template #activator="{ on }">
                <o-btn depressed icon v-on="on" @click="openDialog(item)">
                  <o-icon small> edit </o-icon>
                </o-btn>
              </template>
              <span>Edit Publisher</span>
            </o-tooltip>
            <o-tooltip bottom>
              <template #activator="{ on }">
                <o-btn
                  depressed
                  icon
                  :href="item.data_studio_url"
                  target="_blank"
                  v-on="on"
                >
                  <o-icon
                    small
                    :color="linkColor(item.data_studio_url || false)"
                  >
                    signal_cellular_alt
                  </o-icon>
                </o-btn>
              </template>
              <span>Data Studio Link</span>
            </o-tooltip>
            <o-tooltip bottom>
              <template #activator="{ on }">
                <o-btn
                  depressed
                  icon
                  :href="`${gamUrl}${item.gam_network_id}#home`"
                  target="_blank"
                  v-on="on"
                >
                  <img
                    src="../../../../public/admanager-logo.png"
                    alt=""
                    height="15"
                  />
                </o-btn>
              </template>
              <span>Google Ad Manager</span>
            </o-tooltip>
            <o-tooltip bottom>
              <template #activator="{ on }">
                <o-btn
                  depressed
                  icon
                  @click="openDialog(item, { readOnly: true })"
                  v-on="on"
                >
                  <o-icon small> visibility </o-icon>
                </o-btn>
              </template>
              <span>Overview Publisher</span>
            </o-tooltip>
            <o-tooltip bottom>
              <template #activator="{ on }">
                <o-btn depressed icon v-on="on" @click="addSite(item)">
                  <o-icon small> add </o-icon>
                </o-btn>
              </template>
              <span>Add Site</span>
            </o-tooltip>
            <o-tooltip bottom>
              <template #activator="{ on }">
                <o-btn depressed icon @click="addDPAConfig(item)" v-on="on">
                  <o-icon small> settings </o-icon>
                </o-btn>
              </template>
              <span>Demand Partner Accounts</span>
            </o-tooltip>
            <o-tooltip v-if="!archived" bottom>
              <template #activator="{ on }">
                <o-btn depressed icon @click="onArchive(item)" v-on="on">
                  <o-icon small color="error"> delete </o-icon>
                </o-btn>
              </template>
              <span>Archive publisher</span>
            </o-tooltip>
          </div>
        </template>
      </o-data-table>
      <publisher-dialog
        ref="publishers_dialog"
        :edited-item="editedItem"
        :edited-index="editedIndex"
        :gam_network_name="getGAMNetworkName"
      />
      <o-dialog
        v-model="siteDialogShow"
        persistent
        fullscreen
        max-width="50%"
        @click:outside="closeSiteEntityDialog"
        @keydown.esc="closeSiteEntityDialog"
      >
        <site-form
          :edited-site="editedSiteItem"
          :is-site-new="true"
          :read-only="false"
          :show="siteDialogShow"
          :close="closeSiteEntityDialog"
          :disable-publisher-select="true"
        />
      </o-dialog>
      <publisher-demand-partner-dialog
        ref="publisher_demand_partner_dialog"
        :publisher="editedItem"
      />
      <confirmation-dialog
        ref="confirmation"
        msg="Do you want to archive publisher?"
        @confirm="confirmArchive"
        @close="closeArchive"
      />
    </o-col>
  </o-row>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import store from './../../../store/index';
import PublisherDialog from './PublisherDialog';
import PublisherDemandPartnerDialog from './PublisherDemandParnerDialog';
import { filterMixin } from '@/helpers/filterMixin';
import ConfirmationDialog from '@/components/DesignSystem/ODialog/ConfirmationDialog.vue';
import SiteForm from '@/components/Accounting/Sites/SiteForm.vue';

export default {
  name: 'PublishersTable',
  components: {
    SiteForm,
    PublisherDialog,
    PublisherDemandPartnerDialog,
    ConfirmationDialog,
  },
  mixins: [filterMixin],
  data() {
    return {
      dialog: false,
      itemToRemove: {},
      readOnly: false,
      valid: true,
      search: '',
      editedIndex: -1,
      editedItem: {},
      editedSiteItem: {},
      siteDialogShow: false,
      filterMenuEnabled: false,
      filterList: [],
      archived: false,
      filterOptions: [
        {
          filterField: 'id',
          label: 'ID',
          search: '',
          enabled: false,
        },
        {
          filterField: 'name',
          label: 'Name',
          search: '',
          enabled: false,
        },
        {
          filterField: 'gam_network_id',
          label: 'GAM Network ID',
          search: '',
          enabled: false,
        },
        {
          filterField: 'company_name',
          label: 'Company Name',
          search: '',
          enabled: false,
        },
        {
          filterField: 'sales_manager_user_id',
          label: 'Sales account manager',
          search: '',
          enabled: false,
          custom_filter: ({ element, item }) => {
            return this.filterAgentsFrom({
              element,
              field: item.sales_manager_user_id,
            });
          },
        },
        {
          filterField: 'agent_user_id',
          label: 'Account manager',
          search: '',
          enabled: false,
          custom_filter: ({ element, item }) => {
            return this.filterAgentsFrom({
              element,
              field: item.agent_user_id,
            });
          },
        },
      ],
      headers: [
        {
          text: 'ID',
          value: 'id',
        },
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Reports',
          value: 'active',
        },
        {
          text: 'MCM Manage Inventory',
          value: 'mcm_manage_inventory',
        },
        {
          text: 'GAM Network ID',
          value: 'gam_network_id',
        },
        {
          text: 'Company Name',
          value: 'company_name',
        },
        {
          text: 'Account manager',
          value: 'agent_user_id',
        },
        {
          text: 'Sales account manager',
          value: 'sales_manager_user_id',
        },
        {
          text: 'Action',
          value: 'action',
          sortable: false,
          filterable: false,
          align: 'center',
        },
      ],
      gamUrl: 'https://admanager.google.com/',
    };
  },
  mounted() {
    store.dispatch('context/fetchAgents');
    store.dispatch('publishers/fetchPublishers');
    store.dispatch('publishers/getPennylaneClients');
  },
  computed: {
    getFilteredPublishers() {
      return this.getPublishers.filter(this.filterItem);
    },
    ...mapGetters('publishers', [
      'getLoading',
      'getPublishers',
      'getDefaultPublisher',
      'getGAMNetworkName',
    ]),
    ...mapGetters('siteEntities', ['getDefaultSiteEntity']),
    ...mapGetters('context', ['getAgents']),
  },
  methods: {
    ...mapActions('publishers', [
      'fetchPublishers',
      'deletePublisher',
      'fetchPublisherCountries',
      'fetchAllCountries',
    ]),
    ...mapMutations('publishers', [
      'setPennylaneMappingPublisher',
      'setPublisherCountries',
    ]),
    addPublisher(item) {
      this.editedIndex = -1;
      this.editedItem = { ...item };
      this.$refs.publishers_dialog.readOnly = false;
      this.$refs.publishers_dialog.show = true;
      this.setPublisherCountries({});
      this.fetchAllCountries();
    },
    openDialog(item, { readOnly = false } = {}) {
      this.editedIndex = this.getPublishers.indexOf(item);
      this.editedItem = { ...item };

      // If item.id is 0 then it's a new publisher dialog - there cannot be any existing countries
      if (this.editedItem?.id > 0 && this.editedItem?.publisher_code) {
        this.fetchPublisherCountries(this.editedItem.publisher_code);
      }
      this.fetchAllCountries();

      this.setPennylaneMappingPublisher({
        customer: {
          pennylane_source_id: null,
        },
        supplier: {
          pennylane_source_id: null,
        },
      });

      this.editedItem.gam_network_id?.trim?.() &&
        store.dispatch('publishers/getGAMNetworkName', item.id);

      store.dispatch(
        'publishers/getPennylaneClientMappingByPublisher',
        item.id,
      );
      this.$refs.publishers_dialog.readOnly = readOnly;
      this.$refs.publishers_dialog.show = true;
    },
    addSite(item) {
      this.editedSiteItem = this.getDefaultSiteEntity();
      this.editedSiteItem.publisher_id = item.id;
      this.siteDialogShow = true;
    },
    closeSiteEntityDialog() {
      this.siteDialogShow = false;
    },
    addDPAConfig(item) {
      store.dispatch('publishers/selectPublisher', item);
      store.dispatch('publishers/fetchPDPAForPublisher');
      this.$refs.publisher_demand_partner_dialog.readOnly = false;
      this.$refs.publisher_demand_partner_dialog.show = true;
      this.$refs.publisher_demand_partner_dialog.disable_select = true;
    },
    onArchive(item) {
      this.itemToRemove = item;
      this.$refs.confirmation.open();
    },
    confirmArchive() {
      this.deletePublisher(this.itemToRemove);
      this.$refs.confirmation.close();
    },
    closeArchive() {
      this.$refs.confirmation.close();
    },
    linkColor(param = false) {
      if (param) return 'blue';
      return 'grey';
    },
    toggleArchived() {
      this.search = '';
      this.fetchPublishers(this.archived);
    },
    findAgentsIdByEmail({ google_name }) {
      if (this.getAgents?.length === 0) store.dispatch('context/fetchAgents');
      return this.getAgents
        .filter(({ google_name: r_google_name }) =>
          r_google_name?.toLowerCase?.()?.includes?.(google_name.toLowerCase()),
        )
        .map(({ id }) => id);
    },
    formatAgentEmailById({ id }) {
      if (this.getAgents?.length === 0) store.dispatch('context/fetchAgents');
      const [agent] = this.getAgents.filter(({ id: r_id }) => r_id === id);
      return agent?.google_name;
    },
    filterAgentsFrom({ element, field }) {
      if (!element.search) return true;
      const agents = this.findAgentsIdByEmail({
        google_name: element.search,
      });

      return agents.includes(field);
    },
    customFilter(value, search, item) {
      const gam_network_id_search = item.gam_network_id === +search;
      return (
        `${item.id}`.includes(search) ||
        value?.toLowerCase?.().includes?.(search.toLowerCase()) ||
        value === search ||
        gam_network_id_search ||
        this.filterAgentsFrom({
          element: { search },
          field: item.agent_user_id,
        }) ||
        this.filterAgentsFrom({
          element: { search },
          field: item.sales_manager_user_id,
        })
      );
    },
  },
};
</script>

<style scoped></style>
