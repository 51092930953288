import { render, staticRenderFns } from "./OListItem.vue?vue&type=template&id=19291fb0&scoped=true"
import script from "./OListItem.vue?vue&type=script&lang=js"
export * from "./OListItem.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19291fb0",
  null
  
)

export default component.exports