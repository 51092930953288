<template>
  <o-row align="center" justify="center">
    <o-col cols="12">
      <o-row justify="center" align="center">
        <o-col cols="5">
          <o-row justify="center" align="center">
            <o-col>
              <o-card-title> Auto-invoices Turnover Publisher </o-card-title>
            </o-col>
          </o-row>
          <o-row>
            <o-col cols="12">
              <o-row align="center" justify="center" class="pt-5 pb-5">
                <o-col cols="6">
                  <o-row align="center" justify="center">
                    <o-card-title>Net 30:</o-card-title>
                    <o-chip
                      :color="
                        mapFinancialPaymentColor(
                          getPaymentStatusInfo.opti_net30.status,
                        )
                      "
                    >
                      {{
                        getStatusName(getPaymentStatusInfo.opti_net30.status)
                      }}
                    </o-chip>
                  </o-row>
                </o-col>
                <o-col cols="6">
                  <payment-info-action-buttons
                    class="text-center"
                    :payment-info="getPaymentStatusInfo.opti_net30"
                    dpa_type="optidigital"
                    payment_term="NET 30"
                  />
                </o-col>
              </o-row>
            </o-col>
          </o-row>
          <o-divider />
          <o-row>
            <o-col cols="12">
              <o-row align="center" justify="center" class="pt-5 pb-5">
                <o-col cols="6">
                  <o-row align="center" justify="center">
                    <o-card-title>Net 60:</o-card-title>
                    <o-chip
                      :color="
                        mapFinancialPaymentColor(
                          getPaymentStatusInfo.opti_net60.status,
                        )
                      "
                    >
                      {{
                        getStatusName(getPaymentStatusInfo.opti_net60.status)
                      }}
                    </o-chip>
                  </o-row>
                </o-col>
                <o-col cols="6">
                  <payment-info-action-buttons
                    class="text-center"
                    :payment-info="getPaymentStatusInfo.opti_net60"
                    dpa_type="optidigital"
                    payment_term="NET 60"
                  />
                </o-col>
              </o-row>
            </o-col>
          </o-row>
        </o-col>
        <o-divider vertical />
        <o-col cols="5">
          <o-row justify="center" align="center">
            <o-col>
              <o-card-title> Invoice commission Opti Digital </o-card-title>
            </o-col>
          </o-row>
          <o-row>
            <o-col cols="12">
              <o-row align="center" justify="center" class="pt-5 pb-5">
                <o-col cols="6">
                  <o-row align="center" justify="center">
                    <o-card-title>Net 30:</o-card-title>
                    <o-chip
                      :color="
                        mapFinancialPaymentColor(
                          getPaymentStatusInfo.pub_net30.status,
                        )
                      "
                    >
                      {{ getStatusName(getPaymentStatusInfo.pub_net30.status) }}
                    </o-chip>
                  </o-row>
                </o-col>
                <o-col cols="6">
                  <payment-info-action-buttons
                    class="text-center"
                    :payment-info="getPaymentStatusInfo.pub_net30"
                    dpa_type="publisher"
                    payment_term="NET 30"
                  />
                </o-col>
              </o-row>
            </o-col>
          </o-row>
          <o-divider />
          <o-row>
            <o-col cols="12">
              <o-row align="center" justify="center" class="pt-5 pb-5">
                <o-col cols="6">
                  <o-row align="center" justify="center">
                    <o-card-title>Net 60:</o-card-title>
                    <o-chip
                      :color="
                        mapFinancialPaymentColor(
                          getPaymentStatusInfo.pub_net60.status,
                        )
                      "
                    >
                      {{ getStatusName(getPaymentStatusInfo.pub_net60.status) }}
                    </o-chip>
                  </o-row>
                </o-col>
                <o-col cols="6">
                  <payment-info-action-buttons
                    class="text-center"
                    :payment-info="getPaymentStatusInfo.pub_net60"
                    dpa_type="publisher"
                    payment_term="NET 60"
                  />
                </o-col>
              </o-row>
            </o-col>
          </o-row>
        </o-col>
      </o-row>
    </o-col>
  </o-row>
</template>

<script>
import PaymentInfoActionButtons from './PaymentInfoActionButtons.vue';
import { mapStatusColor } from '@/helpers/mapStatusColorMixin';
import { mapGetters } from 'vuex';

export default {
  components: { PaymentInfoActionButtons },
  mixins: [mapStatusColor],
  computed: {
    ...mapGetters('turnoverFinal', ['getPaymentStatusInfo']),
  },

  methods: {
    getStatusName(status) {
      const sanitized = status?.toLowerCase().trim();
      return (
        {
          ['pending_validation']: 'Pending Validation',
          ['validated']: 'Validated',
          ['invoiced']: 'Invoiced',
          ['invoiced_draft']: 'Invoiced Draft',
          ['payment_done']: 'Payment Done',
          ['invoice_sent']: 'Invoice Sent',
        }[sanitized] ?? 'Not Available'
      );
    },
  },
};
</script>

<style></style>
