import axios from 'axios';

const store = {
  namespaced: true,

  state: {
    loading: true,
    info: {
      startDate: '',
      endDate: '',
      itemsPerPage: 30,
      page: 1,
      publisherFilter: [],
      site_ids: [],
      abTestStatus: 'RUNNING',
      agent_ids: '',
    },
    logCount: 0,
    revisions: [],
    status: [
      {
        text: 'In definition',
        value: 'IN_DEFINITION',
      },
      {
        text: 'Running',
        value: 'RUNNING',
      },
      {
        text: 'Winner selected',
        value: 'WINNER_SELECTED',
      },
      {
        text: 'Finished',
        value: 'FINISHED',
      },
    ],
  },

  getters: {
    getLoading: (state) => {
      return state.loading;
    },
    getRevisions: (state) => {
      return state.revisions;
    },
    getInfoItemsPerPage: (state) => {
      return state.info.itemsPerPage;
    },
    getLogCount: (state) => {
      return state.logCount;
    },
    getStatuses: (state) => {
      return state.status;
    },
  },

  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
    setRevisions(state, revisions) {
      state.revisions = revisions;
    },
    setSearchData(state, payload) {
      state.info.startDate = payload.length ? payload[0].replace(/-/g, '') : '';
      state.info.endDate = payload.length ? payload[1].replace(/-/g, '') : '';
    },
    setPaginationInfo(state, payload) {
      state.info.page = payload.page;
      state.info.itemsPerPage = payload.itemsPerPage;
    },
    setLogsCount(state, payload) {
      state.logCount = payload;
    },
    setPublisherName(state, payload) {
      state.info.publisherFilter = payload;
    },
    setSite(state, payload) {
      state.info.site_ids = payload;
    },
    setStatus(state, payload) {
      state.info.abTestStatus = payload;
    },
    setAgent(state, payload) {
      state.info.agent_ids = payload;
    },
  },

  actions: {
    async fetchRevisions({ commit, state }) {
      commit('setLoading', true);
      return new Promise((resolve) => {
        const queryParams = new URLSearchParams();
        for (const key in state.info) {
          if (
            state.info[key] &&
            key !== 'publisherFilter' &&
            key !== 'site_ids'
          ) {
            queryParams.append(key, state.info[key]);
          }
          if (Array.isArray(state.info[key])) {
            state.info[key].map((i) => {
              queryParams.append(key, i);
            });
          }
        }
        axios
          .get('/tags_manager/ab_tests', {
            params: queryParams,
          })
          .then((response) => {
            commit('setRevisions', response.data.items);
            commit('setLogsCount', response.data.items_count);
            commit('setLoading', false);
          });
        resolve();
      });
    },
    async setSearchDataAction({ commit, dispatch }, data) {
      return new Promise((resolve) => {
        commit('setLoading', true);
        commit('setSearchData', data);
        dispatch('fetchRevisions');
        resolve();
      });
    },
    async updatePaginationInfo({ commit, dispatch }, data) {
      return new Promise((resolve) => {
        commit('setLoading', true);
        commit('setPaginationInfo', {
          page: data.page,
          itemsPerPage: data.itemsPerPage,
        });
        dispatch('fetchRevisions');
        resolve();
      });
    },
    async fetchFilter({ commit, dispatch }, { data, name }) {
      return new Promise((resolve) => {
        commit('setLoading', true);
        commit(name, data);
        dispatch('fetchRevisions');
        resolve();
      });
    },
  },
};

export default store;
